import baseUrl from "../../config";

export const fetchFessteMartOrders = async () => {
    try {
        const response = await fetch(`${baseUrl}fmOrders`);
        const data = await response.json();

        // Map the API response to match the columns
        const mappedData = data.orders?.map((order, index) => ({
            siNo: index + 1,
            id: order?._id, // Order ID
            orderId: order?.orderId || 'N/A', // Razorpay Order ID
            customerName: order?.userId?.fullName || 'Unknown', // Customer Name
            customerEmail: order?.userId?.email || 'Unknown', // Customer Email
            orderDate: order?.createdAt, // Order Date (Same as createdAt)
            customerPhoneNumber: order?.userId?.phoneNumber || 'Unknown', // Customer Phone Number
            businessName: order?.fessteMartId?.businessName || 'Unknown', // Fesste Mart Name
            businessEmail: order?.fessteMartId?.businessEmail || 'Unknown', // Fesste Mart Email
            businessPhoneNumber: order?.fessteMartId?.businessPhoneNumber || 'Unknown', // Fesste Mart Phone Number
            paymentStatus: order?.paymentStatus || 'Unknown', // Payment Status
            fessteMartServices: order?.paymentScheduleId?.services[0]?.serviceId?.typeName,
            quantity: order?.paymentScheduleId?.services[0]?.serviceId?.qty || 1,
            orderStatus: order?.status || 'Unknown', // Order Status
            totalAmount: order?.totalAmount?.toFixed(2),
            createdAt: new Date(order?.createdAt).toLocaleDateString(), // Order Creation Date
            orderDate: new Date(order?.createdAt).toLocaleDateString(), // Order Date (Same as createdAt)
            userId: order?.userId?._id
        }));
        return mappedData;
    } catch (error) {
        console.error('Error fetching orders:', error);
        throw error;
    }
};

// src/api/orders/orderDetails.js
export const fetchFessteMartOrderDetails = async (orderId) => {
    const response = await fetch(`${baseUrl}fmOrders/${orderId}`);
    if (!response.ok) {
        throw new Error('Failed to fetch order details');
    }
    const data = await response.json();
    return data;
};


export const fetchFessteMartOrdersByBusinessType = async (businessType) => {
    try {
        const response = await fetch(`${baseUrl}fmOrders?mainBusiness=${businessType}`);
        const data = await response.json();

        // Map the API response to match the columns
        const mappedData = data?.data?.map((order, index) => ({
            siNo: index + 1,
            id: order?._id, // Order ID
            orderId: order?.orderId || 'N/A', // Razorpay Order ID
            customerName: order?.userId?.fullName || 'Unknown', // Customer Name
            customerEmail: order?.userId?.email || 'Unknown', // Customer Email
            orderDate: order?.createdAt, // Order Date (Same as createdAt)
            customerPhoneNumber: order?.userId?.phoneNumber || 'Unknown', // Customer Phone Number
            businessName: order?.fessteMartId?.businessName || 'Unknown', // Fesste Mart Name
            businessEmail: order?.fessteMartId?.businessEmail || 'Unknown', // Fesste Mart Email
            businessPhoneNumber: order?.fessteMartId?.businessPhoneNumber || 'Unknown', // Fesste Mart Phone Number
            paymentStatus: order?.paymentStatus || 'Unknown', // Payment Status
            fessteMartServices: order?.paymentScheduleId?.services[0]?.serviceId?.typeName,
            quantity: order?.paymentScheduleId?.services[0]?.serviceId?.qty || 1,
            orderStatus: order?.status || 'Unknown', // Order Status
            totalAmount: order?.totalAmount?.toFixed(2),
            createdAt: new Date(order?.createdAt).toLocaleDateString(), // Order Creation Date
            orderDate: new Date(order?.createdAt).toLocaleDateString(), // Order Date (Same as createdAt)
            userId: order?.userId?._id
        }));
        
        return mappedData;
    } catch (error) {
        console.error('Error fetching orders:', error);
        throw error;
    }
};


import React from 'react';
import { Box, Typography, Divider } from '@mui/material';

const CompanyDetails = () => {
    return (
        <Box sx={{ textAlign: 'left', marginTop: 4 }}>
            <Divider sx={{ marginY: 1, borderColor: '#000' }} />

            <Typography variant="body1">
                <span style={{ fontWeight: 'bold' }}>For </span>
                <span style={{ fontWeight: 'bold', color: '#6A1B9A' }}>SELFEEY INFOTECH PRIVATE LIMITED</span>
            </Typography>


            <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
                <span style={{ fontWeight: 'bold' }}>PAN:</span> ABJCS3745D
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
                <span style={{ fontWeight: 'bold' }}>CIN:</span> U72900KA2022PTC166004
            </Typography>
            <Typography variant="body2">
                <span style={{ fontWeight: 'bold' }}>GST:</span> 29ABJCS3745D1Z5
            </Typography>
        </Box>
    );
};

export default CompanyDetails;

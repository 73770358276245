import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Button } from '@mui/material';
import AdminDrawer from '../../../../components/AdminDrawer/AdminDrawer';
import PayoutTable from '../../../../components/DataTables/PayoutTables/PayoutTable';

const catererData = [
    { id: 1, name: "Tandoori Delights", phoneNumber: "9876543210", totalOrders: 15, totalToBePaid: "₹1500" },
    { id: 2, name: "Spice Garden", phoneNumber: "8765432109", totalOrders: 10, totalToBePaid: "₹1000" },
    { id: 3, name: "Mughal's Feast", phoneNumber: "7654321098", totalOrders: 12, totalToBePaid: "₹1200" },
    { id: 4, name: "Curry House", phoneNumber: "6543210987", totalOrders: 20, totalToBePaid: "₹2000" },
    { id: 5, name: "South Indian Diner", phoneNumber: "5432109876", totalOrders: 8, totalToBePaid: "₹800" },
    { id: 6, name: "Punjabi Dhaba", phoneNumber: "4321098765", totalOrders: 18, totalToBePaid: "₹1800" },
    { id: 7, name: "Samosa Corner", phoneNumber: "3210987654", totalOrders: 5, totalToBePaid: "₹500" },
    { id: 8, name: "Chettinad Kitchen", phoneNumber: "2109876543", totalOrders: 14, totalToBePaid: "₹1400" },
    { id: 9, name: "Delhi Biryani", phoneNumber: "1098765432", totalOrders: 25, totalToBePaid: "₹2500" },
    { id: 10, name: "Chinese Express", phoneNumber: "0198765432", totalOrders: 22, totalToBePaid: "₹2200" },
];

const FlowersPayout = () => {
    const navigate = useNavigate();

    const handleMakePayment = (row) => {
        navigate(`/payment-dashboard/caterer/${encodeURIComponent(row.name)}`);
    };

    const columns = [
        { field: 'name', headerName: 'Caterer Name', flex: 1 },
        { field: 'phoneNumber', headerName: 'Phone Number', flex: 1 },
        { field: 'totalOrders', headerName: 'Total Orders', flex: 1 },
        { field: 'totalToBePaid', headerName: 'Total to be Paid', flex: 1 },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1,
            renderCell: (params) => (
                <Button
                    variant="outlined"
                    color="success"
                    onClick={() => handleMakePayment(params.row)}
                >
                    <Typography fontSize='14px' textTransform='none' fontWeight='bold'>Make Payment</Typography>
                </Button>
            ),
        },
    ];

    return (
        <div style={{ backgroundColor: '#f2f2f2', height: '100vh' }}>
            <AdminDrawer>
                <div style={{ padding: '16px' }}>
                    <Typography variant='h4' fontWeight='bolder'>Flowers Payout</Typography>
                    <PayoutTable
                        rows={catererData}
                        columns={columns}
                    />
                </div>
            </AdminDrawer>
        </div>
    );
}

export default FlowersPayout;

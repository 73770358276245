import React, { useState } from "react";
import { TextField, Button, Snackbar } from "@mui/material";

const MarketingOrderCell = ({ catererId, handleMarketingOrderChange, marketingOrder, handleSubmit, snackbarMessage }) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // Correct onChange handler
  const onMarketingOrderChange = (event) => {
    const value = event.target.value;
    handleMarketingOrderChange(catererId, value); // Pass the catererId and new value to the handler
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
      <TextField
        value={marketingOrder}
        onChange={onMarketingOrderChange} // Call the modified handler
        width="50%"
        size="small"
        variant="outlined"
        type="number"
        placeholder="Marketing Order"
      />
      <Button
        variant="contained"
        onClick={() => handleSubmit(catererId)} // Call handleSubmit with catererId
        style={{ marginLeft: "10px" }}
      >
        Submit
      </Button>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </div>
  );
};

export default MarketingOrderCell;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AdminDrawer from "../../../components/AdminDrawer/AdminDrawer";
import { fetchCaterers } from "../../../api/servicePartners/CaterersApis";
import {
  IconButton,
  Tooltip,
  Typography,
  styled,
  Paper,
  Box,
  Snackbar,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import { useDialog } from "../../../hooks/useDialog";
import { renderCellWithMore } from "../../../components/ResuableComponents/ViewMoreComponents/ViewMorePopup";
// import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddIcon from "@mui/icons-material/Add";
import DataTable from "../../../components/DataTables/DataTable";
// import ConfirmDialog from "../../../components/ResuableComponents/ConfirmDialog";
// import { deleteCatererById } from "../../../api/servicePartners/CaterersApis";
import CustomSnackbar from "../../../components/CustomeSnackBar/CustomSnackbar";
import MarketingOrderCell from "../../../components/ResuableComponents/MarkettingOrder/MarkettingOrderCell";
import BASE_URL from "../../../config";
import BreadcrumbsComponent from "../../../components/ResuableComponents/BreadCrumb";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; 

const ButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: theme.spacing(2),
}));

const Caterers = () => {
  const { dialogContent, dialogOpen, handleOpenDialog, handleCloseDialog } =
    useDialog();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [openDialog, setOpenDialog] = useState(false);
  // const [selectedRow, setSelectedRow] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpenClipCard, setSnackbarOpenClipCard] = useState(false);
  const [marketingOrders, setMarketingOrders] = useState({});

  const handleMarketingOrderChange = (catererId, value) => {
    setMarketingOrders((prev) => ({ ...prev, [catererId]: value })); // Update the specific catererId's value
  };

  const navigate = useNavigate();

  const handleAddCatererClick = () => {
    navigate("/add-caterers");
  };

  const handleViewClick = (catererId, serviceProviderId) => {
    navigate(`/caterer-details/${serviceProviderId}`, {
      state: { catererId, serviceProviderId }, // Passing both catererId and serviceProviderId
    });
  };

  // Fetch the caterers' data
  useEffect(() => {
    const getCaterers = async () => {
      try {
        const response = await fetchCaterers(); // Make sure this returns the full response
        const caterers = response?.caterers || []; // Access the caterers array from the response

        // Map the data only if caterers is an array
        const mappedData = caterers.map((item, index) => ({
          id: index + 1,
          catererId: item?._id,
          serviceProviderId: item?.catID,
          businessName: item?.businessName,
          address: `${item?.address?.addressLine1}, ${item?.address?.addressLine2},${item?.address?.landmark},${item?.address?.city}, ${item?.address?.postalCode},${item?.address?.state},${item?.address?.country}`,
          contactNumber: item?.businessPhoneNumber,
          email: item?.serviceProvider?.spEmail,
          ownerName: item?.serviceProvider?.spName,
          ownerImage: item?.ownerPhoto,
          ownerNumber: item?.serviceProvider?.spPhone,
          ownerEmail: item?.serviceProvider?.spEmail,
          registeredOn: new Date(item?.createdAt).toLocaleString(),
          createdOn: new Date(item?.createdAt).toLocaleString(),
          businessImage: Array.isArray(item?.businessProfilePhoto) && item?.businessProfilePhoto.length > 0
          ? item?.businessProfilePhoto[0] // Use the first image if the array is not empty
          : item?.businessProfilePhoto, // Fallback to a placeholder
          fessteApproach: item?.fessteApproach,
          marketingOrder: item?.marketingOrder,
        }));

        setData(mappedData);

        const initialMarketingOrders = {};
        mappedData.forEach((item) => {
          initialMarketingOrders[item.catererId] = item.marketingOrder || ""; // Set initial value
        });
        setMarketingOrders(initialMarketingOrders); // Initialize marketingOrders state
      } catch (error) {
        console.error("Failed to fetch caterers:", error);
      } finally {
        setLoading(false);
      }
    };

    getCaterers();
  }, []);

  const handleEditClick = (rowData) => {
    navigate(`/edit-caterers/${rowData}`, { state: rowData });
  };

  // const handleDeleteClick = (rowData) => {
  //   setSelectedRow(rowData);
  //   setOpenDialog(true);
  // };

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
    setSnackbarOpenClipCard(true); // Show Snackbar when text is copied
  };

  const handleSnackbarCloseClipCard = () => {
    setSnackbarOpenClipCard(false);
  };

  // const confirmDelete = async (deleteReason) => {
  //   if (selectedRow) {
  //     try {
  //       await deleteCatererById(selectedRow.catererId, deleteReason); // Pass the deleteReason here
  //       // Update the data by filtering out the deleted caterer
  //       setData(data.filter((row) => row.catererId !== selectedRow.catererId));
  //       setSnackbarMessage("Caterer deleted successfully");
  //       setSnackbarOpen(true); // Show snackbar for success
  //     } catch (error) {
  //       console.error("Error deleting Caterer:", error);
  //       setSnackbarMessage("Failed to delete Caterer");
  //       setSnackbarOpen(true); // Show snackbar for error
  //     } finally {
  //       setOpenDialog(false);
  //     }
  //   }
  // };

  // const handleCloseDialog1 = () => {
  //   setOpenDialog(false);
  // };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleBackClick = () => {
    navigate(-1); // This will take the user back to the previous page
  };

  const handleSubmit = async (catererId) => {
    try {
      const response = await fetch(
        `${BASE_URL}dashboard/addTopCaterer/${catererId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            marketingOrder: parseInt(marketingOrders[catererId]),
          }), // Send the correct marketing order
        }
      );

      if (response.ok) {
        setSnackbarMessage("Marketing order updated successfully");
        // Optionally, refetch updated caterers data
      } else {
        setSnackbarMessage("Failed to update marketing order");
      }
    } catch (error) {
      console.error("Error updating marketing order", error);
      setSnackbarMessage("Error updating marketing order");
    } finally {
      setSnackbarOpen(true); // Open snackbar after response
    }
  };

  return (
    <div style={{ backgroundColor: "#f2f2f2" }}>
      <AdminDrawer>
        <Box display="flex" alignItems="center">
        <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={handleBackClick}
            sx={{ mr: 2 }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4" fontWeight="bolder" sx={{ mr: 3 }}>
            Caterers
          </Typography>
          <BreadcrumbsComponent />
        </Box>
        <Paper elevation={0} sx={{ padding: "14px", backgroundColor: "#fff" }}>
          <ButtonContainer>
            <Button
              variant="contained"
              color="success"
              startIcon={<AddIcon />}
              onClick={handleAddCatererClick}
            >
              Add Caterer
            </Button>
          </ButtonContainer>
          {loading ? (
            <Typography>Loading...</Typography>
          ) : (
            <DataTable
              rows={data}
              columns={[
                {
                  field: "id",
                  headerName: "Id",
                  minWidth: 10,
                  sortable: false,
                },
                {
                  field: "serviceProviderId",
                  headerName: "Service Provider ID",
                  minWidth: 240,
                  sortable: true,
                  renderCell: (params) => {
                    const text = params.value || ""; // Ensure value is a string

                    return (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleViewClick(
                            params.row.catererId,
                            params.row.serviceProviderId
                          )
                        }
                      >
                        {text}
                      </div>
                    );
                  },
                },
                {
                  field: "businessImage",
                  headerName: "Business Image",
                  type: "image",
                  minWidth: 160,
                  sortable: false,
                  renderCell: (params) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center", // Horizontally center the image
                        alignItems: "center", // Vertically center the image
                        width: "100%", // Ensure the div takes full width of the cell
                        height: "100%", // Ensure the div takes full height of the cell
                      }}
                    >
                      <img
                        src={
                          params.value !== "N/A"
                            ? params.value
                            : "placeholder-image-url.jpg"
                        } // Placeholder for broken images
                        alt="Item"
                        style={{
                          width: "100px", // Fixed width
                          height: "60px", // Fixed height
                          borderRadius: "4px", // Rounded corners
                          objectFit: "cover", // Cover the area while maintaining aspect ratio
                        }}
                      />
                    </div>
                  ),
                },
                {
                  field: "businessName",
                  headerName: "Business Name",
                  minWidth: 250,
                  sortable: true,
                  renderCell: (params) => {
                    const text = params.value || ""; // Ensure value is a string

                    return (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleViewClick(
                            params.row.catererId,
                            params.row.serviceProviderId
                          )
                        }
                      >
                        {text}
                      </div>
                    );
                  },
                },
                {
                  field: "address",
                  headerName: "Address",
                  minWidth: 300,
                  flex: 1, // Makes the column responsive based on available space
                  renderCell: (params) => {
                    const text = params.value || ""; // Ensure value is a string
                    return (
                      <Box
                        sx={{
                          display: "flex", // Use flexbox for alignment
                          justifyContent: "space-between", // Align text on the left and icon on the right
                          alignItems: "center", // Align vertically to the center
                          whiteSpace: "normal", // Allow the text to wrap to the next line
                          wordWrap: "break-word", // Break long words into the next line
                          overflow: "visible", // Ensure the content isn't hidden
                          textOverflow: "clip", // Avoid ellipsis and display all text
                          lineHeight: 1.2, // Adjust line height for better readability
                          height: "100%", // Ensure the content fills the cell vertically
                          padding: "12px 10px", // Add padding for better spacing within the cell
                        }}
                      >
                        <Box sx={{ flexGrow: 1, paddingRight: "10px" }}>
                          {/* Render the address value with "Show More" functionality */}
                          {renderCellWithMore(text, handleOpenDialog)}
                        </Box>
                        <IconButton
                          aria-label="copy to clipboard"
                          size="small"
                          onClick={() => handleCopy(params.value)} // Trigger copy action
                        >
                          <ContentCopyIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    );
                  },
                },
                {
                  field: "contactNumber",
                  headerName: "Contact Number",
                  minWidth: 200,
                  sortable: true,
                },
                {
                  field: "email",
                  headerName: "Email",
                  minWidth: 250,
                  sortable: true,
                  renderCell: (params) => {
                    const text = params.value || ""; // Ensure value is a string
                    return renderCellWithMore(text, handleOpenDialog);
                  },
                },
                {
                  field: "ownerName",
                  headerName: "Owner Name",
                  minWidth: 200,
                  sortable: true,
                },
                {
                  field: "ownerImage",
                  type: "image",
                  headerName: "Owner Image",
                  minWidth: 160,
                  sortable: false,
                  renderCell: (params) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center", // Horizontally center the image
                        alignItems: "center", // Vertically center the image
                        width: "100%", // Ensure the div takes full width of the cell
                        height: "100%", // Ensure the div takes full height of the cell
                      }}
                    >
                      <img
                        src={params.value}
                        alt="Owner"
                        style={{
                          width: "45px",
                          height: "45px",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  ),
                },
                {
                  field: "ownerNumber",
                  headerName: "Owner Number",
                  minWidth: 200,
                  sortable: true,
                },
                {
                  field: "registeredOn",
                  headerName: "Registered On",
                  minWidth: 200,
                  sortable: true,
                },
                {
                  field: "fessteApproach",
                  headerName: "Item Creation Request",
                  minWidth: 250,
                  sortable: true,
                  renderCell: (params) => {
                    return params.value ? "Access Granted" : "No Access";
                  },
                },
                {
                  field: "marketingOrder",
                  headerName: "Marketing Order",
                  minWidth: 400,
                  renderCell: (params) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <MarketingOrderCell
                        catererId={params.row.catererId}
                        marketingOrder={
                          marketingOrders[params.row.catererId] || ""
                        }
                        handleMarketingOrderChange={handleMarketingOrderChange}
                        handleSubmit={handleSubmit}
                        snackbarMessage={snackbarMessage}
                      />
                    </div>
                  ),
                },
                {
                  field: "Actions",
                  headerName: "Actions",
                  type: "actions",
                  minWidth: 160,
                  sortable: false,
                  renderCell: (params) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <Tooltip title="View">
                        <IconButton
                          sx={{ color: "#87CEEB", marginRight: "8px" }}
                          onClick={() =>
                            handleViewClick(
                              params.row.catererId,
                              params.row.serviceProviderId
                            )
                          }
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit">
                        <IconButton
                          color="success"
                          onClick={() => handleEditClick(params.row.catererId)}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      {/* <Tooltip title="Delete">
                        <IconButton
                          color="error"
                          onClick={() => handleDeleteClick(params.row)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip> */}
                    </div>
                  ),
                },
              ]}
            />
          )}
        </Paper>
        <Snackbar
          open={snackbarOpenClipCard}
          autoHideDuration={2000} // Snackbar closes after 2 seconds
          onClose={handleSnackbarCloseClipCard}
          message="Copied to clipboard!"
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }} // Position of Snackbar
        />
        {/* <ConfirmDialog
          open={openDialog}
          onClose={handleCloseDialog1}
          onConfirm={confirmDelete}
          title="Confirm Deletion"
          content="Are you sure you want to delete this Caterer?"
        /> */}
        <CustomSnackbar
          open={snackbarOpen}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
          severity={
            snackbarMessage.toLowerCase().includes("successfully")
              ? "success"
              : "error"
          }
        />
        <Dialog open={dialogOpen} onClose={handleCloseDialog}>
          <DialogTitle>Details</DialogTitle>
          <DialogContent>
            <Typography>{dialogContent}</Typography>
          </DialogContent>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </Dialog>
      </AdminDrawer>
    </div>
  );
};

export default Caterers;

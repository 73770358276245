import baseUrl from "../../config";

// src/api/promocodes.js
export const fetchPromoCodes = async (apiUrl) => {
  try {
    const response = await fetch(apiUrl);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data || [];
  } catch (error) {
    console.error('Failed to fetch promo codes:', error);
    return [];
  }
};


// src/api/promocodes.js
export const createPromoCode = async (promoData) => {
  const accessToken = localStorage.getItem('accessToken'); // Retrieve token from localStorage
  try {
    const response = await fetch(`${baseUrl}create_promoCode`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}` // Add bearer token in header
      },
      body: JSON.stringify(promoData),
    });

    if (!response.ok) {
      throw new Error('Failed to create promo code');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error creating promo code:', error);
    return null;
  }
};

// src/api/promocodes/promocodeApi.js

export const updatePromoCode = async (promoCodeId, promoData) => {
  const accessToken = localStorage.getItem('accessToken'); // Retrieve token from localStorage
  try {
    const response = await fetch(`${baseUrl}update_promoCode/${promoCodeId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}` // Add bearer token in header
      },
      body: JSON.stringify(promoData),
    });

    if (!response.ok) {
      throw new Error('Failed to update promo code');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error updating promo code:', error);
    return null;
  }
};

// src/api/promocodeapi.js

export const deletePromoCode = async (promoCodeId) => {
  const accessToken = localStorage.getItem('accessToken'); // Retrieve token from localStorage

  try {
    const response = await fetch(`${baseUrl}delete_promoCode/${promoCodeId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}` // Add bearer token in header
      },
    });

    if (!response.ok) {
      throw new Error('Failed to delete the promo code');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error deleting promo code:', error);
    throw error;
  }
};


// src/api/promocodes.js
export const updatePromoCodeStatus = async (promocodeId, status) => {
  try {
    const response = await fetch(`${baseUrl}/promocodeStatus`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        promocodeId,
        status,
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to update promo code status');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error updating promo code status:', error);
    throw error;
  }
};
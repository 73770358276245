import BASE_URL from "../../../config";

// Function to fetch party hall details by ID using fetch


export const getPartyHallById = async (partyHallId) => {
    const response = await fetch(`${BASE_URL}partyHallSp/${partyHallId}`);
    if (!response.ok) {
        throw new Error('Failed to fetch  Party hall details');
    }
    return await response.json();
  };

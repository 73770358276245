// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media print {
    /* Hide the print button */
    .orderDetails_printButton__NIuSL {
        display: none;
    }
    /* Ensure only the paper content is printed */
    .orderDetails_printableContent__-RXUS, .orderDetails_printableContent__-RXUS * {
        visibility: visible;
    }
    .orderDetails_printableContent__-RXUS {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/ResuableComponents/OrderDetailsComponents/orderDetails.module.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B;QACI,aAAa;IACjB;IACA,6CAA6C;IAC7C;QACI,mBAAmB;IACvB;IACA;QACI,kBAAkB;QAClB,OAAO;QACP,MAAM;QACN,WAAW;IACf;AACJ","sourcesContent":["@media print {\n    /* Hide the print button */\n    .printButton {\n        display: none;\n    }\n    /* Ensure only the paper content is printed */\n    .printableContent, .printableContent * {\n        visibility: visible;\n    }\n    .printableContent {\n        position: absolute;\n        left: 0;\n        top: 0;\n        width: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"printButton": `orderDetails_printButton__NIuSL`,
	"printableContent": `orderDetails_printableContent__-RXUS`
};
export default ___CSS_LOADER_EXPORT___;

import React, { useState, useEffect, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { styled, Paper, TextField, IconButton, Typography, Button ,Box} from '@mui/material';
import { DateRange as DateRangeIcon } from '@mui/icons-material';
import { jsonToCSV } from 'react-papaparse';
import debounce from 'lodash/debounce';
import DateFilterModal from './DateFilterModal';

const TableContainer = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    maxHeight: 'calc(100vh - 100px)',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[0],
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
    },
}));

const SearchContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'stretch',
    },
}));

const NoRowsMessage = styled(Typography)(({ theme }) => ({
    textAlign: 'center',
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
}));

const ReportsTable = ({ rows, columns }) => {
    const [searchText, setSearchText] = useState('');
    const [filteredRows, setFilteredRows] = useState(rows);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const filterRows = useCallback(
        debounce((searchValue, dateRange) => {
            let data = rows;
    
            if (searchValue !== '') {
                const value = searchValue.toLowerCase();
                data = data.filter(row =>
                    columns.some(column =>
                        row[column.field]?.toString().toLowerCase().includes(value)
                    )
                );
            }
    
            if (dateRange?.startDate && dateRange?.endDate) {
                const startDate = new Date(dateRange.startDate);
                const endDate = new Date(dateRange.endDate);
                data = data.filter(row => {
                    const rowDate = new Date(row.date);
                    return rowDate >= startDate && rowDate <= endDate;
                });
            }
    
            setFilteredRows(data);
        }, 300),
        [rows, columns] // <-- Include rows and columns as dependencies
    );
    

    useEffect(() => {
        filterRows(searchText, {}); // Initial filtering without date
    }, [searchText, filterRows]);

    const handleSearch = (event) => {
        setSearchText(event.target.value);
    };

    const handleDownloadCSV = () => {
        try {
            const csvData = jsonToCSV(filteredRows);
            const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.href = url;
            link.setAttribute('download', 'reports.csv');
            link.click();
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error generating CSV:', error);
        }
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const applyDateFilter = (dateRange) => {
        filterRows(searchText, dateRange);
    };

    return (
        <TableContainer>
            <SearchContainer>
                <TextField
                    variant="outlined"
                    size="small"
                    placeholder="Search..."
                    value={searchText}
                    onChange={handleSearch}
                    sx={{
                        width: '300px',
                        marginRight: '10px',
                    }}
                />
                <Button
                    sx={{
                        backgroundColor: 'green',
                        '&:hover': {
                            backgroundColor: 'green', // Keep background color the same on hover
                            opacity: 1, // Ensure button opacity remains unchanged
                        },
                        color: 'white',
                        textTransform: 'none',
                        mr:80
                    }}
                    startIcon={<DateRangeIcon sx={{ color: 'white' }} />}
                    onClick={handleOpenModal}
                >
                    <Typography color='white' textTransform='none' fontSize={14} fontWeight='bold'>
                        Date Filter
                    </Typography>
                </Button>
                 <IconButton onClick={handleDownloadCSV} color="primary">
                    <Button variant='contained'>Export</Button>
                </IconButton>
            </SearchContainer>
            {filteredRows?.length === 0 ? (
                <NoRowsMessage>No matching rows found.</NoRowsMessage>
            ) : (
                <Box sx={{ height: '60vh', width: '90vw' }}>

                        <DataGrid
                            disableColumnResize={true}
                            columnHeaderHeight={33}
                            disableColumnSelector={true}
                            disableColumnSorting={false}
                            disableDensitySelector={true}
                            disableRowSelectionOnClick={true}
                            showCellVerticalBorder={true}
                            rows={filteredRows}
                            localeText={{
                                columnMenuSortAsc: 'Sort A-Z', // Customize ascending sorting label
                                columnMenuSortDesc: 'Sort Z-A', // Customize descending sorting label
                            }}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pageSizeOptions={[10, 25, 50, 100]}
                            getRowHeight={() => 90}
                            style={{ height: '100%', width: '100%' }}
                            sx={{
                                '& .MuiDataGrid-columnHeader': {
                                    fontWeight: 'bold', // Make header text bold
                                    fontSize: '14px',   // Adjust font size
                                },
                                '& .MuiDataGrid-columnHeaderTitle': {
                                    fontWeight: 'bold',
                                },
                                '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
                                    visibility: 'visible',  // Ensure the filter icon is always visible
                                    width: 'auto',
                                    marginRight: '8px',
                                },
                                '& .MuiDataGrid-columnHeader .MuiDataGrid-menuIcon': {
                                    visibility: 'visible', // Ensure the filter icon is always visible
                                    width: '24px',         // Fixed width for the icon to prevent layout shift
                                    height: '24px',        // Fixed height for consistency
                                    marginLeft: '8px',     // Adjust space between the header title and the icon
                                    display: 'inline-block', // Display as inline block to avoid breaking layout
                                    opacity: 1,            // Ensure the icon is fully visible
                                    transition: 'none',     // Remove transition to prevent movement
                                },
                                '& .MuiDataGrid-columnHeader:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)', // Optional: Add hover effect without affecting layout
                                }
                            }}
                        />
                   </Box>
            )}
            <DateFilterModal
                open={isModalOpen}
                handleClose={handleCloseModal}
                applyFilter={applyDateFilter}
            />
        </TableContainer>
    );
};

export default ReportsTable;

import React from 'react';
import { Box, Typography } from '@mui/material';

const OrderHeader = ({ status, orderDate, orderId }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingBottom: 2 }}>
      <Box>
        <Typography variant="h3" fontWeight='bold' color="primary">
          Fesste    
        </Typography>
        <Typography variant="body1">Order Status: {status}</Typography>
        <Typography variant="body2" color="textSecondary">Ordered On: {orderDate}</Typography>
        <Typography variant="body2" color="textSecondary">Invoice Number: INV1232</Typography>

      </Box>
      <Box>
        <Typography variant="body1" color="textSecondary">Order ID #{orderId}</Typography>
      </Box>
    </Box>
  );
};

export default OrderHeader;

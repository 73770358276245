import React from "react";
import { Box } from "@mui/material";
import FessteMartPriceSummaryPayout from "../../../ResuableComponents/OrderDetailsComponents/PayoutOrderDetails/FessteMartPriceSummaryPayout";

const FessteMartPayoutBreakDown = ({ selectedOrder }) => {
  console.log("selectedOrder in mart", selectedOrder);

  // Accessing the orderId data
  const orderIdData = selectedOrder?.orderId || {};

  const paymentData = {
    totalAmount: selectedOrder?.totalAmount || 'N/A',
    initialPayment: selectedOrder?.initialPayment || 'N/A',
    initialPaymentDate: selectedOrder?.initialPaymentDate || 'N/A',
    initialPaymentSchedule: {
      initialPaymentStatus: 'completed', // Fixed value from the response data
      initialPaymentMethod: 'RazorPay',  // Since it’s RazorPay, hardcoding this value
      remainingCashPaymentHandling: null,  // New field
      remainingPaymentMethod: '-',  // Assuming it would be the same method
      remainingInitialPayment: 0,  // From the response
      cashPaymentHandling: null,  // New field
      initialAmountToBeCollected: 0,  // From the response
    },
    initialPaymentDetails: {
      razorpay_order_id: selectedOrder?.initialPaymentSchedule || 'N/A',
    },
    secondPayment: selectedOrder?.secondPayment || 'N/A',
    secondPaymentSchedule: {
      status: '-', // Hardcoded since not explicitly present in order
      secondPaymentMethod: '-', // Assuming similar method for second payment
    },
    secondPaymentDueDate: selectedOrder?.secondPaymentDueDate || 'N/A',
    finalPayment: selectedOrder?.finalPayment || 'N/A',
    finalPaymentDueDate: selectedOrder?.finalPaymentDueDate || 'N/A',

    finalPaymentSchedule: {
      status: '-', // Same as secondPayment status, assumed
      finalPaymentMethod: '-',  // Assumed same method
    },
    paymentStatus: selectedOrder?.paymentStatus || 'N/A',
    fessteFee: selectedOrder?.fessteFee || '0.00',
    gstonFessteFee: selectedOrder?.gstOnFessteFee || '0.00',  // Fixed typo field name
    transactionFee: selectedOrder?.transactionFee || '0.00',
    tds: selectedOrder?.tds || '0.00',
    netPayout: selectedOrder?.netPayout || '0.00',
    shippingAddress: selectedOrder?.deliveryAddress || 'N/A',  // Using deliveryAddress as shipping address
    orderID: selectedOrder?.orderID || 'N/A',
    invoiceID: selectedOrder?.invoiceID || 'N/A',
    payoutId: selectedOrder?.payoutId || 'N/A',
    catPayoutTransactionId: selectedOrder?.catPayoutTransactionId || 'N/A',
    status: selectedOrder?.payoutStatus || 'N/A',
    totalOrderPaymentStatus: selectedOrder?.totalOrderPaymentStatus || 'N/A',
    fmPayoutTransactionId:selectedOrder?.fmPayoutTransactionId
  };




  return (
    <Box mt={4} p={3} bgcolor="#ffffff" borderRadius="8px">
      {/* Price Summary Payout Table */}
      <FessteMartPriceSummaryPayout paymentData={paymentData} />

    </Box>
  );
};

export default FessteMartPayoutBreakDown;

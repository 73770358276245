import React, { useState, useEffect } from "react";
import {
  Box, Button, TextField, Dialog, Typography, Snackbar, CircularProgress,
  Paper, IconButton, Badge, Slide, Stack, Avatar
} from "@mui/material";
import ChatIcon from '@mui/icons-material/Chat';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import BASE_URL from "../../../../config";

const AdminChatBox = ({ type }) => {
  const [comment, setComment] = useState("");
  const [openChatBox, setOpenChatBox] = useState(false);  // Control chatbox visibility
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });
  const [comments, setComments] = useState([]);  // State to store comments
  const [nameError, setNameError] = useState(false);  // State for name validation

  // Fetch comments when component mounts
  const fetchComments = async () => {
    try {
      const response = await fetch(`${BASE_URL}dashboard/getComments/${type}`);
      const data = await response.json();
      setComments(data);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  useEffect(() => {
    fetchComments();  // Fetch comments on component mount
  }, []);

  // Toggle ChatBox visibility
  const toggleChatBox = () => {
    setOpenChatBox(!openChatBox);
  };

  const handleSendComment = async () => {
    // Validate name
    if (!name) {
      setNameError(true);
      return;
    }
    setLoading(true); // Start loading

    try {
      const response = await fetch(`${BASE_URL}dashboard/comments`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: type,
          comment: comment,
          name: name,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to send comment.");
      }

      const data = await response.json();
      console.log("Comment submitted:", data);
      setComment("");
      setName("");
      setNameError(false);
      setSnackbar({ open: true, message: "Comment submitted successfully!", severity: "success" });

      fetchComments();  // Fetch updated comments after submission
    } catch (error) {
      setSnackbar({ open: true, message: "Error submitting comment.", severity: "error" });
      console.error("Error sending comment:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ open: false, message: "", severity: "success" });
  };

  return (
    <Box>
      {/* Floating Chat Icon Button */}
      <IconButton
        onClick={toggleChatBox}
        sx={{
          position: 'fixed',
          bottom: 24,
          right: 24,
          backgroundColor: '#1e88e5',
          color: 'white',
          boxShadow: 3,
          '&:hover': { backgroundColor: '#1565c0' },
          zIndex: 1300,
        }}
        size="large"
      >
        <Badge badgeContent={comments.length} color="error">
          <ChatIcon fontSize="large" />
        </Badge>
      </IconButton>

      {/* Chatbox Floating Paper */}
      {openChatBox && (
        <Slide direction="up" in={openChatBox} mountOnEnter unmountOnExit>
          <Paper
            elevation={6}
            sx={{
              position: 'fixed',
              bottom: 80, // Adjust to position above chat icon
              right: 24,
              width: 320,
              borderRadius: 4,
              backgroundColor: '#f5f5f5',
              boxShadow: 5,
              overflow: 'hidden',
              zIndex: 1300,
            }}
          >
            {/* Top section with FESSTe title and Close button */}
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 2, backgroundColor: '#1565c0', color: 'white' }}>
              <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Metropolis', fontWeight: 'bold', fontSize: '24px', color: '#baf200' }}>
                FESSTE
              </Typography>
              <IconButton onClick={toggleChatBox} sx={{ color: 'white' }}>
                <CloseIcon />
              </IconButton>
            </Box>

            {/* Display comments */}
            {/* Display comments */}
            <Box sx={{ maxHeight: 250, overflowY: 'auto', px: 2, py: 1, display: 'flex', flexDirection: 'column' }}>
              {comments.length > 0 ? (
                comments.slice(0).reverse().map((comment) => (
                  <Paper key={comment._id} sx={{ mb: 1, p: 1, backgroundColor: '#e3f2fd' }}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Avatar sx={{ bgcolor: '#1976d2' }}>{comment?.name?.[0]}</Avatar>
                      <Stack>
                        <Typography variant="subtitle2" sx={{ color: '#1e88e5' }}>
                          {comment.name} - {new Date(comment.createdAt).toLocaleString()}
                        </Typography>
                        <Typography variant="body2">{comment.comment}</Typography>
                      </Stack>
                    </Stack>
                  </Paper>
                ))
              ) : (
                <Typography variant="body2" color="textSecondary">
                  No comments available.
                </Typography>
              )}
            </Box>

            {/* Input field for new comment */}
            <Box sx={{ p: 2, backgroundColor: '#f5f5f5' }}>
              <Stack direction="column" spacing={1}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Your name..."
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    setNameError(false);  // Reset error when typing
                  }}
                  error={nameError}  // Show error state
                  helperText={nameError ? "Name is required" : null}  // Display error message
                  size="small"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: nameError ? 'red' : '#1976d2',  // Change border color on error
                      },
                      '&:hover fieldset': {
                        borderColor: nameError ? 'red' : '#1565c0',
                      },
                    },
                  }}
                />
                <TextField
                  multiline
                  fullWidth
                  variant="outlined"
                  placeholder="Your comment..."
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  rows={2}
                  helperText={`${comment.length}/250 characters`}
                  size="small"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#1976d2',
                      },
                      '&:hover fieldset': {
                        borderColor: '#1565c0',
                      },
                    },
                  }}
                />

                <Button
                  onClick={handleSendComment}
                  disabled={!comment || !name || loading}
                  variant="contained"
                  color="primary"
                  endIcon={loading ? <CircularProgress size={20} /> : <SendIcon />}
                  fullWidth
                  sx={{
                    backgroundColor: '#1976d2',
                    '&:hover': { backgroundColor: '#1565c0' },
                  }}
                >
                  {loading ? "Submitting..." : "Send"}
                </Button>

              </Stack>
            </Box>
          </Paper>
        </Slide>
      )}

      {/* Snackbar for success/error messages */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbar.message}
      />
    </Box>
  );
};

export default AdminChatBox;

import { useState } from 'react';

export const useDialog = () => {
    const [dialogContent, setDialogContent] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleOpenDialog = (content) => {
        setDialogContent(content);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    return { dialogContent, dialogOpen, handleOpenDialog, handleCloseDialog };
};

// OrderInvoiceHeader.js
import React from 'react';
import { Box, Typography } from '@mui/material';

const OrderInvoiceHeader = ({ invoiceNumber, invoiceDate, orderNumber, restaurantName, restaurantAddress, restaurantGST }) => {  
    
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            {/* Left section for Restaurant Details */}
            <Box sx={{ textAlign: 'left', flex: 1 }}>
                <Typography variant="h4" sx={{ color: '#6A1B9A', fontWeight: 'bold', mb: 10 }}>Fesste</Typography>
                <Typography variant="body">Tax Invoice on behalf of -</Typography>
                <Typography variant="body2">Restaurant Name: {restaurantName}</Typography>
                <Typography variant="body2">
                    Restaurant Address:
                    {`${restaurantAddress?.addressLine1 || ''}, ${restaurantAddress?.city || ''}, ${restaurantAddress?.postalCode || ''}`.replace(/, , /g, ', ').trim()}
                </Typography>
                <Typography variant="body2">Restaurant GST: {restaurantGST}</Typography>
                {/* <Typography variant="body2">Delivery Partner: Karthi</Typography> */}
            </Box>

            {/* Right section for Invoice Details */}
            <Box sx={{ textAlign: 'right', flex: 1, mt: 8 }}>
                <Typography variant="h6" sx={{ marginBottom: '8px', fontWeight: 'bold', }}>TAX INVOICE</Typography>
                <Typography variant="body2">ORIGINAL For Recipient</Typography>
                <Typography variant="body2">Invoice No: {invoiceNumber}</Typography>
                <Typography variant="body2">Invoice Date: {new Date(invoiceDate).toDateString()}</Typography>
                <Typography variant="body2">Order No: {orderNumber}</Typography>
            </Box>
        </Box>
    );
};

export default OrderInvoiceHeader;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Button, Chip, Box } from "@mui/material";
import AdminDrawer from "../../../components/AdminDrawer/AdminDrawer";
import PayoutTable from "../../../components/DataTables/PayoutTables/PayoutTable";
import CustomerQueriesDetailModal from "./CustomerQueriesDetailsModal";
import { fetchCustomerSupportDetails, resolveTicketCustomers } from "../../../api/supports/customerSupportApis";
import { fetchUserById } from "../../../api/users/usersApi";
import BreadcrumbsComponent from "../../../components/ResuableComponents/BreadCrumb";
import CustomSnackbar from "../../../components/CustomeSnackBar/CustomSnackbar";

const CustomerQueries = () => {
  const navigate = useNavigate();
  const [customerData, setCustomerData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [action, setAction] = useState("");
  const [loading, setLoading] = useState(true);
  const [resolveDescription, setResolveDescription] = useState("");
  const [messageContent, setMessageContent] = useState(''); // State to handle the message input
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Default to 'success'

// Declare the fetchData function outside of the useEffect hook
const fetchData = async () => {
  try {
    const fetchedData = await fetchCustomerSupportDetails();
    if (fetchedData && Array.isArray(fetchedData)) {
      const transformedData = fetchedData.map((item, index) => ({
        siNo: index + 1,
        id: item?._id,
        supportId: item?.supportId,
        userName: item?.user?.fullName,
        userId: item?.user?._id,
        phoneNumber: item?.user?.phoneNumber,
        messages: item?.messages,
        email: item.user?.email,
        orderId: item?.orderId,
        serviceProviderId: item?.serviceProviderId,
        topic: item?.topic,
        reason: item?.query,
        actionTaken: item?.status,
        createdAt: item?.createdAt,
        updatedAt: item?.updatedAt,
      }));
      setCustomerData(transformedData);
    }
  } catch (error) {
    console.error("Error fetching customer data:", error);
  } finally {
    setLoading(false);
  }
};

useEffect(() => {
  // Call fetchData when the component mounts
  fetchData();
}, []);  // This will run once on component mount




  const handleViewUser = async (row) => {
    try {
      const customerDetails = await fetchUserById(row.userId); // Correct the field to 'userId'
      navigate(`/user-details/${row.userId}`, { state: customerDetails }); // Use the proper userId for navigation
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>; // Placeholder while loading
  }

  const handleOpenModal = (row) => {
    setSelectedRow(row);
    setAction(row.actionTaken); // Set current action
    setResolveDescription(row.reason); // Set the reason field
    setOpenModal(true);
  };

  const handleResolveDescriptionChange = (event) => {
    setResolveDescription(event.target.value);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setAction("");
    setResolveDescription("");
  };

  const handleActionChange = (event) => {
    setAction(event.target.value);
  };


  // Close Snackbar
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSubmit = async () => {
    const payload = {
      status: action,
    };

    try {
      const result = await resolveTicketCustomers(selectedRow?.id, payload);
      console.log('Update Success:', result);

      // Update both 'action' and 'status' in customerData
      const updatedData = customerData.map((entity) =>
        entity.id === selectedRow.id
          ? { ...entity, actionTaken: action }
          : entity
      );
      setCustomerData(updatedData);
      handleCloseModal();
    } catch (error) {
      console.error('Failed to update:', error);
    }
  };

  console.log("customerData", customerData);

  const columns = [
    { field: "siNo", headerName: "ID", minWidth: 10, sortable: false },
    {
      field: "supportId",
      headerName: "Support Id",
      minWidth: 200,
      sortable: false,
    },
    {
      field: "userName",
      headerName: "User Name",
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Typography
            onClick={() => handleViewUser(params.row)} // Use handleViewUser with the full row
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "14px",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Typography
            onClick={() => handleViewUser(params.row)} // Use handleViewUser with the full row
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "14px",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Typography
            onClick={() => handleViewUser(params.row)} // Use handleViewUser with the full row
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "14px",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            {params.value}
          </Typography>
        );
      },
    },
    { field: "orderId", headerName: "Order ID", minWidth: 200 },
    {
      field: "serviceProviderId",
      headerName: "Service Provider ID",
      minWidth: 250,
    },
    { field: "topic", headerName: "Topic", minWidth: 200 },
    // { field: "reason", headerName: "Reason", minWidth: 200 },
    {
      field: "actionTaken",
      headerName: "Action Taken",
      minWidth: 200,
      renderCell: (params) => {
        let color;
        let label;
    
        // Mapping the status value to a user-friendly label
        switch (params.value) {
          case "open":
            label = "Open"; // Custom label
            color = "warning"; // Yellow
            break;
          case "resolved":
            label = "Resolved"; // Custom label
            color = "success"; // Green
            break;
          case "in-progress":
            label = "In Progress"; // Custom label
            color = "primary"; // Blue
            break;
          case "closed":
            label = "Closed"; // Custom label
            color = "error"; // Red
            break;
          default:
            label = "Unknown"; // Custom label for unknown statuses
            color = "default"; // Grey
            break;
        }
    
        // Returning the chip with the custom label and color
        return <Chip label={label} color={color} />;
      },
    },
    
    {
      field: "action",
      headerName: "Action",
      minWidth: 200,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="success"
          onClick={() => handleOpenModal(params.row)}
        >
          <Typography fontSize="12px" textTransform="none" fontWeight="bold">
            View Details
          </Typography>
        </Button>
      ),
    },
  ];

  return (
    <div style={{ backgroundColor: "#f2f2f2", height: "100vh" }}>
      <AdminDrawer>
        <Box display="flex" mb={3}>
          <Typography variant="h4" fontWeight="bolder">
            Customer Queries
          </Typography>
          <Box ml={3} mt={1}>
            <BreadcrumbsComponent />
          </Box>
        </Box>
        <PayoutTable rows={customerData} columns={columns} />
      </AdminDrawer>

      <CustomerQueriesDetailModal
        open={openModal}
        onClose={handleCloseModal}
        selectedRow={selectedRow}
        action={action}
        onActionChange={handleActionChange}
        resolveDescription={resolveDescription}
        onResolveDescriptionChange={handleResolveDescriptionChange}
        onSubmit={handleSubmit}
        messageContent={messageContent} // Pass the message content to the modal
        setMessageContent={setMessageContent} // Pass the setter to update the message content
        handleSnackbarClose={handleSnackbarClose}
      />
       <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </div>
  );
};

export default CustomerQueries;

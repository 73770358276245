import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Chip,
  CircularProgress,
  Container,
  Tooltip,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import OrdersTable from "../../../../components/DataTables/OrdersTables/OrdersTable";
import {
  fetchFessteMartOrdersByBusinessType,
  fetchFessteMartOrderDetails} from "../../../../api/orders/fessteMartOrders";
import { useDialog } from "../../../../hooks/useDialog";
import CustomSnackbar from "../../../../components/CustomeSnackBar/CustomSnackbar";
import { fetchUserById } from "../../../../api/users/usersApi";


const LightingsOrderTab = ( {fessteMartId}) => {
  const { dialogContent, dialogOpen, handleOpenDialog, handleCloseDialog } =
    useDialog();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [orderData, setOrderData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();


  const getOrders = async () => {
    try {
      const businessType = "Lighting";  // Set the business type dynamically
      const data = await fetchFessteMartOrdersByBusinessType(businessType);
      setOrderData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
};

useEffect(() => {
    getOrders();
}, []);

    const handleViewDetails = async (orderId) => {
        try {
            const data = await fetchFessteMartOrderDetails(orderId); // Fetch order details
            navigate(`/fessteMart-order-invoice/${orderId}`, { state: { orderData: data?.order } }); // Pass data using state
        } catch (error) {
            console.error("Error fetching order details:", error);
        }
    };

  const handleViewUser = async (row) => {
    try {
      const customerDetails = await fetchUserById(row.userId); // Correct the field to 'userId'
      navigate(`/user-details/${row.userId}`, { state: customerDetails }); // Use the proper userId for navigation
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  // Define columns for the DataGrid with minWidth instead of flex
  const columns = [
    { field: "siNo", headerName: "ID", minWidth: 10 },
    {
      field: "orderId",
      headerName: "Order ID",
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Typography
            onClick={() => handleViewDetails(params.row.id)}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "14px",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            {params.value} {/* Caterer name displayed here */}
          </Typography>
        );
      },
    },
    { field: "customerName", headerName: "Customer Name", minWidth: 200, renderCell: (params) => {
        return (
          <Typography
            onClick={() => handleViewUser(params.row)} // Use handleViewUser with the full row
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "14px",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            {params.value}
          </Typography>
        );
      }, },
    { field: "orderDate", headerName: "Booking Date & Time", minWidth: 250 },
    { field: "businessName", headerName: "Service Name", minWidth: 200 },
    { field: "fessteMartServices", headerName: "Item Name", minWidth: 200 },
    { field: "quantity", headerName: "Quantity", minWidth: 200 },

    {
      field: "orderStatus",
      headerName: "Order Status",
      minWidth: 200,
      renderCell: (params) => {
        const statusColors = {
          completed: "success",
          pending: "warning",
          cancelled: "error",
        };
        return (
          <Chip
            label={params.value}
            color={statusColors[params.value] || "default"}
          />
        );
      },
    },
    { field: "createdAt", headerName: "Delivery Date & Time", minWidth: 250 },
    {
      field: "total",
      headerName: "Total Amount(Before Discount)",
      minWidth: 300,
      renderCell: (params) => {
        return (
          <Typography
            onClick={() => handleViewDetails(params.row.id)}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "14px",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            {params.value} {/* Caterer name displayed here */}
          </Typography>
        );
      },
    },
    {
      field: "couponCodeUsed",
      headerName: "Discount/Coupon Code Used",
      minWidth: 300,
    },
    { field: "discountAmount", headerName: "Discount Amount", minWidth: 250 },
    {
      field: "totalAmount",
      headerName: "Total Amount(After Discount)",
      minWidth: 300,
      renderCell: (params) => {
        return (
          <Typography
            onClick={() => handleViewDetails(params.row.id)}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "14px",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            {params.value} {/* Caterer name displayed here */}
          </Typography>
        );
      },
    },
    {
      field: "paymentStatus",
      headerName: "Payment Status",
      minWidth: 220,
      renderCell: (params) => {
        const statusColors = {
          pending: "warning",
          paid: "success",
        };
        return (
          <Chip
            label={params.value}
            color={statusColors[params.value] || "default"}
          />
        );
      },
    },
    {
      field: "view",
      headerName: "View Details",
      minWidth: 150,
      sortable: false,
      renderCell: (params) => (
        <Tooltip title="View Details">
          <IconButton
            color="default"
            onClick={() => handleViewDetails(params.row.id)}
          >
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  return (
    <div style={{ backgroundColor: "#f2f2f2", height: "max-content" }}>
      <div style={{ padding: "16px" }}>
        <Typography variant="h4" fontWeight="bolder">
          Lightings Orders
        </Typography>
        {loading ? (
          <CircularProgress />
        ) : (
          <Container sx={{ minWidth: "100%" }}>
            <OrdersTable rows={orderData} columns={columns} />
          </Container>
        )}
      </div>

      {/* Render CustomSnackbar */}
      <CustomSnackbar
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      />
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Details</DialogTitle>
        <DialogContent>
          <Typography>{dialogContent}</Typography>
        </DialogContent>
        <Button onClick={handleCloseDialog} color="primary">
          Close
        </Button>
      </Dialog>
    </div>
  );
};

export default LightingsOrderTab;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Chip, Box } from "@mui/material";
import AdminDrawer from "../../../components/AdminDrawer/AdminDrawer";
import ReportsTable from "../../../components/DataTables/ReportsTables/ReportsTable";
import { fetchRestaurantReports } from "../../../api/reports/restaurantReports";
import BreadcrumbsComponent from "../../../components/ResuableComponents/BreadCrumb";



const RestaurantReports = () => {
  const navigate = useNavigate();
  const [reportData, setReportData] = useState([]);

  const handleViewClick = (restaurantId) => {
    navigate(`/restaurant-details/${restaurantId}`, {
      state: { restaurantId }, // Pass only restaurantId as it is
    });
  };

  const columns = [
    {
      field: "restaurantName",
      headerName: "Name",
      minWidth: 250,
      renderCell: (params) => {
        const text = params.value || ""; // Ensure value is a string
  
        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => handleViewClick(params.row.restaurantId)} // Pass the restaurantId correctly
          >
            {text}
          </div>
        );
      },
    },
    { field: "phone", headerName: "Phone Number", minWidth: 250 },
    { field: "address", headerName: "Address", minWidth: 250 },
    { field: "totalOrders", headerName: "Total Orders", minWidth: 250 },
    { field: "totalOrderValue", headerName: "Total Earnings", minWidth: 250 },
    { field: "newOrders", headerName: "New Orders", minWidth: 250 },
    { field: "acceptedOrders", headerName: "Accepted Orders", minWidth: 250 },
    { field: "deliveredOrders", headerName: "Delivered Orders", minWidth: 250 },
    { field: "canceledOrders", headerName: "Cancelled Orders", minWidth: 250 },
    { field: "orderReadyOrders", headerName: "Delivery Ready Orders", minWidth: 250 },
    { field: "pickedUpOrders", headerName: "Picked-Up Orders", minWidth: 250 },
    { field: "rejectedOrders", headerName: "Rejected Orders", minWidth: 250 },
    { field: "totalPayout", headerName: "Total Payout", minWidth: 250 },
    { field: "totalfessteFee", headerName: "Total Fesste Fee", minWidth: 250 },
    { field: "totaltransactionFee", headerName: "Total Transaction Fee", minWidth: 250 },
    { field: "totalPlatformFee", headerName: "Total Platform Fee", minWidth: 250 },
    { field: "totalplatformFeeGst", headerName: "Total Platform Fee Gst", minWidth: 250 },
    { field: "totalgst", headerName: "Total GST", minWidth: 250 },
    { field: "pendingPayout", headerName: "Pending Payouts", minWidth: 250 },
    { field: "completedPayout", headerName: "Payouts Completed", minWidth: 250 },
    {
      field: "status",
      headerName: "Status",
      minWidth: 250,
      renderCell: (params) => (
        <Chip
          label={params.value}
          color={params.value === "Active" ? "success" : "warning"}
        />
      ),
    },
    { field: "date", headerName: "Date", minWidth: 250, sortable: true },
  ];

  useEffect(() => {
    const getReports = async () => {
      const data = await fetchRestaurantReports();
      const formattedData = data.map((item) => ({
        id: item.restaurantId,
        restaurantId: item.restId,
        restaurantName: item.restaurantName,
        email: item.contact.email || "N/A", // Assuming email is inside contact, if present
        phone: item.contact.phone,
        ratings: item.ratings || 4, // Adjust if rating data is available
        address: `${item.contact.address.addressLine1}, ${item.contact.address.city}`,
        totalOrders: item.totalOrders,
        newOrders:item.newOrders,
        acceptedOrders:item.acceptedOrders,
        deliveredOrders: item.deliveredOrders,
        canceledOrders:item.canceledOrders,
        orderReadyOrders:item.orderReadyOrders,
        pickedUpOrders:item.pickedUpOrders,
        rejectedOrders:item.rejectedOrders,
        totalOrderValue: `₹${item.totalOrderValue}`,
        totalPayout: `₹${item.totalPayout}`,
        totalfessteFee: `₹${item.totalfessteFee}`,
        totaltransactionFee: `₹${item.totaltransactionFee}`,
        pendingPayout: `₹${item.pendingPayout}`,
        completedPayout: `₹${item.completedPayout}`,
        totalPlatformFee: `₹${item.totalPlatformFee}`,
        totalplatformFeeGst: `₹${item.totalplatformFeeGst}`,
        totalgst: `₹${item.totalgst}`,
        status: item.status || "Active", // Adjust based on actual status
        date: new Date().toLocaleDateString(), // Adjust the date format as needed
      }));
      setReportData(formattedData);
    };

    getReports();
  }, []);

  return (
    <div style={{ backgroundColor: "#f2f2f2", height: "100vh" }}>
      <AdminDrawer>
        <div style={{ padding: "16px" }}>
          <Box display="flex" mb={2}>
            <Typography variant="h4" fontWeight="bolder">
              Restaurant Reports
            </Typography>
            <Box mt={1} ml={3}>
              <BreadcrumbsComponent />
            </Box>
          </Box>

          <ReportsTable rows={reportData} columns={columns} />
        </div>
      </AdminDrawer>
    </div>
  );
};

export default RestaurantReports;

import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Typography,
  IconButton,
  Box,
  Button,
  TextField,
  Card,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PayoutTable from "../../../components/DataTables/PayoutTables/PayoutTable";

const DeliveryPartnerPayoutDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const { name } = location.state || {};
  console.log(name);

  const navigate = useNavigate();
  const [payoutData, setPayoutData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedOrderIds, setSelectedOrderIds] = useState([]); // Track selected rows
  const [transactionId, setTransactionId] = useState(""); // Track transaction ID input
  const [totalPayout, setTotalPayout] = useState(0);
  const [pendingPayout, setPendingPayout] = useState(0);
  const [paidPayout, setPaidPayout] = useState(0);

  // Define the columns with checkbox selection
  const columns = [
    { field: "orderId", headerName: "Order ID", minWidth: 250 },
    { field: "businessType", headerName: "Business Type", minWidth: 200 },
    {
      field: "deliveryFee",
      headerName: "Delivery Fee",
      minWidth: 200,
      formatter: (value) => `₹${value}`,
    },
    { field: "deliveryStatus", headerName: "Delivery Status", minWidth: 200 },
    {
      field: "pickupAddress",
      headerName: "Pickup Address",
      minWidth: 200,
      formatter: (value) =>
        `${value?.addressLine1 || ""}, ${value?.addressLine2 || ""}, ${
          value?.city || ""
        }, ${value?.state || ""}, ${value?.postalCode || ""}, ${
          value?.country || ""
        }`,
    },
    { field: "pickedUpTime", headerName: "Pick Up Time", minWidth: 200 },
    {
      field: "deliveryAddress",
      headerName: "Delivery Address",
      minWidth: 200,
      formatter: (value) =>
        `${value?.address1 || ""}, ${value?.address2 || ""}, ${
          value?.city || ""
        }, ${value?.state || ""}, ${value?.postalCode || ""}, ${
          value?.country || ""
        }`,
    },

    { field: "deliveryTime", headerName: "Delivery Time", minWidth: 200 },
    { field: "payoutStatus", headerName: "Payout Status", minWidth: 200 },
    { field: "transactionId", headerName: "Transaction ID", minWidth: 200 },
    {
      field: "select",
      headerName: "Make Payment",
      width: 180,
      renderCell: (params) => (
        <input
          type="checkbox"
          checked={selectedOrderIds.includes(params.row.id)}
          disabled={params.row.payoutStatus === "completed"}
          onChange={() => handleCheckboxChange(params.row.id)}
        />
      ),
    },
  ];

  const fetchPayoutDetails = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `https://admin.fesste.com/api/dpPayoutById/${id}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch payout details");
      }
      const result = await response.json();
      if (result.success) {
        const transformedData = result.Payout.map((item) => ({
          ...item,
          id: item._id,
          orderId: item?.orderId || item?.catererOrderId,
          deliverypartnerName: item?.deliveryPartnerId?.fullName,
          businessType: item?.businessType,
          deliveryStatus: item?.status,
          payoutStatus: item?.payoutStatus,
          pickupAddress: `${item?.pickupAddress?.addressLine1 || ""}, ${
            item?.pickupAddress?.addressLine2 || ""
          }, ${item?.pickupAddress?.city || ""}, ${
            item?.pickupAddress?.state || ""
          }, ${item?.pickupAddress?.postalCode || ""}, ${
            item?.pickupAddress?.country || ""
          }`,
          deliveryAddress: `${item?.deliveryAddress?.address1 || ""}, ${
            item?.deliveryAddress?.address2 || ""
          }, ${item?.deliveryAddress?.city || ""}, ${
            item?.deliveryAddress?.state || ""
          }, ${item?.deliveryAddress?.postalCode || ""}, ${
            item?.deliveryAddress?.country || ""
          }`,
          pickedUpTime: item?.pickedUpTime
            ? new Date(item.pickedUpTime).toLocaleString()
            : undefined,
          deliveryTime: item?.deliveryTime
            ? new Date(item.deliveryTime).toLocaleString()
            : undefined,
          transactionId: item?.transactionId || "N/A",
        }));

        setPayoutData(transformedData);

        // Calculate total payouts
        const total = transformedData.reduce(
          (sum, item) => sum + item.deliveryFee,
          0
        );
        const pending = transformedData
          .filter((item) => item.payoutStatus !== "completed")
          .reduce((sum, item) => sum + item.deliveryFee, 0);
        const paid = total - pending;

        setTotalPayout(total);
        setPendingPayout(pending);
        setPaidPayout(paid);
      } else {
        throw new Error(result.message || "Unexpected API response");
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchPayoutDetails();
  }, [id]);

  const handleCheckboxChange = (rowId) => {
    setSelectedOrderIds((prevSelected) =>
      prevSelected.includes(rowId)
        ? prevSelected.filter((id) => id !== rowId)
        : [...prevSelected, rowId]
    );
  };

  const totalAmount = selectedOrderIds.reduce((sum, id) => {
    const order = payoutData.find((item) => item.id === id);
    return sum + (order ? order.deliveryFee : 0);
  }, 0);

  const handleUpdateDpPayouts = async () => {
    const body = {
      totalAmount: totalAmount.toString(),
      transactionId: transactionId,
      orderIds: selectedOrderIds,
      deliveryPartnerId: id,
    };

    try {
      const response = await fetch(
        "https://admin.fesste.com/api/updateDpPayouts",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );

      if (response.ok) {
        await fetchPayoutDetails();
        setTransactionId("");
        setSelectedOrderIds([]);
      } else {
        throw new Error("Failed to submit selected orders");
      }
    } catch (error) {
      setError(error.message);
    }
  };

  if (loading) {
    return (
      <div style={{ textAlign: "center", padding: "50px" }}>
        <Typography variant="h6">Loading...</Typography>
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ textAlign: "center", padding: "50px" }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundColor: "#f2f2f2",
        minHeight: "100vh",
        padding: "24px",
      }}
    >
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}
      >
        <IconButton edge="start" color="primary" onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5" style={{ marginLeft: "8px" }}>
          Payout Details for Delivery Partner
        </Typography>
      </div>
      <Box style={{ height: 400, width: "100%" }}>
        <Box mb={2}>
          <Typography>
            <strong>Delivery Partner Name:</strong> {name}{" "}
          </Typography>
        </Box>

        {/* Dashboard Cards */}
        <Box
          display="flex"
          justifyContent="space-between"
          gap={2}
          marginBottom={3}
        >
          <Card
            style={{
              flex: 1,
              padding: "16px",
              backgroundColor: "#4caf50",
              color: "#fff",
              textAlign: "center",
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              Total Payout (Till Date)
            </Typography>
            <Typography variant="h5" fontWeight="bold">
              ₹{totalPayout}
            </Typography>
          </Card>
          <Card
            style={{
              flex: 1,
              padding: "16px",
              backgroundColor: "#ff9800",
              color: "#fff",
              textAlign: "center",
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              Pending Payout (Till Date)
            </Typography>
            <Typography variant="h5" fontWeight="bold">
              ₹{pendingPayout}
            </Typography>
          </Card>
          <Card
            style={{
              flex: 1,
              padding: "16px",
              backgroundColor: "#2196f3",
              color: "#fff",
              textAlign: "center",
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              Total Payout Paid (Till Date)
            </Typography>
            <Typography variant="h5" fontWeight="bold">
              ₹{paidPayout}
            </Typography>
          </Card>
        </Box>
        {selectedOrderIds.length > 0 && (
          <Box
            style={{
              marginTop: "16px",
              padding: "16px",
              backgroundColor: "#fff",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              marginBottom: "10px",
            }}
          >
            <Typography
              variant="h6"
              style={{ fontWeight: 600, marginBottom: "8px" }}
            >
              Total Amount:
            </Typography>
            <Typography
              variant="h5"
              style={{ color: "#4caf50", fontWeight: 700 }}
            >
              ₹{totalAmount}
            </Typography>
            <TextField
              label="Transaction ID"
              variant="outlined"
              size="small"
              value={transactionId}
              onChange={(e) => setTransactionId(e.target.value)}
              style={{ marginTop: "16px", marginRight: "10px", width: "40%" }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdateDpPayouts}
              disabled={!transactionId || totalAmount === 0}
              sx={{ mt: 2 }}
            >
              Make Payment
            </Button>
          </Box>
        )}

        <PayoutTable
          rows={payoutData}
          columns={columns}
          getRowId={(row) => row.id}
          pageSize={5}
          disableSelectionOnClick
          autoHeight
        />
      </Box>
    </div>
  );
};

export default DeliveryPartnerPayoutDetails;

import {useState} from 'react';
import { Modal, Box, Typography, TextField, Button } from '@mui/material';

const PhoneNumberModal = ({ open, onClose, onSubmit }) => {
    const [phoneNumber, setPhoneNumber] = useState('');

    const handleSubmit = () => {
        if (phoneNumber) {
            onSubmit(phoneNumber);
            onClose();
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{ width: 300, padding: 4, backgroundColor: 'white', margin: 'auto', marginTop: '20%' }}>
                <Typography variant="h6" gutterBottom>
                    Enter Your Phone Number
                </Typography>
                <TextField
                    fullWidth
                    variant="outlined"
                    label="Phone Number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ marginTop: 2 }}>
                    Check
                </Button>
            </Box>
        </Modal>
    );
};


export default PhoneNumberModal;
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Paper, Typography, List, ListItem,Box } from '@mui/material';
import AdminDrawer from '../../../../components/AdminDrawer/AdminDrawer';

const RestaurantSubPackageDetails = () => {
    const location = useLocation();
    const { orderData } = location.state || {};

    if (!orderData) {
        return <Typography variant="h6">No data available</Typography>;
    }

    const { restSubPackName, restaurants} = orderData;

    return (
        <div style={{ backgroundColor: '#f2f2f2', minHeight: '100vh' }}>
            <AdminDrawer>
                <Container sx={{ marginTop: '20px' }}>
                    <Paper elevation={0} sx={{ padding: '20px', backgroundColor: '#f9f9f9' }}>
                        {/* Restaurant Subpackage Name */}
                        <Typography variant="h4" sx={{ marginBottom: '20px' }}>
                            Subpackage: <b>{restSubPackName}</b>
                        </Typography>

                        {/* Associated Restaurants */}
                        <Typography variant="h6" sx={{ marginBottom: '10px' }}>
                            Associated Restaurants:
                        </Typography>
                        {restaurants && restaurants.length > 0 ? (
                            <List>
                                {restaurants.map((restaurant) => (
                                    <ListItem key={restaurant._id} sx={{ padding: '10px 0' }}>
                                        <Box>
                                            <Typography variant="body1">
                                                Restaurant Name: {restaurant.restName}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary">
                                                Restaurant ID: {restaurant.restID}
                                            </Typography>
                                        </Box>
                                    </ListItem>
                                ))}
                            </List>
                        ) : (
                            <Typography variant="body2" color="textSecondary">
                                No restaurants associated with this subpackage.
                            </Typography>
                        )}

                    </Paper>
                </Container>
            </AdminDrawer>
        </div>
    );
};

export default RestaurantSubPackageDetails;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Metropolis-Black.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/src/assets/fonts/Metropolis-Black.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'Metropolis-black';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('otf'),
         url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('otf');
    font-weight: normal;
    font-style: normal;
  }
  body {
    font-family: 'Metropolis-black', sans-serif;
  }
  `, "",{"version":3,"sources":["webpack://./src/assets/fonts/fonts.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B;8DACgE;IAChE,mBAAmB;IACnB,kBAAkB;EACpB;EACA;IACE,2CAA2C;EAC7C","sourcesContent":["@font-face {\n    font-family: 'Metropolis-black';\n    src: url('./Metropolis-Black.otf') format('otf'),\n         url('/src/assets/fonts/Metropolis-Black.otf') format('otf');\n    font-weight: normal;\n    font-style: normal;\n  }\n  body {\n    font-family: 'Metropolis-black', sans-serif;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

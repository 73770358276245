import baseUrl from "../../config";


export const fetchCatererReports = async () => {
    try {
      const response = await fetch(`${baseUrl}reports/caterer`);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching Caterer Reports:', error);
      return [];
    }
  };
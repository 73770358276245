import React, { useState, useEffect } from 'react';
import { Grid, Typography, TextField, Button, Box, Autocomplete } from '@mui/material';
import Map from '../../../components/Maps/Map';

const RestaurantForm = ({ onSubmit, initialValues, serviceProviderId, }) => {
    const [selectedAddress, setSelectedAddress] = useState('');


    const handleFormSubmit = (event) => {
        event.preventDefault();
        onSubmit(formData);
    };

    const [formData, setFormData] = useState({
        serviceProviderId: serviceProviderId || '',
        restName: '',
        restAddress: { street: '', city: '', postalCode: '' },
        managerNumber: '',
        ownerName: '',
        ownerNumber: '',
        ownerEmail: '',
        deliveryType: [],
        restDescription: '',
        
        cuisine: '',
        operationalTime: [
            { day: 'Monday', startTime: '06:00 AM', endTime: '11:00 PM' },
            { day: 'Tuesday', startTime: '06:00 AM', endTime: '11:00 PM' },
            { day: 'Wednesday', startTime: '06:00 AM', endTime: '11:00 PM' },
            { day: 'Thursday', startTime: '06:00 AM', endTime: '11:00 PM' },
            { day: 'Friday', startTime: '06:00 AM', endTime: '11:00 PM' },
            { day: 'Saturday', startTime: '06:00 AM', endTime: '11:00 PM' },
            { day: 'Sunday', startTime: '06:00 AM', endTime: '11:00 PM' }
        ],
        fessteApproach: true,
        panNo: '',
        gstNo: '',
        fssaiNo: '',
        address:'',
        bankDetails: { accountNumber: '', accountType: '', ifscCode: '' },
        isDeliveryTimingsSameAsOperational: true,
        fssaiImage: null,
        panImage: null,
        gstImage: null,
        restaurantImage: null,
        ownerPhoto: null,
    });

    // useEffect to update formData when initialValues change
    useEffect(() => {
        if (initialValues) {
            setFormData({
                serviceProviderId: initialValues.serviceProviderId || serviceProviderId || '',  // Default to hardcoded ID
                restName: initialValues.restName || '',
                restAddress: {
                    street: initialValues.restAddress?.street || '',
                    city: initialValues.restAddress?.city || '',
                    postalCode: initialValues.restAddress?.postalCode || '',
                },
                managerNumber: initialValues.managerNumber || '',
                deliveryType: initialValues.deliveryType || [],
                ownerName: initialValues.serviceProvider.spName,
                ownerEmail: initialValues.serviceProvider.spEmail,
                address: initialValues.serviceProvider.address,
                ownerNumber: initialValues.serviceProvider.spPhone,
                restDescription: initialValues.restDescription || '',
                cuisine: initialValues.cuisine || '',
                operationalTime: initialValues.operationalTime || [
                    { day: 'Monday', startTime: '06:00 AM', endTime: '11:00 PM' },
                    { day: 'Tuesday', startTime: '06:00 AM', endTime: '11:00 PM' },
                    { day: 'Wednesday', startTime: '06:00 AM', endTime: '11:00 PM' },
                    { day: 'Thursday', startTime: '06:00 AM', endTime: '11:00 PM' },
                    { day: 'Friday', startTime: '06:00 AM', endTime: '11:00 PM' },
                    { day: 'Saturday', startTime: '06:00 AM', endTime: '11:00 PM' },
                    { day: 'Sunday', startTime: '06:00 AM', endTime: '11:00 PM' }
                ],
                fessteApproach: initialValues.fessteApproach ?? true,  // Fallback to true if undefined
                panNo: initialValues.panNo || '',
                gstNo: initialValues.gstNo || '',
                fssaiNo: initialValues.fssaiNo || '',
                bankDetails: {
                    accountNumber: initialValues.bankDetails?.accountNumber || '',
                    accountType: initialValues.bankDetails?.accountType || '',
                    ifscCode: initialValues.bankDetails?.ifscCode || '',
                },
                isDeliveryTimingsSameAsOperational: initialValues.isDeliveryTimingsSameAsOperational ?? true,
                fssaiImage: initialValues.fssaiImage || null,
                panImage: initialValues.panImage || null,
                gstImage: initialValues.gstImage || null,
                restaurantImage: initialValues.restaurantImage || null,
                ownerPhoto: initialValues.ownerPhoto || null,
            });
        }
    }, [initialValues, serviceProviderId]);


    const handleAddressChange = (address) => {
        setSelectedAddress(address);
    };

    const handleInputChange = (e) => {
        const { name, value, type, files } = e.target;
        if (type === 'file') {
            setFormData((prevState) => ({
                ...prevState,
                ...formData,
                [name]: files[0], // Only handle the first file
            }));
        } else {
            setFormData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    const handleNestedChange = (e, section, key) => {
        const { value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            ...formData,
            [section]: {
                ...prevState[section],
                [key]: value,
            },
        }));
    };

    const handleOperationalTimeChange = (index, e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            ...formData,
            operationalTime: prevState.operationalTime.map((time, i) =>
                i === index ? { ...time, [name]: value } : time
            ),
        }));
    };
    return (
        <form onSubmit={handleFormSubmit}>
            <Grid container spacing={2} mb={2}>
                {/* Restaurant Name */}
                <Grid item xs={12} sm={6}>

                    <Typography component="legend">Owner Name</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter Owner Name Here"
                        name="ownerName"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.ownerName}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">Owner Phone Number</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter Owner Phone Number Here"
                        name="ownerNumber"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.ownerNumber}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">Owner Email</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter Owner Email Here"
                        name="ownerEmail"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.ownerEmail}
                        onChange={handleInputChange}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Typography component="legend">Restaurant Name</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter Restaurant Name Here"
                        name="restName"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.restName}
                        onChange={handleInputChange}
                    />
                </Grid>
                {/* Description */}
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">Delivery Type</Typography>
                    <Autocomplete
                        multiple
                        options={['Fesste', 'Self']}  // Enum options from backend
                        getOptionLabel={(option) => option}  // Display the option as the label
                        value={formData.deliveryType || []}  // Ensure it's an array
                        onChange={(event, newValue) => {
                            setFormData({ ...formData, deliveryType: newValue });  // Update formData as array
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                size="small"
                                placeholder="Select Delivery Type"
                            />
                        )}
                        sx={{ marginBottom: 2, marginTop: 1 }}
                    />
                </Grid>

                {/* Description */}
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">Description</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter Restaurant Description Here"
                        name="restDescription"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.restDescription}
                        onChange={handleInputChange}
                    />
                </Grid>


                {/* Address Fields */}
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">Street</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter Street Here"
                        name="street"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.restAddress.street}
                        onChange={(e) => handleNestedChange(e, 'restAddress', 'street')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">City</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter City Here"
                        name="city"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.restAddress.city}
                        onChange={(e) => handleNestedChange(e, 'restAddress', 'city')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">Postal Code</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter Postal Code Here"
                        name="postalCode"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.restAddress.postalCode}
                        onChange={(e) => handleNestedChange(e, 'restAddress', 'postalCode')}
                    />
                </Grid>

                {/* Manager Number */}
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">Manager Number</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter Manager Number Here"
                        name="managerNumber"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.managerNumber}
                        onChange={handleInputChange}
                    />
                </Grid>
                {/* Cuisine */}
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">Cuisine</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter Cuisine Type Here"
                        name="cuisine"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.cuisine}
                        onChange={handleInputChange}
                    />
                </Grid>

                {/* PAN Number */}
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">PAN Number</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter PAN Number Here"
                        name="panNo"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.panNo}
                        onChange={handleInputChange}
                    />
                </Grid>

                {/* GST Number */}
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">GST Number</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter GST Number Here"
                        name="gstNo"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.gstNo}
                        onChange={handleInputChange}
                    />
                </Grid>

                {/* FSSAI Number */}
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">FSSAI Number</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter FSSAI Number Here"
                        name="fssaiNo"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.fssaiNo}
                        onChange={handleInputChange}
                    />
                </Grid>

                {/* Bank Details */}
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">Account Number</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter Account Number Here"
                        name="accountNumber"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.bankDetails.accountNumber}
                        onChange={(e) => handleNestedChange(e, 'bankDetails', 'accountNumber')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">Account Type</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter Account Type Here"
                        name="accountType"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.bankDetails.accountType}
                        onChange={(e) => handleNestedChange(e, 'bankDetails', 'accountType')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">IFSC Code</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter IFSC Code Here"
                        name="ifscCode"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.bankDetails.ifscCode}
                        onChange={(e) => handleNestedChange(e, 'bankDetails', 'ifscCode')}
                    />
                </Grid>

                 {/* Address */}
             <Grid container spacing={2} mb={2}>
                            <Grid item xs={12} style={{ height: '400px' }}>
                                <Map onAddressChange={handleAddressChange} mapContainerStyle={{ height: '100%' }} />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography component="legend">
                                    Address
                                </Typography>
                                <TextField
                                    disabled
                                    fullWidth
                                    placeholder="Enter Address"
                                    name="address"
                                    value={selectedAddress}
                                    variant="outlined"
                                    size="small"
                                    sx={{ marginBottom: 2, marginTop: 1 }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                        </Grid>

                {/* Operational Time */}
                {formData.operationalTime.map((time, index) => (
                    <Grid container spacing={2} mb={2} key={index}>
                        <Grid item xs={12} sm={4}>
                            <Typography component="legend">Day</Typography>
                            <TextField
                                fullWidth
                                placeholder="Enter Day Here"
                                name="day"
                                variant="outlined"
                                size="small"
                                sx={{ marginBottom: 2, marginTop: 1 }}
                                InputLabelProps={{ shrink: true }}
                                value={time.day}
                                onChange={(e) => handleOperationalTimeChange(index, e)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography component="legend">Start Time</Typography>
                            <TextField
                                fullWidth
                                placeholder="Enter Start Time Here"
                                name="startTime"
                                variant="outlined"
                                size="small"
                                sx={{ marginBottom: 2, marginTop: 1 }}
                                InputLabelProps={{ shrink: true }}
                                value={time.startTime}
                                onChange={(e) => handleOperationalTimeChange(index, e)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography component="legend">End Time</Typography>
                            <TextField
                                fullWidth
                                placeholder="Enter End Time Here"
                                name="endTime"
                                variant="outlined"
                                size="small"
                                sx={{ marginBottom: 2, marginTop: 1 }}
                                InputLabelProps={{ shrink: true }}
                                value={time.endTime}
                                onChange={(e) => handleOperationalTimeChange(index, e)}
                            />
                        </Grid>
                    </Grid>
                ))}

                {/* Files */}
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">FSSAI Image</Typography>
                    <input
                        type="file"
                        name="fssaiImage"
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">PAN Image</Typography>
                    <input
                        type="file"
                        name="panImage"
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">GST Image</Typography>
                    <input
                        type="file"
                        name="gstImage"
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">Restaurant Image</Typography>
                    <input
                        type="file"
                        name="restaurantImage"
                        onChange={handleInputChange}
                    />
                </Grid>
                {/* Submit Button */}

            </Grid>
            <Box mt={5} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Grid item>
                    <Button type="submit" variant="contained" color="primary">
                        {initialValues ? 'Update' : 'Add'}
                    </Button>
                </Grid>
            </Box>

        </form>
    );
};

export default RestaurantForm;

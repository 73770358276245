import React from "react";
import { MenuItem, Select, InputLabel, FormControl } from "@mui/material";

const DeliveryPartnerStatus = ({ statusFilter, setStatusFilter }) => {
  const handleChange = (event) => {
    setStatusFilter(event.target.value); // Pass the selected value as-is
  };

  return (
    <FormControl size="small" variant="outlined" fullWidth>
      <InputLabel>Status</InputLabel>
      <Select
        sx={{
          width: "15%",
        }}
        value={statusFilter || ""}
        onChange={handleChange}
        label="Status"
      >
        <MenuItem value="">All</MenuItem>
        <MenuItem value="onDuty">On-Duty</MenuItem>
        <MenuItem value="offDuty">Off-Duty</MenuItem>
        <MenuItem value="delivering">Delivering</MenuItem>
        <MenuItem value="idle">Idle</MenuItem>
      </Select>
    </FormControl>
  );
};

export default DeliveryPartnerStatus;

import baseUrl from "../../config";

// src/api/deliveryPartnersApi.js
export const fetchDeliveryPartners = async () => {
    try {
        const response = await fetch(`${baseUrl}getAlldp`)
        if (response.ok) {
            const result = await response.json();
            return result.data; // Assuming result.data is the array of delivery partners
        } else {
            console.error("Failed to fetch data:", response.status);
            return [];
        }
    } catch (error) {
        console.error("Error fetching data:", error);
        return [];
    }
};

export const fetchDeliveryPartnerById = async (deliveryPartnerById) => {
  const response = await fetch(`${baseUrl}getDpById/${deliveryPartnerById}`);
  if (!response.ok) {
    throw new Error("Failed to fetch Delivery Partner details");
  }
  return await response.json();
};


export const registerDeliveryPartner = async (formDataObj) => {
    try {
      const response = await fetch(`${baseUrl}register_dp`, {
        method: "POST",
        body: formDataObj,
      });
  
      const responseData = await response.json(); // Parse JSON response
      return { success: response.ok, data: responseData };
    } catch (error) {
      console.error("Fetch Error:", error);
      return { success: false, error: "Network error. Please try again." };
    }
  };
  

  // Function to update Delivery Partner details
export const updateDeliveryPartnerById = async (deliveryPartnerId, formDataObj) => {
    try {
      const response = await fetch(`${baseUrl}deliverypartner/${deliveryPartnerId}`, {
        method: "PUT", // Use PUT for updates
        body: formDataObj,
      });
  
      const responseData = await response.json(); // Parse the JSON response
      if (!response.ok) {
        throw new Error(responseData.message || "Failed to update delivery Partner");
      }
      return { success: true, data: responseData }; // Return success with the response data
    } catch (error) {
      console.error("Error updating delivery partner:", error);
      return { success: false, error: "Network error. Please try again." };
    }
  };

  // Function to delete delivery Partner by ID
export const deleteDeliveryPartnerById = async (deliveryPartnerById, deleteReason) => {
    try {
      const response = await fetch(`${baseUrl}deleteDeliveryPartner/${deliveryPartnerById}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ deleteReason }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to delete deliveryPartner");
      }
  
      return { success: true, message: "Delivery Partner deleted successfully" };
    } catch (error) {
      console.error("Error deleting Delivery Partner:", error);
      return {
        success: false,
        error: "Failed to delete Delivery Partner. Please try again.",
      };
    }
  };
import React, { useState, useEffect } from 'react';
import { Typography, Chip, Box } from '@mui/material';
import AdminDrawer from '../../../../components/AdminDrawer/AdminDrawer';
import ReportsTable from '../../../../components/DataTables/ReportsTables/ReportsTable';
import { fetchFessteMartReports } from '../../../../api/reports/fessteMartReports';
import BreadcrumbsComponent from '../../../../components/ResuableComponents/BreadCrumb';

const FessteMartReports = () => {
    const [reportData, setReportData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchReports = async () => {
            const data = await fetchFessteMartReports();
            // Transform the API data to match the structure expected by the table
            const transformedData = data.map(item => ({
                id: item.fessteMartId,
                name: item.fessteMartName,
                email: item.contact.email,
                phone: item.contact.phone,
                totalOrders: item.totalOrders,
                newOrders: item.newOrders,
                acceptedOrders: item.acceptedOrders,
                inProgressOrders: item.inProgressOrders,
                confirmedOrders: item.confirmedOrders,
                canceledOrders: item.canceledOrders,
                completedOrders: item.completedOrders,
                rejectedOrders: item.rejectedOrders,
                totalEarnings: `₹${parseFloat(item.totalOrderValue).toLocaleString('en-IN')}`,
                pendingPayouts: `₹${(parseFloat(item.totalOrderValue) - parseFloat(item.totalPayout)).toLocaleString('en-IN')}`,
                payoutsCompleted: `₹${parseFloat(item.totalPayout).toLocaleString('en-IN')}`,
                status: 'Active', // Adjust based on additional data if needed
                date: new Date().toISOString().split('T')[0], // Placeholder; replace with actual date if provided
            }));
            setReportData(transformedData);
            setLoading(false);
        };

        fetchReports();
    }, []);

    const columns = [
        { field: 'name', headerName: 'Name', minWidth: 250 },
        { field: 'email', headerName: 'Email', minWidth: 200 },
        { field: 'phone', headerName: 'Phone Number', minWidth: 200 },
        { field: 'totalOrders', headerName: 'Total Orders', minWidth: 200 },
        // { field: 'newOrders', headerName: 'New Orders', minWidth: 200 },
        { field: 'acceptedOrders', headerName: 'Accepted Orders', minWidth: 240 },
        // { field: 'inProgressOrders', headerName: 'In-Progress Orders', minWidth: 250 },
        // { field: 'confirmedOrders', headerName: 'Confirmed Orders', minWidth: 250 },
        { field: 'completedOrders', headerName: 'Completed Orders', minWidth: 240 },
        { field: 'canceledOrders', headerName: 'Canceled Orders', minWidth: 240 },
        { field: 'rejectedOrders', headerName: 'Rejected Orders', minWidth: 240 },
        { field: 'totalEarnings', headerName: 'Total Earnings', minWidth: 200 },
        { field: 'pendingPayouts', headerName: 'Pending Payouts', minWidth: 200 },
        { field: 'payoutsCompleted', headerName: 'Payouts Completed', minWidth: 240 },
        {
            field: 'status',
            headerName: 'Status',
            minWidth: 150,
            sortable: true,
            renderCell: (params) => (
                <Chip label={params.value} color={params.value === 'Active' ? 'success' : 'warning'} />
            ),
        },
        { field: 'date', headerName: 'Date', minWidth: 150, sortable: true },
    ];

    return (
        <div style={{ backgroundColor: '#f2f2f2', height: '100vh' }}>
            <AdminDrawer>
                <div style={{ padding: '16px' }}>
                <Box display="flex" mb={2} >
                    <Typography variant='h4' fontWeight='bolder'>FessteMart Reports</Typography>
                        <Box mt={1} ml={2}>
                        <BreadcrumbsComponent />
                        </Box>
                    </Box>
                    {loading ? (
                        <Typography>Loading...</Typography>
                    ) : (
                        <ReportsTable
                            rows={reportData}
                            columns={columns}
                        />
                    )}
                </div>
            </AdminDrawer>
        </div>
    );
};

export default FessteMartReports;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Button, Box } from "@mui/material";
import AdminDrawer from "../../components/AdminDrawer/AdminDrawer";
import PayoutTable from "../../components/DataTables/PayoutTables/PayoutTable";
import { fetchUsers, fetchUserById } from "../../api/users/usersApi";
import BreadcrumbsComponent from "../../components/ResuableComponents/BreadCrumb";

const UserManagementPage = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const getUsers = async () => {
      try {
        const userData = await fetchUsers();
        setUsers(userData);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    getUsers();
  }, []);

  // Fetch individual user data and navigate with it
  const handleViewUser = async (row) => {
    try {
      const customerDetails = await fetchUserById(row._id); // Fetch user by ID from the API
      navigate(`/user-details/${row._id}`, { state: customerDetails }); // Navigate to user details page with data
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const columns = [
    {
      field: "fullName",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => (
        <div
          onClick={() => handleViewUser(params.row)} // Same function as the button
          style={{
            cursor: "pointer",
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      renderCell: (params) => (
        <div
          onClick={() => handleViewUser(params.row)} // Same function as the button
          style={{
            cursor: "pointer",
          }}
        >
          {params.value}
        </div>
      ),
    },
    { field: "phoneNumber", headerName: "Phone Number", flex: 1 },
    {
      field: "createdAt",
      headerName: "Registered At",
      flex: 1,
      renderCell: (params) => {
        const date = new Date(params.value); // Convert to Date object
        return <div>{date.toLocaleString()}</div>; // Format as local string
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="success"
          onClick={() => handleViewUser(params.row)} // Call the function when clicking the button
        >
          <Typography fontSize="14px" textTransform="none" fontWeight="bold">
            View User
          </Typography>
        </Button>
      ),
    },
  ];

  return (
    <div style={{ backgroundColor: "#f2f2f2", height: "100vh" }}>
      <AdminDrawer>
        <div style={{ padding: "16px" }}>
          <Box display="flex">
            <Typography variant="h4" fontWeight="bolder">
              User Management
            </Typography>
            <Box ml={3} mt={1}>
              <BreadcrumbsComponent />
            </Box>
          </Box>

          <PayoutTable rows={users} columns={columns} />
        </div>
      </AdminDrawer>
    </div>
  );
};

export default UserManagementPage;

import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Avatar } from '@mui/material';
import defaultImage from '../../../assets/images/menuImageOne.jpg';

const OrderItemList = ({ items }) => {
  console.log('items', items);

  // Safely flatten the items array to extract package, subpackage, and item details
  const itemDetails = items?.flatMap((packageDetails) =>
    packageDetails.subPackages?.flatMap((subPackage) =>
      subPackage.selectedItems?.map((item) => ({
        packageName: packageDetails.packageId?.packageName || 'Unknown Package',
        subPackageName: subPackage.subPackageId?.subPackageName || 'Unknown SubPackage',
        itemName: item.itemName || 'Unknown Item',
        itemId: item._id,
        quantity: item.quantity || 0,
        price: item.price || 0,
        image: item.image || defaultImage,
      })) || []
    ) || []
  ) || [];

  console.log('itemDetails', itemDetails);

  return (
    <TableContainer component={Paper} sx={{ marginTop: 2 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>Package Name</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Quantity</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Unit Price</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Total</TableCell>
          </TableRow>
        </TableHead>
        
        <TableBody>
          {/* Render the derived itemDetails */}
          {/* {itemDetails.map((item, index) => (
            <TableRow key={index}>
              
              <TableCell>{item.packageName || 'N/A'}</TableCell>
              <TableCell>{item.quantity || 'No description available'}</TableCell>
              <TableCell>{item.price}</TableCell>
              <TableCell>${item.price?.toFixed(2)}</TableCell>
            </TableRow>
          ))} */}

          {/* Optionally, also map over the original items array if needed */}
          {items?.map((item, index) => (
            <TableRow key={index}>
            
              <TableCell>{item.packageId?.packageName || 'N/A'}</TableCell>
              <TableCell>{item.guestCount || 'No description available'}</TableCell>
              <TableCell>₹{item.pricePerPerson || '0'}</TableCell>
              <TableCell>₹{item.totalAmount?.toFixed(2) || '0.00'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        
      </Table>
    </TableContainer>
  );
};

export default OrderItemList;

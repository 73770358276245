import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Autocomplete,
  Typography,
  Paper,
  Grid,
  Box,
  Checkbox,
  IconButton,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import AdminDrawer from "../../../components/AdminDrawer/AdminDrawer";
import {
  fetchSubPackages,
  fetchPackages,
  createItem,
} from "../../../api/catererPackages/catererItems";
import CustomSnackbar from "../../../components/CustomeSnackBar/CustomSnackbar";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchItemById,
  updateItem,
} from "../../../api/catererPackages/catererItems";
import BreadcrumbsComponent from "../../../components/ResuableComponents/BreadCrumb";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// Define icons for the checkboxes
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const allocationOptions = [{ title: "General" }, { title: "Premium" }];

const typeOptions = [{ title: "Veg" }, { title: "Non-Veg" }];

const AddItems = () => {
  const [itemName, setItemName] = useState("");
  const [itemDescription, setItemDescription] = useState("");
  const [packages, setPackages] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [selectedSubPackage, setSelectedSubPackage] = useState(null);
  const [subPackages, setSubPackages] = useState([]);
  const [selectedAllocationType, setSelectedAllocationType] = useState([]);
  const [itemImage, setItemImage] = useState(null);
  const [selectedType, setSelectedType] = useState({ title: "" });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const navigate = useNavigate();
  const { id } = useParams(); // Get the item ID from the route
  const handleBackClick = () => {
    navigate(-1); // This will take the user back to the previous page
  };

  useEffect(() => {
    const getSubPackages = async () => {
      const data = await fetchSubPackages();
      setSubPackages(data);
    };

    const getPackages = async () => {
      const data = await fetchPackages();
      setPackages(data);
    };

    getSubPackages();
    getPackages();
    if (id) {
      const fetchItemData = async () => {
        const item = await fetchItemById(id);
        setItemName(item.itemName);
        setItemDescription(item.itemDescription);
        setSelectedPackages(item.packages); // Ensure this matches the expected structure
        setSelectedSubPackage(item.subPackage);
        setSelectedType({ title: item.type }); // Ensures selectedType is an object with { title: "Veg" } format
        setSelectedAllocationType(
          item.allocatedTo.map((allocation) => ({ title: allocation }))
        );
        // Set item image if needed
      };
      fetchItemData();
    }
  }, [id]);

  // Handle form changes
  const handleItemNameChange = (event) => setItemName(event.target.value);
  const handleItemDescriptionChange = (event) =>
    setItemDescription(event.target.value);
  const handlePackageChange = (event, newValue) => {
    setSelectedPackages(newValue);
  };
  const handleSubPackageChange = (event, newValue) =>
    setSelectedSubPackage(newValue);
  const handleAllocationTypeChange = (event, newValue) =>
    setSelectedAllocationType(newValue);
  const handleImageUpload = (event) => setItemImage(event.target.files[0]);

  const handleSnackbarClose = () => setSnackbarOpen(false);

  // Handle form submission
  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("itemName", itemName);
    formData.append("itemDescription", itemDescription);
    if (selectedSubPackage)
      formData.append("subPackageId", selectedSubPackage._id);

    // Append selected packages to FormData
    selectedPackages.forEach((pkg) => {
      formData.append("packageIds[]", pkg._id);
    });

    // Append selected allocation types to FormData
    selectedAllocationType.forEach((allocation) => {
      formData.append("allocatedTo[]", allocation.title);
    });

    if (selectedType.title) {
      formData.append("type", selectedType.title); // selectedType.title contains the correct value
    }

    if (itemImage) {
      formData.append("itemImages", itemImage); // Assuming single image upload
    }

    try {
      const response = id
        ? await updateItem(id, formData)
        : await createItem(formData);
      if (response) {
        setSnackbarMessage(
          id ? "Item updated successfully!" : "Item created successfully!"
        );
        setSnackbarSeverity("success");
        setSnackbarOpen(true);

        // Navigate to the previous page after a brief delay
        setTimeout(() => {
          navigate(-1); // Go back to the previous page
        }, 500); // 1.5-second delay
      } else {
        setSnackbarMessage("Failed to create item.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage("An error occurred. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#f2f2f2",
        padding: "20px",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <AdminDrawer>
      <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={handleBackClick}
            
          >
            <ArrowBackIcon />
          </IconButton>
        <Paper
          elevation={0}
          sx={{
            margin: "0 auto",
            padding: "20px",
            backgroundColor: "white",
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box display="flex">
            <Typography
              variant="h5"
              fontWeight="bold"
              gutterBottom
              mb={3}
              mr={3}
            >
              {id ? "Edit Item" : "Add Item"}
            </Typography>
            <BreadcrumbsComponent />
          </Box>

          <div>
            {/* Item Name and Allocation Type */}
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={6}>
                <Typography component="legend">Item Name</Typography>
                <TextField
                  fullWidth
                  placeholder="Enter Item Name Here"
                  variant="outlined"
                  size="small"
                  value={itemName}
                  onChange={handleItemNameChange}
                  sx={{ marginBottom: 2, marginTop: 1 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography component="legend">Allocation Type</Typography>
                <Autocomplete
                  multiple
                  options={allocationOptions}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.title}
                  value={selectedAllocationType}
                  onChange={handleAllocationTypeChange}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={selected}
                        style={{ marginRight: 8 }}
                      />
                      {option.title}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      placeholder="Select Allocation Type"
                    />
                  )}
                  sx={{ marginBottom: 2, marginTop: 1 }}
                />
              </Grid>
            </Grid>

            {/* Package and Subpackage Selection */}
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={6}>
                <Typography component="legend">Select Package</Typography>
                <Autocomplete
                  multiple
                  options={packages}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.packageName}
                  value={selectedPackages}
                  onChange={handlePackageChange}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={selected}
                        style={{ marginRight: 8 }}
                      />
                      {option.packageName}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      placeholder="Select Package"
                    />
                  )}
                  sx={{ marginBottom: 2, marginTop: 1 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography component="legend">Select Subpackage</Typography>
                <Autocomplete
                  options={subPackages}
                  getOptionLabel={(option) => option.subPackageName}
                  value={selectedSubPackage}
                  onChange={handleSubPackageChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      placeholder="Select Subpackage"
                    />
                  )}
                  sx={{ marginBottom: 2, marginTop: 1 }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography component="legend">Type</Typography>
              <Autocomplete
                options={typeOptions}
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.title
                }
                value={selectedType.title} // Access the title property here
                onChange={(event, newValue) =>
                  setSelectedType(
                    newValue
                      ? {
                          title:
                            typeof newValue === "string"
                              ? newValue
                              : newValue.title,
                        }
                      : { title: "" }
                  )
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    placeholder="Select Type"
                  />
                )}
                sx={{ marginBottom: 2, marginTop: 1 }}
              />
            </Grid>

            {/* Item Description and Image Upload */}
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12}>
                <Typography component="legend">Item Description</Typography>
                <TextField
                  fullWidth
                  placeholder="Enter Item Description Here"
                  variant="outlined"
                  size="small"
                  value={itemDescription}
                  onChange={handleItemDescriptionChange}
                  multiline
                  rows={4}
                  sx={{ marginBottom: 2, marginTop: 1 }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography component="legend" sx={{ marginBottom: 1.5 }}>
                  Upload Item Image
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="file"
                  onChange={handleImageUpload}
                  size="small"
                  sx={{ marginBottom: 2, marginTop: 1 }}
                />
                {itemImage && (
                  <Box mt={2}>
                    <Typography>{itemImage.name}</Typography>
                  </Box>
                )}
              </Grid>
            </Grid>

            {/* Submit Button */}
            <Box display="flex" justifyContent="flex-end" mt={4}>
              <Button
                variant="contained"
                color="success"
                sx={{ width: "200px", padding: "10px" }}
                onClick={handleSubmit}
              >
                {id ? "Update Item" : "Add Item"}
              </Button>
            </Box>
          </div>
        </Paper>

        {/* Custom Snackbar */}
        <CustomSnackbar
          open={snackbarOpen}
          message={snackbarMessage}
          severity={snackbarSeverity}
          onClose={handleSnackbarClose}
        />
      </AdminDrawer>
    </div>
  );
};

export default AddItems;

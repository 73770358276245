import React, { useState, useEffect } from "react";
import { Tab, Tabs, Box, Container, Paper } from "@mui/material";
import DeliveryPartnerDetailsTab from "./DeliveryPartnerDetailsTab"; // Assuming it's already created
import DeliveryPartnerOrderTab from "./DeliveryPartnerOrdersTab"; // You need to create the Orders component

const DeliveryPartnerPage = () => {
  const [activeTab, setActiveTab] = useState(0); // To control the active tab

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Container maxWidth="lg">
      <Paper sx={{ marginTop: 2, }}>
        {/* Tab Bar */}
        <Tabs value={activeTab} onChange={handleTabChange} centered>
          <Tab label="Delivery Partner Details" />
          <Tab label="Orders" />
        </Tabs>

        {/* Tab Content */}
        <Box sx={{ marginTop: 3 }}>
          {activeTab === 0 && <DeliveryPartnerDetailsTab />} {/* Delivery Partner Details Tab */}
          {activeTab === 1 && <DeliveryPartnerOrderTab />} {/* Orders Tab */}
        </Box>
      </Paper>
    </Container>
  );
};

export default DeliveryPartnerPage;

import imageCompression from 'browser-image-compression';
import baseUrl from '../../config';

export const createSubPackage = async ({ subPackageName, allocation,selectedType, images }) => {

    console.log('selectedType in api',selectedType);
    
    const accessToken = localStorage.getItem('accessToken')?.replace(/"/g, '');

    if (!accessToken) {
        throw new Error('No access token found');
    }

    const formData = new FormData();

    // Append form data
    formData.append('subPackageName', subPackageName);
    selectedType.forEach((selectedType) => formData.append('type[]',selectedType.title));
    allocation.forEach((allocation) => formData.append('allocatedTo[]', allocation.title));



    const compressedImages = [];
    for (let image of images) {
        if (image.size > 2 * 1024 * 1024) {
            alert('File size should be less than 2MB');
            return;
        }

        const options = {
            maxSizeMB: 2,
            maxWidthOrHeight: 800,
            useWebWorker: true,
        };

        try {
            const compressedImage = await imageCompression(image, options);
            compressedImages.push(compressedImage);
        } catch (error) {
            console.error('Error while compressing the image:', error);
            throw error;
        }
    }

    compressedImages.forEach((image) => formData.append('subPackageImages', image));

    try {
        const response = await fetch(`${baseUrl}menu/create_subPackage`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
            body: formData,
        });

        if (!response.ok) {
            const responseBody = await response.text(); 
            throw new Error(`Failed to create subpackage: ${responseBody}`);
        }

        return response.json();
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const getSubPackageById = async (id) => {
    const accessToken = localStorage.getItem('accessToken')?.replace(/"/g, '');
    if (!accessToken) {
        throw new Error('No access token found');
    }

    try {
        const response = await fetch(`${baseUrl}menu/subPackage/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
        });

        if (!response.ok) {
            throw new Error(`Failed to fetch subpackage: ${response.statusText}`);
        }

        return response.json();
    } catch (error) {
        console.error('Error fetching subpackage details:', error);
        throw error;
    }
};

export const updateSubPackage = async (id, { subPackageName, allocation,selectedType, images }) => {
    const accessToken = localStorage.getItem('accessToken')?.replace(/"/g, '');
    if (!accessToken) {
        throw new Error('No access token found');
    }

    const formData = new FormData();
    formData.append('subPackageName', subPackageName);

    // Append only the title string to the form data
    selectedType.forEach((item) => formData.append('type[]',item.title));
    allocation.forEach((item) => formData.append('allocatedTo[]', item.title));
    images.forEach((image) => formData.append('subPackageImages', image));

    try {
        const response = await fetch(`${baseUrl}menu/update_subPackage/${id}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`Failed to update subpackage: ${response.statusText}`);
        }

        return response.json();
    } catch (error) {
        console.error('Error updating subpackage:', error);
        throw error;
    }
};




export const deleteSubPackage = async (subpackageId) => {
    const accessToken = localStorage.getItem('accessToken')?.replace(/"/g, '');

    if (!accessToken) {
        throw new Error('No access token found');
    }

    try {
        const response = await fetch(`${baseUrl}menu/delete_subPackage/${subpackageId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
        });

        if (!response.ok) {
            const responseBody = await response.text();
            throw new Error(`Failed to delete subpackage: ${responseBody}`);
        }

        return response.json();
    } catch (error) {
        console.error('Error deleting subpackage:', error);
        throw error;
    }
};

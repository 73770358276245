import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Typography, Box } from '@mui/material';

const DateFilterModal = ({ open, handleClose, applyFilter }) => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const handleApply = () => {
        applyFilter({ startDate, endDate });
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { borderRadius: 3, maxWidth: 550, padding: 2 } }}>
            <DialogTitle>
                <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                    Filter by Date
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <TextField
                        label="Start Date"
                        type="date"
                        fullWidth
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        margin="normal"
                        sx={{ backgroundColor: '#f5f5f5', borderRadius: 1 }}
                    />
                    <TextField
                        label="End Date"
                        type="date"
                        fullWidth
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        margin="normal"
                        sx={{ backgroundColor: '#f5f5f5', borderRadius: 1 }}
                    />
                </Box>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', gap: 2 }}>
                <Button onClick={handleClose} sx={{ fontWeight: 'bold' }}>Cancel</Button>
                <Button onClick={handleApply} variant="contained" color="primary" sx={{ fontWeight: 'bold' }}>
                    Apply
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DateFilterModal;

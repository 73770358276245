import baseUrl from "../../config";


export const fetchRestaurantReports = async () => {
    try {
      const response = await fetch(`${baseUrl}reports/restaurant`);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching Restaurant Reports:', error);
      return [];
    }
  };
import baseUrl from "../../config";


export const fetchUserReports = async () => {
    try {
      const response = await fetch(`${baseUrl}reports/user`);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching Restaurant Reports:', error);
      return [];
    }
  };
import React from 'react';
import { Typography, Chip } from '@mui/material';
import AdminDrawer from '../../../components/AdminDrawer/AdminDrawer';
import OrdersTable from '../../../components/DataTables/OrdersTables/OrdersTable';

const orderData = [
    { id: 16, orderId: "ORD138", customerName: "Arjun Patel", orderType: "Delivery", deliveryPerson: "Neha Joshi", restaurant: "Punjabi Tadka", date: "5th September 2024", address: "T-167, Sector 18, Gurugram, Haryana, India", paymentMode: "Credit Card", total: "$130", status: "Delivered" },
    { id: 17, orderId: "ORD139", customerName: "Priya Desai", orderType: "Pickup", deliveryPerson: "Rahul Verma", restaurant: "Bombay Chaat", date: "6th September 2024", address: "U-178, Bandra West, Mumbai, Maharashtra, India", paymentMode: "Debit Card", total: "$60", status: "Accepted" },
    { id: 18, orderId: "ORD140", customerName: "Vikas Singh", orderType: "Delivery", deliveryPerson: "Ajay Sharma", restaurant: "Chennai Express", date: "7th September 2024", address: "V-189, Indiranagar, Bangalore, Karnataka, India", paymentMode: "Cash", total: "$85", status: "Pending" },
    { id: 19, orderId: "ORD141", customerName: "Shweta Kumari", orderType: "Pickup", deliveryPerson: "Meena Rao", restaurant: "Goan Flavours", date: "8th September 2024", address: "W-200, Panjim, Goa, India", paymentMode: "Credit Card", total: "$110", status: "Cancelled" },
    { id: 20, orderId: "ORD142", customerName: "Raghav Jha", orderType: "Delivery", deliveryPerson: "Sunita Mehta", restaurant: "Biryani House", date: "9th September 2024", address: "X-211, Salt Lake, Kolkata, West Bengal, India", paymentMode: "Debit Card", total: "$90", status: "Delivered" },
    { id: 21, orderId: "ORD143", customerName: "Kavita Rao", orderType: "Pickup", deliveryPerson: "Vikram Khanna", restaurant: "Hyderabadi Biryani", date: "10th September 2024", address: "Y-222, Jubilee Hills, Hyderabad, Telangana, India", paymentMode: "Cash", total: "$100", status: "Accepted" },
    { id: 22, orderId: "ORD144", customerName: "Suresh Reddy", orderType: "Delivery", deliveryPerson: "Rani Gupta", restaurant: "Thai Delight", date: "11th September 2024", address: "Z-233, Sector 62, Noida, Uttar Pradesh, India", paymentMode: "Credit Card", total: "$140", status: "Pending" },
    { id: 23, orderId: "ORD145", customerName: "Anjali Sen", orderType: "Pickup", deliveryPerson: "Vikash Yadav", restaurant: "South Indian Bistro", date: "12th September 2024", address: "A-244, MG Road, Bengaluru, Karnataka, India", paymentMode: "Debit Card", total: "$95", status: "Cancelled" },
    { id: 24, orderId: "ORD146", customerName: "Nikhil Chawla", orderType: "Delivery", deliveryPerson: "Karan Singh", restaurant: "Maharaja Dhaba", date: "13th September 2024", address: "B-255, Model Town, Ludhiana, Punjab, India", paymentMode: "Cash", total: "$150", status: "Delivered" },
    { id: 25, orderId: "ORD147", customerName: "Sneha Arora", orderType: "Pickup", deliveryPerson: "Pooja Roy", restaurant: "Malabar Kitchen", date: "14th September 2024", address: "C-266, Marine Drive, Mumbai, Maharashtra, India", paymentMode: "Credit Card", total: "$105", status: "Pending" },
    { id: 26, orderId: "ORD148", customerName: "Rahul Bhatia", orderType: "Delivery", deliveryPerson: "Seema Rani", restaurant: "Kashmiri Kebab", date: "15th September 2024", address: "D-277, Sector 19, Faridabad, Haryana, India", paymentMode: "Debit Card", total: "$115", status: "Accepted" },
    { id: 27, orderId: "ORD149", customerName: "Isha Kapoor", orderType: "Pickup", deliveryPerson: "Manoj Kumar", restaurant: "Chinese Wok", date: "16th September 2024", address: "E-288, Sector 14, Gurugram, Haryana, India", paymentMode: "Cash", total: "$75", status: "Cancelled" },
    { id: 28, orderId: "ORD150", customerName: "Amitabh Roy", orderType: "Delivery", deliveryPerson: "Sonia Mehta", restaurant: "Rajma Chawal Junction", date: "17th September 2024", address: "F-299, South City, Ludhiana, Punjab, India", paymentMode: "Credit Card", total: "$130", status: "Delivered" },
    { id: 29, orderId: "ORD151", customerName: "Mona Singh", orderType: "Pickup", deliveryPerson: "Nitin Sharma", restaurant: "Bengali Sweets", date: "18th September 2024", address: "G-310, Chandni Chowk, Delhi, India", paymentMode: "Debit Card", total: "$55", status: "Accepted" },
    { id: 30, orderId: "ORD152", customerName: "Vikash Chandra", orderType: "Delivery", deliveryPerson: "Shivani Mehta", restaurant: "Rajasthani Rasoi", date: "19th September 2024", address: "H-321, Vaishali Nagar, Jaipur, Rajasthan, India", paymentMode: "Cash", total: "$120", status: "Pending" },
];


const columns = [
    { field: 'orderId', headerName: 'Order ID', flex: 1 },
    { field: 'customerName', headerName: 'Customer Name', flex: 1 },
    { field: 'orderType', headerName: 'Order Type', flex: 1 },
    { field: 'deliveryPerson', headerName: 'Delivery Person', flex: 1 },
    { field: 'restaurant', headerName: 'Restaurant', flex: 1 },
    {
        field: 'status', headerName: 'Status', flex: 1, sortable: true, renderCell: (params) => {
            const statusColors = {
                Delivered: "success",
                Cancelled: "error",
                Pending: "warning",
                Accepted: "info"
            };
            return (
                <Chip
                    label={params.value}
                    color={statusColors[params.value] || "default"}
                />
            );
        }
    },
    { field: 'date', headerName: 'Date', flex: 1 },
    { field: 'address', headerName: 'Address', flex: 2 },
    { field: 'paymentMode', headerName: 'Payment Mode', flex: 1 },
    { field: 'total', headerName: 'Total', flex: 1 },
];

const PartyHallOrders = () => {
    return (
        <div style={{ backgroundColor: '#f2f2f2', height: '100vh' }}>
            <AdminDrawer>
                <div style={{ padding: '16px' }}>
                    <Typography variant='h4' fontWeight='bolder'>Party Hall Orders</Typography>
                    <OrdersTable
                        rows={orderData}
                        columns={columns}
                    />
                </div>
            </AdminDrawer>
        </div>
    );
}

export default PartyHallOrders;

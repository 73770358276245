import React from 'react';
import { MenuItem, Select, InputLabel, FormControl } from '@mui/material';

const StatusFilter = ({ statusFilter, setStatusFilter }) => {
    const handleChange = (event) => {
        setStatusFilter(event.target.value);
    };

    return (
        <FormControl size="small" variant="outlined" fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
                sx={{
                    width: '20%', 
                }}
                value={statusFilter}
                onChange={handleChange}
                label="Status"
                defaultValue=""
            >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="newOrder">New Order</MenuItem>
                <MenuItem value="preparing">Preparing</MenuItem>
                <MenuItem value="pickedUp">Picked Up</MenuItem>
                <MenuItem value="orderReady">Order Ready</MenuItem>
                <MenuItem value="Delivered">Delivered</MenuItem>
                <MenuItem value="Cancelled">Cancelled</MenuItem>
                <MenuItem value="Refunded">Refunded</MenuItem>
                <MenuItem value="rejected">Rejected</MenuItem>
            </Select>
        </FormControl>
    );
};

export default StatusFilter;

import React, { useEffect, useState } from 'react';
import { Paper, CardContent, Typography, List, ListItem, ListItemIcon, ListItemText, CircularProgress } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { green } from '@mui/material/colors';
import BASE_URL from '../../config';

const DashBoardTopRestaurants = () => {
    const [restaurants, setRestaurants] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchTopRestaurants = async () => {
            try {
                const response = await fetch(`${BASE_URL}dashboard/topRestaurants`);
                if (!response.ok) throw new Error("Failed to fetch top restaurants");
                const data = await response.json();
                setRestaurants(data.topRestaurants.slice(0, 10)); // Limit to top 10 restaurants
            } catch (error) {
                console.error("Error fetching top restaurants:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchTopRestaurants();
    }, []);

    return (
        <Paper sx={{ maxWidth: 400, borderRadius: 2 }} elevation={0}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Top Restaurants
                </Typography>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <List>
                        {restaurants.length > 0 ? restaurants.map((restaurant, index) => (
                            <ListItem key={index} disableGutters>
                                <ListItemIcon>
                                    <LocationOnIcon sx={{ color: green[500] }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={restaurant.name}
                                    secondary={`Orders: ${restaurant.restaurantOrders}`} // Updated field name
                                />
                                <Typography variant="body2" sx={{ color: green[500], fontWeight: 'bold' }}>
                                    ₹ {parseFloat(restaurant.restaurantAmount).toFixed(2)} {/* Convert to float and format */}
                                </Typography>
                            </ListItem>
                        )) : (
                            <Typography variant="body2">No top restaurants available</Typography>
                        )}
                    </List>
                )}
            </CardContent>
        </Paper>
    );
};

export default DashBoardTopRestaurants;

// src/api/catersItems.js

import baseUrl from "../../config";

export const fetchSubPackages = async () => {
    try {
        const response = await fetch(`${baseUrl}menu/subPackages`);
        const data = await response.json();
        if (response.ok && data) {
            return data; // Return the data if the response is successful
        } else {
            console.error('Failed to fetch subpackages:', data.message);
            return []; // Return an empty array on failure
        }
    } catch (error) {
        console.error('Error fetching subpackages:', error);
        return []; // Return an empty array in case of error
    }
};


export const fetchPackages = async () => {
    try {
        const response = await fetch(`${baseUrl}menu/packages`);
        const data = await response.json();
        if (response.ok && data) {
            return data;
        } else {
            console.error('Failed to fetch packages:', data.message);
            return [];
        }
    } catch (error) {
        console.error('Error fetching packages:', error);
        return [];
    }
};

// src/api/catererItems.js

export const createItem = async (formData) => {
    try {
        const response = await fetch(`${baseUrl}menu/create_item`, {
            method: 'POST',
            body: formData, // send form data directly
        });
        
        const data = await response.json();
        
        if (response.ok && data) {
            return data; // return success response
        } else {
            console.error('Failed to create item:', data.message);
            return null; // return null on failure
        }
    } catch (error) {
        console.error('Error creating item:', error);
        return null;
    }
};


export const fetchItemById = async (id) => {
    try {
        const response = await fetch(`${baseUrl}menu/item/${id}`);
        if (response.ok) {
            return await response.json();
        }
        throw new Error('Failed to fetch item');
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const updateItem = async (id, formData) => {
    try {
        const response = await fetch(`${baseUrl}menu/update/item/${id}`, {
            method: 'PUT',
            body: formData,
        });
        return response.ok;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const deleteItem = async (itemId) => {
    const response = await fetch(`${baseUrl}menu/delete/item/${itemId}`, {
        method: 'DELETE',
    });

    if (!response.ok) {
        throw new Error('Failed to delete item');
    }

    return response.json();
};

import React from 'react';
import { Typography, Chip } from '@mui/material';
import AdminDrawer from '../../../components/AdminDrawer/AdminDrawer';
import OrdersTable from '../../../components/DataTables/OrdersTables/OrdersTable';

const orderData = [
    { id: 46, orderId: "ORD168", customerName: "Aman Kapoor", orderType: "Delivery", deliveryPerson: "Kishore Patel", restaurant: "Kolkata Biryanis", date: "5th October 2024", address: "X-497, Rajarhat, Kolkata, West Bengal, India", paymentMode: "Credit Card", total: "$150", status: "Delivered" },
    { id: 47, orderId: "ORD169", customerName: "Pooja Reddy", orderType: "Pickup", deliveryPerson: "Ravi Sharma", restaurant: "Bengaluru Spice", date: "6th October 2024", address: "Y-508, Koramangala, Bangalore, Karnataka, India", paymentMode: "Debit Card", total: "$95", status: "Accepted" },
    { id: 48, orderId: "ORD170", customerName: "Rajesh Menon", orderType: "Delivery", deliveryPerson: "Madhav Nair", restaurant: "Kerala Cafe", date: "7th October 2024", address: "Z-519, Fort Kochi, Kochi, Kerala, India", paymentMode: "Cash", total: "$110", status: "Pending" },
    { id: 49, orderId: "ORD171", customerName: "Neha Gupta", orderType: "Pickup", deliveryPerson: "Arvind Kumar", restaurant: "Hyderabad Delights", date: "8th October 2024", address: "A-530, Jubilee Hills, Hyderabad, Telangana, India", paymentMode: "Credit Card", total: "$125", status: "Cancelled" },
    { id: 50, orderId: "ORD172", customerName: "Vikram Singh", orderType: "Delivery", deliveryPerson: "Kiran Das", restaurant: "Punjabi Rasoi", date: "9th October 2024", address: "B-541, Sector 15, Chandigarh, India", paymentMode: "Debit Card", total: "$135", status: "Delivered" },
    { id: 51, orderId: "ORD173", customerName: "Anjali Verma", orderType: "Pickup", deliveryPerson: "Pavan Reddy", restaurant: "Mumbai Masala", date: "10th October 2024", address: "C-552, Bandra West, Mumbai, Maharashtra, India", paymentMode: "Cash", total: "$105", status: "Accepted" },
    { id: 52, orderId: "ORD174", customerName: "Rahul Desai", orderType: "Delivery", deliveryPerson: "Sandeep Kumar", restaurant: "Delhi Dhaba", date: "11th October 2024", address: "D-563, Connaught Place, Delhi, India", paymentMode: "Credit Card", total: "$140", status: "Pending" },
    { id: 53, orderId: "ORD175", customerName: "Kavita Chawla", orderType: "Pickup", deliveryPerson: "Mahesh Sharma", restaurant: "Goa Shack", date: "12th October 2024", address: "E-574, Panjim, Goa, India", paymentMode: "Debit Card", total: "$80", status: "Cancelled" },
    { id: 54, orderId: "ORD176", customerName: "Ravi Thakur", orderType: "Delivery", deliveryPerson: "Vikas Jain", restaurant: "Rajasthan Royals", date: "13th October 2024", address: "F-585, Vaishali Nagar, Jaipur, Rajasthan, India", paymentMode: "Cash", total: "$145", status: "Delivered" },
    { id: 55, orderId: "ORD177", customerName: "Sneha Kaur", orderType: "Pickup", deliveryPerson: "Amit Mehta", restaurant: "Punjab Grill", date: "14th October 2024", address: "G-596, Sector 17, Chandigarh, India", paymentMode: "Credit Card", total: "$115", status: "Accepted" },
    { id: 56, orderId: "ORD178", customerName: "Ajay Mishra", orderType: "Delivery", deliveryPerson: "Sunil Verma", restaurant: "Lucknowi Tandoori", date: "15th October 2024", address: "H-607, Gomti Nagar, Lucknow, Uttar Pradesh, India", paymentMode: "Debit Card", total: "$120", status: "Pending" },
    { id: 57, orderId: "ORD179", customerName: "Shalini Iyer", orderType: "Pickup", deliveryPerson: "Vivek Reddy", restaurant: "South Indian Bistro", date: "16th October 2024", address: "I-618, Anna Nagar, Chennai, Tamil Nadu, India", paymentMode: "Cash", total: "$95", status: "Cancelled" },
    { id: 58, orderId: "ORD180", customerName: "Pranav Joshi", orderType: "Delivery", deliveryPerson: "Karthik Menon", restaurant: "Mysore Palace", date: "17th October 2024", address: "J-629, Mysore Road, Bangalore, Karnataka, India", paymentMode: "Credit Card", total: "$130", status: "Delivered" },
    { id: 59, orderId: "ORD181", customerName: "Meera Nair", orderType: "Pickup", deliveryPerson: "Suresh Nair", restaurant: "Kerala Cafe", date: "18th October 2024", address: "K-640, Vyttila, Kochi, Kerala, India", paymentMode: "Debit Card", total: "$70", status: "Accepted" },
    { id: 60, orderId: "ORD182", customerName: "Rohit Patel", orderType: "Delivery", deliveryPerson: "Arun Shah", restaurant: "Gujarati Thali", date: "19th October 2024", address: "L-651, Navrangpura, Ahmedabad, Gujarat, India", paymentMode: "Cash", total: "$135", status: "Pending" },
];




const columns = [
    { field: 'orderId', headerName: 'Order ID', flex: 1 },
    { field: 'customerName', headerName: 'Customer Name', flex: 1 },
    { field: 'orderType', headerName: 'Order Type', flex: 1 },
    { field: 'deliveryPerson', headerName: 'Delivery Person', flex: 1 },
    { field: 'restaurant', headerName: 'Restaurant', flex: 1 },
    {
        field: 'status', headerName: 'Status', flex: 1, sortable: true, renderCell: (params) => {
            const statusColors = {
                Delivered: "success",
                Cancelled: "error",
                Pending: "warning",
                Accepted: "info"
            };
            return (
                <Chip
                    label={params.value}
                    color={statusColors[params.value] || "default"}
                />
            );
        }
    },
    { field: 'date', headerName: 'Date', flex: 1 },
    { field: 'address', headerName: 'Address', flex: 2 },
    { field: 'paymentMode', headerName: 'Payment Mode', flex: 1 },
    { field: 'total', headerName: 'Total', flex: 1 },
];

const FlowerOrders = () => {
    return (
        <div style={{ backgroundColor: '#f2f2f2', height: '100vh' }}>
            <AdminDrawer>
                <div style={{ padding: '16px' }}>
                    <Typography variant='h4' fontWeight='bolder'>Flower Orders</Typography>
                    <OrdersTable
                        rows={orderData}
                        columns={columns}
                    />
                </div>
            </AdminDrawer>
        </div>
    );
}

export default FlowerOrders;

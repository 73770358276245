import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    TextField,
    IconButton,
    InputAdornment,
    Typography,
    Box,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const ResetPasswordDialog = ({ open, onClose, onConfirm, title, role, email }) => {
    const [password, setPassword] = useState('');
    const [secretKey, setSecretKey] = useState(''); // State for secretKey
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        if (!open) {
            setPassword(''); // Clear password field when the dialog is closed
            setSecretKey(''); // Clear secretKey when dialog is closed
            setShowPassword(false); // Hide password when dialog is reopened
            setError(''); // Reset error message
        }
    }, [open]);

    const handlePasswordChange = (event) => {
        const newPassword = event.target.value;
        setPassword(newPassword);
        validatePassword(newPassword);
    };

    const handleSecretKeyChange = (event) => {
        setSecretKey(event.target.value);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const validatePassword = (password) => {
        let errorMsg = '';
        if (password.length < 8) {
            errorMsg = 'Password must be at least 8 characters long.';
        } else if (!/[A-Z]/.test(password)) {
            errorMsg = 'Password must contain at least one uppercase letter.';
        } else if (!/[a-z]/.test(password)) {
            errorMsg = 'Password must contain at least one lowercase letter.';
        } else if (!/[0-9]/.test(password)) {
            errorMsg = 'Password must contain at least one number.';
        } else if (!/[!@#$%^&*]/.test(password)) {
            errorMsg = 'Password must contain at least one special character (!@#$%^&*).';
        }

        setError(errorMsg); // Set the error message based on validation
    };

    const handleConfirm = () => {
        if (!error) {
            // Instead of preparing data here, just call onConfirm with the current state
            onConfirm(password, secretKey); // Pass password and secretKey directly to handleConfirmResetPassword
        }
    };
    
    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
            <DialogTitle>
                <Typography variant="h6" fontWeight="bold">
                    {title}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Box mt={2}>
                    <TextField
                        placeholder="Enter new password"
                        variant="outlined"
                        type={showPassword ? 'text' : 'password'}
                        fullWidth
                        value={password}
                        onChange={handlePasswordChange}
                        error={!!error} // Set error state
                        helperText={error} // Show error message below the input
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={togglePasswordVisibility}>
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    {role === 'super_admin' && (
                        <TextField
                            placeholder="Enter secret key"
                            variant="outlined"
                            fullWidth
                            value={secretKey}
                            onChange={handleSecretKeyChange}
                            sx={{ mt: 2 }} // Add margin-top for spacing
                        />
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="inherit" variant="contained">
                    <Typography fontSize={14} fontWeight="bold">Cancel</Typography>
                </Button>
                <Button onClick={handleConfirm} color="primary" variant="contained" disabled={!password || !!error}>
                    <Typography fontSize={14} fontWeight="bold">Reset Password</Typography>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ResetPasswordDialog;

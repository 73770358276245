import React, { useEffect, useState } from 'react';
import { Paper, CardContent, Typography, List, ListItem, ListItemAvatar, Avatar, ListItemText, CircularProgress } from '@mui/material';
import { green } from '@mui/material/colors';
import BASE_URL from '../../config';

const DashBoardOrdersCard = () => {
    const [recentOrders, setRecentOrders] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchRecentOrders = async () => {
            try {
                const response = await fetch(`${BASE_URL}dashboard/RecentOrders`);
                if (!response.ok) throw new Error("Failed to fetch recent orders");
                const data = await response.json();
                setRecentOrders(data.recentOrders.slice(0, 10)); 
            } catch (error) {
                console.error("Error fetching recent orders:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchRecentOrders();
    }, []);

    return (
        <Paper sx={{ maxWidth: 400, borderRadius: 2 }} elevation={0}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Recent Orders
                </Typography>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <List>
                        {recentOrders.length > 0 ? recentOrders.map((order, index) => (
                            <ListItem key={index} disableGutters>
                                <ListItemAvatar>
                                    <Avatar alt={order.name ? order.name : 'Customer'}>
                                        {order.name ? order.name[0] : '?' /* Display first letter of the name if available */}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={order.name ? order.name : 'Unknown'}
                                    secondary={`Orders: ${order.totalOrders}`}
                                />
                                <Typography variant="body2" sx={{ color: green[500], fontWeight: 'bold' }}>
                                    ₹ {parseFloat(order.totalAmount).toFixed(2)}
                                </Typography>
                            </ListItem>
                        )) : (
                            <Typography variant="body2">No recent orders available</Typography>
                        )}
                    </List>
                )}
            </CardContent>
        </Paper>
    );
};

export default DashBoardOrdersCard;

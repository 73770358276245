import React, { useState } from 'react';
import { TextField, Button, Select, MenuItem, FormControl, Typography, Paper, Grid, Box } from '@mui/material';
import AdminDrawer from '../../../../components/AdminDrawer/AdminDrawer';
import Map from '../../../../components/Maps/Map';

const stateCityData = {
    Maharashtra: ['Mumbai', 'Pune', 'Nagpur'],
    Karnataka: ['Bengaluru', 'Mysuru', 'Hubli'],
    TamilNadu: ['Chennai', 'Coimbatore', 'Madurai'],
    Delhi: ['New Delhi', 'Dwarka', 'Rohini'],
    UttarPradesh: ['Lucknow', 'Kanpur', 'Agra'],
    WestBengal: ['Kolkata', 'Siliguri', 'Asansol'],
    Rajasthan: ['Jaipur', 'Udaipur', 'Jodhpur'],
    Gujarat: ['Ahmedabad', 'Surat', 'Vadodara'],
    Punjab: ['Chandigarh', 'Amritsar', 'Ludhiana'],
    Haryana: ['Gurugram', 'Faridabad', 'Ambala'],
};

const AddMarquee = () => {
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');

    const [selectedAddress, setSelectedAddress] = useState('');

    const handleAddressChange = (address) => {
        setSelectedAddress(address);
    };

    // Handle state change
    const handleStateChange = (event) => {
        setSelectedState(event.target.value);
        setSelectedCity(''); // Reset city when state changes
    };

    // Handle city change
    const handleCityChange = (event) => {
        setSelectedCity(event.target.value);
    };


    return (
        <div style={{ backgroundColor: '#f2f2f2', padding: '20px', height: '100vh', display: 'flex', flexDirection: 'column' }}>
            <AdminDrawer>
                <Paper
                    elevation={0}
                    sx={{
                        margin: '0 auto',
                        padding: '20px',
                        backgroundColor: 'white',
                        flex: 1, // Allow Paper to expand
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Typography variant="h5" fontWeight='bold' gutterBottom mb={3}>
                        Marquee Details
                    </Typography>

                    {/* Form Fields */}
                    <div>
                        {/* Owner Name */}
                        <Grid container spacing={2} mb={2}>
                            <Grid item xs={12} sm={6}>
                                <Typography component="legend">Owner Name</Typography>
                                <TextField
                                    fullWidth
                                    placeholder="Enter Owner Name Here"
                                    name="ownerName"
                                    variant="outlined"
                                    size="small"
                                    sx={{ marginBottom: 2, marginTop: 1 }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography component="legend">Mobile No</Typography>
                                <TextField
                                    fullWidth
                                    placeholder="Enter Mobile Number Here"
                                    name="mobileNo"
                                    variant="outlined"
                                    size="small"
                                    sx={{ marginBottom: 2, marginTop: 1 }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                        </Grid>

                        {/* Mobile No and Email ID */}
                        <Grid container spacing={2} mb={2}>
                           
                            <Grid item xs={12} sm={6}>
                                <Typography component="legend">Email ID</Typography>
                                <TextField
                                    fullWidth
                                    placeholder="Enter Email ID Here"
                                    name="emailID"
                                    variant="outlined"
                                    size="small"
                                    sx={{ marginBottom: 2, marginTop: 1 }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography component="legend">Delivery Time</Typography>
                                <TextField
                                    fullWidth
                                    placeholder="Enter Delivery Time"
                                    name="deliveryTime"
                                    variant="outlined"
                                    size="small"
                                    sx={{ marginBottom: 2, marginTop: 1 }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                        </Grid>

                        {/* Entity Name and Entity Phone Number */}
                        <Grid container spacing={2} mb={2}>
                            <Grid item xs={12} sm={6}>
                                <Typography component="legend">Entity Name</Typography>
                                <TextField
                                    fullWidth
                                    placeholder="Enter Entity Name Here"
                                    name="entityName"
                                    variant="outlined"
                                    size="small"
                                    sx={{ marginBottom: 2, marginTop: 1 }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography component="legend">Entity Phone Number</Typography>
                                <TextField
                                    fullWidth
                                    placeholder="Enter Entity Number Here"
                                    name="entityPhoneNumber"
                                    variant="outlined"
                                    size="small"
                                    sx={{ marginBottom: 2, marginTop: 1 }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                        </Grid>

                        {/* State and City */}
                        <Grid container spacing={2} mb={2}>
                            <Grid item xs={12} sm={6}>
                                <Typography component="legend" sx={{ marginBottom: 1.5 }}>State</Typography>
                                <FormControl fullWidth variant="outlined" size="small">
                                    <Select value={selectedState} onChange={handleStateChange} displayEmpty>
                                        <MenuItem value="" disabled>Select State</MenuItem>
                                        {Object.keys(stateCityData).map((state) => (
                                            <MenuItem key={state} value={state}>{state}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography component="legend" sx={{ marginBottom: 1.5 }}>City</Typography>
                                <FormControl fullWidth variant="outlined" size="small">
                                    <Select
                                        value={selectedCity}
                                        onChange={handleCityChange}
                                        displayEmpty
                                        disabled={!selectedState}
                                    >
                                        <MenuItem value="" disabled>Select City</MenuItem>
                                        {selectedState &&
                                            stateCityData[selectedState].map((city) => (
                                                <MenuItem key={city} value={city}>{city}</MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        {/* Delivery Time */}
                        
                    </div>

                    {/* Map */}
                    <Grid container spacing={2} mb={2} style={{ flex: 1 }}>
                        <Grid item xs={12} style={{ height: '400px' }}>
                            <Map onAddressChange={handleAddressChange} mapContainerStyle={{ height: '100%' }} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography component="legend" >
                            Address
                        </Typography>
                        <TextField
                            disabled
                            fullWidth
                            placeholder="Enter Address"
                            name="address"
                            value={selectedAddress}
                            variant="outlined"
                            size="small"
                            sx={{ marginBottom: 2, marginTop: 1 }}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>

                    {/* About Us */}
                    <Grid container spacing={2} mb={2}>
                        <Grid item xs={12}>
                            <Typography component="legend" >
                                About Us
                            </Typography>
                            <TextField
                                fullWidth
                                placeholder="Enter About Us"
                                name="aboutUs"
                                variant="outlined"
                                multiline
                                rows={4}
                                size="small"
                                sx={{ marginBottom: 2, marginTop: 1 }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    </Grid>

                    {/* File Uploads */}
                    <Grid container spacing={2} mb={2}>
                        <Grid item xs={12} sm={6}>
                            <Typography component="legend" >
                                Upload Image
                            </Typography>
                            <TextField
                                fullWidth
                                name="uploadImage"
                                variant="outlined"
                                type="file"
                                size="small"
                                sx={{ marginBottom: 2, marginTop: 1 }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography component="legend" >
                                Upload Video
                            </Typography>
                            <TextField
                                fullWidth
                                name="uploadVideo"
                                variant="outlined"
                                type="file"
                                size="small"
                                sx={{ marginBottom: 2, marginTop: 1 }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    </Grid>

                    {/* File Uploads for FSSAI, PAN, GST */}                    <Grid container spacing={2} mb={2}>
                        <Grid item xs={12} sm={4}>
                            <Typography component="legend" >
                                Upload FSSAI
                            </Typography>
                            <TextField
                                fullWidth
                                name="uploadFSSAI"
                                variant="outlined"
                                type="file"
                                size="small"
                                sx={{ marginBottom: 2, marginTop: 1 }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Typography component="legend" >
                                Upload PAN
                            </Typography>
                            <TextField
                                fullWidth
                                name="uploadPAN"
                                variant="outlined"
                                type="file"
                                size="small"
                                sx={{ marginBottom: 2, marginTop: 1 }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Typography component="legend" >
                                Upload GST
                            </Typography>
                            <TextField
                                fullWidth
                                name="uploadGST"
                                variant="outlined"
                                type="file"
                                size="small"
                                sx={{ marginBottom: 2, marginTop: 1 }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    </Grid>

                    {/* Buttons */}
                    <Box display="flex" justifyContent="flex-end" mt={4}>
                        <Button
                            variant="contained" color="success"
                            sx={{ width: '200px', padding: '10px' }}
                        >
                            Submit
                        </Button>
                    </Box>
                </Paper>
            </AdminDrawer>
        </div>
    );
};

export default AddMarquee;

import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Paper,
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  Autocomplete,
} from "@mui/material";
import { fetchRestaurants } from "../../api/servicePartners/RestaurantApis";
import { fetchCaterers } from "../../api/servicePartners/CaterersApis";
import {
  createPromoCode,
  updatePromoCode,
} from "../../api/promocodes/promocodeApi";
import CustomSnackbar from "../../components/CustomeSnackBar/CustomSnackbar";
import AdminDrawer from "../../components/AdminDrawer/AdminDrawer";
import baseUrl from "../../config";
import BreadCrumbComponent from "../../components/ResuableComponents/BreadCrumb";

const AddPromoCodes = () => {
  const { id } = useParams(); // Get promo code ID from URL
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location
  const [title, setTitle] = useState("");
  const [restaurant, setRestaurant] = useState("");
  const [description, setDescription] = useState("");
  const [promocode, setPromocode] = useState("");
  const [offerType, setOfferType] = useState("");
  const [maxAmount, setMaxAmount] = useState("");
  const [minOrderValue, setMinOrderValue] = useState("");
  const [discount, setDiscount] = useState("");
  const [adminContribution, setAdminContribution] = useState("");
  const [usageLimitPerCoupon, setUsageLimitPerCoupon] = useState("");
  const [usageLimitPerUser, setUsageLimitPerUser] = useState("");
  const [availableFrom, setAvailableFrom] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [status, setStatus] = useState("");
  const [promocodeType, setPromocodeType] = useState("");
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [restaurantOptions, setRestaurantOptions] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [caterer, setCaterer] = useState("");
  const [catererOptions, setCatererOptions] = useState([]);

  const isCatererPage =
    location.pathname.includes("/add-caterer-promoCodes") ||
    location.pathname.includes("/edit-caterer-promoCodes");
  const isRestaurantPage =
    location.pathname.includes("/add-restaurant-promoCodes") ||
    location.pathname.includes("/edit-restaurant-promoCodes/");

  const handleSubmit = async () => {
    const promoData = {
      businessType: isRestaurantPage ? "restaurant" : "caterer",
      code: promocode,
      discountType: offerType,
      discountValue: parseFloat(discount),
      minOrderValue: parseFloat(minOrderValue),
      expiryDate,
      usageLimit: parseInt(usageLimitPerCoupon),
      promoDescription: description,
      maxAmount: parseFloat(maxAmount),
      adminContri: parseFloat(adminContribution),
      availableFrom,
      paymentcodeType: promocodeType
        .split(",")
        .map((codeType) => codeType.trim()),
      paymentMethods: paymentMethod.map((method) => method.trim()),
      [isRestaurantPage ? "restaurantId" : "catererId"]: isRestaurantPage
        ? restaurant._id
        : caterer._id, // Set restaurantId or catererId based on the page type
    };

    try {
      let response;
      if (id) {
        // Update existing promo code
        response = await updatePromoCode(id, promoData);
        if (response) {
          console.log("response", response);

          setSnackbarMessage("Promo code updated successfully");
          setTimeout(() => {
            navigate("/promoCodes");
          }, 500);
        } else {
          setSnackbarMessage("Failed to update promo code");
        }
      } else {
        // Create new promo code
        response = await createPromoCode(promoData);
        if (response) {
          setSnackbarMessage("Promo code created successfully");
          setTimeout(() => {
            navigate("/promoCodes");
          }, 500);
        } else {
          setSnackbarMessage("Failed to create promo code");
        }
      }
      setSnackbarSeverity(response ? "success" : "error");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error in handleSubmit:", error);
    }
  };

  useEffect(() => {
    const loadPromoCodeData = async () => {
      if (id) {
        try {
          const response = await fetch(`${baseUrl}promocode/${id}`);
          const promoCode = await response.json();
          // Populate form fields with the fetched data

          setTitle(promoCode.title || "");
          setRestaurant(promoCode.restaurantId || "");
          setDescription(promoCode.promoDescription || "");
          setPromocode(promoCode.code || "");
          setOfferType(promoCode.discountType || "");
          setMaxAmount(promoCode.maxAmount || "");
          setMinOrderValue(promoCode.minOrderValue || "");
          setDiscount(promoCode.discountValue || "");
          setAdminContribution(promoCode.adminContri || "");
          setUsageLimitPerCoupon(promoCode.usageLimit || "");
          setUsageLimitPerUser(promoCode.usageLimitPerUser || "");
          setAvailableFrom(promoCode.availableFrom || "");
          setExpiryDate(promoCode.expiryDate || "");
          setStatus(promoCode.status || "");
          setPromocodeType(promoCode?.paymentcodeType?.join(", ") || ""); // Assuming it's an array
          setPaymentMethod(promoCode?.paymentMethods?.join(", ") || ""); // Assuming it's an array
        } catch (error) {
          console.error("Failed to fetch promo code details:", error);
        }
      }
    };

    loadPromoCodeData();
  }, [id]);

  useEffect(() => {
    const loadRestaurants = async () => {
      try {
        const restaurants = await fetchRestaurants();
        setRestaurantOptions(restaurants?.restaurants); // Update the state with fetched data
      } catch (error) {
        console.error("Failed to fetch restaurants:", error);
      }
    };
    const loadCaterers = async () => {
      try {
        const caterers = await fetchCaterers();
        setCatererOptions(caterers?.caterers);
      } catch (error) {
        console.log("Failed to fetch Caterers", error);
      }
    };
    loadCaterers();
    loadRestaurants();
  }, []);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#f2f2f2",
          padding: "20px",
          height: "max-content",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <AdminDrawer>
          <BreadCrumbComponent />
          <Paper
            elevation={0}
            sx={{
              margin: "0 auto",
              padding: "20px",
              backgroundColor: "white",
              flex: 1,
              display: "flex",
              flexDirection: "column",
              maxWidth: "800px",
              width: "100%",
            }}
          >
            <Typography variant="h5" fontWeight="bolder" mb={3} gutterBottom>
              {id ? "Edit PromoCodes" : "Add PromoCodes"}
            </Typography>
            <Box component="form" noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography component="legend">Title</Typography>
                  <TextField
                    fullWidth
                    placeholder="Enter Title Here"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    variant="outlined"
                    size="small"
                    sx={{ marginBottom: 2, marginTop: 1 }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                {isRestaurantPage && (
                  <Grid item xs={12} sm={6}>
                    <Typography component="legend">Restaurant</Typography>
                    <Autocomplete
                      options={restaurantOptions} // Use the fetched options
                      getOptionLabel={(option) => option.restName || ""} // Ensure the label is from the option's name
                      value={restaurant}
                      onChange={(event, newValue) => setRestaurant(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          placeholder="Select Restaurant"
                          variant="outlined"
                          size="small"
                          sx={{ marginBottom: 2, marginTop: 1 }}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Grid>
                )}
                {isCatererPage && (
                  <Grid item xs={12} sm={6}>
                    <Typography component="legend">Caterers</Typography>
                    <Autocomplete
                      options={catererOptions} // Use the fetched options
                      getOptionLabel={(option) => option.businessName || ""} // Ensure the label is from the option's name
                      value={caterer}
                      onChange={(event, newValue) => setCaterer(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          placeholder="Select Caterer"
                          variant="outlined"
                          size="small"
                          sx={{ marginBottom: 2, marginTop: 1 }}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Typography component="legend">Description</Typography>
                  <TextField
                    fullWidth
                    placeholder="Enter Description Here"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    multiline
                    rows={3}
                    variant="outlined"
                    size="small"
                    sx={{ marginBottom: 2, marginTop: 1 }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography component="legend">Promo Code</Typography>
                  <TextField
                    fullWidth
                    placeholder="Enter Promo Code"
                    value={promocode}
                    onChange={(e) => setPromocode(e.target.value)}
                    variant="outlined"
                    size="small"
                    sx={{ marginBottom: 2, marginTop: 1 }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography component="legend">Offer Type</Typography>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    size="small"
                    sx={{ marginBottom: 2, marginTop: 1 }}
                  >
                    <Select
                      value={offerType}
                      onChange={(e) => setOfferType(e.target.value)}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select Offer Type
                      </MenuItem>
                      <MenuItem value="percentage">Percentage</MenuItem>
                      <MenuItem value="fixed">Fixed</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography component="legend">Maximum Amount</Typography>
                  <TextField
                    fullWidth
                    placeholder="Enter Maximum Amount"
                    value={maxAmount}
                    onChange={(e) => setMaxAmount(e.target.value)}
                    variant="outlined"
                    size="small"
                    sx={{ marginBottom: 2, marginTop: 1 }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography component="legend">
                    Minimum Order Value
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder="Enter Minimum Order Value"
                    value={minOrderValue}
                    onChange={(e) => setMinOrderValue(e.target.value)}
                    variant="outlined"
                    size="small"
                    sx={{ marginBottom: 2, marginTop: 1 }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography component="legend">
                    Discount {offerType === "fixed" ? "Amount" : "Percentage"}
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder="Enter Discount Percentage"
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)}
                    variant="outlined"
                    size="small"
                    sx={{ marginBottom: 2, marginTop: 1 }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography component="legend">Admin Contribution</Typography>
                  <TextField
                    fullWidth
                    placeholder="Enter Admin Contribution"
                    value={adminContribution}
                    onChange={(e) => setAdminContribution(e.target.value)}
                    variant="outlined"
                    size="small"
                    sx={{ marginBottom: 2, marginTop: 1 }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography component="legend">
                    Usage Limit Per Coupon
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder="Enter Usage Limit Per Coupon"
                    value={usageLimitPerCoupon}
                    onChange={(e) => setUsageLimitPerCoupon(e.target.value)}
                    variant="outlined"
                    size="small"
                    sx={{ marginBottom: 2, marginTop: 1 }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography component="legend">
                    Usage Limit Per User
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder="Enter Usage Limit Per User"
                    value={usageLimitPerUser}
                    onChange={(e) => setUsageLimitPerUser(e.target.value)}
                    variant="outlined"
                    size="small"
                    sx={{ marginBottom: 2, marginTop: 1 }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography component="legend">Available From</Typography>
                  <TextField
                    fullWidth
                    type="date" // Set input type to date
                    value={availableFrom}
                    onChange={(e) => setAvailableFrom(e.target.value)}
                    variant="outlined"
                    size="small"
                    sx={{ marginBottom: 2, marginTop: 1 }}
                    InputLabelProps={{
                      shrink: true, // Ensure the label stays above the field
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography component="legend">Expiry Date</Typography>
                  <TextField
                    fullWidth
                    type="date" // Set input type to date
                    value={expiryDate}
                    onChange={(e) => setExpiryDate(e.target.value)}
                    variant="outlined"
                    size="small"
                    sx={{ marginBottom: 2, marginTop: 1 }}
                    InputLabelProps={{
                      shrink: true, // Ensure the label stays above the field
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography component="legend">Status</Typography>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    size="small"
                    sx={{ marginBottom: 2, marginTop: 1 }}
                  >
                    <Select
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select Status
                      </MenuItem>
                      <MenuItem value="active">Active</MenuItem>
                      <MenuItem value="inactive">Inactive</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography component="legend">Promo Code Type</Typography>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    size="small"
                    sx={{ marginBottom: 2, marginTop: 1 }}
                  >
                    <Select
                      value={promocodeType}
                      placeholder="Select Promo Code Type"
                      onChange={(e) => setPromocodeType(e.target.value)}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select Promo Code Type
                      </MenuItem>
                      <MenuItem value="private">Private</MenuItem>
                      <MenuItem value="public">Public</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography component="legend">Payment Method</Typography>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    size="small"
                    sx={{ marginBottom: 2, marginTop: 1 }}
                  >
                    <Select
                      multiple
                      value={paymentMethod}
                      onChange={handlePaymentMethodChange}
                      placeholder="Select Payment Method"
                      displayEmpty
                      renderValue={(selected) => {
                        if (Array.isArray(selected) && selected.length) {
                          return selected.join(", ");
                        }
                        return "Select Payment Method"; // Default text when nothing is selected
                      }}
                    >
                      <MenuItem value="" disabled>
                        Select Payment Method
                      </MenuItem>
                      <MenuItem value="Cash">Cash</MenuItem>
                      <MenuItem value="Online">Online</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} display="flex" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    sx={{ marginTop: 2 }}
                  >
                    {id ? "Update" : "Submit"}
                  </Button>
                </Grid>
              </Grid>
              <CustomSnackbar
                open={snackbarOpen}
                message={snackbarMessage}
                severity={snackbarSeverity}
                onClose={handleCloseSnackbar}
              />
            </Box>
          </Paper>
        </AdminDrawer>
      </div>
    </>
  );
};

export default AddPromoCodes;

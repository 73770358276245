import React from "react";
import {
  Container,
  Paper,
  IconButton,
  Divider,
} from "@mui/material";
import { Print, Download, ArrowBack } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import OrderInvoiceHeader from "./OrderInvoiceHeader";
import OrderCustomerDetails from "./OrderCustomerDetails";
import OrderItemsTable from "./OrderItemsTable";
import OrderPriceSummary from "./OrderPriceSummary";
import PriceSummaryCaters from "./PriceSummaryCaters";
import CompanyDetails from "../PayoutOrderDetails/CompanyDetails";

const OrderInvoice = () => {
  const { state } = useLocation();
  const { orderData } = state || {};
  const navigate = useNavigate();

  console.log(orderData);
  

  const handlePrint = () => {
    window.print();
  };

  const handleDownload = () => {
    const content = document.getElementById("printable-content");
    const excludedElements = document.querySelectorAll(".exclude-from-download");

    // Completely hide excluded elements during PDF generation
    excludedElements.forEach((element) => {
      element.style.display = "none";
    });

    html2canvas(content, {
      scale: 2,
      useCORS: true,
      allowTaint: false,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const imgWidth = 210;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft > 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save("order-details.pdf");

      // Restore visibility of excluded elements after download
      excludedElements.forEach((element) => {
        element.style.display = "";
      });
    });
  };

  const catererItemData = orderData?.items[0];

  return (
    <Container
      sx={{
        padding: 4,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#f5f5f5",
        position: "relative",
        flexDirection: "column",
      }}
    >
      <IconButton
        onClick={() => navigate(-1)}
        sx={{
          position: "absolute",
          top: 16,
          left: 16,
        }}
      >
        <ArrowBack />
      </IconButton>

      <IconButton
        id="print-button"
        onClick={handlePrint}
        sx={{
          position: "absolute",
          top: 16,
          right: 76,
        }}
      >
        <Print />
      </IconButton>

      <IconButton
        id="download-button"
        onClick={handleDownload}
        sx={{
          position: "absolute",
          color: "green",
          top: 16,
          right: 16,
        }}
      >
        <Download />
      </IconButton>

      <Paper
        id="printable-content"
        sx={{
          padding: 4,
          width: "100%",
          maxWidth: "900px",
          backgroundColor: "#fff",
        }}
      >
        <OrderInvoiceHeader
          invoiceNumber={orderData.userInvoiceId}
          invoiceDate={orderData?.orderDate}
          orderNumber={orderData?.orderID}
          restaurantName={orderData?.catererId?.businessName}
          restaurantAddress={orderData?.catererId?.address}
          restaurantGST={orderData?.catererId?.gstNo}
        />

        <OrderCustomerDetails
          customerName={orderData?.userId?.fullName}
          customerAddress={orderData?.shippingAddress}
          customerPhone={orderData?.userId?.phoneNumber}
        />

        <OrderItemsTable
          items={[
            {
              particulars: catererItemData?.packageId?.packageName,
              qty: catererItemData?.guestCount,
              price: catererItemData?.pricePerPerson,
              grossValue: orderData?.grossAmount,
              discount: 0,
              total: catererItemData?.totalAmount,
              additionalTissueCharges: orderData?.additionalTissueCharges,
              additionlWBCharges: orderData?.additionlWBCharges,
              buffetCharge: orderData?.buffetCharge,
              tissuePackets: orderData?.tissuePackets,
              waterBottlePackets: orderData?.waterBottlePackets,
              wbCharges: orderData?.wbCharges,
              tissueCharges: orderData?.tissueCharges,
            },
          ]}
        />

        <OrderPriceSummary
          totalValue={orderData?.totalAmount}
          platformFeeGst={orderData?.platformFeeGst}
          gstTotal={orderData?.gst}
          platformFee={orderData?.platformFee}
          deliveryFee={orderData?.deliveryFee}
          discountAmount={orderData?.savedAmount}
        />

        <Divider sx={{ marginY: 1, borderColor: "#000" }} className="exclude-from-download" />

        {/* Exclude PriceSummaryCaters from download */}
        <div className="exclude-from-download">
          <PriceSummaryCaters paymentData={orderData} />
        </div>

        <Paper sx={{ width: "100%", marginTop: 2 }}>
          <CompanyDetails />
        </Paper>
      </Paper>
      {/* Include inline CSS for PDF compatibility */}
      <style>
        {`
          @media print {
            .exclude-from-download {
              display: none !important;
            }
            #printable-content {
              page-break-inside: avoid !important;
            }
            .MuiPaper-root {
              page-break-inside: avoid !important;
            }
          }
        `}
      </style>
    </Container>
  );
};

export default OrderInvoice;

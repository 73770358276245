import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Typography, Box, CircularProgress, Paper, Grid } from "@mui/material";
import AdminDrawer from "../../../../components/AdminDrawer/AdminDrawer";
import BASE_URL from "../../../../config";
import BreadcrumbsComponent from "../../../../components/ResuableComponents/BreadCrumb";

const ViewMarQuee = () => {
  const { state } = useLocation();
  const { id } = state || {};
  const [loading, setLoading] = useState(true);
  const [marqueeDetails, setMarqueeDetails] = useState(null);

  useEffect(() => {
    const fetchMarqueeDetails = async () => {
      if (id) {
        try {
          const response = await fetch(`${BASE_URL}marqueeSp/${id}`);
          const data = await response.json();
          setMarqueeDetails(data);
        } catch (error) {
          console.error("Error fetching lighting details:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchMarqueeDetails();
  }, [id]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <AdminDrawer>
      <BreadcrumbsComponent />
      <Paper
        elevation={3}
        style={{ padding: "20px", backgroundColor: "#f9f9f9" }}
      >
        <Typography variant="h4" gutterBottom>
          Marquee Details: {marqueeDetails?.marqueeID || "Unknown Lighting"}
        </Typography>

        <Box mt={2}>
          <Box>
            <Typography variant="h5" gutterBottom>
              Menu Items
            </Typography>
            {marqueeDetails?.menu?.marquees &&
            marqueeDetails?.menu?.marquees.length > 0 ? (
              <Grid container spacing={2}>
                {marqueeDetails.menu.marquees.map((marquee) => (
                  <Grid item xs={12} md={6} key={marquee._id}>
                    <Paper elevation={2} style={{ padding: "16px" }}>
                      <Typography variant="h6" mb={1}>
                        {marquee.marqueeType?.typeName}
                      </Typography>
                      <img
                        src={marquee.marqueeType?.images[0]}
                        alt={marquee.marqueeType?.typeName}
                        width="100"
                      />
                      <Typography mt={2}>
                        Color: {marquee?.marqueeType.color}
                      </Typography>
                      <Typography>
                        Area (Sqft): {marquee?.marqueeType.areainsqft}
                      </Typography>
                      <Typography mt={1}>
                        Price: ₹{marquee?.marqueeType.price}
                      </Typography>
                      <Typography>
                        Minimum Price: ₹{marquee?.marqueeType.price}
                      </Typography>
                      <Typography>
                        Maximum Price: ₹{marquee?.marqueeType.price}
                      </Typography>
                      <Typography mb={2} mt={2}>
                        Description: {marquee.marqueeType?.description}
                      </Typography>
                      <Typography>
                        Available:{" "}
                        {marquee.marqueeType?.availability ? "Yes" : "No"}
                      </Typography>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography>No Marquee available</Typography>
            )}
          </Box>
        </Box>
      </Paper>
    </AdminDrawer>
  );
};

export default ViewMarQuee;

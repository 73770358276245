import React, { useState, useEffect } from "react";
import { useDialog } from "../../../hooks/useDialog";
import { renderCellWithMore } from "../../../components/ResuableComponents/ViewMoreComponents/ViewMorePopup";
import { useNavigate } from "react-router-dom";
import AdminDrawer from "../../../components/AdminDrawer/AdminDrawer";
import {
  IconButton,
  Tooltip,
  styled,
  Tab,
  Typography,
  Tabs,
  Button,
  Box,
  Paper,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import DataTable from "../../../components/DataTables/DataTable";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  fetchRestaurantItems,
  fetchRestaurantAddons,
  fetchRestaurantSubPackages,
  deleteRestaurantSubPackage,
  getRestaurantSubPackageById,
} from "../../../api/restaurantPackages/restaurantItemsApi";
import ConfirmDialog from "../../../components/ResuableComponents/ConfirmDialog";
import CustomSnackbar from "../../../components/CustomeSnackBar/CustomSnackbar";
import BreadcrumbsComponent from "../../../components/ResuableComponents/BreadCrumb";

const StyledTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    backgroundColor: "green",
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  color: "green",
  fontWeight: 400,
  "&.Mui-selected": {
    color: "green",
    fontWeight: 500,
  },
}));

const tabConfig = [
  { title: "Items", apiEndpoint: "restaurantItems" },
  { title: "Add-ons", apiEndpoint: "restaurantAddons" },
  { title: "SubPackages", apiEndpoint: "restaurantSubPackages" },
];

const RestaurantItemList = () => {
  const { dialogContent, dialogOpen, handleOpenDialog, handleCloseDialog } =
    useDialog();
  const [value, setValue] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const navigate = useNavigate();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedSubPackageId, setSelectedSubPackageId] = useState(null);

  const itemColumns = [
    { field: "id", headerName: "ID", minWidth: 10, sortable: false },

    {
      field: "Image",
      headerName: "Item Image",
      minWidth: 200,
      sortable: false,
      renderCell: (params) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <img
            src={params.value !== 'N/A' ? params.value : 'placeholder-image-url.jpg'}
            alt="Item"
            style={{
              width: '100px',
              height: '60px',
              borderRadius: '4px',
              objectFit: 'cover',
            }}
          />
        </div>
      ),
    },
    { field: "Name", headerName: "Item Name", minWidth: 200, sortable: true },
    {
      field: "Description",
      headerName: "Item Description",
      minWidth: 250,
      sortable: true,
      renderCell: (params) => {
        const text = params.value || ""; // Ensure value is a string
        return renderCellWithMore(text, handleOpenDialog);
      },
    },
    { field: "Price", headerName: "Price", minWidth: 200, sortable: true },
    {
      field: "ComboType",
      headerName: "Combo Type",
      minWidth: 200,
      sortable: true,
    },
    {
      field: "SubPackage",
      headerName: "Sub Package Type",
      minWidth: 250,
      sortable: true,
    },
    {
      field: "restaurantId",
      headerName: "Restaurant Id",
      minWidth: 200,
      sortable: true,
      renderCell: (params) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            <Typography
              onClick={() => {
                navigate(`/restaurant-details/${params.row.restId}`, {
                  state: { restaurantId: params.row.restId },
                });
              }}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginTop: "14px",
                fontSize: "14px",
                cursor: "pointer",
              }}
            >
              {params.value} {/* Restaurant name displayed here */}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "restaurantName",
      headerName: "Restaurant Name",
      minWidth: 200,
      sortable: true,
      renderCell: (params) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            <Typography
              onClick={() => {
                navigate(`/restaurant-details/${params.row.restId}`, {
                  state: { restaurantId: params.row.restId },
                });
              }}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginTop: "14px",
                fontSize: "14px",
                cursor: "pointer",
              }}
            >
              {params.value} {/* Restaurant name displayed here */}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "Comments",
      headerName: "Comments",
      minWidth: 250,
      sortable: true,
      renderCell: (params) => {
        const text = params.value || ""; // Ensure value is a string
        return renderCellWithMore(text, handleOpenDialog);
      },
    },
    {
      field: "CreatedOn",
      headerName: "Created On",
      minWidth: 160,
      sortable: true,
    },
  ];

  const addonColumns = [
    { field: "id", headerName: "ID", minWidth: 10, sortable: false },
    {
      field: "restaurantId",
      headerName: "Restaurant Id",
      flex: 1,
      sortable: true,
    },
    {
      field: "restaurantName",
      headerName: "Restaurant Name",
      flex: 1,
      sortable: true,
    },
    { field: "Name", headerName: "Add-on Name", flex: 1, sortable: true },
    {
      field: "Price",
      headerName: "Add-on Price",
      flex: 1,
      sortable: true,
    },
    { field: "ComboType", headerName: "Type", flex: 1, sortable: true },
    {
      field: "ItemName",
      headerName: "Item Name",
      flex: 1,
      renderCell: (params) => {
        const text = params.value || ""; // Ensure value is a string
        return renderCellWithMore(text, handleOpenDialog);
      },
    },
  ];

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleAddRestaurantSubPackage = () => {
    navigate("/addRestaurantSubPackage");
  };

  const handleEditRestaurantSubPackage = (id) => {
    navigate(`/editRestaurantSubPackage/${id}`);
  };

  const handleViewRestaurantSubPackage = async (id) => {
    try {
      const orderData = await getRestaurantSubPackageById(id);

      console.log("orderData in item", orderData);

      // Navigate to the OrderDetails component and pass orderData as state
      navigate(`/viewRestaurantSubPackage/${id}`, { state: { orderData } });
    } catch (error) {
      setSnackbarMessage("Error fetching subpackage details");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      console.error("Error fetching subpackage:", error);
    }
  };

  const handleOpenConfirmDialog = (id) => {
    setSelectedSubPackageId(id);
    setConfirmDialogOpen(true);
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };
  const subPackageColumns = [
    { field: "id", headerName: "ID", minWidth: 10, sortable: false },
    {
      field: "subPackageName",
      headerName: "Sub Package Name",
      flex: 2,
      sortable: true,
    },
    { field: "CreatedOn", headerName: "Created On", flex: 2, sortable: true },
    {
      field: "Actions",
      headerName: "Actions",
      minWidth: 200,
      sortable: false,
      renderCell: (params) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {/* <Tooltip title="View">
            <IconButton
              sx={{ color: '#87CEEB', marginRight: '8px' }} 
              onClick={() => handleViewRestaurantSubPackage(params.row._id)}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip> */}
          <Tooltip title="Edit">
            <IconButton
              color="success"
              onClick={() => handleEditRestaurantSubPackage(params.row._id)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              color="error"
              onClick={() => handleOpenConfirmDialog(params.row._id)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const handleDeleteRestaurantSubPackage = async () => {
    try {
      await deleteRestaurantSubPackage(selectedSubPackageId);
      setSnackbarMessage("SubPackage deleted successfully");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);

      const restaurantSubPackages = await fetchRestaurantSubPackages();
      const formattedData = restaurantSubPackages?.map((subPackage, index) => ({
        id: index + 1,
        restaurantId: subPackage.restaurants
          .map((rest) => rest.restID)
          .join(", "),
        restaurantName: subPackage.restaurants
          .map((rest) => rest.restName)
          .join(", "),
        subPackageName: subPackage?.restSubPackName,
        CreatedOn: new Date(subPackage.createdAt).toLocaleString(),
        _id: subPackage._id,
      }));
      setTableData(formattedData);
      setConfirmDialogOpen(false);
    } catch (error) {
      setSnackbarMessage("Error deleting subpackage");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      console.error("Error deleting subpackage:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (tabConfig[value].apiEndpoint === "restaurantItems") {
        const restaurantItems = await fetchRestaurantItems();
        const formattedData = restaurantItems.map((item, index) => ({
          id: index + 1,
          restId: item?.restaurant?._id,
          restaurantId: item?.restaurant?.restID,
          restaurantName: item?.restaurant?.restName,
          Name: item.restItemName,
          Description: item.restItemDescription,
          Price: item.restItemPrice,
          ComboType: item.type,
          SubPackage: item.restSubPackageId?.restSubPackName,
          Ratings: 4,
          Comments: "No comments available",
          CreatedOn: new Date(item.createdAt).toLocaleString(),
          // Add this line to include the image URL in the formatted data
          Image: item.restItemImage?.[0], // Assuming you want the first image in the array
        }));

        setTableData(formattedData);
      } else if (tabConfig[value].apiEndpoint === "restaurantAddons") {
        const restaurantAddons = await fetchRestaurantAddons();
        const formattedData = restaurantAddons.map((addon, index) => ({
          id: index + 1,
          restaurantId: addon?.restaurant?.restID,
          restaurantName: addon?.restaurant?.restName,
          Name: addon.addOnName,
          Price: addon.addOnPrice,
          ComboType: addon.type,
          ItemName: addon.menuItem?.restItemName,
        }));
        setTableData(formattedData);
      } else if (tabConfig[value]?.apiEndpoint === "restaurantSubPackages") {
        const restaurantSubPackages = await fetchRestaurantSubPackages();

        // Ensure that restaurantSubPackages is an array before calling map
        const formattedData = Array.isArray(restaurantSubPackages)
          ? restaurantSubPackages.map((subPackage, index) => ({
            id: index + 1,
            restaurantId: subPackage?.restaurants
              ?.map((rest) => rest?.restID)
              .join(", "),
            restaurantName: subPackage?.restaurants
              ?.map((rest) => rest?.restName)
              .join(", "),
            subPackageName: subPackage?.restSubPackName,
            CreatedOn: new Date(subPackage?.createdAt).toLocaleString(),
            _id: subPackage?._id,
          }))
          : []; // If it's not an array, set empty data

        setTableData(formattedData);
      }
    };

    fetchData();
  }, [value]);

  return (
    <div style={{ backgroundColor: "#f2f2f2" }}>
      <AdminDrawer>
        <Box display="flex">
          <Typography variant="h4" fontWeight="bolder" mr={3}>
            {tabConfig[value].title}
          </Typography>
          <Typography mt={1}>
            <BreadcrumbsComponent />
          </Typography>
        </Box>

        <StyledTabs value={value} onChange={handleTabChange} aria-label="tabs">
          {tabConfig.map((tab, index) => (
            <StyledTab key={index} label={tab.title} />
          ))}
        </StyledTabs>
        <Paper>
          {value === 2 && (
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="success"
                startIcon={<AddIcon />}
                onClick={handleAddRestaurantSubPackage}
                style={{ margin: "20px" }}
              >
                Add SubPackage
              </Button>
            </Box>
          )}
          <DataTable
            rows={tableData}
            columns={
              value === 0
                ? itemColumns
                : value === 1
                  ? addonColumns
                  : subPackageColumns
            }
            onEdit={handleEditRestaurantSubPackage}
            onDelete={handleOpenConfirmDialog}
            onView={handleViewRestaurantSubPackage}
          />
        </Paper>
        <ConfirmDialog
          open={confirmDialogOpen}
          onClose={handleCloseConfirmDialog}
          onConfirm={handleDeleteRestaurantSubPackage}
          title="Confirm Delete"
          message="Are you sure you want to delete this subpackage?"
        />
        <CustomSnackbar
          open={snackbarOpen}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
          severity={snackbarSeverity}
        />
        <Dialog open={dialogOpen} onClose={handleCloseDialog}>
          <DialogTitle>Details</DialogTitle>
          <DialogContent>
            <Typography>{dialogContent}</Typography>
          </DialogContent>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </Dialog>
      </AdminDrawer>
    </div>
  );
};

export default RestaurantItemList;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Grid } from '@mui/material';
import AdminDrawer from '../../../components/AdminDrawer/AdminDrawer';
import StatisticsCard from '../../../components/AdvancedComponents/PayoutComponents/StatisticsCard';
import PaymentBreakdown from '../../../components/AdvancedComponents/PayoutComponents/PaymentBreakdown';
import PaymentDashBoardTable from '../../../components/AdvancedComponents/PayoutComponents/PaymentDashBoardTable';
import { fetchCatererPayout } from '../../../api/payouts/payoutsApi';
import AdminChatBox from "../../../components/ResuableComponents/ChatBoxes/CatererChatBox/AdminChatBox";

const CatererPayoutTab = ({ catererId }) => {
  const { id } = useParams();
  const [payoutData, setPayoutData] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadPayoutData = async () => {
      try {
        let data;
        data = await fetchCatererPayout(catererId);
        setPayoutData(data);
      } catch (error) {
        console.error("Error fetching payout data:", error);
        setPayoutData([]);
      } finally {
        setLoading(false);
      }
    };

    loadPayoutData();
  }, [id]);

  const handleCheckboxChange = (order) => {
    setSelectedOrder(order);
  };

  if (loading) {
    return (
      <div style={{ backgroundColor: '#f2f2f2', height: '100vh' }}>
        <AdminDrawer>
          <div style={{ padding: '24px' }}>
            <Typography variant='h6'>Loading...</Typography>
          </div>
        </AdminDrawer>
      </div>
    );
  }

  // Instead of using an error message, we will show a more user-friendly message with empty columns
  if (!payoutData || payoutData.length === 0) {
    return (
      <div style={{ backgroundColor: '#f2f2f2', height: '100vh' }}>
        <AdminDrawer>
          <Typography variant="h5" gutterBottom>
            Caterer Payout
          </Typography>
          <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>

            <Grid item>
              <Typography variant='h6'>No payout data found</Typography>
            </Grid>
          </Grid>
        </AdminDrawer>
      </div>
    );
  }

  const totalPayout = Array.isArray(payoutData)
    ? payoutData.reduce((acc, payoutDetails) => {
      acc.netPayout += payoutDetails?.netPayout || 0;
      acc.fessteFee += payoutDetails?.fessteFee || 0;
      acc.transactionFee += payoutDetails?.transactionFee || 0;
      return acc;
    }, {
      netPayout: 0,
      fessteFee: 0,
      transactionFee: 0,
    })
    : {
      netPayout: payoutData?.netPayout || 0,
      fessteFee: payoutData?.fessteFee || 0,
      transactionFee: payoutData?.transactionFee || 0,
    };

  return (
    <div style={{ backgroundColor: '#f2f2f2', height: 'max-content' }}>
      <div style={{ padding: '24px' }}>
        <Typography variant='h5' gutterBottom>
          Caterer Payout
        </Typography>
      </div>

      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={6} md={3}>
          <StatisticsCard
            title="Total Earnings"
            value={`₹${totalPayout?.netPayout?.toFixed(2)}`}
            background="linear-gradient(135deg, #6a1b9a 0%, #8e24aa 100%)"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatisticsCard
            title="Fesste Fee"
            value={`₹${totalPayout.fessteFee.toFixed(2)}`}
            background="linear-gradient(135deg, #43a047 0%, #66bb6a 100%)"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatisticsCard
            title="Transaction Fee"
            value={`₹${totalPayout?.transactionFee?.toFixed(2)}`}
            background="linear-gradient(135deg, #ffb300 0%, #ffa000 100%)"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatisticsCard
            title="Net Payout"
            value={`₹${totalPayout?.netPayout?.toFixed(2)}`}
            background="linear-gradient(135deg, #1e88e5 0%, #42a5f5 100%)"
          />
        </Grid>
      </Grid>

      {selectedOrder ? (
        <PaymentBreakdown selectedOrder={selectedOrder} />
      ) : null}

      {/* Conditionally render either PaymentDashBoardTable or FessteMartPayoutTable */}
  
        <PaymentDashBoardTable
          orders={payoutData}
          selectedOrder={selectedOrder}
          onCheckboxChange={handleCheckboxChange}
        />
   
      {/* Chatbox */}
      <AdminChatBox type={'caterer_payouts'} />
    </div>
  );
};

export default CatererPayoutTab;

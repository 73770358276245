import baseUrl from "../../config";

export const fetchQuotations = async () => {
  try {
    const response = await fetch(`${baseUrl}quotes`);
    const data = await response.json();

    if (response.ok) {
      // Map the necessary columns for the quotations
      const mappedData = data.quotes.map((quote, index) => ({
        id: quote?._id,
        SiNo: index + 1,
        customerName: quote?.customer?.fullName || 'N/A',
        customerEmail: quote?.customer?.email || 'N/A',
        customerPhone: quote?.customer?.phoneNumber || 'N/A',
        customerAddress: `${quote?.customer?.addresses?.[0]?.address1 || ''}, ${quote?.customer?.addresses?.[0]?.city || ''}, ${quote?.customer?.addresses?.[0]?.state || ''} - ${quote?.customer?.addresses?.[0]?.postalCode || ''}`,
        serviceProviderName: quote?.serviceProvider?.spName || 'N/A',
        serviceProviderPhone: quote?.serviceProvider?.spPhone || 'N/A',
        serviceProviderEmail: quote?.serviceProvider?.spEmail || 'N/A',
        businessName: quote?.fessteMart?.businessName || 'N/A',
        businessAddress: `${quote?.fessteMart?.address?.street || ''}, ${quote?.fessteMart?.address?.city || ''}, ${quote?.fessteMart?.address?.postalCode || ''}`,
        eventDate: new Date(quote?.eventDate).toLocaleDateString() || 'N/A',
        eventStartTime: new Date(quote?.eventStartTime).toLocaleTimeString() || 'N/A',
        eventEndTime: new Date(quote?.eventEndTime).toLocaleTimeString() || 'N/A',
        // Extracting relevant service fields
        services: quote?.services.map((service) => ({
          serviceId: service?.serviceId?._id || 'N/A',
          type: service?.serviceId?.typeName || 'N/A',
          description: service?.serviceId?.description || 'N/A',
          price: `$${service?.price || 0}`,
          unit: service?.serviceId?.unit || 'N/A',
          availability: service?.serviceId?.availability || false,
          images: service?.serviceId?.images || [],
        })) || [], // Return an empty array if there are no services
        totalPrice: `$${quote?.totalPrice || 0}`,
        status: quote?.status || 'N/A',
        quoteStatus: quote?.quoteStatus || 'N/A',
        visibleToServiceProvider: quote?.visibleToServiceProvider || false,
        visibleToUserProvider: quote?.visibleToUserProvider || false,
        createdAt: new Date(quote?.createdAt).toLocaleDateString(),
        updatedAt: new Date(quote?.updatedAt).toLocaleDateString(),
      }));

      console.log('mappedData', mappedData);
      return mappedData;
    } else {
      throw new Error(data.message || 'Failed to fetch quotes');
    }
  } catch (error) {
    console.error('Error fetching quotes:', error);
    throw error;
  }
};


export const getQuoteById = async (id) => {
  try {
      const response = await fetch(`${baseUrl}quote/${id}`);
      if (response.ok) {
          const data = await response.json();
          return data?.quote;
      } else {
          console.error('Failed to fetch package by ID:', response);
          return null;
      }
  } catch (error) {
      console.error('Error fetching package by ID:', error);
      return null;
  }
};

// In quotationsApis.js
export const toggleQuoteVisibility = async (quoteId) => {
  try {
    const response = await fetch(`${baseUrl}quote/${quoteId}/toggle-visibility`, {
      method: 'PATCH',
    });

    if (!response.ok) {
      throw new Error('Failed to toggle visibility');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error toggling quote visibility:', error);
    throw error;
  }
};

// In quotationsApis.js
export const toggleQuoteUserVisibility = async (quoteId) => {
  try {
    const response = await fetch(`${baseUrl}quote/${quoteId}/toggle-userVisibility`, {
      method: 'PATCH',
    });

    if (!response.ok) {
      throw new Error('Failed to toggle User visibility');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error toggling User quote visibility:', error);
    throw error;
  }
};

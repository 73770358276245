import baseUrl from "../../../config";


export const getFlowerSp = async () => {
    try {
        const response = await fetch(`${baseUrl}flowerSp`);
        if (!response.ok) {
            throw new Error('Failed to fetch flowerSp data');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching flowerSp:', error);
        return [];
    }
};

export const getFlowersById = async (flowersId) => {
    const response = await fetch(`${baseUrl}flowerSp/${flowersId}`);
    if (!response.ok) {
        throw new Error('Failed to fetch Flowers details');
    }
    return await response.json();
  };
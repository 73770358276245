import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AdminDrawer from "../../components/AdminDrawer/AdminDrawer";
import {
  Typography,
  styled,
  Paper,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Box,
} from "@mui/material";
import DataTable from "../../components/DataTables/DataTable";
import { deleteFessteMart } from "../../api/servicePartners/fesstemart/fessteMartRegistrationApis";
import ConfirmDialog from "../../components/ResuableComponents/ConfirmDialog";
import CustomSnackbar from "../../components/CustomeSnackBar/CustomSnackbar";
import { useDialog } from "../../hooks/useDialog";
import { renderCellWithMore } from "../../components/ResuableComponents/ViewMoreComponents/ViewMorePopup";
import BreadcrumbsComponent from "../../components/ResuableComponents/BreadCrumb";

const TableContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  maxHeight: "100vh",
  height: "650px",
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[0],
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1),
    height: "auto",
  },
}));

const PartyHallDeleted = () => {
  const { dialogContent, dialogOpen, handleOpenDialog, handleCloseDialog } =
    useDialog();

  const navigate = useNavigate(); // Initialize navigate
  const [formatdata, setFormatData] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  useEffect(() => {
    const fetchPartyHalls = async () => {
      try {
        const response = await fetch(
          "https://admin.fesste.com/api/deletedPartyHall"
        );
        const jsonData = await response.json();

        const transformedData = jsonData.map((partyHall) => ({
          id: partyHall._id,
          partyHallId: partyHall?.partyHallID,
          fessteMartId: partyHall?.fessteMart?._id,
          businessName: partyHall?.businessName,
          businessImage: partyHall?.businessProfilePhoto[0],
          businessPhoneNumber: partyHall?.businessPhoneNumber,
          address: `${partyHall?.address?.addressLine1}, ${partyHall?.address?.addressLine2},${partyHall?.address?.landmark},${partyHall?.address?.city}, ${partyHall?.address?.postalCode},${partyHall?.address?.state},${partyHall?.address?.country}`,
          spName: partyHall?.serviceProvider?.spName,
          spEmail: partyHall?.serviceProvider?.spEmail,
          spPhone: partyHall?.serviceProvider?.spPhone,
          createdOn: partyHall?.createdAt,
        }));

        setFormatData(transformedData);
      } catch (error) {
        console.error("Error fetching PartyHalls:", error);
      }
    };

    fetchPartyHalls();
  }, []);

  const handleViewClick = (partyHallId, id) => {
    navigate(`/partyhall-details/${partyHallId}`, { state: { id } });
  };

  const handleDelete = async () => {
    try {
      const response = await deleteFessteMart(itemToDelete);

      if (response) {
        setSnackbarSeverity("success");
        setSnackbarMessage(
          response.message || "Fesste Mart deleted successfully"
        );
        // Close the confirmation dialog after successful deletion
        setOpenConfirmDialog(false);

        // Optionally refresh your data or update the formatdata state here
        setFormatData((prevData) =>
          prevData.filter((item) => item.fessteMartId !== itemToDelete)
        );
      } else {
        setSnackbarSeverity("warning");
        setSnackbarMessage(
          response.message || "Operation succeeded but with warnings"
        );
      }
    } catch (error) {
      setSnackbarSeverity("error");
      setSnackbarMessage("Failed to delete Fesste Mart");
      console.error("Error during deletion:", error);
    } finally {
      setSnackbarOpen(true); // Show snackbar after API call
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const columns = [
    {
      field: "partyHallId",
      headerName: "ID",
      minWidth: 10,
      sortable: false,
      renderCell: (params) => {
        const text = params.value || ""; // Ensure value is a string

        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              handleViewClick(params.row.partyHallId, params.row.id)
            }
          >
            {text}
          </div>
        );
      },
    },
    {
      field: "businessImage",
      headerName: "Business Image",
      type: "image",
      minWidth: 160,
      sortable: false,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center", // Horizontally center the image
            alignItems: "center", // Vertically center the image
            width: "100%", // Ensure the div takes full width of the cell
            height: "100%", // Ensure the div takes full height of the cell
          }}
        >
          <img
            src={
              params.value !== "N/A"
                ? params.value
                : "placeholder-image-url.jpg"
            } // Placeholder for broken images
            alt="Item"
            style={{
              width: "100px", // Fixed width
              height: "60px", // Fixed height
              borderRadius: "4px", // Rounded corners
              objectFit: "cover", // Cover the area while maintaining aspect ratio
            }}
          />
        </div>
      ),
    },
    {
      field: "businessName",
      headerName: "Business Name",
      minWidth: 250,
      sortable: true,
      renderCell: (params) => {
        const text = params.value || ""; // Ensure value is a string

        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              handleViewClick(params.row.partyHallId, params.row.id)
            }
          >
            {text}
          </div>
        );
      },
    },
    {
      field: "businessPhoneNumber",
      headerName: "Business Contact",
      minWidth: 250,
      sortable: true,
    },

    {
      field: "spName",
      headerName: "Owner Name",
      minWidth: 250,
      sortable: true,
    },
    {
      field: "spEmail",
      headerName: "Owner Email",
      minWidth: 250,
      sortable: true,
    },

    {
      field: "spPhone",
      headerName: "Owner Contact",
      minWidth: 250,
      sortable: true,
    },
    {
      field: "address",
      headerName: "Address",
      minWidth: 250,
      sortable: true,
      type: "text",
      renderCell: (params) => {
        const text = params.value || ""; // Ensure value is a string
        return renderCellWithMore(text, handleOpenDialog);
      },
    },
    {
      field: "createdOn",
      headerName: "Created On",
      minWidth: 250,
      sortable: true,
      renderCell: (params) => {
        const text = params.value || ""; // Ensure value is a string
        return renderCellWithMore(text, handleOpenDialog);
      },
    },
  ];

  return (
    <div style={{ backgroundColor: "#f2f2f2" }}>
      <AdminDrawer>
        <Box display="flex" mb={3}>
          <Typography variant="h4" fontWeight="bolder">
            PartyHall Deleted
          </Typography>
          <Box ml={3} mt={1}>
            <BreadcrumbsComponent />
          </Box>
        </Box>
        <TableContainer>
          <DataTable rows={formatdata} columns={columns} />
          <ConfirmDialog
            open={openConfirmDialog}
            onClose={() => setOpenConfirmDialog(false)}
            onConfirm={handleDelete}
            title="Confirm Deletion"
            content="Are you sure you want to delete this Party hall?"
          />
          <CustomSnackbar
            open={snackbarOpen}
            message={snackbarMessage}
            severity={snackbarSeverity}
            onClose={handleCloseSnackbar}
          />
        </TableContainer>
        <Dialog open={dialogOpen} onClose={handleCloseDialog}>
          <DialogTitle>Details</DialogTitle>
          <DialogContent>
            <Typography>{dialogContent}</Typography>
          </DialogContent>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </Dialog>
      </AdminDrawer>
    </div>
  );
};

export default PartyHallDeleted;

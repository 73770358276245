import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Download as DownloadIcon } from "@mui/icons-material";
import VerifiedIcon from "../../../assets/images/verified.png";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Tabs,
  Tab,
  Typography,
  CircularProgress,
  Grid,
  Card,
  CardContent,
  Button,
  Divider,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import AdminDrawer from "../../../components/AdminDrawer/AdminDrawer";
import EditIcon from "@mui/icons-material/Edit";
import {
  fetchCatererDetails,
  fetchCatererPackage,
} from "../../../api/servicePartners/CaterersApis";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PackagePopup from "../../../components/ResuableComponents/packaegPopup";
import CustomSnackbar from "../../../components/CustomeSnackBar/CustomSnackbar";
import baseUrl from "../../../config";
import CatererOrderTab from "./CatererOrderTab";
import CatererPayoutTab from "./CatererPayoutTab";
import BreadcrumbsComponent from "../../../components/ResuableComponents/BreadCrumb";
import BASE_URL from "../../../config";
import AdminChatBox from "../../../components/ResuableComponents/ChatBoxes/CatererChatBox/AdminChatBox";

const CatererDetailsPage = () => {
  const { id } = useParams();
  const location = useLocation();
  const { catererId } = location.state || {};
  const [tabIndex, setTabIndex] = useState(0);
  const [catererDetails, setCatererDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [catererPackages, setCatererPackages] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedPackageId, setSelectedPackageId] = useState(null);
  const [isVerified, setIsVerified] = useState(catererDetails?.isVerified);
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [open, setOpen] = useState(false); // State to control the modal
  const [comments, setComments] = useState([]);

  const fetchComments = async (type) => {
    try {
      const response = await fetch(`${BASE_URL}dashboard/getComments/${type}`);
      const data = await response.json();
      setComments(data);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  console.log("comments", comments);

  useEffect(() => {
    const tabToTypeMap = {
      0: "caterers",
      1: "caterer_packages",
      2: "caterer_orders",
      3: "caterer_payouts",
    };

    fetchComments(tabToTypeMap[tabIndex]);
  }, [tabIndex]);

  // Snackbar states
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // Default to success
  const [businessName, setBusinessName] = useState("");
  const [businessProfilePhoto, setBusinessProfilePhoto] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    const getCatererDetails = async () => {
      setLoading(true);
      try {
        const details = await fetchCatererDetails(catererId);
        setCatererDetails(details);
      } catch (err) {
        console.log("Failed to fetch caterer details.");
      } finally {
        setLoading(false);
      }
    };

    const getCatererPackages = async () => {
      setLoading(true);
      console.log(null);
      try {
        const packages = await fetchCatererPackage(catererId);
        setCatererPackages(packages);
      } catch (err) {
        console.log("Failed to fetch caterer packages.");
      } finally {
        setLoading(false);
      }
    };

    if (catererId) {
      getCatererDetails();
      getCatererPackages();
    }
  }, [catererId]);

  useEffect(() => {
    setIsVerified(catererDetails?.isVerified);
  }, [catererDetails?.isVerified]);

  const handlePackageClick = (packageId) => {
    setSelectedPackageId(packageId); // Set selected package ID
    setOpenPopup(true); // Open the popup
  };

  const handleClosePopup = () => {
    setOpenPopup(false); // Close the popup
    setSelectedPackageId(null); // Clear selected package ID
  };

  // Handle verify or unverify based on current state
  const handleVerify = async () => {
    try {
      if (!isVerified) {
        // Call API to verify the caterer
        const response = await fetch(
          `https://admin.fesste.com/api/caterer/verify/${catererId}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          setIsVerified(true); // Update to verified state
          setSnackbarMessage("Caterer verified successfully");
        } else {
          setSnackbarMessage("Failed to verify caterer");
        }
      }
    } catch (error) {
      console.error("Error verifying caterer", error);
      setSnackbarMessage("Error verifying caterer");
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleEdit = async () => {
    const formData = new FormData();

    if (businessName) {
      formData.append("businessName", businessName);
    }

    if (businessProfilePhoto) {
      formData.append("businessProfilePhoto", businessProfilePhoto);
    }

    try {
      const response = await fetch(
        `https://admin.fesste.com/api/caterer/update/${catererId}`,
        {
          method: "PUT",
          body: formData,
          headers: {
            // 'Content-Type' is set automatically by the browser for FormData
            // You can include additional headers if required, but it's not needed for FormData
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json(); // Parse JSON response
      console.log("Update successful:", data);
      setOpenModal(false); // Close modal after successful update
      // Optionally reset state or show a success message
    } catch (error) {
      console.error("Error updating caterer:", error);
      // Handle error (e.g., show an error message)
    }
  };

  // Snackbar close handler
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Handle reject click (open dialog)
  const handleReject = () => {
    setRejectDialogOpen(true);
  };

  // Handle rejection submission
  const handleRejectSubmit = async () => {
    try {
      const response = await fetch(
        `https://admin.fesste.com/api/caterer/reject/${catererId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ rejectionReason }), // Pass rejection reason from state
        }
      );

      if (response.ok) {
        setSnackbarMessage(`Rejected with reason: ${rejectionReason}`);
        setIsVerified(false); // Set to unverified state
        setSnackbarSeverity("success");
      } else {
        setSnackbarMessage("Failed to reject caterer");
        setSnackbarSeverity("error");
      }
    } catch (error) {
      console.error("Error rejecting caterer", error);
      setSnackbarMessage("Error rejecting caterer", error);
      setSnackbarSeverity("error");
    } finally {
      setRejectDialogOpen(false);
      setSnackbarOpen(true);
    }
  };

  // Handle dialog close without submission
  const handleDialogClose = () => {
    setRejectDialogOpen(false);
  };

  const [selectedImage, setSelectedImage] = useState(null); // State to store the selected image

  // Handle image click to open the preview
  const handleImageClick = (image) => {
    setSelectedImage(image); // Set the clicked image URL
    setOpen(true); // Open the modal
  };

  // Handle modal close
  const handleClose = () => {
    setOpen(false); // Close the modal
    setSelectedImage(null); // Clear the selected image
  };

  const handleDownload = async () => {
    const apiUrl = `${baseUrl}downloadCaterers/${catererId}`;

    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "documents.zip"); // Replace with actual file name if necessary
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  const generalVegPackages = catererPackages.filter(
    (pkg) => pkg.packageType === "General" && pkg?.packageId?.type[0] === "Veg"
  );
  const generalNonVegPackages = catererPackages.filter(
    (pkg) =>
      pkg.packageType === "General" && pkg?.packageId?.type[0] === "Non-Veg"
  );
  const premiumVegPackages = catererPackages.filter(
    (pkg) => pkg.packageType === "Premium" && pkg?.packageId?.type[0] === "Veg"
  );
  const premiumNonVegPackages = catererPackages.filter(
    (pkg) =>
      pkg.packageType === "Premium" && pkg?.packageId?.type[0] === "Non-Veg"
  );

  console.log("catererPackages", catererPackages);

  const tabToTypeMap = {
    0: "caterers",
    1: "caterer_packages",
    2: "caterer_orders",
    3: "caterer_payouts",
  };

  const type = tabToTypeMap[tabIndex];

  return (
    <AdminDrawer>
      <BreadcrumbsComponent />
      <Box
        sx={{
          width: "100%",
          typography: "body1",
          bgcolor: "#f4f6f8",
          padding: 2,
          borderRadius: 2,
        }}
      >
        <Tabs value={tabIndex} onChange={handleTabChange} centered>
          <Tab label="Caterer Details" />
          <Tab label="Caterer Packages" />
          <Tab label="Caterer Orders" />
          <Tab label="Caterer Payouts" />
        </Tabs>

        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
          {/* Rejection Reason Dialog */}
          <Dialog open={rejectDialogOpen} onClose={handleDialogClose}>
            <DialogTitle>Reason for Rejection</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                placeholder="Rejection Reason"
                fullWidth
                variant="outlined"
                value={rejectionReason}
                onChange={(e) => setRejectionReason(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose}>Cancel</Button>
              <Button
                onClick={handleRejectSubmit}
                variant="contained"
                color="error"
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>

          {/* Snackbar for success messages */}
          <CustomSnackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={() => setSnackbarOpen(false)}
            message={snackbarMessage}
          />
        </Box>

        {tabIndex === 0 && (
          <Box p={3}>
            <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
              {/* Verify/Unverify Button */}
              <Button
                onClick={isVerified ? handleReject : handleVerify} // Handle reject if verified, else handle verify
                sx={{
                  backgroundColor: isVerified ? "transparent" : "#0e0593",
                  borderRadius: "2rem",
                  color: "white",
                  "&:hover": {
                    backgroundColor: isVerified ? "transparent" : "#0e0593",
                  },
                }}
              >
                <Typography color="white">
                  {isVerified ? (
                    <img src={VerifiedIcon} alt="verified" />
                  ) : (
                    <Typography variant="body2" fontWeight="bold">
                      Verify
                    </Typography>
                  )}
                </Typography>
              </Button>
              {/* Edit Button */}

              {/* Edit Modal */}
              <Dialog open={openModal} onClose={() => setOpenModal(false)}>
                <DialogTitle>Edit Caterer Information</DialogTitle>
                <DialogContent>
                  <TextField
                    label="Business Name"
                    variant="outlined"
                    value={businessName}
                    onChange={(e) => setBusinessName(e.target.value)}
                    fullWidth
                    margin="dense"
                    size="small"
                  />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "10px",
                    }}
                  >
                    <Typography variant="body1" sx={{ marginRight: "30px" }}>
                      Business Profile Image
                    </Typography>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) =>
                        setBusinessProfilePhoto(e.target.files[0])
                      }
                      style={{ marginTop: 0 }} // Reset marginTop to 0 since we are using Box
                    />
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setOpenModal(false)} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={handleEdit} color="primary">
                    Save
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>

            <Box display="flex">
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                Caterer Details: {catererDetails?.businessName}({id})
              </Typography>
              {loading && <CircularProgress />}

              <Button
                onClick={() => setOpenModal(true)} // Open modal on click
                sx={{
                  "&:hover": {
                    backgroundColor: "#fff",
                  },
                }}
              >
                <EditIcon sx={{ marginRight: 1 }} />
              </Button>
            </Box>

            {catererDetails && (
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid container spacing={3}>
                  {/* Business Profile Photo Card */}
                  <Grid item xs={12} md={4}>
                    <Card
                      elevation={3}
                      sx={{
                        borderRadius: 2,
                        overflow: "hidden",
                        height: { xs: "auto", md: "100%" },
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Box sx={{ position: "relative", height: "100%" }}>
                        <img
                          onClick={() =>
                            handleImageClick(
                              catererDetails?.businessProfilePhoto
                            )
                          }
                          src={catererDetails?.businessProfilePhoto}
                          alt="Business Profile"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            cursor: "pointer",
                          }}
                        />
                        <a href={catererDetails?.businessProfilePhoto} download>
                          <IconButton
                            sx={{
                              position: "absolute",
                              top: 8,
                              right: 8,
                              color: "green",
                              backgroundColor: "rgba(255, 255, 255, 0.7)",
                              "&:hover": {
                                backgroundColor: "rgba(255, 255, 255, 0.9)",
                              },
                            }}
                          >
                            <DownloadIcon fontSize="medium" />
                          </IconButton>
                        </a>
                      </Box>
                    </Card>
                  </Grid>

                  <Dialog open={open} onClose={handleClose} maxWidth="md">
                    <DialogTitle>
                      <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                          position: "absolute",
                          right: 8,
                          top: 0,
                          color: (theme) => theme.palette.grey[500],
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </DialogTitle>
                    <img
                      src={selectedImage}
                      alt="Documents"
                      style={{ maxHeight: "40vh", width: "40vw" }}
                    />
                  </Dialog>

                  {/* Contact Information Card */}
                  <Grid item xs={12} md={4}>
                    <Card
                      elevation={3}
                      sx={{
                        borderRadius: 2,
                        padding: 3,
                        height: { xs: "auto", md: "100%" },
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <CardContent>
                        <Typography variant="h5" fontWeight="bold" gutterBottom>
                          Contact Information
                        </Typography>
                        <Box
                          sx={{ display: "flex", alignItems: "center", mt: 1 }}
                        >
                          <PhoneIcon fontSize="small" sx={{ mr: 1 }} />
                          <Typography>
                            {catererDetails?.businessPhoneNumber || "N/A"}
                          </Typography>
                        </Box>
                        <Divider sx={{ my: 2 }} />
                        <Box
                          sx={{ display: "flex", alignItems: "center", mt: 1 }}
                        >
                          <EmailIcon fontSize="small" sx={{ mr: 1 }} />
                          <Typography>
                            {catererDetails?.serviceProvider?.spEmail || "N/A"}
                          </Typography>
                        </Box>
                        <Divider sx={{ my: 2 }} />
                        <Box sx={{ display: "flex", mt: 2 }}>
                          <Typography variant="body2" fontWeight="bold">
                            AboutUs:
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{ textAlign: "justify", ml: 1 }}
                          >
                            {catererDetails.aboutUs}
                          </Typography>
                        </Box>
                        <Divider sx={{ my: 2 }} />
                        <Box sx={{ display: "flex", mt: 2 }}>
                          <Typography variant="body2" fontWeight="bold">
                            Specialized Areas:
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{ textAlign: "justify", ml: 1 }}
                          >
                            {catererDetails.specializedAreas
                              .filter((area) => area.toLowerCase() !== "others")
                              .join(", ")}
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex", mt: 2 }}>
                          <Typography variant="body2" fontWeight="bold">
                            Other Specialized Areas:
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{ textAlign: "justify", ml: 1 }}
                          >
                            {catererDetails.otherspecializedAreas.join(", ")}
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>

                  {/* Address and Service Provider Details Card */}
                  <Grid item xs={12} md={4}>
                    <Card
                      elevation={3}
                      sx={{
                        borderRadius: 2,
                        padding: 3,
                        height: { xs: "auto", md: "100%" },
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <CardContent>
                        <Typography variant="h5" fontWeight="bold" gutterBottom>
                          Address
                        </Typography>
                        <Box
                          sx={{ display: "flex", alignItems: "center", mt: 1 }}
                        >
                          <IconButton size="small">
                            <LocationOnIcon fontSize="small" />
                          </IconButton>
                          <Typography variant="body2">
                            {`${catererDetails?.address?.addressLine1}, ${catererDetails?.address?.addressLine2}, ${catererDetails?.address?.landmark}, ${catererDetails?.address?.city} - ${catererDetails?.address?.postalCode}, ${catererDetails?.address?.state}, ${catererDetails?.address?.country}`}
                          </Typography>
                        </Box>

                        <Divider sx={{ my: 2 }} />
                        <Typography variant="h6" fontWeight="bold">
                          Service Provider
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 1 }}>
                          Name:{" "}
                          {catererDetails?.serviceProvider?.spName || "N/A"}{" "}
                          <br />
                          Phone:{" "}
                          {catererDetails?.serviceProvider?.spPhone ||
                            "N/A"}{" "}
                          <br />
                          Email:{" "}
                          {catererDetails?.serviceProvider?.spEmail || "N/A"}
                        </Typography>

                        <Divider sx={{ my: 2 }} />
                        <Typography variant="h6" fontWeight="bold">
                          Registration Details
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 1 }}>
                          PAN: {catererDetails?.panNo || "N/A"} <br />
                          GST: {catererDetails?.gstNo || "N/A"} <br />
                          FSSAI: {catererDetails?.fssaiNo || "N/A"}
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                        <Typography variant="h6" fontWeight="bold">
                          Bank Details
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 1 }}>
                          Account Type:{" "}
                          {catererDetails?.bankDetails?.accountType || "N/A"}{" "}
                          <br />
                          Account Number:{" "}
                          {catererDetails?.bankDetails?.accountNumber ||
                            "N/A"}{" "}
                          <br />
                          IFSC Code:{" "}
                          {catererDetails?.bankDetails?.ifscCode || "N/A"}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>

                {/* Caterer Images Card */}

                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      sx={{ mt: 2, alignSelf: "flex-start" }}
                    >
                      KYC Details
                    </Typography>
                    <Button
                      variant="contained"
                      sx={{
                        borderRadius: "2rem",
                        alignSelf: "flex-end",
                        fontWeight: "bold",
                      }}
                      onClick={handleDownload}
                    >
                      Download All
                    </Button>
                  </Box>
                  <Grid container spacing={1}>
                    <Grid item xs={3}>
                      <Card
                        sx={{
                          borderRadius: 2,
                          overflow: "hidden",
                          boxShadow: 1,
                          position: "relative",
                          padding: 2,
                        }}
                      >
                        <Typography variant="body2" fontWeight="bold">
                          PAN No:
                        </Typography>
                        <Typography variant="body2">
                          {catererDetails.panNo}
                        </Typography>
                        {/* Pan Image with Download Icon Overlay */}
                        <Box sx={{ position: "relative" }}>
                          <img
                            component="img"
                            style={{
                              width: "100%", // Ensure the image takes up the full card width
                              height: "250px", // Set a fixed height for the image
                              objectFit: "fill", // Ensure the image covers the area
                              cursor: "pointer",
                            }}
                            src={catererDetails.panImage} // Image URL
                            alt="Pan"
                            onClick={() =>
                              handleImageClick(catererDetails.panImage)
                            } // Pass specific image URL
                          />
                          {/* Download Icon Overlay */}
                          <a href={catererDetails.panImage} download>
                            <IconButton
                              sx={{
                                position: "absolute",
                                top: 8,
                                right: 8,
                                color: "green",
                                backgroundColor: "rgba(255, 255, 255, 0.7)",
                                "&:hover": {
                                  backgroundColor: "rgba(255, 255, 255, 0.9)",
                                },
                              }}
                            >
                              <DownloadIcon />
                            </IconButton>
                          </a>
                        </Box>
                      </Card>
                    </Grid>
                    <Grid item xs={3}>
                      <Card
                        sx={{
                          borderRadius: 2,
                          overflow: "hidden",
                          boxShadow: 1,
                          position: "relative",
                          padding: 2,
                        }}
                      >
                        <Typography variant="body2" fontWeight="bold">
                          GST No:
                        </Typography>
                        <Typography variant="body2">
                          {catererDetails.gstNo}
                        </Typography>
                        {/* Gst Image with Download Icon Overlay */}
                        <Box sx={{ position: "relative" }}>
                          <img
                            component="img"
                            src={catererDetails.gstImage} // Image URL
                            onClick={() =>
                              handleImageClick(catererDetails.gstImage)
                            } // Pass specific image URL
                            alt="GST"
                            style={{
                              width: "100%", // Ensure the image takes up the full card width
                              height: "250px", // Set a fixed height for the image
                              objectFit: "fill", // Ensure the image covers the area
                              cursor: "pointer",
                            }}
                          />
                          {/* Download Icon Overlay */}
                          <a href={catererDetails.gstImage} download>
                            <IconButton
                              sx={{
                                position: "absolute",
                                top: 8,
                                right: 8,
                                color: "green",
                                backgroundColor: "rgba(255, 255, 255, 0.7)",
                                "&:hover": {
                                  backgroundColor: "rgba(255, 255, 255, 0.9)",
                                },
                              }}
                            >
                              <DownloadIcon fontSize="medium" />
                            </IconButton>
                          </a>
                        </Box>
                      </Card>
                    </Grid>
                    <Grid item xs={3}>
                      <Card
                        sx={{
                          borderRadius: 2,
                          overflow: "hidden",
                          boxShadow: 1,
                          position: "relative",
                          padding: 2,
                        }}
                      >
                        <Typography variant="body2" fontWeight="bold">
                          FSSAI No:
                        </Typography>
                        <Typography variant="body2">
                          {catererDetails.fssaiNo}
                        </Typography>

                        {/* FSSAI Image with Download Icon Overlay */}
                        <Box sx={{ position: "relative" }}>
                          <img
                            onClick={() =>
                              handleImageClick(catererDetails.fssaiImage)
                            } // Pass specific image URL
                            src={catererDetails.fssaiImage} // Image URL
                            alt="FSSAI"
                            style={{
                              width: "100%", // Ensure the image takes up the full card width
                              height: "250px", // Set a fixed height for the image
                              objectFit: "fill", // Ensure the image covers the area
                              cursor: "pointer",
                            }}
                          />

                          {/* Download Icon Overlay */}
                          <a href={catererDetails.fssaiImage} download>
                            <IconButton
                              sx={{
                                position: "absolute",
                                top: 8,
                                right: 8,
                                color: "green",
                                backgroundColor: "rgba(255, 255, 255, 0.7)",
                                "&:hover": {
                                  backgroundColor: "rgba(255, 255, 255, 0.9)",
                                },
                              }}
                            >
                              <DownloadIcon fontSize="medium" />
                            </IconButton>
                          </a>
                        </Box>
                      </Card>
                    </Grid>
                    <Grid item xs={3}>
                      <Card
                        sx={{
                          borderRadius: 2,
                          overflow: "hidden",
                          boxShadow: 1,
                          position: "relative",
                          padding: 2,
                        }}
                      >
                        <Typography variant="body2" fontWeight="bold">
                          Owner Photo:
                        </Typography>

                        {/* FSSAI Image with Download Icon Overlay */}
                        <Box sx={{ position: "relative" }}>
                          <img
                            onClick={() =>
                              handleImageClick(catererDetails.ownerPhoto)
                            } // Pass specific image URL
                            src={catererDetails.ownerPhoto} // Image URL
                            alt="FSSAI"
                            style={{
                              width: "100%", // Ensure the image takes up the full card width
                              height: "270px", // Set a fixed height for the image
                              objectFit: "fill", // Ensure the image covers the area
                              cursor: "pointer",
                            }}
                          />

                          {/* Download Icon Overlay */}
                          <a href={catererDetails.ownerPhoto} download>
                            <IconButton
                              sx={{
                                position: "absolute",
                                top: 8,
                                right: 8,
                                color: "green",
                                backgroundColor: "rgba(255, 255, 255, 0.7)",
                                "&:hover": {
                                  backgroundColor: "rgba(255, 255, 255, 0.9)",
                                },
                              }}
                            >
                              <DownloadIcon fontSize="medium" />
                            </IconButton>
                          </a>
                        </Box>
                      </Card>
                    </Grid>

                    <Grid item xs={4}>
                      <Card
                        sx={{
                          borderRadius: 2,
                          overflow: "hidden", // Prevent the card from scrolling
                          boxShadow: 1,
                          position: "relative",
                          padding: 2,
                          height: "auto", // Ensure the card can grow to fit content if needed
                        }}
                      >
                        {/* PDF Preview */}
                        <Box sx={{ position: "relative", height: "250px" }}>
                          {" "}
                          {/* Match video height */}
                          <iframe
                            src={catererDetails?.contractUrl} // PDF URL
                            title="Contract PDF"
                            style={{
                              width: "100%", // Ensure the iframe takes up the full card width
                              height: "100%", // Fill the container height (250px)
                              border: "none", // Remove default iframe borders
                            }}
                          />
                        </Box>

                        {/* Download Icon */}
                        <a href={catererDetails?.contractUrl} download>
                          <IconButton
                            sx={{
                              position: "absolute",
                              top: 8,
                              right: 20,
                              color: "green",
                              backgroundColor: "rgba(255, 255, 255, 0.7)",
                              "&:hover": {
                                backgroundColor: "rgba(255, 255, 255, 0.9)",
                              },
                            }}
                          >
                            <DownloadIcon fontSize="medium" />
                          </IconButton>
                        </a>
                      </Card>
                    </Grid>
                    <Grid item xs={4}>
                      <Card
                        sx={{
                          boxShadow: 1,
                          padding: 2,
                        }}
                      >
                        <video
                          component="video"
                          controls
                          src={catererDetails?.businessVideo} // Ensure this contains the correct video URL
                          alt="Business Video"
                          style={{
                            width: "100%", // Ensure the video takes up the full card width
                            height: "250px", // Set the height to match the PDF card
                            objectFit: "fill", // Ensure the video covers the area
                            cursor: "pointer",
                          }}
                        />
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {/* Pass type dynamically to CatererOrderChatBox */}
            <AdminChatBox type={type} />
          </Box>
        )}

        {tabIndex === 1 && (
          <Box p={3}>
            <Typography variant="body2" fontWeight="bold" gutterBottom>
              {catererDetails?.businessName}
            </Typography>
            <Typography variant="h4" fontWeight="bold" gutterBottom mb={6}>
              Caterer Packages
            </Typography>
            {loading && <CircularProgress />}

            {/* Main Grid Container */}

            <Grid container spacing={2}>
              {/* Left Grid for General Packages */}
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  backgroundColor: "#f0f0f0",
                  p: 3,
                  borderRadius: 2,
                  mb: 2,
                }}
              >
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  General Packages
                </Typography>

                <Grid container spacing={2}>
                  {/* General Veg Packages */}
                  {generalVegPackages.length > 0 && (
                    <Grid item xs={12} md={6}>
                      <Typography variant="h6" fontWeight="bold" gutterBottom>
                        Veg Packages
                      </Typography>
                      <Grid container spacing={2}>
                        {generalVegPackages.map((catererPackage) => (
                          <Grid item xs={12} key={catererPackage._id}>
                            <Card
                              elevation={3}
                              sx={{ borderRadius: 2, cursor: "pointer" }}
                            >
                              <CardContent>
                                {catererPackage.packageId?.packageImages && (
                                  <img
                                    src={
                                      catererPackage.packageId.packageImages[0]
                                    }
                                    alt={catererPackage.packageName}
                                    style={{
                                      width: "100%",
                                      height: "150px",
                                      objectFit: "cover",
                                      borderRadius: "8px",
                                    }}
                                  />
                                )}
                                <Typography
                                  variant="h6"
                                  fontWeight="bold"
                                  mt={1}
                                  noWrap
                                >
                                  {catererPackage.packageName}
                                </Typography>
                                <Typography variant="body1">
                                  Price: ₹{catererPackage.amountPerPerson} per
                                  person
                                </Typography>
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  Type: Veg
                                </Typography>
                                <Box mt={2}>
                                  <Button
                                    fullWidth
                                    fontWeight="bold"
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                      handlePackageClick(catererPackage._id)
                                    }
                                  >
                                    View Package
                                  </Button>
                                </Box>
                              </CardContent>
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  )}

                  {/* General Non-Veg Packages */}
                  {generalNonVegPackages.length > 0 && (
                    <Grid item xs={12} md={6}>
                      <Typography variant="h6" fontWeight="bold" gutterBottom>
                        Non-Veg Packages
                      </Typography>
                      <Grid container spacing={2}>
                        {generalNonVegPackages.map((catererPackage) => (
                          <Grid item xs={12} key={catererPackage._id}>
                            <Card
                              elevation={3}
                              sx={{ borderRadius: 2, cursor: "pointer" }}
                            >
                              <CardContent>
                                {catererPackage.packageId?.packageImages && (
                                  <img
                                    src={
                                      catererPackage.packageId.packageImages[0]
                                    }
                                    alt={catererPackage.packageName}
                                    style={{
                                      width: "100%",
                                      height: "150px",
                                      objectFit: "cover",
                                      borderRadius: "8px",
                                    }}
                                  />
                                )}
                                <Typography
                                  variant="h6"
                                  fontWeight="bold"
                                  mt={1}
                                  noWrap
                                >
                                  {catererPackage.packageName}
                                </Typography>
                                <Typography variant="body1">
                                  Price: ₹{catererPackage.amountPerPerson} per
                                  person
                                </Typography>
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  Type: Non-Veg
                                </Typography>
                                <Box mt={2}>
                                  <Button
                                    fullWidth
                                    fontWeight="bold"
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                      handlePackageClick(catererPackage._id)
                                    }
                                  >
                                    View Package
                                  </Button>
                                </Box>
                              </CardContent>
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              {/* Right Grid for Premium Packages */}
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  backgroundColor: "#f0f0f0",
                  p: 3,
                  borderRadius: 2,
                  mb: 2,
                }}
              >
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  Premium Packages
                </Typography>

                <Grid container spacing={2}>
                  {/* Premium Veg Packages */}
                  {premiumVegPackages.length > 0 && (
                    <Grid item xs={12} md={6}>
                      <Typography variant="h6" fontWeight="bold" gutterBottom>
                        Veg Packages
                      </Typography>
                      <Grid container spacing={2}>
                        {premiumVegPackages.map((catererPackage) => (
                          <Grid item xs={12} key={catererPackage._id}>
                            <Card
                              elevation={3}
                              sx={{
                                borderRadius: 2,
                                cursor: "pointer",
                                backgroundColor: "#e3f2fd",
                              }}
                            >
                              <CardContent>
                                {catererPackage.packageId?.packageImages && (
                                  <img
                                    src={
                                      catererPackage.packageId.packageImages[0]
                                    }
                                    alt={catererPackage.packageName}
                                    style={{
                                      width: "100%",
                                      height: "150px",
                                      objectFit: "cover",
                                      borderRadius: "8px",
                                    }}
                                  />
                                )}
                                <Typography
                                  variant="h6"
                                  fontWeight="bold"
                                  mt={1}
                                  noWrap
                                >
                                  {catererPackage.packageName}
                                </Typography>
                                <Typography variant="body1">
                                  Price: ₹{catererPackage.amountPerPerson} per
                                  person
                                </Typography>
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  Type: Veg
                                </Typography>
                                <Box mt={2}>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                      handlePackageClick(catererPackage._id)
                                    }
                                  >
                                    View Package
                                  </Button>
                                </Box>
                              </CardContent>
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  )}

                  {/* Premium Non-Veg Packages */}
                  {premiumNonVegPackages.length > 0 && (
                    <Grid item xs={12} md={6}>
                      <Typography variant="h6" fontWeight="bold" gutterBottom>
                        Non-Veg Packages
                      </Typography>
                      <Grid container spacing={2}>
                        {premiumNonVegPackages.map((catererPackage) => (
                          <Grid item xs={12} key={catererPackage._id}>
                            <Card
                              elevation={3}
                              sx={{
                                borderRadius: 2,
                                cursor: "pointer",
                                backgroundColor: "#e3f2fd",
                              }}
                            >
                              <CardContent>
                                {catererPackage.packageId?.packageImages && (
                                  <img
                                    src={
                                      catererPackage.packageId.packageImages[0]
                                    }
                                    alt={catererPackage.packageName}
                                    style={{
                                      width: "100%",
                                      height: "150px",
                                      objectFit: "cover",
                                      borderRadius: "8px",
                                    }}
                                  />
                                )}
                                <Typography
                                  variant="h6"
                                  fontWeight="bold"
                                  mt={1}
                                  noWrap
                                >
                                  {catererPackage.packageName}
                                </Typography>
                                <Typography variant="body1">
                                  Price: ₹{catererPackage.amountPerPerson} per
                                  person
                                </Typography>
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  Type: Non-Veg
                                </Typography>
                                <Box mt={2}>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                      handlePackageClick(catererPackage._id)
                                    }
                                  >
                                    View Package
                                  </Button>
                                </Box>
                              </CardContent>
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>

            {catererPackages.length === 0 && (
              <Typography variant="body2" color="textSecondary">
                No packages available.
              </Typography>
            )}

            {/* Chatbox */}
            <AdminChatBox type={type} />
          </Box>
        )}

        {tabIndex === 2 && <CatererOrderTab catererId={catererId} />}
        {tabIndex === 3 && (
          <>
            <CatererPayoutTab catererId={catererId} />
            <Box></Box>
          </>
        )}
      </Box>
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />

      {openPopup && (
        <PackagePopup
          open={openPopup}
          onClose={handleClosePopup}
          packageId={selectedPackageId} // Pass packageId to popup
        />
      )}
    </AdminDrawer>
  );
};

export default CatererDetailsPage;

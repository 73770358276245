import React, { useState, useEffect } from "react";
import {
  Badge,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  ListItemText,
  ListItemIcon,
  Divider,
  Box,
  Fade,
  Tabs,
  Tab,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import { useNavigate } from "react-router-dom";

const NotificationBell = () => {
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0); // 0: User, 1: Service Provider
  const navigate = useNavigate();

  const fetchNotifications = async (tabType) => {
    try {
      const url = tabType === 0
        ? "https://admin.fesste.com/api/sp-notifications?noteType=order"
        : "https://admin.fesste.com/api/support-notifications";

      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Failed to fetch notifications");
      }
      const data = await response.json();
      setNotifications(data.notifications);
      setUnreadCount(data?.notifications?.filter((n) => !n.isRead).length);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  useEffect(() => {
    fetchNotifications(selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    if ('serviceWorker' in navigator) {

      // Listen for messages from the service worker
      navigator.serviceWorker.addEventListener('message', (event) => {
        if (event.data && event.data.type === 'NEW_NOTIFICATION') {
          const newNotification = event.data.data;
  
  
          // Update the notifications list and unread count
          setNotifications((prevNotifications) => {
            const updatedNotifications = [newNotification, ...prevNotifications];
            setUnreadCount(updatedNotifications.filter((n) => !n.isRead).length);
            return updatedNotifications;
          });
        }
      });
    }
  }, []);
  
  

  const handleBellClick = async (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleTabChange = (event, newTab) => {
    setSelectedTab(newTab);
    fetchNotifications(newTab);
  };

  const markAsRead = async (notificationId, redirectToPage = false) => {
    try {
      let apiUrl = "";

      if (selectedTab === 0) {
        apiUrl = `https://admin.fesste.com/api/sp-notifications/${notificationId}/seen`;
      } else if (selectedTab === 1) {
        apiUrl = `https://admin.fesste.com/api/supportnote/${notificationId}/seen`;
      }

      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to mark notification as read");
      }

      setNotifications((prev) => prev.filter((n) => n._id !== notificationId));
      setUnreadCount((prev) => prev - 1);

      if (redirectToPage) {
        if (selectedTab === 0) {
          navigate("/ongoing-orders");
        } else if (selectedTab === 1) {
          navigate("/caterer-queries");
        }
      }
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  return (
    <div>
      <IconButton onClick={handleBellClick}>
        <Badge badgeContent={unreadCount} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        PaperProps={{
          style: {
            maxHeight: 48 * 15,
            width: "320px",
            borderRadius: "12px",
            boxShadow: "0 6px 12px rgba(0, 0, 0, 0.1)",
            padding: "12px",
            backgroundColor: "#fff",
            overflow: "hidden",
          },
        }}
        TransitionComponent={Fade}
        TransitionProps={{
          timeout: 300,
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="notification tabs"
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Order " />
          <Tab label="Support " />
        </Tabs>

        <Box sx={{ maxHeight: "calc(48px * 5)", overflowY: "auto" }}>
          {notifications ? (
            notifications.map((notification) => (
              <div key={notification._id}>
                <MenuItem
                  onClick={() => markAsRead(notification._id, true)}
                  style={{
                    padding: "12px 16px",
                    backgroundColor: notification.isRead ? "transparent" : "#f0f4f8",
                    borderRadius: "8px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                  }}
                  onMouseEnter={(e) => (e.target.style.backgroundColor = "#e0e7f3")}
                  onMouseLeave={(e) => (e.target.style.backgroundColor = notification.isRead ? "transparent" : "#f0f4f8")}
                >
                  <ListItemIcon>
                    {notification.isRead ? (
                      <CheckCircleIcon color="disabled" fontSize="small" />
                    ) : (
                      <NewReleasesIcon color="error" fontSize="small" />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="body2" style={{ fontWeight: notification.isRead ? "normal" : "bold" }}>
                        {notification.title}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="body2" color="textSecondary" style={{ fontSize: "0.9rem" }}>
                        {notification.body}
                      </Typography>
                    }
                  />
                </MenuItem>
                <Divider light style={{ margin: "8px 0" }} />
              </div>
            ))
          ) : (
            <MenuItem>
              <Typography variant="body2" style={{ padding: "12px", color: "gray" }}>
                No new notifications
              </Typography>
            </MenuItem>
          )}
        </Box>
      </Menu>
    </div>
  );
};

export default NotificationBell;

import React from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Paper, Box,IconButton } from '@mui/material';
import { Print, Download } from '@mui/icons-material';
import OrderHeader from './OrderHeader';
import OrderDetailsCard from './OrderDetailsCard';
import OrderItemList from './OrderItemList';
import PriceSummary from './PriceSummary';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const OrderDetails = () => {
    const { state } = useLocation(); // Access passed state
    const { orderData } = state || {}; // Retrieve order data

    // Extract and format order date
    const orderDate = orderData?.createdAt ? new Date(orderData.createdAt).toLocaleDateString() : '20-04-2024';

    const handlePrint = () => {
        window.print();
    };

    const handleDownload = () => {
        const content = document.getElementById('printable-content');

        html2canvas(content, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const imgWidth = 210;
            const pageHeight = 295;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            let position = 0;

            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            pdf.save('order-details.pdf');
        });
    };


    const isDownloading = true; // Add this in your state or as a prop for download condition

    console.log('orderData',orderData);
    

    return (
        <Container
            sx={{
                padding: 4,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh', // Center vertically
                backgroundColor: '#f5f5f5',
                position: 'relative', // For absolute positioning of the buttons
            }}
        >
            {/* Print Button */}
            <IconButton
                id="print-button"
                variant="contained"
                onClick={handlePrint}
                sx={{
                    position: 'absolute',
                    top: 16,
                    right: 76,
                }}
            >
                <Print />
            </IconButton>

            {/* Download Button */}
            <IconButton
                id="download-button"
                variant="contained"
                onClick={handleDownload}
                sx={{
                    position: 'absolute',
                    color: 'green',
                    top: 16,
                    right: 16,
                }}
            >
                <Download />
            </IconButton>

            <Paper
                id="printable-content"
                sx={{
                    padding: 4,
                    width: '100%',
                    maxWidth: '900px',
                    backgroundColor: '#fff',
                    boxShadow: isDownloading ? 'none' : '0px 12px 14px rgba(0, 0, 0, 0.1)',
                }}
            >

                {/* Order Header Section */}
                <OrderHeader
                    status={orderData?.status}
                    orderDate={orderDate}
                    orderId={orderData?.restaurant_orderId || 'N/A'}
                />

                {/* Order Details Section */}
                <OrderDetailsCard
                    orderData={orderData}
                    customerDetails={orderData?.customer || orderData?.userId}
                    restaurantDetails={orderData?.restaurantId || orderData?.catererId}
                />

                {/* Order Item List Section */}
                <Box
                    sx={{
                        marginBottom: 4,
                    }}
                >
                    <OrderItemList items={orderData?.items || []} />
                </Box>

                {/* Price Summary Section */}
                <PriceSummary
                    paymentData={orderData}
                    total={orderData?.totalAmount || 0}
                    packingCharge={0} // Default value
                    deliveryFee={0} // Default value
                    tax={0} // Default value
                    tips={0} // Default value
                    discount={0} // Default value
                />
            </Paper>
        </Container>
    );
};

export default OrderDetails;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AdminDrawer from "../../components/AdminDrawer/AdminDrawer";
import {
  styled,
  Container,
  Tab,
  Typography,
  Tabs,
  Paper,
  Button,
  IconButton,
  Tooltip,
  Dialog,
  DialogContent,
  DialogTitle,
  Box
} from "@mui/material";
import DataTable from "../../components/DataTables/DataTable";
import {
  fetchPromoCodes,
  deletePromoCode,
  updatePromoCodeStatus,
} from "../../api/promocodes/promocodeApi";
import ConfirmDialog from "../../components/ResuableComponents/ConfirmDialog";
import CustomSnackbar from "../../components/CustomeSnackBar/CustomSnackbar";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import baseUrl from "../../config"; // Assuming baseUrl is defined in config
import { useDialog } from "../../hooks/useDialog";
import { renderCellWithMore } from "../../components/ResuableComponents/ViewMoreComponents/ViewMorePopup";
import BreadcrumbsComponent from "../../components/ResuableComponents/BreadCrumb";

const StyledTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    backgroundColor: "green",
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  color: "green",
  fontWeight: 400,
  "&.Mui-selected": {
    color: "green",
    fontWeight: 500,
  },
}));

const tabConfig = [
  { title: "All", apiUrl: `${baseUrl}promocodes`, filter: null },
  {
    title: "Caterers",
    apiUrl: `${baseUrl}promocode_caterer`,
    filter: "caterer",
  },
  {
    title: "Restaurants",
    apiUrl: `${baseUrl}promocode_restaurant`,
    filter: "restaurant",
  },
];

const PromoCodes = () => {
  const { dialogContent, dialogOpen, handleOpenDialog, handleCloseDialog } =
    useDialog();

  const [value, setValue] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const navigate = useNavigate();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedPromoCodeId, setSelectedPromoCodeId] = useState(null);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleAddClick = () => {
    if (value === 1) {
      navigate("/add-caterer-promoCodes");
    } else if (value === 2) {
      navigate("/add-restaurant-promoCodes");
    }
  };

  const handleEditPromoCode = (id) => {
    if (value === 1) {
      navigate(`/edit-caterer-promoCodes/${id}`);
    } else if (value === 2) {
      navigate(`/edit-restaurant-promoCodes/${id}`);
    }
  };

  const handleViewPromoCode = (id) => {
    if (value === 1) {
      navigate(`/view-caterer-promocodes/${id}`);
    } else if (value === 2) {
      navigate(`/view-restaurant-promocodes/${id}`);
    }
  };

  // Function to update promo code status
  const handlePromoCodeAction = async (promocodeId, status) => {
    try {
      await updatePromoCodeStatus(promocodeId, status);
      setSnackbarMessage(
        `Promo code ${status === "approved" ? "approved" : "rejected"
        } successfully`
      );
      setSnackbarSeverity("success");
      setSnackbarOpen(true);

      // Refresh the promo code list after action
      const apiUrl = tabConfig[value].apiUrl;
      const promoCodes = await fetchPromoCodes(apiUrl);
      const formattedData = promoCodes?.map((promo, index) => ({
        id: index + 1,
        code: promo.code,
        discountType: promo.discountType,
        discountValue: promo.discountValue,
        minOrderValue: promo.minOrderValue,
        approvalStatus: promo?.approvalStatus,
        createdByRole: promo.createdBy?.role,
        userUsageLimit: promo.userUsageLimit,
        createdAt: new Date(promo.createdAt).toLocaleString(),
        expiryDate: new Date(promo.expiryDate).toLocaleDateString(),
        promoDescription: promo.promoDescription,
        maxAmount: promo.maxAmount,
        adminContri: promo.adminContri,
        availableFrom: new Date(promo.availableFrom).toLocaleDateString(),
        status: promo.status,
        promocodeType: promo?.promocodeType.join(", "),
        paymentMethods: promo.paymentMethods.join(", "),
        _id: promo._id,
      }));
      setTableData(formattedData);
    } catch (error) {
      setSnackbarMessage("Failed to update promo code status");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    const fetchAndSetPromoCodes = async () => {
      try {
        const apiUrl = tabConfig[value].apiUrl; // Fetch URL based on selected tab
        const promoCodes = await fetchPromoCodes(apiUrl);
        const formattedData = promoCodes?.map((promo, index) => ({
          id: index + 1,
          code: promo.code,
          discountType: promo.discountType,
          discountValue: promo.discountValue,
          minOrderValue: promo.minOrderValue,
          createdByRole: promo.createdBy?.role,
          userUsageLimit: promo.userUsageLimit,
          createdAt: new Date(promo.createdAt).toLocaleString(),
          expiryDate: new Date(promo.expiryDate).toLocaleDateString(),
          promoDescription: promo.promoDescription,
          maxAmount: promo.maxAmount,
          adminContri: promo.adminContri,
          availableFrom: new Date(promo.availableFrom).toLocaleDateString(),
          status: promo.status,
          approvalStatus: promo?.approvalStatus,
          promocodeType: promo?.promocodeType.join(", "),
          paymentMethods: promo.paymentMethods.join(", "),
          _id: promo._id,
        }));
        setTableData(formattedData);
      } catch (error) {
        setSnackbarMessage("Error fetching promo codes");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    };

    fetchAndSetPromoCodes();
  }, [value]);

  const promoCodeColumns = [
    { field: "id", headerName: "ID", minWidth: 10, sortable: true },
    { field: "code", headerName: "Promo Code", minWidth: 200, sortable: true },
    {
      field: "discountType",
      headerName: "Discount Type",
      minWidth: 200,
      sortable: true,
    },
    {
      field: "discountValue",
      headerName: "Discount Value",
      minWidth: 200,
      sortable: true,
    },
    {
      field: "minOrderValue",
      headerName: "Min Order Value",
      minWidth: 200,
      sortable: true,
    },
    {
      field: "createdByRole",
      headerName: "Created By Role",
      minWidth: 200,
      sortable: true,
    },
    {
      field: "userUsageLimit",
      headerName: "Usage Limit",
      minWidth: 200,
      sortable: true,
    },
    {
      field: "createdAt",
      headerName: "Created On",
      minWidth: 200,
      sortable: true,
    },
    {
      field: "expiryDate",
      headerName: "Expiry Date",
      minWidth: 200,
      sortable: true,
    },
    {
      field: "promoDescription",
      headerName: "Description",
      minWidth: 250,
      sortable: true,
      renderCell: (params) => {
        const text = params.value || ""; // Ensure value is a string
        return renderCellWithMore(text, handleOpenDialog);
      },
    },
    {
      field: "maxAmount",
      headerName: "Max Discount Amount",
      minWidth: 250,
      sortable: true,
    },
    {
      field: "adminContri",
      headerName: "Admin Contribution",
      minWidth: 250,
      sortable: true,
    },
    {
      field: "availableFrom",
      headerName: "Available From",
      minWidth: 200,
      sortable: true,
    },
    { field: "status", headerName: "Status", minWidth: 200, sortable: true },
    {
      field: "promocodeType",
      headerName: "PromoCode Type",
      minWidth: 200,
      sortable: true,
    },
    {
      field: "paymentMethods",
      headerName: "Payment Methods",
      minWidth: 250,
      sortable: true,
      renderCell: (params) => {
        const text = params.value || ""; // Ensure value is a string
        return renderCellWithMore(text, handleOpenDialog);
      },
    },
    {
      field: "takeAction",
      headerName: "Take Action",
      minWidth: 250,
      sortable: true,
      renderCell: (params) => {
        const isApproved = params.row.approvalStatus === "approved";
        const isRejected = params.row.approvalStatus === "rejected";

        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {/* Accept button */}
            <Tooltip title="Accept">
              <span>
                <Button
                  variant="contained"
                  disabled={isApproved} // Only disable if it's already approved
                  onClick={() =>
                    handlePromoCodeAction(params.row._id, "approved")
                  }
                  style={{ marginRight: "10px" }} // Add margin to the right
                >
                  {isApproved ? "Approved" : "Accept"}
                </Button>
              </span>
            </Tooltip>

            {/* Reject button */}
            <Tooltip title="Reject">
              <span>
                <Button
                  variant="contained"
                  disabled={isRejected} // Only disable if it's already rejected
                  onClick={() =>
                    handlePromoCodeAction(params.row._id, "rejected")
                  }
                >
                  {isRejected ? "Rejected" : "Reject"}
                </Button>
              </span>
            </Tooltip>
          </div>
        );
      },
    },

    // Conditional action buttons for Caterers or Restaurants
    ...(value === 0
      ? []
      : [
        {
          field: "Actions",
          headerName: "Actions",
          minWidth: 120,
          sortable: false,
          renderCell: (params) => (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
              }}
            >                <Tooltip title="View">
                <IconButton
                  sx={{ color: "#87CEEB", marginRight: "8px" }}
                  onClick={() => handleViewPromoCode(params.row._id)}
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit">
                <IconButton
                  color="success"
                  onClick={() => handleEditPromoCode(params.row._id)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  color="error"
                  onClick={() => handleOpenConfirmDialog(params.row._id)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </div>
          ),
        },
      ]),
  ];

  const handleOpenConfirmDialog = (promoCodeId) => {
    setSelectedPromoCodeId(promoCodeId);
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
    setSelectedPromoCodeId(null);
  };

  const handleDeletePromoCode = async () => {
    if (selectedPromoCodeId) {
      try {
        await deletePromoCode(selectedPromoCodeId);
        handleCloseConfirmDialog();
        const apiUrl = tabConfig[value].apiUrl;
        const promoCodes = await fetchPromoCodes(apiUrl);
        const formattedData = promoCodes?.map((promo, index) => ({
          id: index + 1,
          code: promo.code,
          discountType: promo.discountType,
          discountValue: promo.discountValue,
          minOrderValue: promo.minOrderValue,
          createdByRole: promo.createdBy?.role,
          userUsageLimit: promo.userUsageLimit,
          createdAt: new Date(promo.createdAt).toLocaleString(),
          expiryDate: new Date(promo.expiryDate).toLocaleDateString(),
          promoDescription: promo.promoDescription,
          maxAmount: promo.maxAmount,
          adminContri: promo.adminContri,
          availableFrom: new Date(promo.availableFrom).toLocaleDateString(),
          status: promo.status,
          promocodeType: promo?.promocodeType.join(", "),
          paymentMethods: promo.paymentMethods.join(", "),
          _id: promo._id,
        }));
        setTableData(formattedData);
        setSnackbarMessage("Successfully Deleted PromoCode");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      } catch (error) {
        console.error("Error deleting promo code:", error);
        setSnackbarMessage("Error deleting promo code");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    }
  };

  return (
    <div style={{ backgroundColor: "#f2f2f2" }}>
      <AdminDrawer>
        <Box display="flex">
          <Typography variant="h4" fontWeight="bolder">
            PromoCodes
          </Typography>
          <Box ml={3} mt={1}>
            <BreadcrumbsComponent />
          </Box>
        </Box>

        <StyledTabs value={value} onChange={handleTabChange}>
          {tabConfig.map((tab, index) => (
            <StyledTab key={index} label={tab.title} />
          ))}
        </StyledTabs>
        <Paper elevation={0} sx={{ padding: "6px", backgroundColor: "#fff" }}>
          <Container sx={{ minWidth: "100%" }}>
            {/* Conditionally render the add buttons based on selected tab */}
            {(value === 1 || value === 2) && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  color="success"
                  startIcon={<AddIcon />}
                  onClick={handleAddClick}
                >
                  {value === 1
                    ? "Add Caterer PromoCodes"
                    : "Add Restaurant PromoCodes"}
                </Button>
              </div>
            )}
            <DataTable
              rows={tableData}
              columns={promoCodeColumns}
              onEdit={handleEditPromoCode}
            />
          </Container>
        </Paper>
        <ConfirmDialog
          open={openConfirmDialog}
          onClose={handleCloseConfirmDialog}
          onConfirm={handleDeletePromoCode}
          title="Confirm Delete"
          message="Are you sure you want to delete this promo code?"
        />
        <CustomSnackbar
          open={snackbarOpen}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
          severity={snackbarSeverity}
        />
        <Dialog open={dialogOpen} onClose={handleCloseDialog}>
          <DialogTitle>Details</DialogTitle>
          <DialogContent>
            <Typography>{dialogContent}</Typography>
          </DialogContent>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </Dialog>
      </AdminDrawer>
    </div>
  );
};

export default PromoCodes;

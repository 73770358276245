// src/pages/PackageDetailsPage.js

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, Paper, Button, Container } from '@mui/material';
import { getPackageById } from '../../../../api/catererPackages/catererPackages';
import AdminDrawer from '../../../../components/AdminDrawer/AdminDrawer';
import BreadcrumbsComponent from '../../../../components/ResuableComponents/BreadCrumb';

const PackageDetailsPage = () => {
    const { id } = useParams(); // Get the package ID from the URL parameters
    const [packageData, setPackageData] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPackage = async () => {
            const data = await getPackageById(id);
            if (data) {
                setPackageData(data);
            } else {
                console.error('Failed to fetch package details');
            }
        };

        fetchPackage();
    }, [id]);

    const handleBackClick = () => {
        navigate(-1); // Navigate back to the previous page
    };

    return (
        <div style={{ backgroundColor: '#f2f2f2' }}>
            <AdminDrawer>
                <BreadcrumbsComponent />
                <Container sx={{ marginTop: '20px' }}>
                    {packageData ? (
                        <Paper elevation={0} sx={{ padding: '20px', backgroundColor: '#f9f9f9' }}>
                            <Typography variant='h4' fontWeight='bolder' gutterBottom>
                                {packageData.packageName}
                            </Typography>
                            <Typography variant='body1'>
                                <strong>Package Type:</strong> {packageData.type}
                            </Typography>
                            <Typography variant='body1'>
                                <strong>Allocated To:</strong> {packageData.allocatedTo.join(', ')}
                            </Typography>
                            <Typography variant='body1'>
                                <strong>Created At:</strong> {new Date(packageData.createdAt).toLocaleString()}
                            </Typography>
                            {packageData.packageImages && packageData.packageImages.length > 0 && (
                                <div style={{ marginTop: '20px' }}>
                                    <Typography variant='body1'>
                                        <strong>Package Images:</strong>
                                    </Typography>
                                    <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                                        {packageData.packageImages.map((image, index) => (
                                            <img
                                                key={index}
                                                src={image}
                                                alt="Package"
                                                style={{ width: '150px', height: 'auto', borderRadius: '4px' }}
                                            />
                                        ))}
                                    </div>
                                </div>
                            )}
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{ marginTop: '20px' }}
                                onClick={handleBackClick}
                            >
                                Back
                            </Button>
                        </Paper>
                    ) : (
                        <Typography variant='h6'>Loading package details...</Typography>
                    )}
                </Container>
            </AdminDrawer>
        </div>

    );
};

export default PackageDetailsPage;

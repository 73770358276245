import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, Tooltip, Typography, Paper, Button, Container, Dialog, DialogContent, DialogTitle, Skeleton } from '@mui/material';
import DataTable from '../../../components/DataTables/DataTable';
import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
// import ConfirmDialog from '../../../components/ResuableComponents/ConfirmDialog';
// import { deleteItem } from '../../../api/catererPackages/catererItems';
import CustomSnackbar from '../../../components/CustomeSnackBar/CustomSnackbar';
import baseUrl from '../../../config';

const MenuItems = () => {
    const [tableData, setTableData] = useState([]);
    // const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    // const [selectedItemId, setSelectedItemId] = useState(null);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' }); // Snackbar state
    const navigate = useNavigate();
    const [dialogContent, setDialogContent] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [loading, setLoading] = useState(true); // Add loading state

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${baseUrl}menu/items`);
                if (response.ok) {
                    const data = await response.json();

                    // Transforming data
                    const transformedData = data.map((item, index) => ({
                        id: item._id,
                        siNo: index + 1,
                        itemName: item.itemName,
                        itemDescription: item.itemDescription,
                        itemImage: item.itemImages[0] || 'N/A',
                        packageName: item.packages?.map((data) => data.packageName),
                        subPackageName: item?.subPackage?.subPackageName,
                        allocatedTo: item?.allocatedTo?.join(', '),
                        packageType: item?.type,
                        createdAt: new Date(item.createdAt).toLocaleDateString(),
                    }));

                    setTableData(transformedData);                    
                } else {
                    console.error('Failed to fetch data:', response.statusText);
                    setTableData([]);
                }
            } catch (error) {
                console.error('Failed to fetch data:', error);
                setTableData([]);
            }
            finally {
                setLoading(false); // Stop loading when data is fetched
            }
        };

        fetchData();
    }, []);

    const handleAddClick = () => {
        navigate('/add-caterer-items');
    };

    const handleEditClick = (id) => {
        navigate(`/edit-caterer-item/${id}`);
    };

    // const handleDeleteClick = (id) => {
    //     setSelectedItemId(id);
    //     setOpenConfirmDialog(true);
    // };

    // const handleConfirmDelete = async () => {
    //     try {
    //         await deleteItem(selectedItemId);
    //         setTableData(prevData => prevData.filter(item => item.id !== selectedItemId));

    //         // Show success snackbar
    //         setSnackbar({
    //             open: true,
    //             message: 'Item deleted successfully',
    //             severity: 'success',
    //         });
    //     } catch (error) {
    //         console.error('Error deleting item:', error);

    //         // Show error snackbar
    //         setSnackbar({
    //             open: true,
    //             message: 'Failed to delete item',
    //             severity: 'error',
    //         });
    //     } finally {
    //         setOpenConfirmDialog(false);
    //         setSelectedItemId(null);
    //     }
    // };

    const handleSnackbarClose = () => {
        setSnackbar({ open: false, message: '', severity: '' });
    };

    const handleItemViewClick = (id) => {
        navigate(`/view-item-details/${id}`);
    };

    const handleOpenDialog = (content) => {
        setDialogContent(content);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const renderCellWithMore = (text, handleOpenDialog) => (
        <div style={{
            whiteSpace: 'normal', // Allow text to wrap
            overflow: 'hidden',
            textOverflow: 'ellipsis', // Add ellipsis for overflow text
            display: '-webkit-box',
        }}>
            {text.length > 20 ? `${text.slice(0, 20)}...` : text}
            {text.length > 20 && (
                <Button
                    variant="text"
                    onClick={() => handleOpenDialog(text)} // Open dialog with the full description
                >
                    <Typography fontSize='12px' variant='secondary' sx={{ textTransform: 'none' }}>More</Typography>
                </Button>
            )}
        </div>
    );

    const columns = [
        { field: 'siNo', headerName: 'ID', minWidth: 10, sortable: true },
        {
            field: 'itemImage', // Clickable image rendering logic
            headerName: 'Item Image',
            sortable: false,
            minWidth: 180,
            renderCell: (params) => (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <img
                        onClick={() => navigate(`/view-item-details/${params.row.id}`)}  // Navigate to item details on click
                        src={params.value !== 'N/A' ? params.value : 'placeholder-image-url.jpg'}
                        alt="Item"
                        style={{
                            width: '100px',
                            height: '60px',
                            borderRadius: '4px',
                            objectFit: 'fill',
                        }}
                    />
                </div>
            ),
        },

        {
            field: 'itemName', 
            headerName: 'Item Name', 
            minWidth: 250,
            renderCell: (params) => {
                // Convert array of package names to a single string
                const text = Array.isArray(params.value) ? params.value.join(', ') : (params.value || '');
        
                return (
                    <div
                        onClick={() => handleItemViewClick(params.row.id)} // Navigate to package details page on click
                        style={{
                            cursor: 'pointer',
                            // Optional: to show it's clickable
                        }}
                    >
                        {renderCellWithMore(text, handleOpenDialog)} {/* Display the processed text */}
                    </div>
                );
            }
        },
        
        {
            field: 'itemDescription',
            headerName: 'Item Description',
            minWidth: 250,
            sortable: true,
            renderCell: (params) => {
                const text = params.value || ''; // Ensure value is a string
                return renderCellWithMore(text, handleOpenDialog);
            },
        },
        {
            field: 'packageName',
            headerName: 'Package Name',
            minWidth: 250,
            sortable: true,
            renderCell: (params) => {
                // Convert array of package names to a single string
                const text = Array.isArray(params.value) ? params.value.join(', ') : (params.value || '');

                return renderCellWithMore(text, handleOpenDialog);
            },
        },

        {
            field: 'subPackageName', headerName: 'Sub Package Name', minWidth: 250, renderCell: (params) => {
                // Convert array of package names to a single string
                const text = Array.isArray(params.value) ? params.value.join(', ') : (params.value || '');

                return renderCellWithMore(text, handleOpenDialog);
            },
        },
        {
            field: 'allocatedTo', headerName: 'Allocated To', minWidth: 200, renderCell: (params) => {
                // Convert array of package names to a single string
                const text = Array.isArray(params.value) ? params.value.join(', ') : (params.value || '');

                return renderCellWithMore(text, handleOpenDialog);
            },
        },
        {
            field: 'packageType', headerName: 'Package Type', minWidth: 200, renderCell: (params) => {
                // Convert array of package names to a single string
                const text = Array.isArray(params.value) ? params.value.join(', ') : (params.value || '');

                return renderCellWithMore(text, handleOpenDialog);
            },
        },
        {
            field: 'createdAt', headerName: 'Created At', minWidth: 200, renderCell: (params) => {
                // Convert array of package names to a single string
                const text = Array.isArray(params.value) ? params.value.join(', ') : (params.value || '');

                return renderCellWithMore(text, handleOpenDialog);
            },
        },
        {
            field: 'Actions',
            headerName: 'Actions',
            minWidth: 160,
            sortable: false,
            renderCell: (params) => (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',   // Horizontally center the items
                        alignItems: 'center',       // Vertically center the items
                        width: '100%',              // Ensure the div takes full width of the cell
                        height: '100%',             // Ensure the div takes full height of the cell
                    }}
                >
                    <Tooltip title="View">
                        <IconButton sx={{ color: '#87CEEB', marginRight: '8px' }} onClick={() => handleItemViewClick(params.row.id)}>
                            <VisibilityIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit">
                        <IconButton color="success" sx={{ marginRight: '8px' }} onClick={() => handleEditClick(params.row.id)}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    {/* <Tooltip title="Delete">
                        <IconButton color="error" onClick={() => handleDeleteClick(params.row.id)}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip> */}
                </div>
            ),
        },
    ];

    return (
        <div style={{ backgroundColor: '#f2f2f2' }}>
            <Typography variant='h4' fontWeight='bolder' gutterBottom>
                Items
            </Typography>
            <Paper elevation={0} sx={{ padding: '14px', backgroundColor: '#fff' }}>
                <Container sx={{ minWidth: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Button
                            variant="contained"
                            color="success"
                            startIcon={<AddIcon />}
                            onClick={handleAddClick}
                        >
                            Add Item
                        </Button>
                    </div>
                    {loading ? (
                        // Skeleton loading shimmer while data is being fetched
                        <>
                            {[...Array(5)].map((_, index) => (
                                <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                                    <Skeleton variant="rectangular" width={100} height={60} style={{ marginRight: '16px' }} />
                                    <Skeleton variant="text" width="30%" />
                                    <Skeleton variant="text" width="20%" style={{ marginLeft: '16px' }} />
                                    <Skeleton variant="text" width="20%" style={{ marginLeft: '16px' }} />
                                </div>
                            ))}
                        </>
                    ) : (
                        <DataTable
                            rows={tableData}
                            columns={columns}

                        />
                    )}
                </Container>
            </Paper>

            {/* Confirm Delete Dialog */}
            {/* <ConfirmDialog
                open={openConfirmDialog}
                onClose={() => setOpenConfirmDialog(false)}
                onConfirm={handleConfirmDelete}
                title="Confirm Deletion"
                content="Are you sure you want to delete this item?"
            /> */}

            {/* Custom Snackbar */}
            <CustomSnackbar
                open={snackbar.open}
                message={snackbar.message}
                severity={snackbar.severity}
                onClose={handleSnackbarClose}
            />
            <Dialog open={dialogOpen} onClose={handleCloseDialog}>
                <DialogTitle>Details</DialogTitle>
                <DialogContent>
                    <Typography>{dialogContent}</Typography>
                </DialogContent>
                <Button onClick={handleCloseDialog} color="primary">
                    Close
                </Button>
            </Dialog>
        </div>
    );
};

export default MenuItems;

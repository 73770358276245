import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getSubPackageById } from '../../../../api/catererPackages/catererSubPackages';
import { Typography, Container, Paper, CircularProgress, Grid, Box } from '@mui/material';
import AdminDrawer from '../../../../components/AdminDrawer/AdminDrawer';
import BreadcrumbsComponent from '../../../../components/ResuableComponents/BreadCrumb';

const SubPackageDetailsPage = () => {
    const { id } = useParams(); // Get the subpackage ID from the URL
    const [subPackageData, setSubPackageData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getSubPackageDetails = async () => {
            try {
                const response = await getSubPackageById(id);
                setSubPackageData(response);
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch subpackage details:', error);
                setLoading(false);
            }
        };

        getSubPackageDetails();
    }, [id]);

    if (loading) {
        return <CircularProgress style={{ display: 'block', margin: 'auto', padding: '20px' }} />;
    }

    if (!subPackageData) {
        return <Typography variant="h6" align="center" color="textSecondary">SubPackage not found</Typography>;
    }

    return (
        <AdminDrawer>
            <BreadcrumbsComponent />
            <Container maxWidth="lg">
                <Typography variant="h4" fontWeight="bold" gutterBottom>
                    SubPackage Details
                </Typography>
                <Paper elevation={3} sx={{ padding: '20px', backgroundColor: '#fafafa' }}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h6" fontWeight="bold" gutterBottom>
                                {subPackageData.subPackageName}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong>Allocated To:</strong> {subPackageData.allocatedTo?.join(', ')}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong>Created At:</strong> {new Date(subPackageData.createdAt).toLocaleDateString()}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {subPackageData.subPackageImages?.length > 0 && (
                                <Box>
                                    <Typography variant="h6" fontWeight="bold" gutterBottom>
                                        Images
                                    </Typography>
                                    <Grid container spacing={2}>
                                        {subPackageData.subPackageImages.map((image, index) => (
                                            <Grid item xs={6} md={4} key={index}>
                                                <img
                                                    src={image}
                                                    alt={`SubPackage ${index}`}
                                                    style={{ width: '100%', height: 'auto', borderRadius: '8px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)' }}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </AdminDrawer>
    );
};

export default SubPackageDetailsPage;

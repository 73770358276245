import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Button, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AdminDrawer from '../../../components/AdminDrawer/AdminDrawer';
import PayoutTable from '../../../components/DataTables/PayoutTables/PayoutTable';
import BreadcrumbsComponent from '../../../components/ResuableComponents/BreadCrumb';

const DeliveryPartnersPayout = () => {
    const navigate = useNavigate();
    const [deliveryPartnerData, setDeliveryPartnerData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPayoutData = async () => {
            try {
                setLoading(true); // Start loading
                const response = await fetch('https://admin.fesste.com/api/dpPayouts');
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const result = await response.json();
                if (result.success) {
                    const transformedData = result.data.map((item) => ({
                        id: item?.deliveryPartnerId,
                        name: item?.name,
                        phoneNumber: item?.phoneNumber,
                        totalDeliveries: item?.totalOrders,
                        totalToBePaid: `₹${item?.totalDeliveredFee}`,
                    }));
                    setDeliveryPartnerData(transformedData); 
                } else {
                    throw new Error('Unexpected API response');
                }
            } catch (error) {
                setError(error.message); 
            } finally {
                setLoading(false); 
            }
        };

        fetchPayoutData();
    }, []);

    const handleMakePayment = (deliveryPartnerId, name) => {
        navigate(`/payment-dashboard/delivery-partner/${encodeURIComponent(deliveryPartnerId)}`, {
            state: { name: name },
        });
    };
    

    const columns = [
        { field: 'name', headerName: 'Delivery Partner Name', flex: 1 },
        { field: 'phoneNumber', headerName: 'Phone Number', flex: 1 },
        { field: 'totalDeliveries', headerName: 'Total Deliveries', flex: 1 },
        { field: 'totalToBePaid', headerName: 'Total to be Paid', flex: 1 },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1,
            renderCell: (params) => (
                <Button
                    variant="outlined"
                    color="success"
                    onClick={() => handleMakePayment(params.row.id, params.row.name)}
                >
                    Make Payment
                </Button>
            ),
        },
    ];

    if (loading) {
        return (
            <div style={{ textAlign: 'center', padding: '50px' }}>
                <Typography variant="h6">Loading...</Typography>
            </div>
        );
    }

    if (error) {
        return (
            <div style={{ textAlign: 'center', padding: '50px' }}>
                <Typography variant="h6" color="error">
                    {error}
                </Typography>
            </div>
        );
    }

    return (
        <div style={{ backgroundColor: '#f2f2f2', height: '100vh' }}>
            <AdminDrawer>
                <div style={{ display: 'flex', alignItems: 'center', padding: '16px' }}>
                    <IconButton
                        edge="start"
                        color="primary"
                        onClick={() => navigate(-1)} // Navigate back
                        style={{ marginRight: '8px' }}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h5">Delivery Partner Payout</Typography>
                </div>
                <BreadcrumbsComponent />
                <div style={{ padding: '24px' }}>
                    <PayoutTable
                        rows={deliveryPartnerData}
                        columns={columns}
                    />
                </div>
            </AdminDrawer>
        </div>
    );
};

export default DeliveryPartnersPayout;

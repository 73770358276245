import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AdminDrawer from '../../../components/AdminDrawer/AdminDrawer';
import { Grid, Paper, Typography, TextField, Autocomplete, Button, Box } from '@mui/material';
import { fetchRestaurants, createRestaurantSubPackage, updateRestaurantSubPackage } from '../../../api/restaurantPackages/restaurantItemsApi';
import CustomSnackbar from '../../../components/CustomeSnackBar/CustomSnackbar';
import  baseUrl  from "../../../config";

const AddRestaurantSubPackage = () => {
    const [restaurants, setRestaurants] = useState([]);
    const [selectedRestaurants, setSelectedRestaurants] = useState([]);
    const [subPackageName, setSubPackageName] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const navigate = useNavigate();
    const { id } = useParams(); // Get the ID from URL params

    useEffect(() => {
        const loadRestaurants = async () => {
            const data = await fetchRestaurants();
            setRestaurants(data);
        };
        loadRestaurants();
    }, []);

    useEffect(() => {
        if (id) {
            const loadSubPackageData = async () => {
                const response = await fetch(`${baseUrl}rest_SubPackages/${id}`);
                const data = await response.json();
                setSubPackageName(data.restSubPackName);
                setSelectedRestaurants(data.restaurants || []);
            };
            loadSubPackageData();
        }
    }, [id]);

    const handleSubmit = async () => {
        const restaurantIds = selectedRestaurants.map((restaurant) => restaurant._id);

        try {
            if (id) {
                // Update existing subpackage
                await updateRestaurantSubPackage(id, {
                    subPackageId: id,
                    restSubPackName: subPackageName,
                    restaurantIds: restaurantIds,
                });
                setSnackbarMessage('Subpackage updated successfully!');
            } else {
                // Create new subpackage
                await createRestaurantSubPackage({
                    restSubPackName: subPackageName,
                    restaurantIds: restaurantIds,
                });
                setSnackbarMessage('Subpackage created successfully!');
            }
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            setTimeout(() => {
                navigate(-1);
            }, 500);
        } catch (error) {
            console.error('Failed to save subpackage:', error);
            setSnackbarMessage('Failed to save subpackage. Please try again.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    return (
        <div style={{ backgroundColor: '#f2f2f2', padding: '20px', display: 'flex', flexDirection: 'column' }}>
            <AdminDrawer>
                <Paper
                    elevation={0}
                    sx={{
                        margin: '0 auto',
                        padding: '20px',
                        backgroundColor: 'white',
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Typography variant="h5" fontWeight='bold' gutterBottom mb={3}>
                        {id ? 'Edit SubPackage' : 'Add SubPackage'}
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <Typography component="legend">Subpackage Name</Typography>
                            <TextField
                                fullWidth
                                placeholder="Enter Subpackage Name Here"
                                variant="outlined"
                                size="small"
                                value={subPackageName}
                                onChange={(e) => setSubPackageName(e.target.value)}
                                sx={{ marginBottom: 2, marginTop: 1 }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        {/* <Grid item xs={12} sm={6}>
                            <Typography component="legend" sx={{ marginBottom: 1 }}>
                                Select Restaurants
                            </Typography>
                            <Autocomplete
                                size="small"
                                multiple
                                id="restaurants"
                                options={restaurants}
                                getOptionLabel={(option) => option.restName || ''}
                                disableCloseOnSelect
                                value={selectedRestaurants}
                                onChange={(event, newValue) => setSelectedRestaurants(newValue)}
                                renderInput={(params) => (
                                    <TextField {...params} placeholder="Select Restaurants" />
                                )}
                            />
                        </Grid> */}
                    </Grid>
                    <Box display="flex" justifyContent="flex-end" mt={4}>
                        <Button
                            variant="contained"
                            color="success"
                            sx={{ width: '200px', padding: '10px' }}
                            onClick={handleSubmit}
                        >
                            {id ? 'Update Subpackage' : 'Add Subpackage'}
                        </Button>
                    </Box>
                </Paper>
            </AdminDrawer>

            {/* CustomSnackbar */}
            <CustomSnackbar
                open={snackbarOpen}
                message={snackbarMessage}
                severity={snackbarSeverity}
                onClose={handleCloseSnackbar}
            />
        </div>
    );
};

export default AddRestaurantSubPackage;

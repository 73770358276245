import React, { useState, useEffect } from "react";
import AdminDrawer from "../../components/AdminDrawer/AdminDrawer";
import { useDialog } from "../../hooks/useDialog";
import { renderCellWithMore } from "../../components/ResuableComponents/ViewMoreComponents/ViewMorePopup";
import { fetchServiceProviders } from "../../api/servicePartners/ServiceProviderApis";
import {
  Typography,
  Paper,
  Container,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Box
} from "@mui/material";
import DataTable from "../../components/DataTables/DataTable";
import BreadcrumbsComponent from "../../components/ResuableComponents/BreadCrumb";

const ServiceProviders = () => {
  const { dialogContent, dialogOpen, handleOpenDialog, handleCloseDialog } =
    useDialog();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch the serviceProviders' data
  useEffect(() => {
    const getServiceProviders = async () => {
      try {
        const serviceProviders = await fetchServiceProviders();
        const mappedData = serviceProviders.map((item, index) => {
          // Determine business type from array
          const businessType = item.businesses
            .map((b) => b.businessType)
            .join(", ");

          return {
            id: index + 1,
            serviceProviderId: item?.spId, // Renamed to match API response
            ownerName: item?.spName, // Owner name from response
            email: item?.spEmail, // Email from response
            phone: item?.spPhone, // Phone from response
            businessType: businessType, // Business type from array
            registered: item.isRegistered ? "Yes" : "No", // Registered status
            otp:item?.otp,
            createdOn: new Date(item.createdAt).toLocaleDateString(), // Created date (only date)
            updatedOn: new Date(item.updatedAt).toLocaleDateString(), // Updated date (only date)
          };
        });
        setData(mappedData);
      } catch (error) {
        console.error("Failed to fetch serviceProviders:", error);
      } finally {
        setLoading(false);
      }
    };
    getServiceProviders();
    
    
  }, []);

  const columns = [
    { field: "id", headerName: "SI No", minWidth: 10, sortable: false },
    {
      field: "serviceProviderId",
      headerName: "Service Provider ID",
      minWidth: 250,
      sortable: true,
    },
    {
      field: "ownerName",
      headerName: "Owner Name",
      minWidth: 200,
      sortable: true,
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 250,
      sortable: true,
      renderCell: (params) => {
        const text = params.value || ""; // Ensure value is a string
        return renderCellWithMore(text, handleOpenDialog);
      },
    },
    { field: "phone", headerName: "Phone", minWidth: 200, sortable: true },
    {
      field: "businessType",
      headerName: "Business Type",
      minWidth: 250,
      sortable: true,
      renderCell: (params) => {
        const text = params.value || ""; // Ensure value is a string
        return renderCellWithMore(text, handleOpenDialog);
      },
    },
    {
      field: "registered",
      headerName: "Registered",
      minWidth: 200,
      sortable: true,
      renderCell: (params) => (
        <Chip
          label={params.value}
          color={params.value === "Yes" ? "success" : "default"}
          size="small"
        />
      ),
    },
    {
      field: "otp",
      headerName: "OTP",
      minWidth: 200,
      sortable: true,
    },
    {
      field: "createdOn",
      headerName: "Created On",
      minWidth: 200,
      sortable: true,
    },
    {
      field: "updatedOn",
      headerName: "Updated On",
      minWidth: 200,
      sortable: true,
    },
  ];

  return (
    <div style={{ backgroundColor: "#f2f2f2" }}>
      <AdminDrawer>
        <Box display="flex">
          <Typography variant="h4" fontWeight="bolder">
            Service Providers
          </Typography>
          <Box ml={3} mt={1}>
            <BreadcrumbsComponent />
          </Box>
        </Box>

        <Paper elevation={0} sx={{ padding: "14px", backgroundColor: "#fff" }}>
          <Container sx={{ minWidth: "100%" }}>
            {loading ? (
              <Typography>Loading...</Typography>
            ) : (
              <DataTable rows={data} columns={columns} />
            )}
          </Container>
        </Paper>
        <Dialog open={dialogOpen} onClose={handleCloseDialog}>
          <DialogTitle>Details</DialogTitle>
          <DialogContent>
            <Typography>{dialogContent}</Typography>
          </DialogContent>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </Dialog>
      </AdminDrawer>
    </div>
  );
};

export default ServiceProviders;

// restaurantSupportApis.js

import baseUrl from "../../config";

export const fetchRestaurantSupportDetails = async () => {
    const url = `${baseUrl}restsupport`; // Update this URL if necessary.

    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            throw new Error(`Error fetching data: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error in fetchRestauarantSupportDetails:", error);
        return null; // or handle error appropriately
    }
};

export const resolveRestaurantTickets = async (ticketId, data) => {
    try {
      const response = await fetch(`${baseUrl}restaurant-support/${ticketId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      if (!response.ok) {
        throw new Error('Failed to update the ticket status.');
      }
  
      const result = await response.json();
      return result;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };
import React, { useState, useEffect } from "react";
import { Box, Typography, Button, selectClasses } from "@mui/material";

const RestaurantPaymentBreakdown = ({ selectedOrder }) => {
  const [restPayoutTransactionId, setRestPayoutTransactionId] = useState("");
  const [isNetPayoutStage, setIsNetPayoutStage] = useState(true); // To handle different stages

  console.log("selectedOrder", selectedOrder);
  

  // Initialize restPayoutTransactionId with netPayout from selectedOrder
  useEffect(() => {
    if (selectedOrder?.netPayout) {
      setRestPayoutTransactionId(selectedOrder.netPayout);
    }
  }, [selectedOrder]);

  const handleSubmit = async () => {
    if (isNetPayoutStage) {
      // First stage: clear input to enter transaction ID
      setRestPayoutTransactionId("");
      setIsNetPayoutStage(false); // Move to transaction ID entry stage
    } else {
      // Second stage: Submit the transaction ID
      const payoutId = selectedOrder?.payoutId; // Assuming orderID is the payoutId

      if (!restPayoutTransactionId) {
        alert("Please enter the Payout Transaction ID.");
        return;
      }

      try {
        const response = await fetch(`https://admin.fesste.com/api/updateRestPayoutTransactionId/${payoutId}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            restPayoutTransactionId: restPayoutTransactionId,
          }),
        });

        if (response.ok) {
          alert("Payout transaction ID submitted successfully.");
          setRestPayoutTransactionId(""); // Clear the input after successful submission
        } else {
          alert("Failed to submit Payout ID. Please try again.");
        }
      } catch (error) {
        console.error("Error submitting Payout ID:", error);
        alert("An error occurred while submitting the Payout ID.");
      }
    }
  };

  return (
    <Box mt={4} p={3} bgcolor="#ffffff" borderRadius="8px">
      <Box mb={3}>
        <Typography>Order ID: {selectedOrder?.orderID}</Typography>
      </Box>
      <Typography
        variant="h5"
        gutterBottom
        sx={{ fontWeight: "bold", color: "#3f51b5" }}
      >
        Payment Breakdown
      </Typography>
      <Typography sx={{ fontSize: "1rem", mb: 1 }}>
        Total Value:{" "}
        <strong>
          ₹{parseFloat(selectedOrder?.totalAmount).toLocaleString()}
        </strong>
      </Typography>
      <Typography sx={{ fontSize: "1rem", mb: 1 }}>
        Fesste Fee:{" "}
        <strong>₹{selectedOrder?.fessteFee.toLocaleString()}</strong>
      </Typography>
      <Typography sx={{ fontSize: "1rem", mb: 1 }}>
        Transaction Fee:{" "}
        <strong>₹{selectedOrder?.transactionFee.toLocaleString()}</strong>
      </Typography>

      <Typography
        variant="h6"
        color="primary"
        sx={{ fontWeight: "bold", mt: 2 }}
      >
        Net Amount to be Settled:{" "}
        <strong>
          ₹{parseFloat(selectedOrder?.netPayout).toLocaleString()}
        </strong>
      </Typography>

      <Box display="flex" alignItems="center" mt={3}>
        <input
          type="text"
          value={restPayoutTransactionId}
          onChange={(e) => setRestPayoutTransactionId(e.target.value)}
          placeholder={isNetPayoutStage ? "Net Payout" : "Enter Pay Out Transaction ID"}
          style={{
            flexGrow: 1,
            padding: "12px",
            borderRadius: "8px",
            marginRight: "12px",
            border: "1px solid #3f51b5",
            fontSize: "1rem",
            fontWeight: "500",
            color: "#3f51b5",
          }}
          disabled={isNetPayoutStage} // Disable input editing during the first stage
        />
        <Button
          variant="contained"
          color="primary"
          sx={{
            padding: "10px 20px",
            fontSize: "1rem",
            fontWeight: "bold",
            textTransform: "none",
            backgroundColor: "#f44336",
            "&:hover": {
              backgroundColor: "#d32f2f",
            },
          }}
          onClick={handleSubmit}
        >
          {isNetPayoutStage ? "Proceed" : "Submit"}
        </Button>
      </Box>
    </Box>
  );
};

export default RestaurantPaymentBreakdown;

import React from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  MenuItem,
  Grid,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const CatererQueriesModal = ({
  open,
  onClose,
  selectedRow,
  action,
  onActionChange,
  onSubmit,
  resolveDescription,
  setResolveDescription,
}) => {

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={{ ...modalStyle }}>
        {/* Close Icon */}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>

        <Typography
          id="modal-title"
          variant="h6"
          component="h2"
          fontWeight="bold"
          mb={2}
        >
          Entity Details
        </Typography>

        {/* Scrollable content */}
        <Box sx={{ maxHeight: "60vh", overflowY: "auto", mb: 2, pr: 2 }}>
          {selectedRow && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography component="legend">Support ID</Typography>
                <TextField
                  fullWidth
                  placeholder="Enter Support ID Here"
                  value={selectedRow.supportId}
                  variant="outlined"
                  size="small"
                  sx={{ marginBottom: 2, marginTop: 1 }}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography component="legend">Entity Name</Typography>
                <TextField
                  fullWidth
                  placeholder="Enter Entity Name Here"
                  value={selectedRow.catererName}
                  variant="outlined"
                  size="small"
                  sx={{ marginBottom: 2, marginTop: 1 }}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography component="legend">Phone Number</Typography>
                <TextField
                  fullWidth
                  placeholder="Enter Phone Number Here"
                  value={selectedRow.catererContact}
                  variant="outlined"
                  size="small"
                  sx={{ marginBottom: 2, marginTop: 1 }}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography component="legend">Email</Typography>
                <TextField
                  fullWidth
                  placeholder="Enter Email Here"
                  value={selectedRow.catererEmail}
                  variant="outlined"
                  size="small"
                  sx={{ marginBottom: 2, marginTop: 1 }}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography component="legend">Status</Typography>
                <TextField
                  fullWidth
                  placeholder="Ticket Status"
                  value={selectedRow.status}
                  variant="outlined"
                  size="small"
                  sx={{ marginBottom: 2, marginTop: 1 }}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography component="legend">Service Provider ID</Typography>
                <TextField
                  fullWidth
                  placeholder="Enter Service Provider ID Here"
                  value={selectedRow.catererId}
                  variant="outlined"
                  size="small"
                  sx={{ marginBottom: 2, marginTop: 1 }}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography component="legend">Topic</Typography>
                <TextField
                  fullWidth
                  placeholder="Enter topic here"
                  value={selectedRow?.topic}
                  variant="outlined"
                  size="small"
                  sx={{ marginBottom: 2, marginTop: 1 }}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography component="legend">Query</Typography>
                <TextField
                  fullWidth
                  placeholder="Service Provider Query"
                  value={selectedRow?.query}
                  variant="outlined"
                  size="small"
                  sx={{ marginBottom: 2, marginTop: 1 }}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography component="legend">Resolve Description</Typography>
                <TextField
                  fullWidth
                  value={resolveDescription}
                  variant="outlined"
                  size="small"
                  multiline
                  rows={3}
                  sx={{ marginBottom: 2, marginTop: 1 }}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setResolveDescription(e.target.value)} // Handle change
                />
              </Grid>
              <Grid item xs={12}>
                <Typography component="legend">Action</Typography>
                <TextField
                  select
                  fullWidth
                  value={action}
                  onChange={onActionChange} // Use the prop method here
                  variant="outlined"
                  size="small"
                  sx={{ marginBottom: 2, marginTop: 1 }}
                  InputLabelProps={{ shrink: true }}
                >
                  <MenuItem value="open">Open</MenuItem>
                  <MenuItem value="resolved">Resolved</MenuItem>
                  <MenuItem value="in-progress">In Progress</MenuItem>
                  <MenuItem value="closed">Closed</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          )}
        </Box>

        {/* Submit Button */}
        <Box textAlign="right">
          <Button variant="contained" color="primary" onClick={onSubmit}>
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

// Modal styling
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
  width: "80vw", // You can adjust this width as needed
  maxHeight: "90vh", // Restrict the modal height
  display: "flex",
  flexDirection: "column",
};

export default CatererQueriesModal;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AdminDrawer from "../../../components/AdminDrawer/AdminDrawer";
import {
  Typography,
  styled,
  Paper,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Tooltip,
  IconButton,
  Box
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DataTable from "../../../components/DataTables/DataTable";
import CustomSnackbar from "../../../components/CustomeSnackBar/CustomSnackbar";
import { useDialog } from "../../../hooks/useDialog";
import { renderCellWithMore } from "../../../components/ResuableComponents/ViewMoreComponents/ViewMorePopup";
import BASE_URL from "../../../config";
import BreadcrumbsComponent from "../../../components/ResuableComponents/BreadCrumb";

const TableContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  maxHeight: "100vh",
  height: "650px",
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[0],
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1),
    height: "auto",
  },
}));

const LightingsMenu = () => {
  const { dialogContent, dialogOpen, handleOpenDialog, handleCloseDialog } =
    useDialog();

  const navigate = useNavigate(); // Initialize navigate
  const [data, setData] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  useEffect(() => {
    const fetchLightings = async () => {
      try {
        const response = await fetch(`${BASE_URL}lightingSp`); // Replace with your API endpoint
        const jsonData = await response.json();

        // Assuming jsonData is an array of lighting objects
        const transformedData = jsonData.map((item) => ({
          id: item._id,
          lightID: item?.lightingID, // or the appropriate field for name
          fessteMartId: item?.fessteMart?._id,
          businessName: item?.businessName,
          businessContact: item?.businessPhoneNumber,
          businessImage: item?.businessProfilePhoto[0],
          spName: item.serviceProvider?.spName, // Example field
          spPhone: item.serviceProvider?.spPhone, // Customize this logic as needed
          address: `${item?.address?.addressLine1}, ${item?.address?.addressLine2},${item?.address?.landmark},${item?.address?.city}, ${item?.address?.postalCode},${item?.address?.state},${item?.address?.country}`,
          Status: item.availability ? "Active" : "In-Active",
          Rating: 4, // Set default or fetch this value if available
          ContactNumber: item.serviceProvider?.spPhone,
          CreatedOn: new Date(item.createdAt).toLocaleString(), // Format the date as needed
        }));

        setData(transformedData);
      } catch (error) {
        console.error("Error fetching lightings:", error);
      }
    };

    fetchLightings();
  }, []);
  

  const handleViewClick = (lightID, id) => {
    console.log('lightID',lightID);
    
    navigate(`/view-lightings-menu/${id}`, { state: { id } });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const columns = [
    {
      field: "partyHallId",
      headerName: "ID",
      minWidth: 10,
      sortable: false,
      renderCell: (params) => {
        const text = params.value || ""; // Ensure value is a string
  
        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => handleViewClick(params.row.lightID, params.row.id)}
          >
            {text}
          </div>
        );
      },
    },
    {
      field: "businessImage",
      headerName: "Business Image",
      type: "image",
      minWidth: 160,
      sortable: false,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <img
            src={
              params.value !== "N/A"
                ? params.value
                : "placeholder-image-url.jpg"
            } // Placeholder for broken images
            alt="Business"
            style={{
              width: "100px",
              height: "60px",
              borderRadius: "4px",
              objectFit: "cover",
            }}
          />
        </div>
      ),
    },
    {
      field: "businessName",
      headerName: "Business Name",
      minWidth: 250,
      sortable: true,
      renderCell: (params) => {
        const text = params.value || "";
  
        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              handleViewClick(params.row.partyHallId, params.row.id)
            }
          >
            {text}
          </div>
        );
      },
    },
    {
      field: "menu",
      headerName: "Menu Details",
      minWidth: 300,
      sortable: true,
      renderCell: (params) => {
        const text = params.value || "N/A";
        return <div>{text}</div>;
      },
    },
    {
      field: "businessPhoneNumber",
      headerName: "Business Contact",
      minWidth: 250,
      sortable: true,
    },
    {
      field: "spName",
      headerName: "Owner Name",
      minWidth: 250,
      sortable: true,
    },
    {
      field: "spEmail",
      headerName: "Owner Email",
      minWidth: 250,
      sortable: true,
    },
    {
      field: "spPhone",
      headerName: "Owner Contact",
      minWidth: 250,
      sortable: true,
    },
    {
      field: "address",
      headerName: "Address",
      minWidth: 250,
      sortable: true,
      type: "text",
      renderCell: (params) => {
        const text = params.value || "";
        return renderCellWithMore(text, handleOpenDialog);
      },
    },
    {
      field: "createdOn",
      headerName: "Created On",
      minWidth: 250,
      sortable: true,
      renderCell: (params) => {
        const text = params.value || "";
        return renderCellWithMore(text, handleOpenDialog);
      },
    },
    {
        field: "Actions",
        headerName: "Actions",
        minWidth: 160,
        sortable: false,
        
        renderCell: (params) => (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
  
            <Tooltip title="View">
              <IconButton
                sx={{ color: "#87CEEB", marginRight: "8px" }}
                onClick={() =>
                  handleViewClick(params.row.partyHallId, params.row.id)
                }
              >
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
          </div>
        ),
      },
  ];
  

  return (
    <div style={{ backgroundColor: "#f2f2f2" }}>
      <AdminDrawer>
        <Box display="flex">
        <Typography variant="h4" fontWeight="bolder">
         Lightings
        </Typography>
        <Typography ml={3} mt={1}>
          <BreadcrumbsComponent />
        </Typography>
        </Box>
        
        <TableContainer>
        <DataTable rows={data} columns={columns} />
        <CustomSnackbar
            open={snackbarOpen}
            message={snackbarMessage}
            severity={snackbarSeverity}
            onClose={handleCloseSnackbar}
          />
        </TableContainer>
        <Dialog open={dialogOpen} onClose={handleCloseDialog}>
          <DialogTitle>Details</DialogTitle>
          <DialogContent>
            <Typography>{dialogContent}</Typography>
          </DialogContent>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </Dialog>
      </AdminDrawer>
    </div>
  );
};

export default LightingsMenu;

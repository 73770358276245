import React from 'react';
import { Typography, Chip } from '@mui/material';
import AdminDrawer from '../../../components/AdminDrawer/AdminDrawer';
import OrdersTable from '../../../components/DataTables/OrdersTables/OrdersTable';

const orderData = [
    { id: 31, orderId: "ORD153", customerName: "Rohan Shetty", orderType: "Delivery", deliveryPerson: "Divya Nair", restaurant: "Spicy Curry House", date: "20th September 2024", address: "I-332, Vashi, Navi Mumbai, Maharashtra, India", paymentMode: "Credit Card", total: "$125", status: "Delivered" },
    { id: 32, orderId: "ORD154", customerName: "Meera Menon", orderType: "Pickup", deliveryPerson: "Amit Kumar", restaurant: "Kerala Cafe", date: "21st September 2024", address: "J-343, MG Road, Kochi, Kerala, India", paymentMode: "Debit Card", total: "$70", status: "Accepted" },
    { id: 33, orderId: "ORD155", customerName: "Aditya Sharma", orderType: "Delivery", deliveryPerson: "Suresh Gupta", restaurant: "Mughlai Mirch", date: "22nd September 2024", address: "K-354, Connaught Place, Delhi, India", paymentMode: "Cash", total: "$95", status: "Pending" },
    { id: 34, orderId: "ORD156", customerName: "Aisha Khan", orderType: "Pickup", deliveryPerson: "Ravi Malhotra", restaurant: "Awadhi Biryani", date: "23rd September 2024", address: "L-365, Gomti Nagar, Lucknow, Uttar Pradesh, India", paymentMode: "Credit Card", total: "$115", status: "Cancelled" },
    { id: 35, orderId: "ORD157", customerName: "Gaurav Mehta", orderType: "Delivery", deliveryPerson: "Pooja Desai", restaurant: "Gujarati Thali", date: "24th September 2024", address: "M-376, Paldi, Ahmedabad, Gujarat, India", paymentMode: "Debit Card", total: "$105", status: "Delivered" },
    { id: 36, orderId: "ORD158", customerName: "Snehal Deshmukh", orderType: "Pickup", deliveryPerson: "Rakesh Rao", restaurant: "Marathi Masala", date: "25th September 2024", address: "N-387, Shivaji Park, Mumbai, Maharashtra, India", paymentMode: "Cash", total: "$80", status: "Accepted" },
    { id: 37, orderId: "ORD159", customerName: "Harish Bansal", orderType: "Delivery", deliveryPerson: "Anita Singh", restaurant: "Punjabi Junction", date: "26th September 2024", address: "O-398, Sector 15, Chandigarh, India", paymentMode: "Credit Card", total: "$135", status: "Pending" },
    { id: 38, orderId: "ORD160", customerName: "Lavanya Reddy", orderType: "Pickup", deliveryPerson: "Kiran Kumar", restaurant: "Andhra Spice", date: "27th September 2024", address: "P-409, Banjara Hills, Hyderabad, Telangana, India", paymentMode: "Debit Card", total: "$90", status: "Cancelled" },
    { id: 39, orderId: "ORD161", customerName: "Rajiv Saxena", orderType: "Delivery", deliveryPerson: "Sunil Yadav", restaurant: "Rajasthani Bhog", date: "28th September 2024", address: "Q-420, C-Scheme, Jaipur, Rajasthan, India", paymentMode: "Cash", total: "$145", status: "Delivered" },
    { id: 40, orderId: "ORD162", customerName: "Divya Pandey", orderType: "Pickup", deliveryPerson: "Neeraj Dubey", restaurant: "Bengali Bhojanalaya", date: "29th September 2024", address: "R-431, Ballygunge, Kolkata, West Bengal, India", paymentMode: "Credit Card", total: "$95", status: "Pending" },
    { id: 41, orderId: "ORD163", customerName: "Anil Kapoor", orderType: "Delivery", deliveryPerson: "Nandini Rao", restaurant: "South Indian Delights", date: "30th September 2024", address: "S-442, Koramangala, Bangalore, Karnataka, India", paymentMode: "Debit Card", total: "$110", status: "Accepted" },
    { id: 42, orderId: "ORD164", customerName: "Pooja Sharma", orderType: "Pickup", deliveryPerson: "Ajay Verma", restaurant: "Goan Spice", date: "1st October 2024", address: "T-453, Candolim, Goa, India", paymentMode: "Cash", total: "$85", status: "Cancelled" },
    { id: 43, orderId: "ORD165", customerName: "Amitabh Bachchan", orderType: "Delivery", deliveryPerson: "Sanjeev Kapoor", restaurant: "Maharaja Dhaba", date: "2nd October 2024", address: "U-464, Juhu, Mumbai, Maharashtra, India", paymentMode: "Credit Card", total: "$150", status: "Delivered" },
    { id: 44, orderId: "ORD166", customerName: "Katrina Kaif", orderType: "Pickup", deliveryPerson: "Arjun Rampal", restaurant: "Delhi Dhaba", date: "3rd October 2024", address: "V-475, Karol Bagh, Delhi, India", paymentMode: "Debit Card", total: "$75", status: "Accepted" },
    { id: 45, orderId: "ORD167", customerName: "Deepika Padukone", orderType: "Delivery", deliveryPerson: "Ranveer Singh", restaurant: "Udupi Cafe", date: "4th October 2024", address: "W-486, JP Nagar, Bangalore, Karnataka, India", paymentMode: "Cash", total: "$100", status: "Pending" },
];



const columns = [
    { field: 'orderId', headerName: 'Order ID', flex: 1 },
    { field: 'customerName', headerName: 'Customer Name', flex: 1 },
    { field: 'orderType', headerName: 'Order Type', flex: 1 },
    { field: 'deliveryPerson', headerName: 'Delivery Person', flex: 1 },
    { field: 'restaurant', headerName: 'Restaurant', flex: 1 },
    {
        field: 'status', headerName: 'Status', flex: 1, sortable: true, renderCell: (params) => {
            const statusColors = {
                Delivered: "success",
                Cancelled: "error",
                Pending: "warning",
                Accepted: "info"
            };
            return (
                <Chip
                    label={params.value}
                    color={statusColors[params.value] || "default"}
                />
            );
        }
    },
    { field: 'date', headerName: 'Date', flex: 1 },
    { field: 'address', headerName: 'Address', flex: 2 },
    { field: 'paymentMode', headerName: 'Payment Mode', flex: 1 },
    { field: 'total', headerName: 'Total', flex: 1 },
];

const LightingsOrders = () => {
    return (
        <div style={{ backgroundColor: '#f2f2f2', height: '100vh' }}>
            <AdminDrawer>
                <div style={{ padding: '16px' }}>
                    <Typography variant='h4' fontWeight='bolder'>Lightings Orders</Typography>
                    <OrdersTable
                        rows={orderData}
                        columns={columns}
                    />
                </div>
            </AdminDrawer>
        </div>
    );
}

export default LightingsOrders;

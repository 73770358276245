import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography, TextField } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { styled } from '@mui/system';

// Draggable Dialog Title
const DraggableDialogTitle = styled(DialogTitle)(({ theme }) => ({
    cursor: 'move',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
}));

const ConfirmDialog = ({ open, onClose, onConfirm, title, content, message }) => {
    const [deleteReason, setDeleteReason] = useState('');

    const handleReasonChange = (event) => {
        setDeleteReason(event.target.value);
    };

    const handleConfirm = () => {
        onConfirm(deleteReason); 
        setDeleteReason('');
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                style: { padding: '20px' }
            }}
            transitionDuration={{ enter: 300, exit: 200 }}
        >
            <DraggableDialogTitle disableTypography>
                <WarningIcon color="error" />
                <Typography variant="h6" component="span">
                    {title}
                </Typography>
            </DraggableDialogTitle>
            <DialogContent dividers>
                <Typography variant="body1" gutterBottom>
                    {content || message}
                </Typography>
                {/* Delete Reason Input */}
                <TextField
                    label="Reason for Deletion"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={3}
                    value={deleteReason}
                    onChange={handleReasonChange}
                    required
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    color="primary"
                    variant="outlined"
                    style={{ textTransform: 'none' }}
                >
                    No
                </Button>
                <Button
                    onClick={handleConfirm}
                    color="secondary"
                    variant="contained"
                    style={{ textTransform: 'none' }}
                >
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;

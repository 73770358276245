import React, { useState, useEffect, useCallback } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  styled,
  Paper,
  TextField,
  IconButton,
  Typography,
  Box,
  Button,
  ButtonGroup
} from "@mui/material";
import { jsonToCSV } from "react-papaparse";
import debounce from "lodash/debounce";
import StatusFilter from "./StatusFilter"; // Import the StatusFilter component

const TableContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  maxHeight: "calc(100vh - 100px)",
  overflow: "hidden",
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[0],
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1),
  },
}));

const SearchContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: theme.spacing(2),
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "stretch",
  },
}));

const NoRowsMessage = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  padding: theme.spacing(2),
  color: theme.palette.text.secondary,
}));

const CatererOrdersTable = ({ rows, columns }) => {
  const [searchText, setSearchText] = useState("");
  const [filteredRows, setFilteredRows] = useState(rows);
  const [statusFilter, setStatusFilter] = useState("");
  const [orderModeFilter, setOrderModeFilter] = useState(null);

  const filterRows = useCallback(
    debounce((searchValue, statusValue, orderMode) => {
      let data = rows;
      if (searchValue !== "") {
        const value = searchValue.toLowerCase();
        data = data.filter((row) =>
          columns.some((column) =>
            row[column.field]?.toString().toLowerCase().includes(value)
          )
        );
      }
      if (statusValue !== "") {
        data = data.filter((row) => row.status === statusValue);
      }
      if (orderMode) {
        data = data.filter((row) => row.orderMode === orderMode);
      }
      setFilteredRows(data);
    }, 300),
    [rows, columns]
  );

  useEffect(() => {
    filterRows(searchText, statusFilter, orderModeFilter);
  }, [searchText, statusFilter, orderModeFilter, filterRows]);

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const handleDownloadCSV = () => {
    try {
      const csvData = jsonToCSV(filteredRows);
      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.setAttribute("download", "orders.csv");
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error generating CSV:", error);
    }
  };

  return (
    <TableContainer>
      <SearchContainer>
        <TextField
          variant="outlined"
          size="small"
          placeholder="Search..."
          value={searchText}
          onChange={handleSearch}
          sx={{
            width: "300px",
            marginRight: "10px",
          }}
        />
        <StatusFilter
          statusFilter={statusFilter}
          setStatusFilter={setStatusFilter}
        />

        <IconButton onClick={handleDownloadCSV} color="primary">
          <Button variant="contained">Export</Button>
        </IconButton>
      </SearchContainer>
      <Box>
      <ButtonGroup
            variant="contained"
            sx={{
                overflow: 'hidden',
                display: 'inline-flex',
                marginBottom: "8px"
            }}
        >
            <Button
                variant="contained"
                color="success"
                onClick={() => setOrderModeFilter("paid")}
                sx={{
                    whiteSpace: 'nowrap', 
                    textTransform: 'none', 
                    fontWeight: 'bold',
                    backgroundColor: '#551A8B', // Dark Blue for Paid Orders
                    '&:hover': {
                        backgroundColor: '#3E0072', // Darker shade on hover
                    },
                }}
            >
                Paid Orders
            </Button>
            <Button
                variant="contained"
                color="info"
                onClick={() => setOrderModeFilter("book")}
                sx={{
                    whiteSpace: 'nowrap', 
                    textTransform: 'none', 
                    fontWeight: 'bold',
                    backgroundColor: '#6A8DFF', // Light Blue for Book Orders
                    '&:hover': {
                        backgroundColor: '#4C78E3', // Darker light blue on hover
                    },
                    
                }}
            >
                Book Orders
            </Button>
            <Button
                variant="outlined"
                onClick={() => setOrderModeFilter(null)}
                sx={{
                    whiteSpace: 'nowrap', 
                    textTransform: 'none', 
                    fontWeight: 'bold',
                    
                }}
            >
                All Orders
            </Button>
        </ButtonGroup>
      </Box>
      
      {filteredRows?.length === 0 ? (
        <NoRowsMessage>No matching rows found.</NoRowsMessage>
      ) : (
        <Box sx={{ height: "60vh", width: "90vw" }}>
          <DataGrid
            disableColumnResize={true}
            columnHeaderHeight={33}
            disableColumnSelector={true}
            disableColumnSorting={false}
            disableDensitySelector={true}
            disableRowSelectionOnClick={true}
            showCellVerticalBorder={true}
            rows={filteredRows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10, 25, 50]}
            style={{ height: "100%", width: "100%" }}
            sx={{
              "& .MuiDataGrid-columnHeader": {
                fontWeight: "bold",
                fontSize: "14px",
              },
            }}
          />
        </Box>
      )}
    </TableContainer>
  );
};

export default CatererOrdersTable;

import BASE_URL from "../../../config";

// Function to fetch party hall details by ID using fetch


export const getLightingsById = async (lightID) => {
    const response = await fetch(`${BASE_URL}lightingSp/${lightID}`);
    if (!response.ok) {
        throw new Error('Failed to fetch Lightings details');
    }
    return await response.json();
  };

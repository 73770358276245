import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AdminDrawer from "../../components/AdminDrawer/AdminDrawer";
import { fetchDeletedCaterers } from "../../api/servicePartners/CaterersApis";
import {

  Typography,
  Paper,
  Button,
  Dialog, DialogContent, DialogTitle, Box
} from "@mui/material";
import { useDialog } from "../../hooks/useDialog";
import { renderCellWithMore } from "../../components/ResuableComponents/ViewMoreComponents/ViewMorePopup";
import DataTable from "../../components/DataTables/DataTable";
import BreadcrumbsComponent from "../../components/ResuableComponents/BreadCrumb";


const CaterersDeleted = () => {
  const { dialogContent, dialogOpen, handleOpenDialog, handleCloseDialog } = useDialog();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
 

  const navigate = useNavigate();


  const handleViewClick = (catererId, serviceProviderId) => {
    navigate(`/caterer-details/${serviceProviderId}`, {
      state: { catererId, serviceProviderId }, // Passing both catererId and serviceProviderId
    });
  };

  // Fetch the caterers' data
  useEffect(() => {
    const getCaterers = async () => {
      try {
        const caterers = await fetchDeletedCaterers();
        const mappedData = caterers.map((item, index) => ({
          id: index + 1,
          catererId: item._id,
          serviceProviderId: item.catID,
          businessName: item.businessName,
          address: `${item?.address?.addressLine1}, ${item?.address?.addressLine2},${item?.address?.landmark},${item?.address?.city}, ${item?.address?.postalCode},${item?.address?.state},${item?.address?.country}`,
          contactNumber: item.businessPhoneNumber,
          email: item.serviceProvider?.spEmail,
          ownerName: item.serviceProvider?.spName,
          ownerImage: item.ownerPhoto,
          ownerNumber: item.serviceProvider?.spPhone,
          ownerEmail: item.serviceProvider?.spEmail,
          registeredOn: new Date(item.createdAt).toLocaleString(),
          createdOn: new Date(item.createdAt).toLocaleString(),
          businessImage: item.businessProfilePhoto,
          fessteApproach: item.fessteApproach,
          deleteReason: item.deleteReason
        }));
        setData(mappedData);
      } catch (error) {
        console.error("Failed to fetch caterers:", error);
      } finally {
        setLoading(false);
      }
    };
    getCaterers();
  }, []);



  return (
    <div style={{ backgroundColor: '#f2f2f2' }}>
      <AdminDrawer>
      <Box display="flex" mb={3}>
          <Typography variant="h4" fontWeight="bolder">
            Caterers Deleted
          </Typography>
          <Box ml={3} mt={1}>
            <BreadcrumbsComponent />
          </Box>
        </Box>

        <Paper elevation={0} sx={{ padding: "14px", backgroundColor: "#fff" }}>
          
          {loading ? (
            <Typography>Loading...</Typography>
          ) : (

            <DataTable rows={data} columns={[

              { field: "id", headerName: "Id", minWidth: 10, sortable: false },
              {
                field: "serviceProviderId",
                headerName: "Service Provider ID",
                minWidth: 240,
                sortable: true,
                renderCell: (params) => {
                  const text = params.value || ''; // Ensure value is a string
              
                  return (
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleViewClick(params.row.catererId, params.row.serviceProviderId)}
                    >
                      {text}
                    </div>
                  );
                },
              },
              {
                field: "businessImage",
                headerName: "Business Image",
                type: 'image',
                minWidth: 160,
                sortable: false,
                renderCell: (params) => (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',   // Horizontally center the image
                      alignItems: 'center',       // Vertically center the image
                      width: '100%',              // Ensure the div takes full width of the cell
                      height: '100%',             // Ensure the div takes full height of the cell
                    }}
                  >
                    <img
                      src={params.value !== 'N/A' ? params.value : 'placeholder-image-url.jpg'} // Placeholder for broken images
                      alt="Item"
                      style={{
                        width: '100px',        // Fixed width
                        height: '60px',        // Fixed height
                        borderRadius: '4px',   // Rounded corners
                        objectFit: 'cover',     // Cover the area while maintaining aspect ratio
                      }}
                    />
                  </div>

                ),
              },
              {
                field: "businessName",
                headerName: "Business Name",
                minWidth: 250,
                sortable: true,
                renderCell: (params) => {
                  const text = params.value || ''; // Ensure value is a string
              
                  return (
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleViewClick(params.row.catererId, params.row.serviceProviderId)}
                    >
                      {text}
                    </div>
                  );
                },
              },
              {
                field: "address", headerName: "Address", minWidth: 250,
                sortable: true,
                type: 'text',
                renderCell: (params) => {
                  const text = params.value || ''; // Ensure value is a string
                  return renderCellWithMore(text, handleOpenDialog);
                },
              },
              {
                field: "contactNumber",
                headerName: "Contact Number",
                minWidth: 200,
                sortable: true,
              },
              { field: "email", headerName: "Email", minWidth: 160, sortable: true },
              {
                field: "ownerName",
                headerName: "Owner Name",
                minWidth: 200,
                sortable: true,
              },
              {
                field: "ownerImage",
                type: 'image',
                headerName: "Owner Image",
                minWidth: 160,
                sortable: false,
                renderCell: (params) => (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',   // Horizontally center the image
                      alignItems: 'center',       // Vertically center the image
                      width: '100%',              // Ensure the div takes full width of the cell
                      height: '100%',             // Ensure the div takes full height of the cell
                    }}
                  >
                    <img
                      src={params.value}
                      alt="Owner"
                      style={{
                        width: "45px",
                        height: "45px",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                ),
              },
              {
                field: "ownerNumber",
                headerName: "Owner Number",
                minWidth: 200,
                sortable: true,
              },
              {
                field: "registeredOn",
                headerName: "Registered On",
                minWidth: 200,
                sortable: true,
              },
              {
                field: "fessteApproach",
                headerName: "Item Creation Request",
                minWidth: 250,
                sortable: true,
                renderCell: (params) => {
                  return params.value ? "Access Granted" : "No Access";
                },
              },
              {
                field: "deleteReason",
                headerName: "Delete Reason",
                minWidth: 250,
                sortable: true,
              },
              
              // {
              //   field: "Actions",
              //   headerName: "Actions",
              //   type: 'actions',
              //   minWidth: 160,
              //   sortable: false,
              //   renderCell: (params) => (
              //     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>

              //       <Tooltip title="View">
              //         <IconButton
              //          sx={{ color: '#87CEEB', marginRight: '8px' }}
              //           onClick={() =>
              //             handleViewClick(
              //               params.row.catererId,
              //               params.row.serviceProviderId
              //             )
              //           }
              //         >
              //           <VisibilityIcon />
              //         </IconButton>
              //       </Tooltip>
              //       <Tooltip title="Edit">
              //         <IconButton color="success" onClick={() => handleEditClick(params.row.catererId)}>
              //           <EditIcon />
              //         </IconButton>
              //       </Tooltip>
              //       <Tooltip title="Delete">
              //         <IconButton color="error" onClick={() => handleDeleteClick(params.row)}>
              //           <DeleteIcon />
              //         </IconButton>
              //       </Tooltip>
              //     </div>
              //   ),
              // },

            ]} />
          )}
        </Paper>
        
        <Dialog open={dialogOpen} onClose={handleCloseDialog}>
          <DialogTitle>Details</DialogTitle>
          <DialogContent>
            <Typography>{dialogContent}</Typography>
          </DialogContent>
          <Button onClick={handleCloseDialog} color="primary">Close</Button>
        </Dialog>
      </AdminDrawer>
    </div>
  );
};

export default CaterersDeleted;

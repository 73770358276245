import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchPolicyById } from "../../api/contentManagement/contentManagementApis";
import { Typography, Box } from "@mui/material";

const ViewPolicy = () => {
  const { id } = useParams();
  const [policy, setPolicy] = useState(null);

  useEffect(() => {
    const fetchPolicy = async () => {
      try {
        const data = await fetchPolicyById(id);
        setPolicy(data);
      } catch (error) {
        console.error("Error fetching policy:", error);
      }
    };
    fetchPolicy();
  }, [id]);

  console.log(policy);

  if (!policy) return <Typography>Loading...</Typography>;

  const isFaq = policy.content?.some((item) => item.type === "list");

  return (
    <Box mr={15} ml={15} mt={10} p={2}>
      <Typography variant="h4" gutterBottom>
        {policy.title}
      </Typography>
      <Typography variant="subtitle1" color="textSecondary">
        Effective Date: {new Date(policy.effectiveDate).toLocaleDateString()}
      </Typography>

      <Box mt={3} textAlign="justify">
        {isFaq ? (
          // Render FAQ style policy
          policy.content.map((item) => (
            item.type === "list" &&
            <Box key={item._id} mt={2}>
              {item.value.map((qa, index) => (
                <Box key={index} mb={2}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Q: {qa.question}
                  </Typography>
                  <Typography variant="body1">A: {qa.answer}</Typography>
                </Box>
              ))}
            </Box>
          ))
        ) : (
          // Render standard policy format
          policy.content.map((item) => (
            <Box key={item._id} mt={2}>
              {item.type === "header" && (
                <Typography variant="h5" gutterBottom>
                  {item.value}
                </Typography>
              )}
              {item.type === "paragraph" && (
                <Typography variant="body1" paragraph>
                  {item.value}
                </Typography>
              )}
            </Box>
          ))
        )} 
      </Box>

      <Typography variant="caption" display="block" color="textSecondary" mt={4}>
        Created At: {new Date(policy.createdAt).toLocaleDateString()}
      </Typography>
    </Box>
  );
};

export default ViewPolicy;

import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Download as DownloadIcon } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Tabs,
  Tab,
  Typography,
  CircularProgress,
  Grid,
  Card,
  DialogTitle,
  CardContent,
  IconButton,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  Chip,
} from "@mui/material";
import AdminDrawer from "../../../components/AdminDrawer/AdminDrawer";
import { fetchRestaurantById } from "../../../api/servicePartners/RestaurantApis";
import VerifiedIcon from "../../../assets/images/verified.png";
import PhoneIcon from "@mui/icons-material/Phone";
import AccessTimeIcon from "@mui/icons-material/AccessTime"; // Icon for timing
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining"; // Icon for delivery timing
import CustomSnackbar from "../../../components/CustomeSnackBar/CustomSnackbar";
import baseUrl from "../../../config";
import BreadcrumbsComponent from "../../../components/ResuableComponents/BreadCrumb";
import RestaurantOrderTab from "./RestaurantOrderTab";
import RestaurantPayoutTab from "./RestaurantPayoutTab";
import BASE_URL from "../../../config";
import AdminChatBox from "../../../components/ResuableComponents/ChatBoxes/CatererChatBox/AdminChatBox";

const RestaurantDetailsPage = () => {
  const location = useLocation();
  const { restaurantId } = location.state || {};
  const [tabIndex, setTabIndex] = useState(0);
  const [restaurantDetails, setRestaurantDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [rejectionReason, setRejectionReason] = useState("");
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
  const [isVerified, setIsVerified] = useState(restaurantDetails?.isVerified);
  const [comments, setComments] = useState([]);

  const fetchComments = async (type) => {
    try {
      const response = await fetch(`${BASE_URL}dashboard/getComments/${type}`);
      const data = await response.json();
      setComments(data);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  useEffect(() => {
    const tabToTypeMap = {
      0: "restaurants",
      1: "rest_menu",
      2: "rest_orders",
      3: "rest_payouts",
    };

    fetchComments(tabToTypeMap[tabIndex]);
  }, [tabIndex]);

  // Snackbar states
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // Default to success
  const commentsEndRef = useRef(null); // For auto-scrolling to the bottom
  const [restName, setRestName] = useState("");
  const [restaurantImages, setRestaurantImages] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  // Handle verify or unverify based on current state
  const handleVerify = async () => {
    try {
      if (!isVerified) {
        // Call API to verify the restaurant
        const response = await fetch(
          `https://admin.fesste.com/api/restaurant/verify/${restaurantId}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          console.log("response", response);

          setIsVerified(true); // Update to verified state
          setSnackbarMessage("Restaurant verified successfully");
          setSnackbarSeverity("success");
        } else {
          setSnackbarMessage("Failed to verify restaurant");
        }
      }
    } catch (error) {
      console.error("Error verifying restaurant", error);
      setSnackbarMessage("Error verifying restaurant");
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleEdit = async () => {
    const formData = new FormData();

    if (restName) {
      formData.append("restName", restName);
    }

    if (restaurantImages) {
      formData.append("restaurantImage", restaurantImages);
    }

    try {
      const response = await fetch(
        `https://admin.fesste.com/api/restaurant/update/${restaurantId}`,
        {
          method: "PUT",
          body: formData,
          headers: {
            // 'Content-Type' is set automatically by the browser for FormData
            // You can include additional headers if required, but it's not needed for FormData
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json(); // Parse JSON response
      console.log("Update successful:", data);
      setOpenModal(false); // Close modal after successful update
      // Optionally reset state or show a success message
    } catch (error) {
      console.error("Error updating caterer:", error);
      // Handle error (e.g., show an error message)
    }
  };

  useEffect(() => {
    const getRestaurantDetails = async () => {
      setLoading(true);
      try {
        const details = await fetchRestaurantById(restaurantId);
        setRestaurantDetails(details?.data);
      } catch (err) {
        console.log("Failed to fetch restaurant details.");
      } finally {
        setLoading(false);
      }
    };

    if (restaurantId) {
      getRestaurantDetails();
    }
  }, [restaurantId]);

  useEffect(() => {
    if (restaurantDetails?.isVerified !== undefined) {
      setIsVerified(restaurantDetails.isVerified);
    }
  }, [restaurantDetails]);

  // Handle dialog close without submission
  const handleDialogClose = () => {
    setRejectDialogOpen(false);
  };

  // Snackbar close handler
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const [open, setOpen] = useState(false); // State to control the modal

  const [selectedImage, setSelectedImage] = useState(null); // State to store the selected image

  // Handle image click to open the preview
  const handleImageClick = (image) => {
    setSelectedImage(image); // Set the clicked image URL
    setOpen(true); // Open the modal
  };

  // Handle modal close
  const handleClose = () => {
    setOpen(false); // Close the modal
    setSelectedImage(null); // Clear the selected image
  };

  const handleDownload = async () => {
    const apiUrl = `${baseUrl}downloadRestaurants/${restaurantId}`;

    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "documents.zip"); // Replace with actual file name if necessary
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  // Handle reject click (open dialog)
  const handleReject = () => {
    setRejectDialogOpen(true);
  };

  // Handle rejection submission
  const handleRejectSubmit = async () => {
    try {
      const response = await fetch(
        `https://admin.fesste.com/api/restaurant/reject/${restaurantId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ rejectionReason }), // Pass rejection reason from state
        }
      );

      if (response.ok) {
        setSnackbarMessage(`Rejected with reason: ${rejectionReason}`);
        setIsVerified(false); // Set to unverified state
      } else {
        setSnackbarMessage("Failed to reject restaurant");
      }
    } catch (error) {
      console.error("Error rejecting restaurant", error);
      setSnackbarMessage("Error rejecting restaurant");
    } finally {
      setRejectDialogOpen(false);
      setSnackbarOpen(true);
    }
  };

  const tabToTypeMap = {
    0: "restaurants",
    1: "rest_menu",
    2: "rest_orders",
    3: "rest_payouts",
  };

  const type = tabToTypeMap[tabIndex];

  // Scroll to the bottom when new comments are added
  const scrollToBottom = () => {
    if (commentsEndRef.current) {
      commentsEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    scrollToBottom(); // Auto-scroll to bottom when comments update
  }, [comments]);

  return (
    <AdminDrawer>
      <BreadcrumbsComponent />
      <Box
        sx={{
          width: "100%",
          typography: "body1",
          bgcolor: "#f4f6f8",
          padding: 2,
          borderRadius: 2,
        }}
      >
        <Tabs value={tabIndex} onChange={handleTabChange} centered>
          <Tab label="Restaurant Details" />
          <Tab label="Menu Details" />
          <Tab label="Restaurant Orders" />
          <Tab label="Restaurant Payout" />
        </Tabs>

        {tabIndex === 0 && (
          <Box p={3}>
            <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
              {/* Verify/Unverify Button */}
              <Button
                onClick={isVerified ? handleReject : handleVerify} // Handle reject if verified, else handle verify
                sx={{
                  backgroundColor: isVerified ? "transparent" : "#0e0593",
                  borderRadius: "2rem",
                  color: "white",
                  "&:hover": {
                    backgroundColor: isVerified ? "transparent" : "#0e0593",
                  },
                }}
              >
                <Typography color="white">
                  {isVerified ? (
                    <img src={VerifiedIcon} alt="verification" />
                  ) : (
                    <Typography variant="body2" fontWeight="bold">
                      Verify
                    </Typography>
                  )}
                </Typography>
              </Button>
            </Box>
            {/* Edit Modal */}
            <Dialog open={openModal} onClose={() => setOpenModal(false)}>
              <DialogTitle>Edit Restaurant Information</DialogTitle>
              <DialogContent>
                <TextField
                  label="Restaurant Name"
                  variant="outlined"
                  value={restName}
                  onChange={(e) => setRestName(e.target.value)}
                  fullWidth
                  margin="dense"
                  size="small"
                />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <Typography variant="body1" sx={{ marginRight: "30px" }}>
                    Restaurant Images
                  </Typography>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => setRestaurantImages(e.target.files[0])}
                    style={{ marginTop: 0 }} // Reset marginTop to 0 since we are using Box
                  />
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenModal(false)} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleEdit} color="primary">
                  Save
                </Button>
              </DialogActions>
            </Dialog>

            <Box display="flex">
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                Restaurant Details:
              </Typography>
              <Button
                onClick={() => setOpenModal(true)} // Open modal on click
                sx={{
                  "&:hover": {
                    backgroundColor: "#fff",
                  },
                }}
              >
                <EditIcon sx={{ marginRight: 1 }} />
              </Button>
            </Box>

            {/* Rejection Reason Dialog */}
            <Dialog open={rejectDialogOpen} onClose={handleDialogClose}>
              <DialogTitle>Reason for Rejection</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  placeholder="Rejection Reason"
                  fullWidth
                  variant="outlined"
                  value={rejectionReason}
                  onChange={(e) => setRejectionReason(e.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDialogClose}>Cancel</Button>
                <Button
                  onClick={handleRejectSubmit}
                  variant="contained"
                  color="error"
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>

            {loading && <CircularProgress />}

            {restaurantDetails ? (
              <Grid container spacing={2} sx={{ mt: 4 }}>
                <Grid container spacing={2}>
                  {/* Restaurant Profile Card */}
                  <Grid item xs={12} md={6}>
                    <Card elevation={3} sx={{ borderRadius: 2 }}>
                      <Box sx={{ position: "relative" }}>
                        <img
                          onClick={() =>
                            handleImageClick(
                              restaurantDetails?.restaurantImages?.[0]
                            )
                          } // Pass specific image URL
                          src={restaurantDetails?.restaurantImages?.[0]} // Image URL
                          alt="Business Profile"
                          style={{
                            width: "100%",
                            height: "190px",
                            objectFit: "cover",
                            cursor: "pointer",
                          }}
                        />
                        {/* Download Icon on Top of the Image */}
                        <a
                          href={restaurantDetails?.restaurantImages?.[0]}
                          download
                        >
                          <IconButton
                            sx={{
                              position: "absolute",
                              top: 8,
                              right: 8,
                              color: "green",
                              backgroundColor: "rgba(255, 255, 255, 0.7)",
                              "&:hover": {
                                backgroundColor: "rgba(255, 255, 255, 0.9)",
                              },
                            }}
                          >
                            <DownloadIcon fontSize="medium" />
                          </IconButton>
                        </a>
                      </Box>

                      {/* Dialog for full-size image */}
                      <Dialog open={open} onClose={handleClose} maxWidth="md">
                        <DialogTitle>
                          <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                              position: "absolute",
                              right: 8,
                              top: 0,
                              color: (theme) => theme.palette.grey[500],
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </DialogTitle>
                        <img
                          src={selectedImage}
                          alt="Documents"
                          style={{ maxHeight: "60vh", width: "50vw" }}
                        />
                      </Dialog>

                      {/* Card Content */}
                      <CardContent>
                        <Typography variant="h6" fontWeight="bold">
                          {restaurantDetails.restName}
                        </Typography>
                        <Box
                          sx={{ display: "flex", alignItems: "center", mt: 1 }}
                        >
                          <IconButton size="small">
                            <PhoneIcon fontSize="small" />
                          </IconButton>
                          <Typography color="textSecondary">
                            {restaurantDetails.managerNumber}
                          </Typography>
                        </Box>

                        {/* Owner Details */}
                        <Box sx={{ mt: 1 }}>
                          <Typography variant="h6" fontWeight="bold">
                            Owner Details
                          </Typography>
                          <Typography variant="body2">
                            Name:{" "}
                            {restaurantDetails.serviceProvider?.spName || "N/A"}
                          </Typography>
                          <Typography variant="body2">
                            Contact:{" "}
                            {restaurantDetails.serviceProvider?.spPhone ||
                              "N/A"}
                          </Typography>
                          <Typography variant="body2">
                            Email:{" "}
                            {restaurantDetails.serviceProvider?.spEmail ||
                              "N/A"}
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>

                  {/* Address and Additional Cards */}
                  <Grid item xs={12} md={6}>
                    <Card elevation={3} sx={{ borderRadius: 2, p: 2 }}>
                      <Typography variant="h6" fontWeight="bold">
                        Address
                      </Typography>
                      <Typography variant="body2">
                        {`${restaurantDetails.restAddress?.addressLine1}, ${restaurantDetails.restAddress?.addressLine2}, ${restaurantDetails.restAddress?.city}, ${restaurantDetails.restAddress?.postalCode}`}
                      </Typography>
                    </Card>

                    <Card elevation={3} sx={{ borderRadius: 2, p: 2, mt: 1 }}>
                      <Typography variant="h6" fontWeight="bold">
                        Cuisine
                      </Typography>
                      <Typography variant="body2">
                        {restaurantDetails?.cuisine?.join(", ") || "N/A"}
                      </Typography>
                    </Card>

                    <Card elevation={3} sx={{ borderRadius: 2, p: 2, mt: 1 }}>
                      <Typography variant="h6" fontWeight="bold">
                        Restaurant Description
                      </Typography>
                      <Typography variant="body2">
                        {restaurantDetails?.restDescription?.join(", ") ||
                          "N/A"}
                      </Typography>
                    </Card>
                    <Card elevation={3} sx={{ borderRadius: 2, p: 2, mt: 1 }}>
                    
                      <Typography variant="h6" fontWeight="bold">Bank Details</Typography>
                        <Typography variant="body2" sx={{ mt: 1 }}>
                          Account Type: {restaurantDetails?.bankDetails?.accountType || "N/A"} <br />
                          Account Number: {restaurantDetails?.bankDetails?.accountNumber || "N/A"} <br />
                          IFSC Code: {restaurantDetails?.bankDetails?.ifscCode || "N/A"}
                        </Typography>
                    </Card>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Card elevation={3} sx={{ borderRadius: 2, p: 2 }}>
                    <Typography variant="h6" fontWeight="bold">
                      Restaurant Timings
                    </Typography>

                    {/* Wrap both sections in a flexbox container */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                      }}
                    >
                      {/* Operational Hours Section */}
                      <Box sx={{ flex: 1, pr: 2 }}>
                        {" "}
                        {/* Add padding to the right for spacing */}
                        <Box
                          sx={{ display: "flex", alignItems: "center", mb: 1 }}
                        >
                          <AccessTimeIcon sx={{ mr: 1 }} />
                          <Typography variant="body2" fontWeight="bold">
                            Operational Hours
                          </Typography>
                        </Box>
                        {restaurantDetails?.operationalTime?.map((time) => (
                          <Box
                            key={time._id}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mt: 0.5,
                              flexWrap: "wrap", // Ensure rows wrap if necessary
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                width: "100px",
                                minWidth: "100px",
                                fontWeight: "bold",
                                mr: 2, // Add margin to space between day and slots
                              }}
                            >
                              {time.day}
                            </Typography>
                            {/* Check if the time has slots */}
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                              }}
                            >
                              {time.slots ? (
                                // If slots are present, map through the slots
                                time.slots.map((slot) => (
                                  <Typography
                                    variant="body2"
                                    key={slot._id}
                                    sx={{
                                      marginRight: 1,
                                      whiteSpace: "nowrap",
                                    }} // Ensure text stays on one line
                                  >
                                    {slot.startTime} - {slot.endTime}
                                  </Typography>
                                ))
                              ) : (
                                // If no slots, directly render startTime and endTime
                                <Typography variant="body2">
                                  {time.startTime} - {time.endTime}
                                </Typography>
                              )}
                            </Box>
                          </Box>
                        ))}
                      </Box>

                      {/* Delivery Timings Section */}
                      <Box sx={{ flex: 1, pl: 2 }}>
                        {" "}
                        {/* Add padding to the left for spacing */}
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mb: 1,
                            mt: 2,
                          }}
                        >
                          <DeliveryDiningIcon sx={{ mr: 1 }} />
                          <Typography variant="body2" fontWeight="bold">
                            Delivery Timings
                          </Typography>
                        </Box>
                        {restaurantDetails?.deliveryTimings?.map((delivery) => (
                          <Box
                            key={delivery._id}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mt: 0.5,
                              flexWrap: "wrap", // Ensure rows wrap if necessary
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                width: "100px",
                                minWidth: "100px",
                                fontWeight: "bold",
                                mr: 2, // Add margin to space between day and slots
                              }}
                            >
                              {delivery.day}
                            </Typography>
                            {/* Check if the delivery time has slots */}
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                              }}
                            >
                              {delivery.slots ? (
                                // If slots are present, map through the slots
                                delivery.slots.map((slot) => (
                                  <Typography
                                    variant="body2"
                                    key={slot._id}
                                    sx={{
                                      marginRight: 1,
                                      whiteSpace: "nowrap",
                                    }} // Ensure text stays on one line
                                  >
                                    {slot.startTime} - {slot.endTime}
                                  </Typography>
                                ))
                              ) : (
                                // If no slots, directly render startTime and endTime
                                <Typography variant="body2">
                                  {delivery.startTime} - {delivery.endTime}
                                </Typography>
                              )}
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Card>
                </Grid>

                {/* Cuisine Card */}
                <Grid item xs={12}></Grid>

                {/* PAN, GST, FSSAI Card */}
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      sx={{ mt: 2, alignSelf: "flex-start" }}
                    >
                      KYC Details
                    </Typography>
                    <Button
                      variant="contained"
                      sx={{ borderRadius: "2rem", alignSelf: "flex-end" }}
                      onClick={handleDownload}
                    >
                      Download All
                    </Button>
                  </Box>
                  <Card elevation={3} sx={{ borderRadius: 2, p: 2 }}>
                    <Typography variant="h6" fontWeight="bold">
                      Legal Information
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Box sx={{ position: "relative" }}>
                          <Typography variant="body2" fontWeight="bold">
                            PAN No:
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            {restaurantDetails.panNo}
                          </Typography>
                          <img
                            onClick={() =>
                              handleImageClick(restaurantDetails.panImage)
                            } // Pass specific image URL
                            src={restaurantDetails.panImage} // Image URL
                            alt="Business Profile"
                            style={{
                              width: "100%",
                              height: "180px",
                              objectFit: "fill",
                              cursor: "pointer",
                            }}
                          />
                          {/* Download Icon on Top of the Image */}
                          <a href={restaurantDetails.panImage} download>
                            <IconButton
                              sx={{
                                position: "absolute",
                                top: 20,
                                right: 8,
                                color: "green",
                                backgroundColor: "rgba(255, 255, 255, 0.7)",
                                "&:hover": {
                                  backgroundColor: "rgba(255, 255, 255, 0.9)",
                                },
                              }}
                            >
                              <DownloadIcon fontSize="medium" />
                            </IconButton>
                          </a>
                        </Box>
                      </Grid>

                      <Grid item xs={4}>
                        <Box sx={{ position: "relative" }}>
                          <Typography variant="body2" fontWeight="bold">
                            GST No:
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            {restaurantDetails.gstNo}
                          </Typography>

                          <img
                            src={restaurantDetails.gstImage} // Image URL
                            alt="GST"
                            onClick={() =>
                              handleImageClick(restaurantDetails.gstImage)
                            } // Pass specific image URL
                            style={{
                              width: "100%",
                              height: "180px",
                              objectFit: "fill",
                              cursor: "pointer",
                            }}
                          />
                          <a href={restaurantDetails.gstImage} download>
                            <IconButton
                              sx={{
                                position: "absolute",
                                top: 8,
                                right: 8,
                                color: "green",
                                backgroundColor: "rgba(255, 255, 255, 0.7)",
                                "&:hover": {
                                  backgroundColor: "rgba(255, 255, 255, 0.9)",
                                },
                              }}
                            >
                              <DownloadIcon fontSize="medium" />
                            </IconButton>
                          </a>
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box sx={{ position: "relative" }}>
                          <Typography variant="body2" fontWeight="bold">
                            FSSAI No:
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            {restaurantDetails.fssaiNo}
                          </Typography>
                          <img
                            onClick={() =>
                              handleImageClick(restaurantDetails.fssaiImage)
                            } // Pass specific image URL
                            src={restaurantDetails.fssaiImage} // Image URL
                            alt="FSSAI"
                            style={{
                              width: "100%",
                              height: "180px",
                              objectFit: "fill",
                              cursor: "pointer",
                            }}
                          />
                          <a href={restaurantDetails.fssaiImage} download>
                            <IconButton
                              sx={{
                                position: "absolute",
                                top: 8,
                                right: 8,
                                color: "green",
                                backgroundColor: "rgba(255, 255, 255, 0.7)",
                                "&:hover": {
                                  backgroundColor: "rgba(255, 255, 255, 0.9)",
                                },
                              }}
                            >
                              <DownloadIcon fontSize="medium" />
                            </IconButton>
                          </a>
                        </Box>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card
                    sx={{
                      borderRadius: 2,
                      overflow: "hidden", // Prevent the card from scrolling
                      boxShadow: 1,
                      position: "relative",
                      padding: 2,
                      height: "auto", // Ensure the card can grow to fit content if needed
                    }}
                  >
                    {/* PDF Preview */}
                    <Box sx={{ position: "relative", height: "250px" }}>
                      {" "}
                      {/* Match video height */}
                      <iframe
                        src={restaurantDetails?.contractUrl} // PDF URL
                        title="Contract PDF"
                        style={{
                          width: "100%", // Ensure the iframe takes up the full card width
                          height: "100%", // Fill the container height (250px)
                          border: "none", // Remove default iframe borders
                        }}
                      />
                    </Box>

                    {/* Download Icon */}
                    <a href={restaurantDetails?.contractUrl} download>
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: 8,
                          right: 20,
                          color: "green",
                          backgroundColor: "rgba(255, 255, 255, 0.7)",
                          "&:hover": {
                            backgroundColor: "rgba(255, 255, 255, 0.9)",
                          },
                        }}
                      >
                        <DownloadIcon fontSize="medium" />
                      </IconButton>
                    </a>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card elevation={3} sx={{ borderRadius: 2, p: 2 }}>
                    <Typography variant="h6" fontWeight="bold">
                      Branches
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                      {restaurantDetails?.branches?.length > 0 ? (
                        restaurantDetails?.branches?.map((branch) => (
                          <Box
                            key={branch._id}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: 1,
                              p: 1,
                              borderBottom: "1px solid #e0e0e0",
                            }}
                          >
                            <Typography variant="body2" fontWeight="bold">
                              {branch.branchName}
                            </Typography>
                          </Box>
                        ))
                      ) : (
                        <Typography variant="body2">
                          No branches available
                        </Typography>
                      )}
                    </Box>
                  </Card>
                </Grid>
              </Grid>
            ) : (
              !loading && (
                <Typography variant="body2" color="textSecondary">
                  No restaurant details available.
                </Typography>
              )
            )}

            {/* Chatbox for adding new comments */}
            <Box>
              <AdminChatBox type={type} />
            </Box>
          </Box>
        )}

        {tabIndex === 1 && (
          <Box p={3} sx={{ backgroundColor: '#f5f5f5', borderRadius: 2 }}>
            <Typography variant="h5" fontWeight="bold" gutterBottom sx={{ textAlign: 'center', mb: 3 }}>
              Menu Details
            </Typography>
            {loading && <CircularProgress />}
            {restaurantDetails?.menu?.length > 0 ? (
              <Grid container spacing={3}>
                {restaurantDetails?.menu.map((menuItem) => (
                  <React.Fragment key={menuItem._id}>
                    {menuItem.subPackages.map((subPackage) => (
                      <Grid item xs={12} key={subPackage._id}>
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          sx={{ mb: 2, color: '#333', textAlign: 'left' }}
                        >
                          {subPackage.restSubPackName}
                        </Typography>
                        <Grid container spacing={3}>
                          {subPackage.items.map((item) => (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={item._id}>
                              <Card
                                elevation={4}
                                sx={{
                                  borderRadius: 3,
                                  overflow: 'hidden',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'space-between',
                                  height: '100%',
                                  transition: 'transform 0.2s, box-shadow 0.2s',
                                  '&:hover': {
                                    transform: 'scale(1.05)',
                                    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.15)',
                                  },
                                }}
                              >
                                <Box
                                  component="img"
                                  src={item.restItemImage[0]}
                                  alt={item.restItemName}
                                  sx={{
                                    width: '100%',
                                    height: 160,
                                    objectFit: 'cover',
                                  }}
                                />
                                <CardContent sx={{ flexGrow: 1, p: 2 }}>
                                  <Typography
                                    variant="subtitle1"
                                    fontWeight="600"
                                    sx={{ mb: 0.5, color: '#444' }}
                                  >
                                    {item.restItemName}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    sx={{ mb: 1, fontStyle: 'italic' }}
                                  >
                                    {item.restItemDescription}
                                  </Typography>

                                  {/* Display add-ons as chips */}
                                  {item.addons && item.addons.length > 0 && (
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: 0.5,
                                        mt: 1,
                                        justifyContent: 'flex-start',
                                      }}
                                    >
                                      {item.addons.map((addonDetails, index) => (
                                        <Chip
                                          key={addonDetails._id || index}
                                          label={`${addonDetails.addOnName} - ₹${addonDetails.addOnPrice}`}
                                          variant="outlined"
                                          size="small"
                                          color="primary"
                                          sx={{
                                            fontSize: '0.75rem',
                                            borderRadius: 1,
                                          }}
                                        />
                                      ))}
                                    </Box>
                                  )}
                                </CardContent>
                                <Box sx={{ p: 2, backgroundColor: '#fafafa' }}>
                                  <Typography variant="body1" fontWeight="bold" color="primary">
                                    ₹{item.restItemPrice}
                                  </Typography>
                                </Box>
                              </Card>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    ))}
                  </React.Fragment>
                ))}
              </Grid>
            ) : (
              !loading && (
                <Typography variant="body2" color="textSecondary" sx={{ textAlign: 'center', mt: 4 }}>
                  No menu details available.
                </Typography>
              )
            )}

            {/* Chatbox */}
            <AdminChatBox type={type} />
          </Box>
        )}

        {tabIndex === 2 && <RestaurantOrderTab restaurantId={restaurantId} />}

        {tabIndex === 3 && <RestaurantPayoutTab restaurantId={restaurantId} />}
      </Box>
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </AdminDrawer>
  );
};

export default RestaurantDetailsPage;

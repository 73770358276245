import React from "react";
import { useNavigate } from 'react-router-dom';
import { Box, Checkbox, Tooltip, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';

import PayoutTable from "../../DataTables/PayoutTables/PayoutTable";
import { fetchRestaurantPayoutById } from "../../../api/payouts/payoutsApi";

const RestaurantPayoutTable = ({ orders, selectedOrder, onCheckboxChange }) => {
  // Transform the orders array into a format suitable for the table
  console.log("orders in rest", orders);
  const navigate = useNavigate();

  const transformedOrders = orders.map((order) => ({
    id: order?._id || 'N/A', // Fallback if _id is not available
    orderID: order?.orderId?.restaurant_orderId || 'N/A', // Restaurant-specific order ID
    custName: order?.orderId?.customer?.fullName || 'N/A', // Customer Name
    deliveryAddress: `${order?.orderId?.deliveryAddress?.street}, ${order?.orderId?.deliveryAddress?.city}, ${order?.orderId?.deliveryAddress?.state} - ${order?.orderId?.deliveryAddress?.postalCode}`, // Full delivery address
    orderDate: order?.orderId?.createdAt ? new Date(order?.orderId?.createdAt).toLocaleDateString() : 'N/A', // Order creation date
    quantity: order?.orderId?.items[0]?.quantity || 'N/A', // Quantity of items
    paymentStatus: order?.orderId?.paymentStatus || 'N/A', // Payment status
    grossPayout: order?.grossAmount ? order.grossAmount.toFixed(2) : '0.00', // Gross payout
    fessteFee: order?.fessteFee ? order.fessteFee.toFixed(2) : '0.00', // Fesste fee
    transactionFee: order?.transactionFee ? order.transactionFee.toFixed(2) : '0.00', // Transaction fee
    netPayout: order?.netPayout ? order.netPayout.toFixed(2) : '0.00', // Net payout
    deliveryFee: order?.orderId?.deliveryFee ? order.orderId.deliveryFee.toFixed(2) : '0.00', // Delivery fee
    platformFee: order?.orderId?.platformFee ? order.orderId.platformFee.toFixed(2) : '0.00', // Platform fee
    platformFeeGst: order?.orderId?.platformFeeGst ? order.orderId.platformFeeGst.toFixed(2) : '0.00', // GST on platform fee
    specialInstructions: order?.orderId?.specialInstructions || 'N/A', // Special instructions
    payoutId: order?._id || 'N/A', // Payout ID
    payoutStatus: order?.status || 'N/A', // Payout status
    totalOrderPaymentStatus: order?.orderId?.paymentStatus || 'N/A', // Total order payment status
    restPayoutTransactionId: order?.restPayoutTransactionId,
    invoiceId: order?.invoiceId,
    tds:order?.tds ? order?.tds.toFixed(2) : 0.00,
    fessteGST: order?.fessteGST ? order.fessteGST.toFixed(2) : 0.00,
    deliveryPartnerName: order?.orderId?.deliveryPartnerId[0]?.fullName || 'NA',
    restName: order?.restaurant?.restName
  }));
  
  


  const handleViewDetails = async (orderId) => {
    try {
      const data = await fetchRestaurantPayoutById(orderId);
      navigate(`/restaurant-price-summary-payout/${orderId}`, { state: { orderData: data } });
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };


  // Define columns for the table
  const columns = [
    { field: "orderID", headerName: "Order ID", minWidth: 200 },
    { field: "restName", headerName: "Restaurant Name", minWidth: 200 },
    { field: "deliveryAddress", headerName: "Delivery Address", minWidth: 250 },
    { field: "orderDate", headerName: "Order Date", minWidth: 200 },
    { field: "quantity", headerName: "Quantity", minWidth: 200 },
    { field: "payoutStatus", headerName: "Payout Status", minWidth: 200 },
    { field: "restPayoutTransactionId", headerName: "Payout Transaction ID", minWidth: 200 },
    { field: "grossPayout", headerName: "Gross Payout", minWidth: 200 },
    { field: "fessteFee", headerName: "Fesste Fee", minWidth: 200 },
    { field: "fessteGST", headerName: "Fesste Fee GST", minWidth: 200 },
    { field: "transactionFee", headerName: "Transaction Fee", minWidth: 200 },
    { field: "tds", headerName: "TDS", minWidth: 200 },
    { field: "netPayout", headerName: "Net Payout", minWidth: 200 },
    
    
    {
      field: "pay",
      headerName: "Pay",
      minWidth: 160,
      renderCell: (params) => (
        <Checkbox
          color="primary"
          checked={selectedOrder?._id === params.row.id} // Check if the current row is selected
          onChange={() => onCheckboxChange(params.row)} // Handle checkbox change
        />
      ),
      flex: 0.5,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 60,
      sortable: false,
      renderCell: (params) => (
        <>
          <Tooltip title="View Details">
            <IconButton
              color="default"
              onClick={() => handleViewDetails(params.row.id)}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];
  

  return (
    <Box style={{ height: 400, width: "100%" }}>
      <PayoutTable
        rows={transformedOrders} // Use the transformed data
        columns={columns}
        getRowId={(row) => row.id} // Use _id as row identifier
        pageSize={5}
        disableSelectionOnClick
        autoHeight
      />
    </Box>
  );
};

export default RestaurantPayoutTable;

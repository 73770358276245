import React from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Paper, Box, Button } from '@mui/material';
import OrderHeader from './OrderHeader';
import OrderDetailsCard from './OrderDetailsCard';
import OrderItemList from './OrderItemList';
import PriceSummary from './PriceSummary';
import styles from './orderDetails.module.css';

const FessteMartOrderDetails = () => {
    const { state } = useLocation(); // Access passed state
    const { orderData } = state || {}; // Retrieve order data

    // Extract and format order date
    const orderDate = orderData?.createdAt ? new Date(orderData.createdAt).toLocaleDateString() : '20-04-2024';

    const handlePrint = () => {
        window.print();
    };

    return (
        <Container
            sx={{
                padding: 4,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh', // Center vertically
                backgroundColor: '#f5f5f5',
                position: 'relative', // For absolute positioning of the button
            }}
        >
            {/* Print Button */}
            <Button
                id="print-button"
                variant="contained"
                onClick={handlePrint}
                sx={{
                    position: 'absolute',
                    top: 16,
                    right: 16,
                }}
            >
                Print
            </Button>

            <Paper
                id="printable-content"
                className={styles.printableContent} // assuming you're importing the CSS module
                sx={{
                    padding: 4,
                    width: '100%', // Full width to fit screen or print format
                    maxWidth: '900px', // Max width to ensure proper centering on larger screens
                    backgroundColor: '#fff',
                    elevation: 3,
                    boxShadow: '0px 12px 14px rgba(0, 0, 0, 0.1)',
                }}
            >
                {/* Order Header Section */}
                <OrderHeader
                    status={orderData?.status}
                    orderDate={orderDate}
                    orderId={orderData?.restaurant_orderId || 'N/A'}
                />

                {/* Order Details Section */}
                <OrderDetailsCard
                    customerDetails={orderData?.customer || orderData?.userId}
                    restaurantDetails={orderData?.restaurantId || orderData?.catererId}
                />

                {/* Order Item List Section */}
                <Box
                    sx={{
                        marginBottom: 4,
                    }}
                >
                    <OrderItemList items={orderData?.items || []} />
                </Box>

                {/* Price Summary Section */}
                <PriceSummary
                    orderData={orderData}
                    total={orderData?.totalAmount || 0}
                    packingCharge={0} // Default value
                    deliveryFee={0} // Default value
                    tax={0} // Default value
                    tips={0} // Default value
                    discount={0} // Default value
                />
            </Paper>
        </Container>
    );
};

export default FessteMartOrderDetails;

import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Tooltip,
  Grid,
  CardMedia,
  Box,
  Card,
} from "@mui/material";
import { ExpandMore, Close } from "@mui/icons-material";
import { fetchPackageDetails } from "../../api/servicePartners/CaterersApis"; // Adjust the import according to your structure

const PackagePopup = ({ open, onClose, packageId }) => {
  const [packageDetails, setPackageDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getPackageDetails = async () => {
      if (packageId) {
        setLoading(true);
        setError(null);
        try {
          const details = await fetchPackageDetails(packageId); // Fetch package details
          setPackageDetails(details);
        } catch (err) {
          setError("Failed to fetch package details.");
        } finally {
          setLoading(false);
        }
      }
    };

    getPackageDetails();
  }, [packageId]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      sx={{ "& .MuiDialog-paper": { width: "600px", maxHeight: "90%" } }}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">Package Details</Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ overflowY: "auto", p: 3 }}>
        {loading && (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <CircularProgress />
          </Box>
        )}
        {error && <Typography color="error">{error}</Typography>}
        {packageDetails && (
          <div>
            {/* Main package details */}
            <Typography variant="h5" fontWeight="bold" gutterBottom>
              {packageDetails.packageName}
            </Typography>
            {/* Sub-packages section */}
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Sub-Packages
            </Typography>
            {Array.isArray(packageDetails.subPackages) &&
              packageDetails.subPackages.length > 0 ? (
              packageDetails.subPackages.map((subPackage) => (
                <Card key={subPackage.subPackageId._id} sx={{ mb: 2, p: 2, boxShadow: 1 }}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {subPackage.subPackageId.subPackageName} (Max Count:{" "}
                        {subPackage.maxCount})
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {subPackage.selectedItems && subPackage.selectedItems.length > 0 ? (
                        subPackage.selectedItems.map((item) => (
                          <Grid
                            container
                            alignItems="center"
                            key={item._id}
                            sx={{ mb: 1 }}
                          >
                            <Grid item xs={8}>
                              <Typography variant="body2" fontWeight="bold">
                                {item.itemName}
                              </Typography>
                              <Typography variant="caption" color="textSecondary">
                                {item.itemDescription}
                              </Typography>
                            </Grid>
                            <Grid item xs={4} container justifyContent="flex-end">
                              {item.itemImages && item.itemImages.length > 0 && (
                                <Tooltip title="Click to enlarge">
                                  <CardMedia
                                    component="img"
                                    height={80}  // Set unique height
                                    width={80}   // Set unique width
                                    image={item.itemImages[0]} // Display the first image
                                    alt={item.itemName}
                                    sx={{
                                      objectFit: "cover", // Ensure the image covers the space
                                      borderRadius: 2, // Optional: for soft corners
                                      cursor: "pointer",
                                      mr: 1, // Add right margin for spacing
                                      ml: 2, // Add left margin for spacing between text and image
                                      "&:hover": { transform: "scale(1.1)" },
                                      transition: "transform 0.3s ease-in-out",
                                    }}
                                    onClick={() =>
                                      window.open(item.itemImages[0], "_blank")
                                    }
                                  />
                                </Tooltip>
                              )}
                            </Grid>
                          </Grid>
                        ))
                      ) : (
                        <Typography variant="body2" color="textSecondary">
                          No items available
                        </Typography>
                      )}
                    </AccordionDetails>



                  </Accordion>
                </Card>
              ))
            ) : (
              <Typography variant="body2" color="textSecondary">
                No sub-packages available
              </Typography>
            )}
          </div>
        )}
      </DialogContent>
      <DialogActions sx={{ padding: "16px" }}>
        <Button variant="contained" color="primary" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PackagePopup;

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDTN2iCd30FoZ2oo-ra8dCB-vAVgaFpPOU",
  authDomain: "fesste-v2.firebaseapp.com",
  projectId: "fesste-v2",
  storageBucket: "fesste-v2.firebasestorage.app",
  messagingSenderId: "1050270900987",
  appId: "1:1050270900987:web:c3f91387d43d0a374ec65e",
  measurementId: "G-Q4FBE4RVBC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app)

// Optionally, initialize Firebase Analytics if needed
const analytics = getAnalytics(app);

export { app, analytics, messaging};

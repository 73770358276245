import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Typography, Grid } from "@mui/material";
import AdminDrawer from "../../../components/AdminDrawer/AdminDrawer";
import StatisticsCard from "../../AdvancedComponents/PayoutComponents/StatisticsCard";
import { fetchRestaurantPayout } from "../../../api/payouts/payoutsApi";
import RestaurantPayoutTable from "../../AdvancedComponents/RestaurantPayoutComponents/RestaurantPayoutTable";
import RestaurantPaymentBreakdown from "../../AdvancedComponents/RestaurantPayoutComponents/RestaurantBreakDown";
import BreadCrumbComponent from "../../ResuableComponents/BreadCrumb";

const RestaurantPaymentDashBoard = () => {
  const { id } = useParams();
  const [payoutData, setPayoutData] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadPayoutData = async () => {
      try {
        const data = await fetchRestaurantPayout(id);
        setPayoutData(data);
      } catch (error) {
        console.error("Error fetching payout data:", error);
        setPayoutData([]);
      } finally {
        setLoading(false);
      }
    };

    loadPayoutData();
  }, [id]);

  const handleCheckboxChange = (order) => {
    setSelectedOrder(order);
  };

  if (loading) {
    return (
      <div style={{ backgroundColor: "#f2f2f2", height: "100vh" }}>
        <AdminDrawer>
          <div style={{ padding: "24px" }}>
            <Typography variant="h6">Loading...</Typography>
          </div>
        </AdminDrawer>
      </div>
    );
  }

  if (!payoutData || payoutData.length === 0) {
    return (
      <div style={{ backgroundColor: "#f2f2f2", height: "100vh" }}>
        <AdminDrawer>
          <Typography variant="h5" gutterBottom>
            Restaurant Payout
          </Typography>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ height: "100vh" }}
          >
            <Grid item>
              <Typography variant="h6">No payout data found</Typography>
            </Grid>
          </Grid>
        </AdminDrawer>
      </div>
    );
  }

  const totalPayout = Array.isArray(payoutData)
    ? payoutData.reduce(
        (acc, payoutDetails) => {
          acc.totalAmount +=
            payoutDetails?.grossAmount ||
            0;
          acc.netPayout +=
            payoutDetails?.amount || payoutDetails?.netPayout || 0;
          acc.fessteFee += (payoutDetails?.fessteFee || 0) + (payoutDetails?.fessteGST || 0); 
          acc.transactionFee += (payoutDetails?.transactionFee || 0) + (payoutDetails?.tds || 0);
          return acc;
        },
        {
          totalAmount: 0,
          netPayout: 0,
          fessteFee: 0,
          transactionFee: 0,
        }
      )
    : {
        totalAmount: payoutData?.grossAmount || 0,
        netPayout: payoutData?.amount || 0,
        fessteFee: (payoutData?.fessteFee || 0) + (payoutData?.fessteGST || 0) ,
        transactionFee: (payoutData?.transactionFee || 0) + (payoutData?.tds || 0),
      };

  return (
    <div style={{ backgroundColor: "#f2f2f2", height: "max-content" }}>
      <AdminDrawer>
        <BreadCrumbComponent />
        <div style={{ padding: "24px" }}>
          <Typography variant="h5" gutterBottom>
            {payoutData[0]?.restaurant?.restName ||
              payoutData[0]?.fessteMartId?.businessName}
          </Typography>
        </div>

        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={6} md={3}>
            <StatisticsCard
              title="Total Earnings (Till Date)"
              value={`₹${totalPayout?.totalAmount?.toFixed(2)}`}
              background="linear-gradient(135deg, #6a1b9a 0%, #8e24aa 100%)"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatisticsCard
              title="Fesste Fee + GST (Till Date)"
              value={`₹${totalPayout.fessteFee.toFixed(2)}`}
              background="linear-gradient(135deg, #43a047 0%, #66bb6a 100%)"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatisticsCard
              title="Transaction Fee + TDS(Till Date)"
              value={`₹${totalPayout?.transactionFee?.toFixed(2)}`}
              background="linear-gradient(135deg, #ffb300 0%, #ffa000 100%)"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatisticsCard
              title="Net Payout (Till Date)"
              value={`₹${totalPayout?.netPayout?.toFixed(2)}`}
              background="linear-gradient(135deg, #1e88e5 0%, #42a5f5 100%)"
            />
          </Grid>
        </Grid>

        {selectedOrder ? (
          <RestaurantPaymentBreakdown selectedOrder={selectedOrder} />
        ) : null}

        <RestaurantPayoutTable
          orders={payoutData}
          selectedOrder={selectedOrder}
          onCheckboxChange={handleCheckboxChange}
        />
      </AdminDrawer>
    </div>
  );
};

export default RestaurantPaymentDashBoard;

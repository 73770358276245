
import baseUrl from "../../config";

// src/api/restaurantPackages/restaurantItems.js
export const fetchRestaurantItems = async () => {
    try {
      const response = await fetch(`${baseUrl}restItems`);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching restaurant items:', error);
      return [];
    }
  };
  // addons

export const fetchRestaurantAddons = async () => {
  const response = await fetch(`${baseUrl}restAddons`);
  const data = await response.json();
  return data;
};


export const fetchRestaurantSubPackages = async () => {
  const response = await fetch (`${baseUrl}rest_SubPackages`);
  const data = await response.json();
  return data;
}

// src/api/restaurantApi.js
export const fetchRestaurants = async () => {
  try {
      const response = await fetch(`${baseUrl}restaurants`);
      if (!response.ok) {
          throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data.restaurants || [];
  } catch (error) {
      console.error('Failed to fetch restaurants:', error);
      return [];
  }
};

//  adding restaurant sub package
export const createRestaurantSubPackage = async (subPackageData) => {
  try {
      const response = await fetch(`${baseUrl}create_rest_SubPackage`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(subPackageData),
      });

      if (!response.ok) {
          throw new Error('Failed to create subpackage');
      }

      return await response.json();
  } catch (error) {
      console.error('Error:', error);
      throw error;
  }
};

// delete restaurant sub package

export const deleteRestaurantSubPackage = async (subPackageId) => {
  try {
      const response = await fetch(`${baseUrl}delete_rest_SubPackage`, {
          method: 'DELETE',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ subPackageId }),
      });

      if (!response.ok) {
          throw new Error('Failed to delete subpackage');
      }

      return await response.json();
  } catch (error) {
      console.error('Error deleting subpackage:', error);
      throw error;
  }
};


// src/api/restaurantPackages/restaurantItemsApi.js

export const getRestaurantSubPackageById = async (id) => {
  const response = await fetch(`${baseUrl}rest_SubPackages/${id}`);
  if (!response.ok) {
      throw new Error('Failed to fetch subpackage');
  }
  return response.json();
};

export const updateRestaurantSubPackage = async (id, subPackageData) => {
  const response = await fetch(`${baseUrl}update_rest_SubPackage`, {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(subPackageData),
  });

  if (!response.ok) {
      throw new Error('Failed to update subpackage');
  }
  return response.json();
};

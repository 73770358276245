import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Typography, Box, CircularProgress, Paper, Grid } from "@mui/material";
import BASE_URL from "../../../../config";
import AdminDrawer from "../../../../components/AdminDrawer/AdminDrawer";
import BreadcrumbsComponent from "../../../../components/ResuableComponents/BreadCrumb";

const ViewPartyHalls = () => {
  const { state } = useLocation();
  const { id } = state || {};
  const [loading, setLoading] = useState(true);
  const [partyHallDetails, setPartyHallDetails] = useState(null);

  useEffect(() => {
    const fetchPartyHallDetails = async () => {
      if (id) {
        try {
          const response = await fetch(`${BASE_URL}partyHallSp/${id}`);
          const data = await response.json();
          setPartyHallDetails(data);
        } catch (error) {
          console.error("Error fetching lighting details:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchPartyHallDetails();
  }, [id]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <AdminDrawer>
      <BreadcrumbsComponent />
      <Paper
        elevation={3}
        style={{ padding: "20px", backgroundColor: "#f9f9f9" }}
      >
        <Typography variant="h4" gutterBottom>
          Party Hall Details:{" "}
          {partyHallDetails?.partyHallID || "Unknown Lighting"}
        </Typography>

        <Box mt={2}>
          <Box>
            <Typography variant="h5" gutterBottom>
              Menu Items
            </Typography>
            {partyHallDetails?.menu?.halls &&
            partyHallDetails?.menu?.halls.length > 0 ? (
              <Grid container spacing={2}>
                {partyHallDetails.menu.halls.map((hall) => (
                  <Grid item xs={12} md={6} key={hall._id}>
                    <Paper elevation={2} style={{ padding: "16px" }}>
                      <Typography variant="h6">
                        {hall.partyHallType.typeName}
                      </Typography>
                      <img
                        src={hall.partyHallType.images[0]}
                        alt={hall.partyHallType.typeName}
                        width="100"
                      />
                      <Typography mt={2}>
                        Price: ₹{hall.partyHallType.price}
                      </Typography>
                      <Typography>
                        Minimum Price: ₹{hall.partyHallType.minPrice}
                      </Typography>
                      <Typography>
                        Maximum Price: ₹{hall.partyHallType.maxPrice}
                      </Typography>
                      <Typography>
                        Hall Capacity: {hall.partyHallType.capacity}
                      </Typography>
                      <Typography mt={1} mb={2}>
                        Description: {hall.partyHallType.description}
                      </Typography>
                      <Typography>
                        Available:{" "}
                        {hall.partyHallType.availability ? "Yes" : "No"}
                      </Typography>

                      {/* Display Available Dates */}
                      {hall.partyHallType.availableDates.length > 0 ? (
                        <Box mt={2}>
                          <Typography variant="subtitle1">
                            Available Dates:
                          </Typography>
                          <ul>
                            {hall.partyHallType.availableDates.map(
                              (date, index) => (
                                <li key={index}>
                                  <Typography variant="body2">
                                    {new Date(date).toLocaleDateString()}
                                  </Typography>
                                </li>
                              )
                            )}
                          </ul>
                        </Box>
                      ) : (
                        <Typography>No available dates</Typography>
                      )}

                      {/* Display Blocked Dates */}
                      {hall.partyHallType.blockedDates.length > 0 ? (
                        <Box mt={2}>
                          <Typography variant="subtitle1">
                            Blocked Dates:
                          </Typography>
                          <ul>
                            {hall.partyHallType.blockedDates.map(
                              (date, index) => (
                                <li key={index}>
                                  <Typography variant="body2">
                                    {new Date(date).toLocaleDateString()}
                                  </Typography>
                                </li>
                              )
                            )}
                          </ul>
                        </Box>
                      ) : (
                        <Typography>No blocked dates</Typography>
                      )}
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography>No Halls available</Typography>
            )}
          </Box>
        </Box>
      </Paper>
    </AdminDrawer>
  );
};

export default ViewPartyHalls;

import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Tooltip, IconButton, Button } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PaymentIcon from '@mui/icons-material/Payment';
import PayoutTable from "../../DataTables/PayoutTables/PayoutTable";
import { fetchCatererPayoutById } from "../../../api/payouts/payoutsApi";

const PaymentDashBoardTable = ({ orders, selectedOrder, onCheckboxChange }) => {
  const navigate = useNavigate();

  const transformedOrders = orders.map((order) => ({
    id: order?._id || "N/A", // Fallback if _id is not available
    orderID: order?.orderID || order?.orderId?.orderId || "N/A", // Fallback if orderID is missing
    custName:
      order?.caterer?.businessName || order?.caterer?.businessName || "N/A", // Customer Name fallback
    custContact:
      order?.caterer?.businessPhoneNumber ||
      order?.caterer?.businessPhoneNumber ||
      "N/A", // Customer Name fallback
    ownerName: order?.caterer?.serviceProvider?.spName || "N/A",
    deliveryAddress:
      order?.caterer?.address?.addressLine1 ||
      `${order?.caterer?.address?.addressLine1}, ${order?.caterer?.address?.addressLine2}, ${order?.caterer?.address?.landmark} , ${order?.caterer?.address?.city} - ${order?.caterer?.address?.postalCode}`,
    orderDate:
      order?.orderId?.orderDate
        ? new Date(order.orderId.orderDate).toLocaleDateString()
        : "N/A", // Fallback for missing date
    packageName: order?.orderId?.items?.[0]?.packageId?.packageName || "N/A", // Fallback for missing package name
    quantity:
      order?.orderId?.items?.[0]?.guestCount ||
      order?.orderId?.items?.[0]?.quantity ||
      "N/A", // Fallback for quantity
    totalAmount: order?.totalAmount ? order.totalAmount.toFixed(2) : "0.00", // Fallback for missing total amount
    initialPayment: order?.initialPayment
      ? order.initialPayment.toFixed(2)
      : "0.00", // Fallback for missing initial payment
    secondPayment: order?.orderId?.secondPayment
      ? order.orderId.secondPayment.toFixed(2)
      : "0.00", // Fallback for second payment
    initialPaymentSchedule: order?.orderId?.initialPaymentSchedule || "N/A", // Fallback for payment schedule
    initialPaymentDate: order?.orderId?.createdAt
      ? new Date(order.orderId.createdAt).toLocaleDateString()
      : "N/A", // Fallback for initial payment date
    finalPayment: order?.orderId?.finalPayment
      ? order.orderId.finalPayment.toFixed(2)
      : "0.00", // Fallback for final payment
    paymentStatus: order?.paymentStatus || "N/A", // Fallback for payment status
    finalPaymentDueDate: order?.orderId?.finalPaymentDueDate
      ? new Date(order.orderId.finalPaymentDueDate).toLocaleDateString()
      : "N/A", // Fallback for due date
    grossPayout: order?.grossAmount ? order.grossAmount.toFixed(2) : "0.00", // Fallback for gross payout
    fessteFee: order?.fessteFee ? order.fessteFee.toFixed(2) : "0.00", // Fallback for Fesste fee
    transactionFee: order?.transactionFee
      ? order.transactionFee.toFixed(2)
      : "0.00", // Fallback for transaction fee
    netPayout: order?.netPayout ? order.netPayout.toFixed(2) : "0.00", // Fallback for net payout
    deliveryFee: order?.orderId?.deliveryFee
      ? order.orderId.deliveryFee.toFixed(2)
      : "0.00", // Fallback for delivery fee
    platformFee: order?.orderId?.platformFee
      ? order.orderId.platformFee.toFixed(2)
      : "0.00", // Fallback for platform fee
    platformFeeGst: order?.orderId?.platformFeeGst
      ? order.orderId.platformFeeGst.toFixed(2)
      : "0.00", // Fallback for platform fee GST
    specialInstructions: order?.orderId?.specialInstructions || "N/A", // Fallback for special instructions
    waterBottleType: order?.orderId?.waterBottleType
      ? order.orderId.waterBottleType.join(", ")
      : "N/A", // Fallback for water bottle type
    invoiceID: order?.invoiceId || "N/A", // Fallback for invoice ID
    payoutId: order?._id || "N/A", // Fallback for payout ID
    catPayoutTransactionId: order?.catPayoutTransactionId || "N/A", // Fallback for transaction ID
    payoutStatus: order?.status || "N/A", // Fallback for payout status
    totalOrderPaymentStatus: order?.orderId?.paymentStatus || "N/A", // Fallback for total order payment status
    initialPayout: order?.initialPayout?.toFixed(2)
      ? order?.orderId?.initialPayout?.toFixed(2)
      : 0.0,
    secondPayout: order?.secondPayout?.toFixed(2)
      ? order?.orderId?.secondPayout?.toFixed(2)
      : 0.0,
    finalPayout: order?.finalPayout?.toFixed(2)
      ? order?.orderId?.finalPayout?.toFixed(2)
      : 0.0,
    paymentStage: order?.paymentStage,
    payoutDate: order?.payoutDate,
    secondPayoutDate: order?.secondPayoutDate,
    finalPayoutDate: order?.finalPayoutDate,
    additionalTissueCharges: order?.additionalTissueCharges?.toFixed(2)
    ? order?.additionalTissueCharges?.toFixed(2)
    : 0.0,
    additionlWBCharges: order?.additionlWBCharges?.toFixed(2)
    ? order?.additionlWBCharges?.toFixed(2)
    : 0.0,
    buffetCharge: order?.buffetCharge?.toFixed(2)
    ? order?.buffetCharge?.toFixed(2)
    : 0.0,
    payoutWithAdditionalCharges: order?.payoutWithAdditionalCharges?.toFixed(2)
    ? order?.payoutWithAdditionalCharges?.toFixed(2)
    : 0.0,
    orderStatus: order?.orderStatus || "N/A",
    packagingCharges: order?.packagingCharges?.toFixed(2)
    ? order?.packagingCharges?.toFixed(2)
    : 0.0,
  }));

  const handleViewDetails = async (orderId) => {
    try {
      const data = await fetchCatererPayoutById(orderId);
      navigate(`/price-summary-payout/${orderId}`, {
        state: { orderData: data },
      });
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  // Define columns for the table
  const columns = [
    { field: "orderID", headerName: "Order ID", minWidth: 200 },
    { field: "invoiceID", headerName: "Invoice ID", minWidth: 200 },
    { field: "custName", headerName: "Caterer Name", minWidth: 200 },
    {
      field: "ownerName",
      headerName: "Owner Name",
      minWidth: 200,
      renderCell: (params) => (
        <span>{params?.value ? params?.value : "NA"}</span>
      ),
    },
    { field: "custContact", headerName: "Caterer Contact", minWidth: 200 },
    { field: "deliveryAddress", headerName: "Caterer Address", minWidth: 250 },
    { field: "orderDate", headerName: "Order Date", minWidth: 200 },
    { field: "packageName", headerName: "Package Name", minWidth: 200 },
    { field: "quantity", headerName: "Quantity", minWidth: 150 },
    { field: "grossPayout", headerName: "Total Package Amount", minWidth: 250 },
    { field: "additionalTissueCharges", headerName: "Tissue Charges", minWidth: 200 },
    { field: "additionlWBCharges", headerName: "Water bottle Charges", minWidth: 200 },
    { field: "buffetCharge", headerName: "Buffet Charges", minWidth: 200 },
    { field: "packagingCharges", headerName: "Packaging Charges", minWidth: 200 },
    { field: "payoutWithAdditionalCharges", headerName: "Gross Payout", minWidth: 200 },
    { field: "fessteFee", headerName: "Fesste Fee", minWidth: 200 },
    { field: "transactionFee", headerName: "Transaction Fee", minWidth: 200 },
    { field: "netPayout", headerName: "Net Payout", minWidth: 200 },
    { field: "initialPayout", headerName: "Advance Payout", minWidth: 220 },
    { field: "secondPayout", headerName: "Second Payout", minWidth: 200 },
    { field: "finalPayout", headerName: "Final Payout", minWidth: 200 },
    { field: "paymentStage", headerName: "Payout Stage", minWidth: 200 },
    { field: "payoutStatus", headerName: "Payout Status", minWidth: 200 },
    { field: "orderStatus", headerName: "Order Status", minWidth: 200 },
    {
      field: "catPayoutTransactionId",
      headerName: "Payout Transaction ID",
      minWidth: 230,
    },
    {
      field: "pay",
      headerName: "Pay",
      minWidth: 160,
      renderCell: (params) => {
        const isRejected = (params.row.orderStatus === "rejected" || params.row.orderStatus === "cancelled");
        return (
          <Button
            variant="contained"
            startIcon={<PaymentIcon />}
            sx={{
              backgroundColor: '#3c006a',
              color: '#fff',
              borderRadius: '8px',
              textTransform: 'none',
              '&:hover': {
                  backgroundColor: '#32005a',
              },
          }}
            disabled={isRejected} // Disable if orderStatus is "rejected"
            onClick={() => onCheckboxChange(params.row)} // Trigger the onCheckboxChange function
          >
            Pay Now
          </Button>
        );
      },
      flex: 0.5,
    },
    
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 60,
      sortable: false,
      renderCell: (params) => (
        <>
          <Tooltip title="View Details">
            <IconButton
              color="default"
              onClick={() => handleViewDetails(params.row.id)}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <Box style={{ height: 400, width: "100%" }}>
      <PayoutTable
        rows={transformedOrders} // Use the transformed data
        columns={columns}
        getRowId={(row) => row.id} // Use _id as row identifier
        pageSize={5}
        disableSelectionOnClick
        autoHeight
      />
    </Box>
  );
};

export default PaymentDashBoardTable;

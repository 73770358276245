import React, { useEffect, useState } from "react";
import { Typography, Chip, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom"; // To handle navigation
import AdminDrawer from "../../components/AdminDrawer/AdminDrawer";
import ReportsTable from "../../components/DataTables/ReportsTables/ReportsTable";
import BreadcrumbsComponent from "../../components/ResuableComponents/BreadCrumb";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const columns = [
  { field: "name", headerName: "Name", flex: 1 },
  { field: "phoneNumber", headerName: "Phone Number", flex: 1 },
  { field: "totalOrders", headerName: "Total Orders", flex: 1 },
  { field: "deliveredCount", headerName: "Delivered Count", flex: 1 },
  { field: "rejectedCount", headerName: "Rejected Count", flex: 1 },
  { field: "ongoingCount", headerName: "Ongoing Count", flex: 1 },
  { field: "totalDeliveredFee", headerName: "Total Delivered Fee", flex: 1 },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    sortable: true,
    renderCell: (params) => (
      <Chip
        label={params.value}
        color={params.value === "Active" ? "success" : "warning"}
      />
    ),
  },
];

const DeliveryReports = () => {
  const [reportData, setReportData] = useState([]);
  const navigate = useNavigate(); // Hook to handle navigation

  useEffect(() => {
    // Fetch data from the API
    fetch("https://admin.fesste.com/api/dpPayouts")
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // Transform the API data into the format needed for the table
          const transformedData = data.data.map((dp) => ({
            id: dp.deliveryPartnerId, // Use a unique id for each row
            name: dp.name,
            phoneNumber: dp.phoneNumber,
            totalOrders: dp.totalOrders,
            deliveredCount: dp.deliveredCount,
            rejectedCount: dp.rejectedCount,
            ongoingCount: dp.ongoingCount,
            totalDeliveredFee: `₹${dp.totalDeliveredFee}`,
            status: dp.totalOrders > 0 ? "Active" : "InActive", // Set status based on the number of total orders
          }));
          setReportData(transformedData);
        }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, []);

  return (
    <div style={{ backgroundColor: "#f2f2f2", height: "100vh" }}>
      <AdminDrawer>
        <div style={{ padding: "16px", display: "flex", alignItems: "center" }}>
          <IconButton
            onClick={() => navigate(-1)}
            sx={{
              marginRight: "10px",
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <BreadcrumbsComponent />
        </div>
        <div style={{ padding: "16px" }}>
          <Typography variant="h4" fontWeight="bolder">
            Delivery Partner Reports
          </Typography>
          <ReportsTable rows={reportData} columns={columns} />
        </div>
      </AdminDrawer>
    </div>
  );
};

export default DeliveryReports;

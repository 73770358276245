import React, { useState, useEffect } from 'react';
import { TextField, Typography, Grid, Button, Box, MenuItem } from '@mui/material';


const DeliveryPartnerForm = ({ onSubmit, initialValues }) => {


    const [formData, setFormData] = useState({
        fullName: '', // Maps to ownerName
        email: '', // Maps to emailID
        phoneNumber: '', // Maps to mobileNo
        secondaryPhone: '', // Additional field
        dob: '', // Additional field

        // Current address fields
        currentAddressLine1: '', // Maps to street
        currentCity: '', // Maps to city
        currentState: '', // Maps to state
        currentPostCode: '', // Maps to postalCode

        // Permanent address fields
        parmanentAddressLine1: '',
        parmanentCity: '',
        parmanentState: '',
        parmanentPostCode: '',

        vehicleType: '', // Additional field
        vehicleRegistrationNumber: '', // Additional field

        profileImage: null,
        aadharFront: null,
        aadharBack: null,
        panCard: null,
        drivingLicenseFront: null,
        drivingLicenseBack: null,
        vehicleFrontDoc: null,
        vehicleBackDoc: null,

        address: '', // Retained field
        serviceRange: '', // Retained field
        waitTime: '', // Retained field
        aboutUs: '', // Retained field
        specializedAreas: [], // Retained field
        flowerFilter: [], // Retained field
        panNo: '', // Retained field
        gstNo: '', // Retained field
        fssaiNo: '', // Retained field
        bussinessImage: null, // Retained field
        panImage: null, // Retained field
        gstImage: null, // Retained field
        fssaiImage: null, // Retained field
        ownerPhoto: null, // Retained field
        bussinessVideo: null, // Retained field
    });

    const [errors, setErrors] = useState({
        panNo: '',
        gstNo: '',
        fssaiNo: '',
    });

    // useEffect to update formData when initialValues change
    useEffect(() => {
        if (initialValues) {
            setFormData({
                // Mapping incoming data to formData
                fullName: initialValues.fullName || '',
                email: initialValues.email || '',
                phoneNumber: initialValues.phoneNumber || '',
                secondaryPhone: initialValues.secondaryPhone || '',
                dob: initialValues.dob || '',

                // Current address fields
                currentAddressLine1: initialValues.currentAddressLine1  || '',
                currentCity: initialValues.currentCity  || '',
                currentState: initialValues.currentState || '',
                currentPostCode: initialValues.currentPostCode || '',

                // Permanent address fields
                parmanentAddressLine1: initialValues.parmanentAddressLine1 || '',
                parmanentCity: initialValues.parmanentCity || '',
                parmanentState: initialValues.parmanentState || '',
                parmanentPostCode: initialValues.parmanentPostCode || '',

                vehicleType: initialValues.vehicleType || '',
                vehicleRegistrationNumber: initialValues.vehicleRegistrationNumber || '',

                aadharNo: initialValues?.aadharNo,
                panNo:initialValues?.panNo,

                profileImage: initialValues.profileImage || null,
                aadharFront: initialValues.aadharFront || null,
                aadharBack: initialValues.aadharBack || null,
                panCard: initialValues.panCard || null,
                drivingLicenseFront: initialValues.drivingLicenseFront || null,
                drivingLicenseBack: initialValues.drivingLicenseBack || null,
                vehicleFrontDoc: initialValues.vehicleFrontDoc || null,

            });
        }
    }, [initialValues]); // Re-run when initialValues change



    const handleInputChange = (event) => {
        const { name, value, files } = event.target;

        // Check if the input is a file input
        if (files && files.length > 0) {
            // Handle file input
            setFormData((prevData) => ({
                ...prevData,
                [name]: files[0], // Set the File object
            }));
        } else {
            // Validation logic for text inputs
            if (name === 'panNo' && value && !panRegex.test(value)) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    panNo: 'Invalid PAN format. Expected: AAAAA9999A',
                }));

            } else {
                setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
            }

            // Set form data for non-file input
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleVehicleTypeChange = (event) => {
        setFormData({
            ...formData,
            vehicleType: event.target.value,
        });
    };



    const handleFormSubmit = (event) => {
        event.preventDefault();

        // Check if any errors exist before submitting
        if (Object.values(errors).some((error) => error)) {
            alert("Please fix errors before submitting.");
            return;
        }

        onSubmit(formData);
    };

    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/; // PAN: 5 letters, 4 digits, 1 letter


    return (
        <form onSubmit={handleFormSubmit}>
            {/* Full Name, Email, Phone Number, Secondary Phone, and Date of Birth */}
            <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">Full Name</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter Full Name Here"
                        name="fullName"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.fullName}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">Email</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter Email Here"
                        name="email"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.email}
                        onChange={handleInputChange}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">Phone Number</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter Phone Number Here"
                        name="phoneNumber"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.phoneNumber}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">Secondary Phone</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter Secondary Phone Here"
                        name="secondaryPhone"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.secondaryPhone}
                        onChange={handleInputChange}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">Date of Birth</Typography>
                    <TextField
                        fullWidth
                        type="date"
                        name="dob"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.dob}
                        onChange={handleInputChange}
                    />
                </Grid>
            </Grid>


            {/* Current Address Section */}
            <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">Current Address Line 1</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter Current Address Line 1"
                        name="currentAddressLine1"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.currentAddressLine1}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">Current City</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter Current City"
                        name="currentCity"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.currentCity}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">Current State</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter Current State"
                        name="currentState"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.currentState}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">Current Postal Code</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter Current Postal Code"
                        name="currentPostCode"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.currentPostCode}
                        onChange={handleInputChange}
                    />
                </Grid>
            </Grid>

            {/* Permanent Address Section */}
            <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">Permanent Address Line 1</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter Permanent Address Line 1"
                        name="parmanentAddressLine1"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.parmanentAddressLine1}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">Permanent City</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter Permanent City"
                        name="parmanentCity"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.parmanentCity}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">Permanent State</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter Permanent State"
                        name="parmanentState"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.parmanentState}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">Permanent Postal Code</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter Permanent Postal Code"
                        name="parmanentPostCode"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.parmanentPostCode}
                        onChange={handleInputChange}
                    />
                </Grid>
            </Grid>


            {/* PAN, GST, FSSAI, and Owner Photo */}
            <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">PAN Number</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter PAN Number"
                        name="panNo"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        value={formData.panNo}
                        onChange={handleInputChange}
                        error={!!errors.panNo}
                        helperText={errors.panNo}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">Aadhar Number</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter Aadhar Number"
                        name="aadharNo"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        value={formData.aadharNo}
                        onChange={handleInputChange}
                        error={!!errors.aadharNo}
                        helperText={errors.aadharNo}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Typography component="legend">Vehicle Type</Typography>
                    <TextField
                        select
                        fullWidth
                        name="vehicleType"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        value={formData.vehicleType}
                        onChange={handleVehicleTypeChange} // New function for handling change
                        error={!!errors.vehicleType}
                        helperText={errors.vehicleType}
                    >
                        {[
                            { value: "twoWheeler", label: "Two-Wheeler" },
                            { value: "threeWheeler", label: "Three-Wheeler" },
                            { value: "fourWheeler", label: "Four-Wheeler" },
                            { value: "heavyVehicle", label: "Heavy Vehicle" }
                        ].map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Typography component="legend">Vehicle Registration Number</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter Vehicle Registration Number"
                        name="vehicleRegistrationNumber"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        value={formData.vehicleRegistrationNumber}
                        onChange={handleInputChange}
                        error={!!errors.vehicleRegistrationNumber}
                        helperText={errors.vehicleRegistrationNumber}
                    />
                </Grid>

                {/* File Upload Section */}
                <Grid container spacing={2} mb={2}>
                    <Grid item xs={12} sm={6}>
                        <Typography component="legend">Profile Image</Typography>
                        <input
                            type="file"
                            name="profileImage"
                            accept="image/*"
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography component="legend">Aadhar Front</Typography>
                        <input
                            type="file"
                            name="aadharFront"
                            accept="image/*"
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography component="legend">Aadhar Back</Typography>
                        <input
                            type="file"
                            name="aadharBack"
                            accept="image/*"
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography component="legend">PAN Card</Typography>
                        <input
                            type="file"
                            name="panCard"
                            accept="image/*"
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography component="legend">Driving License Front</Typography>
                        <input
                            type="file"
                            name="drivingLicenseFront"
                            accept="image/*"
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography component="legend">Driving License Back</Typography>
                        <input
                            type="file"
                            name="drivingLicenseBack"
                            accept="image/*"
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography component="legend">Vehicle Front Document</Typography>
                        <input
                            type="file"
                            name="vehicleFrontDoc"
                            accept="image/*"
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography component="legend">Vehicle Front Document</Typography>
                        <input
                            type="file"
                            name="vehicleBackDoc"
                            accept="image/*"
                            onChange={handleInputChange}
                        />
                    </Grid>
                </Grid>
            </Grid>

            {/* Submit Button */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: 2 }}
                >
                    {initialValues ? 'Update' : 'Add'}
                </Button>
            </Box>
        </form>
    );
};

export default DeliveryPartnerForm;

// src/api/payoutApi.js

import baseUrl from "../../config";

export const fetchCatererPayout = async (catererId) => {
  try {
    const response = await fetch(`${baseUrl}catererPayout/${catererId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch caterer payout');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching payout:', error);
    return null;
  }
};


// Fetch restaurant payout
export const fetchRestaurantPayout = async (restaurantId) => {
  try {
    const response = await fetch(`${baseUrl}restPayout/${restaurantId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch restaurant payout');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching restaurant payout:', error);
    return null;
  }
};

// Fetch restaurant payout
export const fetchRestaurantPayoutById = async (orderId) => {
  try {
    const response = await fetch(`${baseUrl}restaurantPayout/${orderId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch restaurant payout');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching restaurant payout:', error);
    return null;
  }
};

export const fetchCatererPayouts = async () => {
  const response = await fetch(`${baseUrl}caterer/payout`);
  if (!response.ok) {
    console.error('Error fetching restaurant payout');
  }
  return response.json();
};

// Fetch restaurant payout
export const fetchFessteMartPayout = async () => {
  try {
    const response = await fetch(`${baseUrl}fmpayouts`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch fessteMart payout');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching fessteMart payout:', error);
    return null;
  }
};


// Fetch restaurant payout
export const fetchFessteMartPayoutById = async (fessteMartId) => {
  try {
    const response = await fetch(`${baseUrl}fmpayouts/fessteMart/${fessteMartId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch fessteMart payout by id');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching fessteMart payout by id:', error);
    return null;
  }
};


// Fetch restaurant payout
export const fetchCatererPayoutById = async (payoutId) => {
  try {
    const response = await fetch(`${baseUrl}catPayout/${payoutId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch Caterer payout by id');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching Caterer payout by id:', error);
    return null;
  }
};

// Fetch fm invoice payout
export const fetchFmInvoicePayoutById = async (payoutId) => {
  try {
    const response = await fetch(`${baseUrl}fmpayout/${payoutId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch Caterer payout by id');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching Caterer payout by id:', error);
    return null;
  }
};


// Fetch restaurant payout
export const fetchFessteMartPayoutByIdAndBussinessType = async (fessteMartId, businessType) => {
  try {
    const response = await fetch(`${baseUrl}fmpayouts/fessteMart/${fessteMartId}?mainBusiness=${businessType}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch fessteMart payout by id and business type');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching fessteMart payout by id:', error);
    return null;
  }
};

import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';

const OrderItemsTable = ({ items }) => {

    const formatCurrency = (value) => `₹${Number(value).toFixed(2)}`; // Format to currency (assuming Indian Rupee)

    // Function to calculate total price with add-ons included
    const calculateItemTotal = (item) => {
        const addOnTotal = item.addOns.reduce((sum, addOn) => sum + (addOn.price * addOn.addonQty), 0); // Sum of add-on prices considering their quantity
        return (item.price * item.qty) + addOnTotal; // Total price = (item price * item quantity) + total add-on value
    };

    // Function to calculate gross value including add-ons
    const calculateGrossValue = (item) => {
        const addOnTotal = item.addOns.reduce((sum, addOn) => sum + (addOn.price * addOn.addonQty), 0); // Total add-on value considering quantity
        return (item.price * item.qty) + addOnTotal; // Gross value = (item price * item quantity) + total add-on value
    };

    return (
        <TableContainer component={Paper} sx={{ mb: 3, borderRadius: 2, boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
            <Table>
                <TableHead>
                    <TableRow sx={{ backgroundColor: '#f0f0f0' }}>
                        <TableCell><Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Particulars</Typography></TableCell>
                        <TableCell><Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Qty</Typography></TableCell>
                        <TableCell><Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Price</Typography></TableCell>
                        <TableCell><Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Gross Value</Typography></TableCell>
                        <TableCell><Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Discount</Typography></TableCell>
                        <TableCell><Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Total</Typography></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((item, index) => (
                        <React.Fragment key={index}>
                            {/* Main Item Row */}
                            <TableRow
                                sx={{
                                    backgroundColor: index % 2 === 0 ? '#fafafa' : '#fff',
                                    '&:last-child td, &:last-child th': { border: 0 },
                                }}
                            >
                                <TableCell>{item.particulars}</TableCell>
                                <TableCell>{item.qty}</TableCell>
                                <TableCell>
                                    {formatCurrency(item.price)}
                                    {item.addOns && item.addOns.length > 0 && (
                                        <>
                                            <Typography variant="body2" sx={{ color: '#666', fontSize: '0.85rem', mt: 1 }}>
                                                Add-ons:
                                            </Typography>
                                            {item.addOns.map((addOn, addOnIndex) => (
                                                <Typography key={addOnIndex} variant="body2" sx={{ color: '#666', fontSize: '0.85rem' }}>
                                                    {addOn.name}: {formatCurrency(addOn.price * addOn.addonQty)}
                                                </Typography>
                                            ))}
                                        </>
                                    )}
                                </TableCell>
                                <TableCell>{formatCurrency(calculateGrossValue(item))}</TableCell>
                                <TableCell>{formatCurrency(item.discount)}</TableCell>
                                <TableCell>
                                    <Typography sx={{ fontWeight: 'bold' }}>
                                        {formatCurrency(calculateItemTotal(item))}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default OrderItemsTable;

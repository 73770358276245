import baseUrl from "../../../config";

export const registerFessteMart = async (formData) => {
    try {
        const response = await fetch(`${baseUrl}registerFessteMart`, {
            method: 'POST',
            // Do not set 'Content-Type' explicitly
            body: formData
        });

        if (!response) {
            throw new Error('Failed to register Fesste Mart');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error registering Fesste Mart:', error);
        throw error;
    }
};


export const updateFessteMart = async (formData) => {
    try {
        const response = await fetch(`${baseUrl}updateFessteMartBT`, {
            method: 'PUT',
            // Do not set 'Content-Type' explicitly
            body: formData
        });

        if (!response.ok) {
            throw new Error('Failed to Update Fesste Mart');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error Updating Fesste Mart:', error);
        throw error;
    }
};
export const deleteFessteMart = async (fessteMartId) => {
    const formData = new FormData();
    formData.append('businessType', 'partyHall'); // Add the required business type

    try {
        const response = await fetch(`${baseUrl}deletefmBT/${fessteMartId}`, {
            method: 'POST',
            body: formData // Send the FormData
        });

        if (!response.ok) {
            throw new Error('Failed to delete Fesste Mart');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error deleting Fesste Mart:', error);
        throw error;
    }
};

// Function to delete a Fesste Mart
export const deleteFessteMartLightings = async (fessteMartId) => {
    const formData = new FormData();
    formData.append('businessType', 'lightings'); // Add the required business type

    try {
        const response = await fetch(`${baseUrl}deletefmBT/${fessteMartId}`, {
            method: 'POST',
            body: formData // Send the FormData
        });

        if (!response.ok) {
            throw new Error('Failed to delete Fesste Mart');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error deleting Fesste Mart:', error);
        throw error;
    }
};

// Function to delete a Fesste Mart
export const deleteFessteMartFlowers = async (fessteMartId) => {
    const formData = new FormData();
    formData.append('businessType', 'flowers'); // Add the required business type

    try {
        const response = await fetch(`${baseUrl}deletefmBT/${fessteMartId}`, {
            method: 'POST',
            body: formData // Send the FormData
        });

        if (!response.ok) {
            throw new Error('Failed to delete Fesste Mart');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error deleting Fesste Mart:', error);
        throw error;
    }
};


// Function to delete a Fesste Mart
export const deleteFessteMartMarquee = async (fessteMartId) => {
    const formData = new FormData();
    formData.append('businessType', 'marquee'); // Add the required business type

    try {
        const response = await fetch(`${baseUrl}deletefmBT/${fessteMartId}`, {
            method: 'POST',
            body: formData // Send the FormData
        });

        if (!response.ok) {
            throw new Error('Failed to delete Fesste Mart');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error deleting Fesste Mart:', error);
        throw error;
    }
};



import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AdminDrawer from "../../../components/AdminDrawer/AdminDrawer";
import {
  Typography,
  styled,
  Paper,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Tooltip,
  Chip,
  IconButton,
  Box
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DataTable from "../../../components/DataTables/DataTable";
import CustomSnackbar from "../../../components/CustomeSnackBar/CustomSnackbar";
import { useDialog } from "../../../hooks/useDialog";
import { renderCellWithMore } from "../../../components/ResuableComponents/ViewMoreComponents/ViewMorePopup";
import BASE_URL from "../../../config";
import BreadcrumbsComponent from "../../../components/ResuableComponents/BreadCrumb";

const TableContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  maxHeight: "100vh",
  height: "650px",
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[0],
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1),
    height: "auto",
  },
}));

const MarQueeMenu = () => {
  const { dialogContent, dialogOpen, handleOpenDialog, handleCloseDialog } =
    useDialog();

  const navigate = useNavigate(); // Initialize navigate
  const [data, setData] = useState([]);
  const [formatdata, setFormatData] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  useEffect(() => {
    const fetchMarquee = async () => {
      try {
        const response = await fetch(`${BASE_URL}marqueeSp`);
        const jsonData = await response.json();

        const transformedData = jsonData.map((marquee, index) => ({
          siNo: index + 1,
          id: marquee._id,
          marqueeID: marquee?.marqueeID,
          businessName: marquee?.businessName,
          fessteMartId: marquee?.fessteMart?._id,
          businessContact: marquee?.businessPhoneNumber,
          businessImage: marquee?.businessProfilePhoto[0],
          spName: marquee.serviceProvider?.spName, // Example field
          spPhone: marquee.serviceProvider?.spPhone, // Customize this logic as needed
          address: `${marquee?.address?.addressLine1}, ${marquee?.address?.addressLine2},${marquee?.address?.landmark},${marquee?.address?.city}, ${marquee?.address?.postalCode},${marquee?.address?.state},${marquee?.address?.country}`,
          spEmail: marquee.serviceProvider?.spEmail,
          Status: marquee.availability ? "Available" : "Out of Stock",
          Rating: 4,
          ContactNumber: marquee.serviceProvider?.spPhone,
          CreatedOn: new Date(marquee.createdAt).toLocaleString(), // Format the date as needed
        }));

        setData(transformedData);
      } catch (error) {
        console.error("Error fetching marquees:", error);
      }
    };
    fetchMarquee();
  }, []);

  console.log("formatdata", formatdata);

  const handleViewClick = (partyHallId, id) => {
    navigate(`/view-marquee-menu/${partyHallId}`, { state: { id } });
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const columns = [
    { field: "id", headerName: "ID", minWidth: 10, sortable: false },
    {
      field: "businessImage",
      headerName: "Business Image",
      type: "image",
      minWidth: 160,
      sortable: false,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center", // Horizontally center the image
            alignItems: "center", // Vertically center the image
            width: "100%", // Ensure the div takes full width of the cell
            height: "100%", // Ensure the div takes full height of the cell
          }}
        >
          <img
            src={
              params.value !== "N/A"
                ? params.value
                : "placeholder-image-url.jpg"
            } // Placeholder for broken images
            alt="Item"
            style={{
              width: "100px", // Fixed width
              height: "60px", // Fixed height
              borderRadius: "4px", // Rounded corners
              objectFit: "cover", // Cover the area while maintaining aspect ratio
            }}
          />
        </div>
      ),
    },
    {
      field: "businessName",
      headerName: "Business Name",
      minWidth: 200,
      sortable: true,
      renderCell: (params) => {
        const text = params.value || ""; // Ensure value is a string

        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => handleViewClick(params.row.marqueeID, params.row.id)}
          >
            {text}
          </div>
        );
      },
    },
    {
      field: "businessContact",
      headerName: "Business Contact",
      minWidth: 200,
      sortable: true,
    },
    {
      field: "address",
      headerName: "Address",
      minWidth: 250,
      sortable: true,
      type: "text",
      renderCell: (params) => {
        const text = params.value || ""; // Ensure value is a string
        return renderCellWithMore(text, handleOpenDialog);
      },
    },
    {
      field: "spName",
      headerName: "Owner Name",
      minWidth: 200,
      sortable: true,
    },
    {
      field: "spPhone",
      headerName: "Owner Contact",
      minWidth: 200,
      sortable: true,
    },
    {
      field: "spEmail",
      headerName: "Owner Email",
      minWidth: 200,
      sortable: true,
    },
    {
      field: "Status",
      headerName: "Status",
      minWidth: 200,
      sortable: true,
      renderCell: (params) => (
        <Chip
          label={params.value}
          color={params.value === "Active" ? "success" : "warning"}
        />
      ),
    },

    {
      field: "CreatedOn",
      headerName: "Created On",
      minWidth: 200,
      sortable: true,
    },
    {
      field: "Actions",
      headerName: "Actions",
      minWidth: 160,
      sortable: false,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Tooltip title="View">
            <IconButton
              sx={{ color: "#87CEEB", marginRight: "8px" }}
              onClick={() =>
                handleViewClick(params.row.marqueeID, params.row.id)
              }
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div style={{ backgroundColor: "#f2f2f2" }}>
      <AdminDrawer>
        <Box display="flex">
          <Typography variant="h4" fontWeight="bolder">
            Marquee
          </Typography>
          <Typography ml={3} mt={1}>
            <BreadcrumbsComponent />
          </Typography>
        </Box>

        <TableContainer>
          <DataTable rows={data} columns={columns} />
          <CustomSnackbar
            open={snackbarOpen}
            message={snackbarMessage}
            severity={snackbarSeverity}
            onClose={handleCloseSnackbar}
          />
        </TableContainer>
        <Dialog open={dialogOpen} onClose={handleCloseDialog}>
          <DialogTitle>Details</DialogTitle>
          <DialogContent>
            <Typography>{dialogContent}</Typography>
          </DialogContent>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </Dialog>
      </AdminDrawer>
    </div>
  );
};

export default MarQueeMenu;

import React, { useState, useEffect, useCallback } from "react"; // Import useEffect
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Box,
} from "@mui/material";

const PriceSummaryPayout = ({ paymentData }) => {
  // State to manage input field activation and transaction ID
  // eslint-disable-next-line
  const [isInputActive, setIsInputActive] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [isAmountEntered, setIsAmountEntered] = useState(
    paymentData?.status === "pending"
  );

  const getAmountByStage = useCallback(() => {
    switch (paymentData?.paymentStage) {
      case "initial":
        return paymentData?.initialPayout;
      case "second":
        return paymentData?.secondPayout;
      case "final":
        return paymentData?.finalPayout;
      default:
        return paymentData?.netPayout;
    }
  }, [paymentData]);

  useEffect(() => {
    if (paymentData?.netPayout && paymentData?.status === "pending") {
      setTransactionId(parseFloat(getAmountByStage() || 0).toLocaleString());
    }
  }, [paymentData, getAmountByStage]);

  const handleSubmit = async () => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    if (isAmountEntered) {
      setTransactionId("");
      setIsAmountEntered(false);
    } else {
      try {
        const response = await fetch(
          `https://admin.fesste.com/api/updateCatPayoutTransactionId/${paymentData?.payoutId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              catPayoutTransactionId: transactionId,
              paymentStage: paymentData?.paymentStage,
            }),
          }
        );

        if (response.ok) {
          setSuccess(true);
          setTransactionId("");
          setIsInputActive(false);
        } else {
          throw new Error("Failed to submit transaction ID.");
        }
      } catch (error) {
        console.error("Error submitting transaction ID:", error);
        setError("Failed to submit transaction ID.");
      }
    }
    setLoading(false);
  };

  return (
    <>
      <Box display="flex">
        <Typography>Order ID: {paymentData?.orderID}</Typography>
        <Typography ml={3}>Invoice ID: {paymentData?.invoiceID}</Typography>
      </Box>

      <TableContainer component={Paper} sx={{ mt: 3 }}>
        <Table aria-label="payment breakdown">
          <TableHead>
            <TableRow>
              <TableCell sx={{ border: "1px solid #e0e0e0" }}>
                <strong>Payout Stage: {paymentData?.paymentStage}</strong>
              </TableCell>
              <TableCell sx={{ border: "1px solid #e0e0e0" }}>
                <strong>Amount</strong>
              </TableCell>
              <TableCell sx={{ border: "1px solid #e0e0e0" }}>
                <strong>Payout Status</strong>
              </TableCell>
              <TableCell sx={{ border: "1px solid #e0e0e0" }}>
                <strong>Payout Date</strong>
              </TableCell>
              <TableCell sx={{ border: "1px solid #e0e0e0" }}>
                <strong>Payment Mode</strong>
              </TableCell>
              <TableCell sx={{ border: "1px solid #e0e0e0" }}>
                <strong>Transaction ID</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Total Amount */}
            <TableRow>
              <TableCell sx={{ border: "1px solid #e0e0e0" }}>
                <strong>Net Payout</strong>
              </TableCell>
              <TableCell sx={{ border: "1px solid #e0e0e0" }}>
                ₹{paymentData?.netPayout}
              </TableCell>
              <TableCell sx={{ border: "1px solid #e0e0e0" }}>-</TableCell>
              <TableCell sx={{ border: "1px solid #e0e0e0" }}>-</TableCell>
              <TableCell sx={{ border: "1px solid #e0e0e0" }}>-</TableCell>
              <TableCell sx={{ border: "1px solid #e0e0e0" }}>-</TableCell>
            </TableRow>

            {/* Advance Payment */}
            <TableRow>
              <TableCell sx={{ border: "1px solid #e0e0e0" }}>
                Advance Payout
              </TableCell>
              <TableCell sx={{ border: "1px solid #e0e0e0" }}>
                {`₹${paymentData?.initialPayout}`}
              </TableCell>
              <TableCell sx={{ border: "1px solid #e0e0e0" }}>
                {paymentData?.paymentStage === "initial"
                  ? paymentData?.status
                  : "-"}
              </TableCell>
              <TableCell sx={{ border: "1px solid #e0e0e0" }}>
                {paymentData?.paymentStage === "initial" &&
                paymentData?.payoutDate
                  ? new Date(paymentData.payoutDate).toDateString()
                  : "-"}
              </TableCell>
              <TableCell sx={{ border: "1px solid #e0e0e0" }}>
                {paymentData?.paymentStage === "initial"
                  ? paymentData?.initialPaymentSchedule?.initialPaymentMethod
                  : "-"}
              </TableCell>
              <TableCell sx={{ border: "1px solid #e0e0e0" }}>
                {paymentData?.paymentStage === "initial"
                  ? paymentData?.catPayoutTransactionId
                  : "-"}
              </TableCell>
            </TableRow>

            {/* Second Payment */}
            <TableRow>
              <TableCell sx={{ border: "1px solid #e0e0e0" }}>
                Second Payout
              </TableCell>
              <TableCell sx={{ border: "1px solid #e0e0e0" }}>
                {`₹${paymentData?.secondPayout}`}
              </TableCell>
              <TableCell sx={{ border: "1px solid #e0e0e0" }}>
                {paymentData?.paymentStage === "second"
                  ? paymentData?.status
                  : "-"}
              </TableCell>
              <TableCell sx={{ border: "1px solid #e0e0e0" }}>
                {paymentData?.paymentStage === "second" &&
                paymentData?.secondPayoutDate
                  ? new Date(paymentData.secondPayoutDate).toDateString()
                  : "-"}
              </TableCell>
              <TableCell sx={{ border: "1px solid #e0e0e0" }}>
                {paymentData?.paymentStage === "second"
                  ? paymentData?.secondPaymentSchedule?.secondPaymentMethod
                  : "-"}
              </TableCell>
              <TableCell sx={{ border: "1px solid #e0e0e0" }}>
                {paymentData?.paymentStage === "second"
                  ? paymentData?.catPayoutTransactionId
                  : "-"}
              </TableCell>
            </TableRow>

            {/* Final Payment */}
            <TableRow>
              <TableCell sx={{ border: "1px solid #e0e0e0" }}>
                Final Payout
              </TableCell>
              <TableCell sx={{ border: "1px solid #e0e0e0" }}>
                {`₹${paymentData?.finalPayout}`}
              </TableCell>
              <TableCell sx={{ border: "1px solid #e0e0e0" }}>
                {paymentData?.paymentStage === "final"
                  ? paymentData?.status
                  : "-"}
              </TableCell>
              <TableCell sx={{ border: "1px solid #e0e0e0" }}>
                {paymentData?.paymentStage === "final" &&
                paymentData?.finalPayoutDate
                  ? new Date(paymentData.finalPayoutDate).toDateString()
                  : "-"}
              </TableCell>
              <TableCell sx={{ border: "1px solid #e0e0e0" }}>
                {paymentData?.paymentStage === "final"
                  ? paymentData?.finalPaymentSchedule?.finalPaymentMethod
                  : "-"}
              </TableCell>
              <TableCell sx={{ border: "1px solid #e0e0e0" }}>
                {paymentData?.paymentStage === "final"
                  ? paymentData?.catPayoutTransactionId
                  : "-"}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Typography
        variant="h6"
        color="primary"
        sx={{ fontWeight: "bold", mt: 2 }}
      >
        {paymentData.status === "completed"
          ? "Amount Settled: "
          : "Net Amount to be Settled: "}
        <strong>₹{parseFloat(getAmountByStage() || 0).toLocaleString()}</strong>
      </Typography>
      <Box display="flex" alignItems="center" mt={3}>
        <input
          type="text"
          value={transactionId}
          onChange={(e) => setTransactionId(e.target.value)}
          disabled={
            paymentData.status === "completed" ||
            (isAmountEntered && paymentData.status !== "pending")
          }
          placeholder={
            paymentData.status === "pending"
              ? "Enter Transaction ID"
              : "Amount Settled"
          }
          style={{
            flexGrow: 1,
            padding: "12px",
            borderRadius: "8px",
            marginRight: "12px",
            border: "1px solid grey",
            fontSize: "1rem",
            fontWeight: "500",
            color: "grey",
          }}
        />
        <Button
          variant="contained"
          color="primary"
          sx={{
            padding: "10px 20px",
            fontSize: "1rem",
            fontWeight: "bold",
            textTransform: "none",
          }}
          onClick={handleSubmit}
          disabled={
            paymentData.status === "completed" ||
            (isAmountEntered && paymentData.status !== "pending")
          }
        >
          {loading ? "Submitting..." : isAmountEntered ? "Confirm" : "Submit"}
        </Button>
      </Box>
      {error && <Typography color="error">{error}</Typography>}
      {success && (
        <Typography color="success">
          Transaction ID submitted successfully!
        </Typography>
      )}
    </>
  );
};

export default PriceSummaryPayout;

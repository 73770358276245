import React, { useEffect, useState } from "react";
import { useParams} from "react-router-dom";
import {
  Typography,
  Box,
} from "@mui/material";
import PayoutTable from "../../components/DataTables/PayoutTables/PayoutTable";

const DeliveryPartnerOrderTab = () => {
  const { id } = useParams();
  
  
  const [payoutData, setPayoutData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  // Define the columns with checkbox selection
  const columns = [
    { field: "orderId", headerName: "Order ID", minWidth: 250 },
    { field: "businessType", headerName: "Business Type", minWidth: 200 },
    {
      field: "deliveryFee",
      headerName: "Delivery Fee",
      minWidth: 200,
      formatter: (value) => `₹${value}`,
    },
    { field: "deliveryStatus", headerName: "Delivery Status", minWidth: 200 },
    {
      field: "pickupAddress",
      headerName: "Pickup Address",
      minWidth: 200,
      formatter: (value) =>
        `${value?.addressLine1 || ""}, ${value?.addressLine2 || ""}, ${value?.city || ""
        }, ${value?.state || ""}, ${value?.postalCode || ""}, ${value?.country || ""
        }`,
    },
    { field: "pickedUpTime", headerName: "Pick Up Time", minWidth: 200 },
    {
      field: "deliveryAddress",
      headerName: "Delivery Address",
      minWidth: 200,
      formatter: (value) =>
        `${value?.address1 || ""}, ${value?.address2 || ""}, ${value?.city || ""
        }, ${value?.state || ""}, ${value?.postalCode || ""}, ${value?.country || ""
        }`,
    },

    { field: "deliveryTime", headerName: "Delivery Time", minWidth: 200 },

  ];

  const fetchPayoutDetails = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `https://admin.fesste.com/api/dpPayoutById/${id}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch payout details");
      }
      const result = await response.json();
      if (result.success) {
        const transformedData = result.Payout.map((item) => ({
          ...item,
          id: item._id,
          orderId: item?.orderId || item?.catererOrderId,
          deliverypartnerName: item?.deliveryPartnerId?.fullName,
          businessType: item?.businessType,
          deliveryStatus: item?.status,
          payoutStatus: item?.payoutStatus,
          pickupAddress: `${item?.pickupAddress?.addressLine1 || ""}, ${item?.pickupAddress?.addressLine2 || ""
            }, ${item?.pickupAddress?.city || ""}, ${item?.pickupAddress?.state || ""
            }, ${item?.pickupAddress?.postalCode || ""}, ${item?.pickupAddress?.country || ""
            }`,
          deliveryAddress: `${item?.deliveryAddress?.address1 || ""}, ${item?.deliveryAddress?.address2 || ""
            }, ${item?.deliveryAddress?.city || ""}, ${item?.deliveryAddress?.state || ""
            }, ${item?.deliveryAddress?.postalCode || ""}, ${item?.deliveryAddress?.country || ""
            }`,
          pickedUpTime: item?.pickedUpTime
            ? new Date(item.pickedUpTime).toLocaleString()
            : undefined,
          deliveryTime: item?.deliveryTime
            ? new Date(item.deliveryTime).toLocaleString()
            : undefined,
          transactionId: item?.transactionId || "N/A",
        }));

        setPayoutData(transformedData);



      } else {
        throw new Error(result.message || "Unexpected API response");
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };



  useEffect(() => {
    fetchPayoutDetails();
  }, [id]);

  if (loading) {
    return (
      <div style={{ textAlign: "center", padding: "50px" }}>
        <Typography variant="h6">Loading...</Typography>
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ textAlign: "center", padding: "50px" }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundColor: "#f2f2f2",
        minHeight: "100vh",
        padding: "24px",
      }}
    >
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}
      >
      </div>
      <Box style={{ height: 400, width: "100%" }}>
        <PayoutTable
          rows={payoutData}
          columns={columns}
          getRowId={(row) => row.id}
          pageSize={5}
          disableSelectionOnClick
          autoHeight
        />
      </Box>
    </div>
  );
};

export default DeliveryPartnerOrderTab;

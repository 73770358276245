import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Button, Chip } from "@mui/material";
import AdminDrawer from "../../../../components/AdminDrawer/AdminDrawer";
import PayoutTable from "../../../../components/DataTables/PayoutTables/PayoutTable";
import FessteMartQueriesModal from "../FessteMartQueriesModal";
import {
  fetchSupportByBusinessType,
  resolveTicket,
} from "../../../../api/supports/fessteMartSupportApis";

const PartyHallQueries = () => {
  const navigate = useNavigate();
  const [entityData, setEntityData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(true);
  const [resolveDescription, setResolveDescription] = useState("");
  const [action, setAction] = useState("pending"); // or whatever your default value is

  useEffect(() => {
    const fetchData = async () => {
      try {
        const businessType = "PartyHall"; // You can update this value or pass it dynamically
        const fetchedData = await fetchSupportByBusinessType(businessType);
        if (
          fetchedData &&
          fetchedData.tickets &&
          Array.isArray(fetchedData.tickets)
        ) {
          const transformedData = fetchedData.tickets.map((item, index) => ({
            id: item._id, // Unique ID from the fetched data,
            siNo: index + 1,
            supportId: item.supportId,
            fmId: item?.fessteMart?.fmID,
            ph_Id: item?.businessId?._id,
            partyHallID: item.businessId?.partyHallID,
            businessName: item?.fessteMart?.businessName,
            createdAt: item.createdAt,
            providerType:
              item.fessteMart.businesses[0]?.businessType ||
              item.serviceProvider.spName,
            businessPhoneNumber: item.businessId?.businessPhoneNumber,
            email: item?.businessId?.businessEmail,
            action: item.action,
            status: item.action,
            reason: item.reason,
            query: item.description,
            topic: item.requestType,
            updatedAt: item.updatedAt,
          }));
          setEntityData(transformedData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleViewClick = (ph_Id) => {
    console.log(ph_Id);
    navigate(`/partyhall-details/${ph_Id}`, { state: { id: ph_Id } });
  };

  const handleOpenModal = (row) => {
    setSelectedRow(row);
    setAction(row.action); // Ensure status is set correctly
    setResolveDescription("");
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setResolveDescription("");
    setAction("pending"); // Reset to default or initial value
  };

  const onActionChange = (event) => {
    console.log("Selected action:", event.target.value); // Check selected value
    setAction(event.target.value);
  };

  const handleSubmit = async () => {
    const payload = {
      topic: "payment issue",
      action: action, // Ensure this is the action state
      reason: resolveDescription,
    };

    try {
      const result = await resolveTicket(selectedRow?.supportId, payload);
      console.log("Update Success:", result);

      // Update both 'action' and 'status' in entityData
      const updatedData = entityData.map((entity) =>
        entity.supportId === selectedRow.supportId
          ? {
              ...entity,
              action: action,
              status: action,
              reason: resolveDescription,
            }
          : entity
      );
      setEntityData(updatedData);
      handleCloseModal();
    } catch (error) {
      console.error("Failed to update:", error);
    }
  };

  const columns = [
    { field: "siNo", headerName: "SI No", minWidth: 10, sortable: false },
    { field: "supportId", headerName: "Support Id", minWidth: 200 },
    {
      field: "fmId",
      headerName: "FessteMart Id",
      minWidth: 200,
      renderCell: (params) => (
        <Typography
          fontSize="14px"
          mt={2}
          style={{ cursor: "pointer" }} // Cursor will change to pointer
          onClick={() => handleViewClick(params.row.ph_Id)} // Call the modal on click
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "partyHallID",
      headerName: "Party Hall Id",
      minWidth: 200,
      renderCell: (params) => (
        <Typography
          fontSize="14px"
          mt={2}
          style={{ cursor: "pointer" }} // Cursor will change to pointer
          onClick={() => handleViewClick(params.row.ph_Id)} // Call the modal on click
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "businessName",
      headerName: "Business Name",
      minWidth: 200,
      renderCell: (params) => (
        <Typography
          fontSize="14px"
          mt={2}
          style={{ cursor: "pointer" }} // Cursor will change to pointer
          onClick={() => handleViewClick(params.row.ph_Id)} // Call the modal on click
        >
          {params.value}
        </Typography>
      ),
    },
    { field: "providerType", headerName: "Provider Type", minWidth: 200 },
    { field: "businessPhoneNumber", headerName: "Phone Number", minWidth: 200 },
    { field: "email", headerName: "Email", minWidth: 200 },
    { field: "topic", headerName: "Topic", minWidth: 200 },
    { field: "query", headerName: "Query", minWidth: 200 },
    { field: "reason", headerName: "FeedBack", minWidth: 200 },
    {
      field: "createdAt",
      headerName: "Created At",
      minWidth: 200,
      renderCell: (params) => new Date(params.value).toLocaleString(),
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      minWidth: 200,
      renderCell: (params) => new Date(params.value).toLocaleString(),
    },
    {
      field: "status",
      headerName: "Action Taken",
      minWidth: 200,
      renderCell: (params) => {
        let color;
        switch (params.value) {
          case "open":
            color = "warning";
            break;
          case "resolved":
            color = "success";
            break;
          case "inProgress":
            color = "primary";
            break;
          case "closed":
            color = "default";
            break;
          default:
            color = "default";
            break;
        }

        return <Chip label={params.value} color={color} />;
      },
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 200,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="success"
          onClick={() => handleOpenModal(params.row)}
        >
          <Typography fontSize="12px" textTransform="none" fontWeight="bold">
            View Details
          </Typography>
        </Button>
      ),
    },
  ];

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ backgroundColor: "#f2f2f2", height: "100vh" }}>
      <AdminDrawer>
        <Typography variant="h4" fontWeight="bolder">
          Party Hall Queries
        </Typography>
        <PayoutTable rows={entityData} columns={columns} />
      </AdminDrawer>

      <FessteMartQueriesModal
        open={openModal}
        onClose={handleCloseModal}
        selectedRow={selectedRow}
        action={action}
        onActionChange={onActionChange} // Pass the function
        onSubmit={handleSubmit}
        setAction={setAction}
        resolveDescription={resolveDescription}
        setResolveDescription={setResolveDescription}
      />
    </div>
  );
};

export default PartyHallQueries;

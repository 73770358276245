import React, { useState, useEffect } from 'react';
import {
    Modal, Box, Typography, TextField, MenuItem, Grid, Button, IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import BASE_URL from '../../../config';
import CustomSnackbar from '../../../components/CustomeSnackBar/CustomSnackbar';

const CustomerQueriesDetailModal = ({ open, onClose, selectedRow, action, onActionChange, onSubmit, messageContent, handleSnackbarClose,
    setMessageContent, onMessageSubmit }) => {

    const [conversation, setConversation] = useState([]);
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Default to 'success'


    const supportTicketId = selectedRow?.id;

    // Fetch conversation messages on modal open
    useEffect(() => {
        if (open && supportTicketId) {
            fetchConversation();
        }
    }, [open, supportTicketId]);

    // Fetch conversation API call
    const fetchConversation = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${BASE_URL}convo/${supportTicketId}`);
            const data = await response.json();
            setConversation(data.messages || []);
        } catch (error) {
            console.error('Error fetching conversation:', error);
        } finally {
            setLoading(false);
        }
    };

    // Handle sending a message
    const handleSendMessage = async () => {
        if (messageContent.trim()) {
            setLoading(true);

            const accessToken = localStorage.getItem('accessToken');

            if (!accessToken) {
                setSnackbarMessage('User is not authenticated');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                return;
            }
            try {
                const response = await fetch(`${BASE_URL}usersupport/${supportTicketId}/reply`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`, // Pass the access token in the Authorization header

                    },
                    body: JSON.stringify({
                        messageContent,
                    }),
                });
                if (response.ok) {
                    setMessageContent('');
                    fetchConversation(); // Re-fetch conversation after message is sent
                    onMessageSubmit();  // Call the message submit handler
                } else {
                    console.error('Message send failed');
                }
            } catch (error) {
                console.error('Error sending message:', error);
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={{ ...modalStyle }}>
                {/* Close Icon */}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                >
                    <CloseIcon />
                </IconButton>

                <Typography id="modal-title" variant="h6" component="h2" fontWeight="bold" mb={4} gutterBottom>
                    Customer Details
                </Typography>

                {selectedRow && (
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Typography component="legend">User Name</Typography>
                            <TextField
                                fullWidth
                                placeholder="Enter User Name Here"
                                value={selectedRow.userName}
                                variant="outlined"
                                size="small"
                                sx={{ marginBottom: 2, marginTop: 1 }}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ readOnly: true }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography component="legend">Phone Number</Typography>
                            <TextField
                                fullWidth
                                placeholder="Enter Phone Number Here"
                                value={selectedRow.phoneNumber}
                                variant="outlined"
                                size="small"
                                sx={{ marginBottom: 2, marginTop: 1 }}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ readOnly: true }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography component="legend">Email</Typography>
                            <TextField
                                fullWidth
                                placeholder="Enter Email Here"
                                value={selectedRow.email}
                                variant="outlined"
                                size="small"
                                sx={{ marginBottom: 2, marginTop: 1 }}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ readOnly: true }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography component="legend">Action</Typography>
                            <TextField
                                select
                                fullWidth
                                value={action}
                                onChange={onActionChange}
                                variant="outlined"
                                size="small"
                                sx={{ marginBottom: 2, marginTop: 1 }}
                                InputLabelProps={{ shrink: true }}
                            >
                                <MenuItem value="open">Open</MenuItem>
                                <MenuItem value="closed">Closed</MenuItem>
                                <MenuItem value="resolved">Resolved</MenuItem>
                                <MenuItem value="in-progress">In Progress</MenuItem>
                            </TextField>
                        </Grid>

                        {/* Chatbox for conversation */}
                        <Grid item xs={12}>
                            <Typography component="legend" fontWeight="bold" mt={2} mb={1}>
                                Conversation
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column-reverse',
                                    gap: 1.5,
                                    height: '300px',
                                    overflowY: 'auto',
                                    padding: 2,
                                    border: '1px solid #e0e0e0',
                                    borderRadius: 2,
                                    background: 'linear-gradient(135deg, #f3f3f3, #eaeaea)',
                                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                }}
                            >
                                {loading ? (
                                    <Typography variant="body2" color="text.secondary" textAlign="center">
                                        Loading conversation...
                                    </Typography>
                                ) : conversation.length > 0 ? (
                                    conversation.map((message, index) => (
                                        <Box
                                            key={index}
                                            sx={{
                                                alignSelf: message.senderRole === 'customer' ? 'flex-start' : 'flex-end',
                                                maxWidth: '75%',
                                                padding: 1.5,
                                                borderRadius: 2,
                                                backgroundColor: message.senderRole === 'customer' ? '#e6f7ff' : '#ffe6ea',
                                                margin: '6px 0',
                                                boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.08)',
                                                transition: 'transform 0.2s ease-in-out',
                                                '&:hover': {
                                                    transform: 'scale(1.02)',
                                                },
                                            }}
                                        >
                                            <Typography
                                                variant="body1"
                                                sx={{ fontWeight: 'bold', color: '#333', marginBottom: 0.5 }}
                                            >
                                                {message?.sender?.fullName}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 0.5 }}>
                                                {message?.messageContent}
                                            </Typography>
                                            <Typography variant="caption" color="text.secondary">
                                                {new Date(message?.createdAt).toLocaleString()}
                                            </Typography>
                                        </Box>
                                    ))
                                ) : (
                                    <Typography variant="body2" color="text.secondary" textAlign="center">
                                        No conversation available.
                                    </Typography>
                                )}
                            </Box>
                        </Grid>
                        {/* Input to reply */}
                        <Grid item xs={10}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                value={messageContent}
                                onChange={(e) => setMessageContent(e.target.value)} // Handle message input change
                                placeholder="Type your message here"
                                sx={{ marginBottom: 2 }}
                            />
                        </Grid>

                        {/* Send Button */}
                        <Grid item xs={2} textAlign="right">
                            <Button variant="contained" color="success" onClick={handleSendMessage}>
                                Send
                            </Button>
                        </Grid>

                        <Grid item xs={12} textAlign="right">
                            <Button variant="contained" color="primary" onClick={onSubmit}>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                )}
                <CustomSnackbar
                    open={snackbarOpen}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                    onClose={handleSnackbarClose}
                />
            </Box>
        </Modal>
    );
};

// Modal styling
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
};

export default CustomerQueriesDetailModal;

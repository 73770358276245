import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Button, Chip, Box } from "@mui/material";
import AdminDrawer from "../../../components/AdminDrawer/AdminDrawer";
import PayoutTable from "../../../components/DataTables/PayoutTables/PayoutTable";
import CatererQueriesModal from "./CaterersQueriesModal";
import { fetchCatererSupportDetails } from "../../../api/supports/catererSupportApis";
import BreadcrumbsComponent from "../../../components/ResuableComponents/BreadCrumb";
import { resolveTicketCaterers } from "../../../api/supports/catererSupportApis";

const CatererQueries = () => {
  const navigate = useNavigate();
  const [entityData, setEntityData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [action, setAction] = useState("");
  const [loading, setLoading] = useState(true);
  const [resolveDescription, setResolveDescription] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedData = await fetchCatererSupportDetails();
        if (fetchedData && Array.isArray(fetchedData.data)) {
          const transformedData = fetchedData.data.map((item, index) => ({
            id: item._id, // Unique ID from the fetched data
            siNo: index + 1,
            supportId: item.supportId,
            createdAt: item.createdAt, // Include createdAt field
            providerType: item.providerType, // Include providerType field
            query: item.query, // Include query field
            status: item.action, // Include status field
            topic: item.topic, // Include topic field
            updatedAt: item.updatedAt, // Include updatedAt field
            catId: item?.caterer?._id,
            catererId: item?.caterer?.catID,
            catererName: item?.caterer?.businessName,
            catererContact: item?.caterer?.businessPhoneNumber,
            catererEmail: item?.caterer?.businessEmail,
            reason: item.reason,
          }));
          setEntityData(transformedData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  console.log("entityData", entityData);

  if (loading) {
    return <div>Loading...</div>; // Placeholder while loading
  }

  const handleViewClick = (catId) => {
    navigate(`/caterer-details/${catId}`, {
      state: { catererId : catId }, // Passing both catererId and serviceProviderId
    });
  };

  const handleOpenModal = (row) => {
    setSelectedRow(row);
    setOpenModal(true);
    setAction("");
    setResolveDescription("");
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setAction("");
  };

  const handleActionChange = (event) => {
    setAction(event.target.value);
  };
  const handleSubmit = async () => {
    const payload = {
      topic: "payment issue",
      action: action, // Ensure this is the action state
      reason: resolveDescription,
    };

    try {
      const result = await resolveTicketCaterers(selectedRow?.id, payload);
      console.log("Update Success:", result);

      // Update both 'action' and 'status' in entityData
      const updatedData = entityData.map((entity) =>
        entity.id === selectedRow.id
          ? {
              ...entity,
              action: action,
              status: action,
              reason: resolveDescription,
            }
          : entity
      );
      setEntityData(updatedData);
      handleCloseModal();
    } catch (error) {
      console.error("Failed to update:", error);
    }
  };

  const columns = [
    { field: "siNo", headerName: "SI No", minWidth: 10, sortable: false },
    {
      field: "supportId",
      headerName: "Support Id",
      minWidth: 200,
      renderCell: (params) => (
        <Typography
          fontSize="14px"
          mt={2}
          style={{ cursor: "pointer" }} // Cursor will change to pointer
          onClick={() => handleOpenModal(params.row)} // Call the modal on click
        >
          {params.value}
        </Typography>
      ),
    },
    { field: "catererId", headerName: "Caterer ID", minWidth: 200,
      renderCell: (params) => (
        <Typography
          fontSize="14px"
          mt={2}
          style={{ cursor: "pointer" }} // Cursor will change to pointer
          onClick={() => handleViewClick(params.row.catId)} // Call the modal on click
        >
          {params.value}
        </Typography>
      ), },
    {
      field: "catererName",
      headerName: "Caterer Name",
      minWidth: 200,
      renderCell: (params) => (
        <Typography
          fontSize="14px"
          mt={2}
          style={{ cursor: "pointer" }} // Cursor will change to pointer
          onClick={() => handleViewClick(params.row.catId)} // Call the modal on click
        >
          {params.value}
        </Typography>
      ),
    },

    { field: "catererContact", headerName: "Caterer Contact", minWidth: 200 },
    { field: "providerType", headerName: "Provider Type", minWidth: 200 },
    { field: "query", headerName: "Query", minWidth: 300 },

    { field: "topic", headerName: "Topic", minWidth: 200 },
    {
      field: "createdAt",
      headerName: "Created At",
      minWidth: 200,
      renderCell: (params) => new Date(params.value).toLocaleString(),
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      minWidth: 200,
      renderCell: (params) => new Date(params.value).toLocaleString(),
    },
    {
      field: "status",
      headerName: "Action Taken",
      minWidth: 200,
      renderCell: (params) => {
        let color;
        switch (params.value) {
          case "Pending":
            color = "warning";
            break;
          case "Resolved":
            color = "success";
            break;
          case "open":
            color = "primary";
            break;
          default:
            color = "default";
            break;
        }
        return <Chip label={params.value} color={color} />;
      },
    },
    {
      field: "reason",
      headerName: "Remark",
      minWidth: 200,
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 200,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="success"
          onClick={() => handleOpenModal(params.row)}
        >
          <Typography fontSize="12px" textTransform="none" fontWeight="bold">
            View Details
          </Typography>
        </Button>
      ),
    },
  ];

  return (
    <div style={{ backgroundColor: "#f2f2f2", height: "100vh" }}>
      <AdminDrawer>
        <Box display="flex" mb={2}>
          <Typography variant="h4" fontWeight="bolder" mr={3}>
            Caterer Queries
          </Typography>
          <Box mt={1}>
            <BreadcrumbsComponent />
          </Box>
        </Box>

        <PayoutTable rows={entityData} columns={columns} />
      </AdminDrawer>

      <CatererQueriesModal
        open={openModal}
        onClose={handleCloseModal}
        selectedRow={selectedRow}
        action={action}
        onActionChange={handleActionChange}
        onSubmit={handleSubmit}
        setResolveDescription={setResolveDescription}
      />
    </div>
  );
};

export default CatererQueries;

// src/api/restaurantApi.js
import baseUrl from "../../config";

export const fetchRestaurants = async () => {
  try {
    const response = await fetch(`${baseUrl}restaurants`);
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      throw new Error(data.message || 'Failed to fetch restaurants');
    }
  } catch (error) {
    console.error('Error fetching restaurants:', error);
    throw error;
  }
};

// api/restaurant.js
export const registerRestaurant = async (formDataObj) => {
  try {
      const response = await fetch(`${baseUrl}restaurant/register`, {
          method: 'POST',
          body: formDataObj,
      });

      const responseData = await response.json();

      if (response.ok && responseData?.restaurantId) {
          return { success: true, message: 'Restaurant added successfully!' };
      } else {
          return { success: false, message: responseData?.message || 'Failed to add restaurant.' };
      }
  } catch (error) {
      console.error('Error:', error);
      return { success: false, message: 'An error occurred. Please try again.' };
  }
};

export const fetchRestaurantById = async (restaurantId) => {
  try {
      const response = await fetch(`${baseUrl}restaurants/${restaurantId}`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
          },
      });

      if (!response.ok) {
          throw new Error('Failed to fetch restaurant details');
      }

      const data = await response.json();
      return { success: true, data };
  } catch (error) {
      console.error('Error fetching restaurant details:', error);
      return { success: false, message: 'Failed to fetch restaurant details' };
  }
};


// Function to update caterer details
export const updateRestaurantById = async (restauarantId, formDataObj) => {
  try {
    const response = await fetch(`${baseUrl}restaurant/update/${restauarantId}`, {
      method: "PUT", // Use PUT for updates
      body: formDataObj,
    });

    const responseData = await response.json(); // Parse the JSON response
    if (!response.ok) {
      throw new Error(responseData.message || "Failed to update restaurant");
    }
    return { success: true, data: responseData }; // Return success with the response data
  } catch (error) {
    console.error("Error updating restaurant:", error);
    return { success: false, error: "Network error. Please try again." };
  }
};

export const deleteRestaurantById = async (restaurantId) => {
  const response = await fetch(`${baseUrl}restaurant/delete/${restaurantId}`, {
      method: 'POST',
  });
  if (!response.ok) {
      throw new Error('Failed to delete restaurant');
  }
  return await response.json(); // or return a success message, depending on your API
};


// In your RestaurantApis.js
export const fetchServiceProviderByPhone = async (phoneNumber) => {
  try {
      const response = await fetch(`${baseUrl}serviceProvider/${phoneNumber}`);
      
      const data = await response.json();
      return { success: true, data }; // Adjust based on your API response structure
  } catch (error) {
      console.error('Error fetching service provider:', error);
      return { success: false, message: error.message };
  }
};

export const fetchDeletedRestaurants = async () => {
  try {
    const response = await fetch(`${baseUrl}deletedRestaurants`);
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      throw new Error(data.message || 'Failed to fetch restaurants');
    }
  } catch (error) {
    console.error('Error fetching restaurants:', error);
    throw error;
  }
};

import baseUrl from "../../config";

// createPolicyApi.js
export const createPolicy = async (formData) => {
    try {
      const response = await fetch(`${baseUrl}createPolicy`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (!response.ok) {
        throw new Error('Failed to add policy');
      }
  
      return await response.json(); // Assuming the response returns JSON data
    } catch (error) {
      console.error('Error:', error);
      throw error; // Rethrow the error to be handled in the component
    }
  };
  
  export default createPolicy;


export const fetchPolicies = async () => {
    try {
        const response = await fetch(`${baseUrl}getAllPolicies`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Failed to fetch policies');
        }

        return await response.json();
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled in the component
    }
};

export const fetchPolicyById = async (id) => {
  try {
    const response = await fetch(`${baseUrl}policy/${id}`);
    if (!response.ok) {
      throw new Error(`Error fetching policy: ${response.statusText}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching policy by ID:", error);
    throw error;
  }
};

import React from 'react';
import AddPackages from '../AddMenus/AddPackages';
import AddSubPackages from '../AddMenus/AddSubPackages';
import AddItems from '../AddMenus/AddItems';

const AddAllMenu = () => {
    return (
        <><AddPackages /><AddSubPackages /><AddItems /></>
    )
}

export default AddAllMenu;
import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';

const SlideTransition = (props) => {
  return <Slide {...props} direction="left" />;
};

const CustomSnackbar = ({ open, message, severity, onClose }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={1000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      TransitionComponent={SlideTransition}
      sx={{
        width: 'max-content', // Make it larger
        '& .MuiAlert-root': {
          fontSize: '0.9rem', // Increase the font size
          fontWeight: 'bold',
          padding: '5px', // Increase padding for a larger look
          borderRadius: '8px', // Round the corners more
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', // Add a shadow for a modern look
        },
      }}
    >
      <Alert
        onClose={onClose}
        severity={severity}
        variant="filled"
        sx={{
          width: '100%',
          display: 'flex', 
          alignItems: 'center',
          backgroundColor: severity === 'success' ? '#4caf50' : '#f44336', // Custom background colors
          color: '#fff', // White text for better contrast
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;

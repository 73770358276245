import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Chip,
  CircularProgress,
  Container,
  Tooltip,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Modal,
  Select,
  MenuItem,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import OrdersTable from "../../../components/DataTables/OrdersTables/OrdersTable";
import { fetchRestaurantOrders } from "../../../api/orders/restaurantOrders";
import { fetchRestaurantOrderDetails } from "../../../api/orders/restaurantOrders";
import { useDialog } from "../../../hooks/useDialog";
import { renderCellWithMore } from "../../../components/ResuableComponents/ViewMoreComponents/ViewMorePopup";
import { fetchUserById } from "../../../api/users/usersApi";
import BreadcrumbsComponent from "../../../components/ResuableComponents/BreadCrumb";
import BASE_URL from "../../../config";

const RestaurantOrders = () => {
  const { dialogContent, dialogOpen, handleOpenDialog, handleCloseDialog } =
    useDialog();
  // eslint-disable-next-line
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [orderData, setOrderData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [customRejectReason, setCustomRejectReason] = useState("");
  const [currentOrderId, setCurrentOrderId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [deliveryPartners, setDeliveryPartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState("");
  const [selectedOrderId, setSelectedOrderId] = useState("");

  const getOrders = async () => {
    try {
      const data = await fetchRestaurantOrders();
      setOrderData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrders();
  }, []);

  const acceptOrder = async (id) => {
    try {
      await fetch(`https://admin.fesste.com/api/order/${id}/accept`, {
        method: "POST",
      });
      setSnackbar({
        open: true,
        message: "Order accepted successfully",
        severity: "success",
      });
      getOrders();
    } catch (error) {
      console.error("Failed to accept order:", error);
      setSnackbar({
        open: true,
        message: "Failed to accept order",
        severity: "error",
      });
    }
  };

  const rejectOrderApi = async (id, rejectReason, customRejectReason = "") => {
    await fetch(`https://admin.fesste.com/api/reject_catOrder/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ rejectReason, customRejectReason }),
    });
  };

  const handleRejectOrder = async () => {
    try {
      await rejectOrderApi(currentOrderId, rejectReason, customRejectReason);
      setSnackbar({
        open: true,
        message: "Order rejected successfully",
        severity: "success",
      });
      setRejectDialogOpen(false);
      getOrders();
    } catch (error) {
      console.error("Failed to reject order:", error);
      setSnackbar({
        open: true,
        message: "Failed to reject order",
        severity: "error",
      });
    }
  };

  const openRejectDialog = (orderId) => {
    setCurrentOrderId(orderId);
    setRejectDialogOpen(true);
  };

  const handleViewDetails = async (orderId) => {
    try {
      const data = await fetchRestaurantOrderDetails(orderId); // Fetch order details
      navigate(`/restaurant-order-invoice/${orderId}`, {
        state: { orderData: data },
      }); // Pass data using state
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  const handleViewUser = async (row) => {
    try {
      const customerDetails = await fetchUserById(row.userId); // Correct the field to 'userId'
      navigate(`/user-details/${row.userId}`, { state: customerDetails }); // Use the proper userId for navigation
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const handleOpenModal = async (orderId) => {
    setSelectedOrderId(orderId);
    setOpenModal(true);

    // Fetch delivery partners from the API
    try {
      const response = await fetch(`${BASE_URL}getNearByDpForRest/${orderId}`);
      const data = await response.json();
      setDeliveryPartners(data?.data); // Assuming the response is an array of partners
    } catch (error) {
      console.error("Failed to fetch delivery partners:", error);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedPartner("");
  };

  // Function to handle assigning delivery partner
  const handleAssignPartner = async () => {
    if (!selectedPartner) return;

    const requestBody = {
      restOrderId: selectedOrderId,
      deliveryPartnerId: selectedPartner,
    };

    try {
      const response = await fetch(`${BASE_URL}assignRestOrder`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
      const data = await response.json();

      if (data.success && data.data.status === "accepted") {
        // Update order data in state
        setOrderData((prevOrders) =>
          prevOrders.map((order) =>
            order._id === data.data.restOrderId
              ? { ...order, deliveryStatus: "accepted" }
              : order
          )
        );

        setSnackbar({
          open: true,
          message: "Delivery partner assigned successfully",
          severity: "success",
        });
        handleCloseModal(); // Close modal after success
        getOrders(); // Re-fetch data after assignment
      }
    } catch (error) {
      console.error("Failed to assign delivery partner:", error);
      setSnackbar({
        open: true,
        message: "Failed to assign delivery partner",
        severity: "error",
      });
    }
  };

  // Function to handle unassigning the delivery partner
  const handleUnassignOrder = async (id, deliveryPartnerId) => {
    try {
      const response = await fetch(`${BASE_URL}unAssignRestOrder`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          catererOrderId: id,
          deliveryPartnerId: deliveryPartnerId,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json();

      if (responseData.success) {
        // Optimistically update the order data state
        setOrderData((prevOrders) =>
          prevOrders.map((order) =>
            order._id === id
              ? {
                  ...order,
                  deliveryStatus: "unassigned",
                  deliveryPartnerId: null,
                }
              : order
          )
        );

        setSnackbar({
          open: true,
          message: "Delivery partner unassigned successfully",
          severity: "success",
        });
        getOrders(); // Re-fetch data after unassignment
      }
    } catch (error) {
      console.error("Error unassigning delivery partner:", error);
      setSnackbar({
        open: true,
        message: "Failed to unassign delivery partner",
        severity: "error",
      });
    }
  };

  // Define columns for the DataGrid with minWidth instead of flex
  const columns = [
    { field: "siNo", headerName: "ID", minWidth: 10 },
    {
      field: "orderId",
      headerName: "Order ID",
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Typography
            onClick={() => handleViewDetails(params.row.id)}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "14px",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            {params.value} {/* Caterer name displayed here */}
          </Typography>
        );
      },
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Typography
            onClick={() => handleViewUser(params.row)} // Use handleViewUser with the full row
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "14px",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            {params.value}
          </Typography>
        );
      },
    },
    { field: "orderDate", headerName: "Order Date", minWidth: 200 },
    {
      field: "restaurant",
      headerName: "Restaurant Name",
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Typography
            onClick={() => {
              navigate(`/restaurant-details/${params.row.restaurantId}`, {
                state: { restaurantId: params.row.restaurantId },
              });
            }}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "14px",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            {params.value} {/* Restaurant name displayed here */}
          </Typography>
        );
      },
    },
    {
      field: "itemOrdered",
      headerName: "Items Ordered",
      minWidth: 250,
      sortable: true,
      renderCell: (params) => {
        const text = params.value || ""; // Ensure value is a string
        return renderCellWithMore(text, handleOpenDialog);
      },
    },
    { field: "quantity", headerName: "Quantity", minWidth: 200 },
    { field: "rejectReason", headerName: "Reject Reason", minWidth: 200 },
    { field: "deliveryDate", headerName: "Delivered Date", minWidth: 200 },
    {
      field: "shippingAddress",
      headerName: "Delivered Address",
      minWidth: 250,
      sortable: true,
      renderCell: (params) => {
        const text = params.value || ""; // Ensure value is a string
        return renderCellWithMore(text, handleOpenDialog);
      },
    },
    { field: "deliveredBy", headerName: "Delivered By", minWidth: 200 },
    {
      field: "total",
      headerName: "Total(Before Discount)",
      minWidth: 250,
      renderCell: (params) => {
        return (
          <Typography
            onClick={() => handleViewDetails(params.row.id)}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "14px",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            {params.value} {/* Caterer name displayed here */}
          </Typography>
        );
      },
    },
    { field: "couponCodeUsed", headerName: "Coupon Code Used", minWidth: 250 },
    { field: "discountAmount", headerName: "Discount Amount", minWidth: 250 },
    {
      field: "totalAmount",
      headerName: "Total Amount(After Discount)",
      minWidth: 300,
      renderCell: (params) => {
        return (
          <Typography
            onClick={() => handleViewDetails(params.row.id)}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "14px",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            {params.value} {/* Caterer name displayed here */}
          </Typography>
        );
      },
    },
    { field: "paymentStatus", headerName: "Payment Status", minWidth: 300 },
    {
      field: "status",
      headerName: "Order Status",
      minWidth: 200,
      renderCell: (params) => {
        const statusMapping = {
          newOrder: "New Order",
          preparing: "Preparing",
          delivered: "Delivered",
          pickedUp: "Picked-Up",
          orderReady: "Order-Ready",
          Cancelled: "Cancelled",
          Refunded: "Refunded",
          rejected: "Rejected",
        };

        const statusColors = {
          Delivered: "success",
          rejected: "error",
          newOrder: "success",
          preparing: "warning",
          orderReady: "default",
          pickedUp: "primary",
          Cancelled: "default",
          Refunded: "info",
        };

        const label = statusMapping[params.value] || params.value;

        return (
          <Chip label={label} color={statusColors[params.value] || "primary"} />
        );
      },
    },
    {
      field: "acceptOrder",
      headerName: "Order Action",
      minWidth: 260,
      sortable: false,
      renderCell: (params) => (
        <>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => acceptOrder(params.row.id)}
            disabled={
              params.row.status === "accepted" ||
              params.row.status === "orderReady" ||
              params.row.status === "delivered" ||
              params.row.status === "rejected" ||
              params.row.status === "cancelled" ||
              params.row.status === "preparing"
            }
            sx={{
              padding: "4px 8px",
              fontSize: "0.75rem",
              marginRight: "4px",
            }}
          >
            Accept Order
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => openRejectDialog(params.row.id)}
            disabled={
              params.row.status === "accepted" ||
              params.row.status === "orderReady" ||
              params.row.status === "delivered" ||
              params.row.status === "rejected" ||
              params.row.status === "cancelled" ||
              params.row.status === "preparing"
            }
            sx={{
              padding: "4px 8px",
              fontSize: "0.75rem",
            }}
          >
            Reject Order
          </Button>
        </>
      ),
    },
    {
      field: "assignDeliveryPartner",
      headerName: "Assign Delivery Partner",
      minWidth: 220,
      sortable: false,
      renderCell: (params) => (
        <>
          {(params.row.status === "accepted" || params.row.status === "orderReady" || params.row.status === "inProcess" || params.row.paymentStatus === "failed") && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center", // Center horizontally
                alignItems: "center", // Center vertically
                gap: "8px", // Space between buttons
                marginTop: "10px"
              }}
            >
              {/* Assign Delivery Partner Button */}
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => handleOpenModal(params.row.id)}
                disabled={params.row.deliveryStatus === "assigned"}
                sx={{
                  padding: "4px 8px",
                  fontSize: "0.75rem",
                }}
              >
                Assign
              </Button>
    
              {/* Unassign Delivery Partner Button */}
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={() =>
                  handleUnassignOrder(params.row.id, params.row.deliveryPartnerId)
                }
                sx={{
                  padding: "4px 8px",
                  fontSize: "0.75rem",
                }}
              >
                Unassign
              </Button>
            </Box>
          )}
        </>
      ),
    },
    {
      field: "deliverystatus",
      headerName: "Delivery Status",
      minWidth: 200,
      renderCell: (params) => {
        // Map the statuses to labels
        const statusMapping = {
          assigned: "Assigned",
          unassigned: "Unassigned",
        };

        // Define colors for each status
        const statusColors = {
          assigned: "success", // Green for assigned
          unassigned: "default", // Grey for unassigned
        };

        // Get the label and color based on the status
        const label = statusMapping[params.value] || params.value;
        const color = statusColors[params.value] || "primary"; // Default to "primary" if not matched

        return <Chip label={label} color={color} />;
      },
    },
    {
      field: "deliveryPartnerName",
      headerName: "Delivery Partner Name",
      minWidth: 300,
    },
    {
      field: "deliveryPartnerContact",
      headerName: "Delivery Partner Contact",
      minWidth: 300,
    },
    {
      field: "pickUpOtp",
      headerName: "Pick Up OTP",
      minWidth: 300,
    },
    { field: "rejectReason", headerName: "Reject Reason", minWidth: 200 },

    {
      field: "view",
      headerName: "Actions",
      minWidth: 150,
      sortable: false,
      renderCell: (params) => (
        <Tooltip title="View Details">
          <IconButton
            color="default"
            onClick={() => handleViewDetails(params.row.id)}
          >
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  return (
    <div style={{ backgroundColor: "#f2f2f2", height: "100vh" }}>
      <div style={{ padding: "16px" }}>
        <Box display="flex" mb={3}>
          <Typography variant="h4" fontWeight="bolder">
            Restaurant Orders
          </Typography>
          <Box ml={3} mt={1}>
            <BreadcrumbsComponent />
          </Box>
        </Box>
        {loading ? (
          <CircularProgress />
        ) : (
          <Container sx={{ minWidth: "100%" }}>
            <OrdersTable rows={orderData} columns={columns} />
          </Container>
        )}
      </div>
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Details</DialogTitle>
        <DialogContent>
          <Typography>{dialogContent}</Typography>
        </DialogContent>
        <Button onClick={handleCloseDialog} color="primary">
          Close
        </Button>
      </Dialog>
      {/* Reject Order Dialog */}
      <Dialog
        open={rejectDialogOpen}
        onClose={() => setRejectDialogOpen(false)}
      >
        <DialogTitle>Reject Order</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <RadioGroup
              value={rejectReason}
              onChange={(e) => setRejectReason(e.target.value)}
            >
              <FormControlLabel
                value="Unavailable on the selected Date"
                control={<Radio />}
                label="Unavailable on the selected Date"
              />
              <FormControlLabel
                value="Overbooked with existing orders"
                control={<Radio />}
                label="Overbooked with existing orders"
              />
              <FormControlLabel
                value="Menu customization not possible"
                control={<Radio />}
                label="Menu customization not possible"
              />
              <FormControlLabel
                value="Order placed too late"
                control={<Radio />}
                label="Order placed too late"
              />
              <FormControlLabel
                value="Items out of Stock"
                control={<Radio />}
                label="Items out of Stock"
              />
              <FormControlLabel
                value="Other"
                control={<Radio />}
                label="Other"
              />
            </RadioGroup>
          </FormControl>
          {rejectReason === "Other" && (
            <TextField
              label="Custom Reject Reason"
              fullWidth
              margin="normal"
              value={customRejectReason}
              onChange={(e) => setCustomRejectReason(e.target.value)}
            />
          )}
          <Button
            variant="contained"
            color="secondary"
            onClick={handleRejectOrder}
          >
            Confirm Reject
          </Button>
        </DialogContent>
      </Dialog>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            width: 300,
            margin: "auto",
            padding: 2,
            backgroundColor: "white",
            borderRadius: 1,
          }}
        >
          <Typography variant="h6" mb={2}>
            Assign Delivery Partner
          </Typography>
          <FormControl fullWidth>
            <Select
              value={selectedPartner}
              onChange={(e) => setSelectedPartner(e.target.value)}
              displayEmpty
            >
              <MenuItem value="" disabled>
                Select a Delivery Partner
              </MenuItem>
              {deliveryPartners.map((partner) => (
                <MenuItem key={partner._id} value={partner._id}>
                  {partner.fullName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAssignPartner}
            sx={{ marginTop: 2 }}
            disabled={!selectedPartner}
          >
            Assign
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default RestaurantOrders;

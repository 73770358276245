import React from 'react';
import { Grid, Box } from '@mui/material';
import AdminDrawer from '../../components/AdminDrawer/AdminDrawer';
import DashboardStatistics from '../../components/DasboardCards/DashBoardStats';
import DashBoardOrdersReport from '../../components/DasboardCards/DashBoardOrdersReport';
import DashBoardTopRestaurants from '../../components/DasboardCards/DashBoardTopRestaurants';
import DashBoardOrdersCard from '../../components/DasboardCards/DashBoardOrdersCard';
import DashBoardTopUsers from '../../components/DasboardCards/DashBoardTopUsers';

const Dashboard = () => {
  return (
    <div style={{ backgroundColor: '#f2f2f2', width: '100%', overflowX: 'hidden' }}> {/* Prevent horizontal scroll */}
      <AdminDrawer>
        <Box
          component="main"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh', // Ensure full page height
            overflowY: 'auto', // Allow vertical scrolling if needed
            padding: '16px',
          }}
        >
          <Grid container spacing={3}>
            {/* Dashboard Statistics on Top */}
            <Grid item xs={12}>
              <DashboardStatistics />
            </Grid>
            {/* Orders Report */}
            <Grid item xs={12}>
              <DashBoardOrdersReport />
            </Grid>
            {/* Top users, Restaurants, and orders */}
            <Grid item xs={12} md={4}>
              <DashBoardOrdersCard />
            </Grid>
            <Grid item xs={12} md={4}>
              <DashBoardTopRestaurants />
            </Grid>
            <Grid item xs={12} md={4}>
              <DashBoardTopUsers />
            </Grid>
          </Grid>
        </Box>
      </AdminDrawer>
    </div>
  );
};

export default Dashboard;

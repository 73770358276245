import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, Tooltip, Typography, Paper, Button, Container, Skeleton } from '@mui/material';
import DataTable from '../../../components/DataTables/DataTable';
import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import ConfirmDialog from '../../../components/ResuableComponents/ConfirmDialog';
import CustomSnackbar from '../../../components/CustomeSnackBar/CustomSnackbar';
import { deleteSubPackage } from '../../../api/catererPackages/catererSubPackages';
import baseUrl from '../../../config';

const SubPackagesPage = () => {
    const [tableData, setTableData] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedSubPackageId, setSelectedSubPackageId] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [loading, setLoading] = useState(true); // Add loading state
    const navigate = useNavigate();

    const [subPackageOrders, setSubPackageOrders] = useState({});

  // Update the state with the current order when it changes
  const handleOrderChange = (id, newOrder) => {
    setSubPackageOrders((prev) => ({
      ...prev,
      [id]: newOrder,
    }));
  };

  // Update the order on the server when the user clicks "Update"
  const handleUpdateOrder = async (id) => {
    try {
      const response = await fetch(
        `https://admin.fesste.com/api/dashboard/addTopSubPackage/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ subPackagesOrder: subPackageOrders[id] }),
        }
      );

      if (response.ok) {
        console.log("Package order updated successfully");
      } else {
        console.error("Failed to update package order:", response.statusText);
      }
    } catch (error) {
      console.error("Failed to update package order:", error);
    }
  };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${baseUrl}menu/subPackages`);
                if (response.ok) {
                    const data = await response.json();
                    const transformedData = data?.map((subPackageItem, index) => ({
                        id: subPackageItem._id,
                        siNo: index + 1,
                        subPackageName: subPackageItem.subPackageName,
                        subPackageImage: subPackageItem.subPackageImages || 'N/A',
                        packageAllocatedTo: subPackageItem.allocatedTo,
                        packageType: subPackageItem.type || 'N/A',
                        subPackagesOrder: subPackageItem.subPackagesOrder,
                        createdAt: new Date(subPackageItem.createdAt).toLocaleDateString(),
                    }));

                    setTableData(transformedData);
                } else {
                    console.error('Failed to fetch data:', response.statusText);
                    setTableData([]);
                }
            } catch (error) {
                console.error('Failed to fetch data:', error);
                setTableData([]);
            } finally {
                setLoading(false); // Stop loading when data is fetched
            }
        };

        fetchData();
    }, []);


    const handleAddClick = () => {
        navigate('/add-caterer-sub-packages');
    };

    const handleEditClick = (id) => {
        navigate(`/edit-subpackages/${id}`);
    };

    const handleViewClick = (id) => {
        navigate(`/view-subpackage-details/${id}`);
    };

    // const handleDeleteClick = (id) => {
    //     setSelectedSubPackageId(id);
    //     setDialogOpen(true);
    // };

    const handleConfirmDelete = async () => {
        try {
            await deleteSubPackage(selectedSubPackageId);
            setTableData(prevData => prevData.filter(item => item.id !== selectedSubPackageId));
            setSnackbarMessage('SubPackage deleted successfully');
            setSnackbarSeverity('success');
        } catch (error) {
            setSnackbarMessage('Failed to delete subpackage');
            setSnackbarSeverity('error');
            console.error('Failed to delete subpackage:', error);
        } finally {
            setDialogOpen(false);
            setSelectedSubPackageId(null);
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <div style={{ backgroundColor: '#f2f2f2' }}>
            <Typography variant='h4' fontWeight='bolder' gutterBottom>
                SubPackages
            </Typography>
            <Paper elevation={0} sx={{ padding: '14px', backgroundColor: '#fff' }}>
                <Container sx={{ minWidth: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Button
                            variant="contained"
                            color="success"
                            startIcon={<AddIcon />}
                            onClick={handleAddClick}
                        >
                            Add SubPackage
                        </Button>
                    </div>
                    {loading ? (
                        // Skeleton loading shimmer while data is being fetched
                        <>
                            {[...Array(5)].map((_, index) => (
                                <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                                    <Skeleton variant="rectangular" width={100} height={60} style={{ marginRight: '16px' }} />
                                    <Skeleton variant="text" width="30%" />
                                    <Skeleton variant="text" width="20%" style={{ marginLeft: '16px' }} />
                                    <Skeleton variant="text" width="20%" style={{ marginLeft: '16px' }} />
                                </div>
                            ))}
                        </>
                    ) : (
                        <DataTable
                            rows={tableData}
                            columns={[
                                { field: 'siNo', headerName: 'ID', minWidth: 10, sortable: true },
                                {
                                    field: 'subPackageImage',
                                    headerName: 'Sub Package Image',
                                    flex: 1,
                                    sortable: false,
                                    minWidth: 150,
                                    renderCell: (params) => (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '100%',
                                                height: '100%',
                                            }}
                                        >
                                            <img
                                                src={params.value !== 'N/A' ? params.value : 'placeholder-image-url.jpg'}
                                                alt="Item"
                                                style={{
                                                    width: '100px',
                                                    height: '60px',
                                                    borderRadius: '4px',
                                                    objectFit: 'cover',
                                                }}
                                            />
                                        </div>
                                    ),
                                },
                                {
                                    field: 'subPackageName', headerName: 'Sub Package Name', flex: 1, sortable: true, renderCell: (params) => (
                                        <div
                                            onClick={() => handleViewClick(params.row.id)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {params.value}
                                        </div>
                                    )
                                },
                                { field: 'packageAllocatedTo', headerName: 'Allocated To', flex: 1, sortable: true },
                                { field: 'packageType', headerName: 'Package Type', flex: 1, sortable: true },
                                {
                                    field: 'subPackagesOrder',
                                    headerName: 'SubPackage Order',
                                    minWidth: 200,
                                    flex: 1,
                                    sortable: true,
                                    renderCell: (params) => {
                                        const order = subPackageOrders[params.row.id] || params.value;
                        
                                        return (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: '8px',
                                                    width: '100%',
                                                    height: '100%',
                                                }}
                                            >
                                                <input
                                                    type="number"
                                                    value={order}
                                                    onChange={(e) => handleOrderChange(params.row.id, e.target.value)}
                                                    style={{
                                                        width: '80px',
                                                        padding: '4px',
                                                        textAlign: 'center',
                                                        borderRadius: '4px',
                                                        border: '1px solid #ccc',
                                                    }}
                                                />
                                                <Button
                                                    variant="contained"
                                                    size="small"
                                                    onClick={() => handleUpdateOrder(params.row.id)}
                                                    style={{
                                                        minWidth: '60px',
                                                        fontSize: '12px',
                                                        textTransform: 'capitalize',
                                                    }}
                                                >
                                                    Update
                                                </Button>
                                            </div>
                                        );
                                    },
                                },
                                { field: 'createdAt', headerName: 'Created At', flex: 1, sortable: true },
                                {
                                    field: 'Actions',
                                    headerName: 'Actions',
                                    minWidth: 160,
                                    sortable: false,
                                    renderCell: (params) => (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '100%',
                                                height: '100%',
                                            }}
                                        >
                                            <Tooltip title="View">
                                                <IconButton sx={{ color: '#87CEEB', marginRight: '8px' }} onClick={() => handleViewClick(params.row.id)}>
                                                    <VisibilityIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Edit">
                                                <IconButton color="success" sx={{ marginRight: '8px' }} onClick={() => handleEditClick(params.row.id)}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                            {/* <Tooltip title="Delete">
                                                <IconButton color="error" onClick={() => handleDeleteClick(params.row.id)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip> */}
                                        </div>
                                    ),
                                },
                            ]}
                        />
                    )}
                </Container>
            </Paper>
            <ConfirmDialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                onConfirm={handleConfirmDelete}
                title="Delete SubPackage"
                content="Are you sure you want to delete this subpackage? This action cannot be undone."
            />
            <CustomSnackbar
                open={snackbarOpen}
                message={snackbarMessage}
                severity={snackbarSeverity}
                onClose={handleSnackbarClose}
            />
        </div>
    );
};

export default SubPackagesPage;

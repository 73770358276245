import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AdminDrawer from "../../../components/AdminDrawer/AdminDrawer";
import {
  Typography,
  styled,
  Paper,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Tooltip,
  Chip,
  IconButton,
  Box
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DataTable from "../../../components/DataTables/DataTable";
import CustomSnackbar from "../../../components/CustomeSnackBar/CustomSnackbar";
import { useDialog } from "../../../hooks/useDialog";
import { renderCellWithMore } from "../../../components/ResuableComponents/ViewMoreComponents/ViewMorePopup";
import BreadcrumbsComponent from "../../../components/ResuableComponents/BreadCrumb";

const TableContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  maxHeight: "100vh",
  height: "650px",
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[0],
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1),
    height: "auto",
  },
}));

const PartyHallMenu = () => {
  const { dialogContent, dialogOpen, handleOpenDialog, handleCloseDialog } =
    useDialog();

  const navigate = useNavigate(); // Initialize navigate
  const [formatdata, setFormatData] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [flowersData, setFlowersData] = useState([]);
  const [loading, setLoading] = useState(true);



  useEffect(() => {
    // Fetch flower data from the API
    const fetchFlowers = async () => {
      try {
        const response = await fetch(
          "https://admin.fesste.com/api/flowerSp"
        );
        const data = await response.json();
        if (response.ok) {
          const formattedData = data.map((item, index) => ({
            id: index + 1,
            flowersId: item?._id,
            flowID: item?.flowersID,
            Name: item.serviceProvider?.spName,
            Email: item.serviceProvider?.spEmail,
            businessImage: item?.businessProfilePhoto[0],
            Contact: item.serviceProvider?.spPhone,
            address: `${item?.address?.addressLine1}, ${item?.address?.addressLine2},${item?.address?.landmark},${item?.address?.city}, ${item?.address?.postalCode},${item?.address?.state},${item?.address?.country}`,
            fmID: item.fessteMart?.fmID,
            fessteMartId: item.fessteMart?._id,
            BusinessName: item.businessName,
            Status: item.availability ? "Active" : "In-Active",
            BusinessPhone: item.businessPhoneNumber,
            CreatedOn: new Date(item.createdAt).toLocaleString(),
          }));
          setFlowersData(formattedData);
        } else {
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error fetching flowers data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchFlowers();
  }, []);
  


  console.log('formatdata',formatdata);
  
  const handleViewClick = (fmID, flowersId) => {
    navigate(`/view-flowers-menu/${fmID}`, { state: { flowersId } }); // Navigate to the details page with flowersId
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const columns = [
    {
      field: "flowID",
      headerName: "ID",
      minWidth: 10,
      sortable: false,
      renderCell: (params) => {
        const text = params.value || ""; // Ensure value is a string

        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              handleViewClick(params.row.fmID, params.row.flowersId)
            }
          >
            {text}
          </div>
        );
      },
    },
    // { field: "fmID", headerName: "FM ID", minWidth: 140, sortable: true },
    {
      field: "businessImage",
      headerName: "Business Image",
      type: "image",
      minWidth: 160,
      sortable: false,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center", // Horizontally center the image
            alignItems: "center", // Vertically center the image
            width: "100%", // Ensure the div takes full width of the cell
            height: "100%", // Ensure the div takes full height of the cell
          }}
        >
          <img
            src={
              params.value !== "N/A"
                ? params.value
                : "placeholder-image-url.jpg"
            } // Placeholder for broken images
            alt="Item"
            style={{
              width: "100px", // Fixed width
              height: "60px", // Fixed height
              borderRadius: "4px", // Rounded corners
              objectFit: "cover", // Cover the area while maintaining aspect ratio
            }}
          />
        </div>
      ),
    },
    {
      field: "BusinessName",
      headerName: "Business Name",
      minWidth: 250,
      sortable: true,
      renderCell: (params) => {
        const text = params.value || ""; // Ensure value is a string

        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              handleViewClick(params.row.fmID, params.row.flowersId)
            }
          >
            {text}
          </div>
        );
      },
    },
    {
      field: "BusinessPhone",
      headerName: "Business Contact",
      minWidth: 200,
      sortable: true,
    },
    { field: "Name", headerName: "Owner Name", minWidth: 250, sortable: true },
    {
      field: "Email",
      headerName: "Owner Email",
      minWidth: 250,
      sortable: true,
    },
    {
      field: "Contact",
      headerName: "Owner Contact",
      minWidth: 200,
      sortable: true,
    },
    {
      field: "address",
      headerName: "Address",
      minWidth: 250,
      sortable: true,
      type: "text",
      renderCell: (params) => {
        const text = params.value || ""; // Ensure value is a string
        return renderCellWithMore(text, handleOpenDialog);
      },
    },
    {
      field: "CreatedOn",
      headerName: "Created On",
      minWidth: 200,
      sortable: true,
    },

    {
      field: "Status",
      headerName: "Status",
      minWidth: 200,
      sortable: true,
      renderCell: (params) => (
        <Chip
          label={params.value}
          color={params.value === "Active" ? "success" : "warning"}
        />
      ),
    },

    {
      field: "Actions",
      headerName: "Actions",
      minWidth: 160,
      sortable: false,
      renderCell: (params) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>

          <Tooltip title="View">
            <IconButton
              sx={{ color: "#87CEEB", marginRight: "8px" }}
              onClick={() =>
                handleViewClick(params.row.fmID, params.row.flowersId)
              }
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
         
        </div>
      ),
    },
  ];

  if (loading) {
    return <Typography variant="h6">Loading...</Typography>;
  }
  

  return (
    <div style={{ backgroundColor: "#f2f2f2" }}>
      <AdminDrawer>
        <Box display="flex">
        <Typography variant="h4" fontWeight="bolder">
          Flowers
        </Typography>
        <Typography ml={3} mt={1}>
          <BreadcrumbsComponent />
        </Typography>
        </Box>
        
        <TableContainer>
        <DataTable rows={flowersData} columns={columns} />
        <CustomSnackbar
            open={snackbarOpen}
            message={snackbarMessage}
            severity={snackbarSeverity}
            onClose={handleCloseSnackbar}
          />
        </TableContainer>
        <Dialog open={dialogOpen} onClose={handleCloseDialog}>
          <DialogTitle>Details</DialogTitle>
          <DialogContent>
            <Typography>{dialogContent}</Typography>
          </DialogContent>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </Dialog>
      </AdminDrawer>
    </div>
  );
};

export default PartyHallMenu;

// src/api//serviceproviders.js

import baseUrl from "../../config";

export const fetchServiceProviders = async () => {
    try {
      const response = await fetch(`${baseUrl}serviceproviders`);
      const data = await response.json();
      if (response.ok) {
        return data?.serviceProviders;
      } else {
        throw new Error(data.message || 'Failed to fetch caterers');
      }
    } catch (error) {
      console.error('Error fetching caterers:', error);
      throw error;
    }
  };
  
import React, { useState } from 'react';
import {
    Box,
    TextField,
    Button,
    MenuItem,
    Grid,
    Typography,
    TextareaAutosize,
} from '@mui/material';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import createPolicy from '../../api/contentManagement/contentManagementApis';
import CustomSnackbar from '../../components/CustomeSnackBar/CustomSnackbar';
import AdminDrawer from '../../components/AdminDrawer/AdminDrawer';
import BreadcrumbsComponent from '../../components/ResuableComponents/BreadCrumb';

const AddPrivacyPolicy = () => {
    const [formData, setFormData] = useState({
        title: '',
        email: '', // Optional: Add if needed
        effectiveDate: dayjs(),
        content: [{ type: 'header', value: '' }],
    });

    // Snackbar state
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    // Effective date validation state
    const [effectiveDateError, setEffectiveDateError] = useState('');

    const handleChange = (index, key, value) => {
        const newContent = [...formData.content];
        newContent[index][key] = value;
        setFormData({ ...formData, content: newContent });
    };

    const handleAddContentBlock = () => {
        setFormData({
            ...formData,
            content: [...formData.content, { type: 'header', value: '' }],
        });
    };

    const handleSubmit = async () => {
        // Validate effective date
        if (formData.effectiveDate.isBefore(dayjs(), 'day')) {
            setEffectiveDateError('Effective date cannot be in the past.');
            return;
        } else {
            setEffectiveDateError('');
        }

        try {
            await createPolicy(formData);
            setSnackbarMessage('Policy added successfully');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
        } catch (error) {
            setSnackbarMessage('Error adding policy: ' + error.message);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <AdminDrawer>
            <BreadcrumbsComponent />
            <Box
                sx={{
                    p: 6,
                    bgcolor: '#ffffff',
                    borderRadius: '8px',
                    boxShadow: 3,
                    maxWidth: 800,
                    mx: 'auto',
                }}
            >
                <Typography variant="h4" gutterBottom textAlign="center" color="#3f51b5" mb={3}>
                    Add Policy
                </Typography>
                <Grid container spacing={2}>
                    {/* Title Field */}
                    <Grid item xs={12} sm={6}>
                        <Typography component="legend">Enter Title</Typography>
                        <TextField
                            fullWidth
                            placeholder="Enter Title Here"
                            value={formData.title}
                            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                            variant="outlined"
                            size="small"
                            sx={{ mb: 2 }}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>

                    {/* Effective Date */}
                    <Grid item xs={12} sm={6}>
                        <Typography component="legend">Effective Date</Typography>
                        <DatePicker
                            selected={formData.effectiveDate.toDate()}
                            onChange={(date) => setFormData({ ...formData, effectiveDate: dayjs(date) })}
                            customInput={
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    error={!!effectiveDateError}
                                    helperText={effectiveDateError} // Show error message if any
                                />
                            }
                        />
                    </Grid>

                    {/* Content Blocks */}
                    {formData.content.map((contentBlock, index) => (
                        <Grid container spacing={2} key={index} sx={{ mt: 2 }}>
                            <Grid item xs={12} sm={4}>
                                <Typography component="legend">Content Type</Typography>
                                <TextField
                                    fullWidth
                                    select
                                    value={contentBlock.type}
                                    onChange={(e) => handleChange(index, 'type', e.target.value)}
                                    variant="outlined"
                                    size="small"
                                    sx={{ mb: 2 }}
                                    InputLabelProps={{ shrink: true }}
                                >
                                    <MenuItem value="header">Header</MenuItem>
                                    <MenuItem value="paragraph">Paragraph</MenuItem>
                                    <MenuItem value="list">List</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <Typography component="legend">Content List</Typography>
                                <TextareaAutosize
                                    minRows={3}
                                    placeholder="Enter content here..."
                                    value={contentBlock.value}
                                    onChange={(e) => handleChange(index, 'value', e.target.value)}
                                    style={{
                                        width: '100%',
                                        padding: '8px',
                                        borderRadius: '4px',
                                        border: '1px solid #ccc',
                                        resize: 'vertical',
                                    }}
                                />
                            </Grid>
                        </Grid>
                    ))}
                </Grid>

                {/* Add Content Block Button */}
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid item xs={12}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleAddContentBlock}
                            size="small"
                            sx={{ mb: 2 }}
                        >
                            Add Content Block
                        </Button>
                    </Grid>

                    <Grid container spacing={2} sx={{ mt: 2 }}>
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleSubmit}
                                    size="small"
                                    sx={{ mb: 2 }}
                                >
                                    Submit Policy
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

                {/* Snackbar for notifications */}
                <CustomSnackbar
                    open={snackbarOpen}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                    onClose={handleSnackbarClose}
                />
            </Box>
        </AdminDrawer>
    );
};

export default AddPrivacyPolicy;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Grid } from '@mui/material';
import AdminDrawer from '../../../../components/AdminDrawer/AdminDrawer';
import StatisticsCard from '../../../../components/AdvancedComponents/PayoutComponents/StatisticsCard';
import PaymentBreakdown from '../../../../components/AdvancedComponents/PayoutComponents/PaymentBreakdown';
import FessteMartPayoutBreakdown from '../../../../components/AdvancedComponents/PayoutComponents/FessteMartPayoutTable/FessteMartPayoutBreakDown';
import PaymentDashBoardTable from '../../../../components/AdvancedComponents/PayoutComponents/PaymentDashBoardTable';
import FessteMartPayoutTable from '../../../../components/AdvancedComponents/PayoutComponents/FessteMartPayoutTable/FessteMartPayoutTable';
import { fetchFessteMartPayoutByIdAndBussinessType } from '../../../../api/payouts/payoutsApi';
import BASE_URL from '../../../../config';
import AdminChatBox from '../../../../components/ResuableComponents/ChatBoxes/CatererChatBox/AdminChatBox';

const MarqueePayoutTab = ({ fessteMartId }) => {
  const { id } = useParams();
  const [payoutData, setPayoutData] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [loading, setLoading] = useState(true);


  const [comments, setComments] = useState([]);

  // Fetch comments on component mount
  const fetchComments = async () => {
    try {
      const response = await fetch(`${BASE_URL}dashboard/getComments/fessteMart_orders`);
      const data = await response.json();
      setComments(data);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  useEffect(() => {
    fetchComments();
  }, []);

  useEffect(() => {
    const loadPayoutData = async () => {
      try {
        const businessType = "Marquee";
        const data = await fetchFessteMartPayoutByIdAndBussinessType(fessteMartId, businessType);
        setPayoutData(data);
      } catch (error) {
        console.error("Error fetching payout data:", error);
        setPayoutData([]);
      } finally {
        setLoading(false);
      }
    };

    loadPayoutData();
  }, [id]);  // Ensure both dependencies are included

  const handleCheckboxChange = (order) => {
    setSelectedOrder(order);
  };

  if (loading) {
    return (
      <div style={{ backgroundColor: '#f2f2f2', height: '100vh' }}>
        <AdminDrawer>
          <div style={{ padding: '24px' }}>
            <Typography variant='h6'>Loading...</Typography>
          </div>
        </AdminDrawer>
      </div>
    );
  }

  // Instead of using an error message, we will show a more user-friendly message with empty columns
  if (!payoutData || payoutData.length === 0) {
    return (
      <div style={{ backgroundColor: '#f2f2f2', height: '100vh' }}>
        <AdminDrawer>
          <Typography variant="h5" gutterBottom>
            Marquee Payout
          </Typography>
          <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>

            <Grid item>
              <Typography variant='h6'>No payout data found</Typography>
            </Grid>
          </Grid>
        </AdminDrawer>
      </div>
    );
  }

  const totalPayout = Array.isArray(payoutData)
        ? payoutData.reduce((acc, payoutDetails) => {
            acc.totalAmount += payoutDetails?.totalInitialPayoutAmt || 0;
            acc.netPayout += payoutDetails?.amount || 0;
            acc.fessteFee += payoutDetails?.fessteFee || 0;
            acc.transactionFee += payoutDetails?.transactionFee || 0;
            return acc;
        }, {
            totalAmount: 0,
            netPayout: 0,
            fessteFee: 0,
            transactionFee: 0,
        })
        : {
            totalAmount: payoutData?.totalInitialPayoutAmt || 0,
            netPayout: payoutData?.amount || 0,
            fessteFee: payoutData?.fessteFee || 0,
            transactionFee: payoutData?.transactionFee || 0,
        };


    console.log('payoutData in party hall tab',payoutData);
    

  return (
    <div style={{ backgroundColor: '#f2f2f2', height: 'max-content' }}>
      <div style={{ padding: '24px' }}>
        <Typography variant='h5' gutterBottom>
          {payoutData[0]?.fessteMartId?.businessName}
        </Typography>
      </div>

      <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={6} md={3}>
            <StatisticsCard
              title="Total Earnings (Till Date)"
              value={`₹${totalPayout?.totalAmount?.toFixed(2)}`}
              background="linear-gradient(135deg, #6a1b9a 0%, #8e24aa 100%)"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatisticsCard
              title="Fesste Fee (Till Date)"
              value={`₹${totalPayout.fessteFee.toFixed(2)}`}
              background="linear-gradient(135deg, #43a047 0%, #66bb6a 100%)"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatisticsCard
              title="Transaction Fee (Till Date)"
              value={`₹${totalPayout?.transactionFee?.toFixed(2)}`}
              background="linear-gradient(135deg, #ffb300 0%, #ffa000 100%)"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatisticsCard
              title="Net Payout (Till Date)"
              value={`₹${totalPayout?.netPayout?.toFixed(2)}`}
              background="linear-gradient(135deg, #1e88e5 0%, #42a5f5 100%)"
            />
          </Grid>
        </Grid>

      { selectedOrder ? (
        <FessteMartPayoutBreakdown selectedOrder={selectedOrder} />
      ) :null}

      {/* Conditionally render either PaymentDashBoardTable or FessteMartPayoutTable */}
    
        <FessteMartPayoutTable
          orders={payoutData}
          selectedOrder={selectedOrder}
          onCheckboxChange={handleCheckboxChange}
        />
    
      {/* Chatbox */}
      <AdminChatBox type={'fessteMart_payouts'} />
    </div>
  );
};

export default MarqueePayoutTab;

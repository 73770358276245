import React from "react";
import { Container, Paper, IconButton, Divider } from "@mui/material";
import { Print, Download, ArrowBack } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import OrderInvoiceHeader from "./OrderInvoiceHeader";
import OrderPriceSummary from "./OrderPriceSummary";
import OrderServiceProviderDetails from "./OrderServiceProviderDetails";

const PayoutInvoice = () => {
  const { state } = useLocation(); // Access passed state
  const { orderData } = state || {}; // Retrieve order data
  const navigate = useNavigate(); // Hook for navigation

  const handlePrint = () => {
    window.print();
  };

  const handleDownload = () => {
    const content = document.getElementById("printable-content");
    const excludedElements = document.querySelectorAll(
      ".exclude-from-download"
    );

    // Hide the elements with the 'exclude-from-download' class
    excludedElements.forEach((element) => {
      element.style.visibility = "hidden"; // Set visibility to hidden instead of display none
    });

    html2canvas(content, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save("payout.pdf");

      // Restore the visibility of the excluded elements
      excludedElements.forEach((element) => {
        element.style.visibility = ""; // Restore visibility
      });
    });
  };

  // Back button handler
  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <Container
      sx={{
        padding: 4,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#f5f5f5",
        position: "relative",
      }}
    >
      {/* Back button */}
      <IconButton
        id="back-button"
        onClick={handleBack}
        sx={{
          position: "absolute",
          top: 16,
          left: 16,
          color: "primary.main",
        }}
      >
        <ArrowBack />
      </IconButton>

      <IconButton
        id="print-button"
        onClick={handlePrint}
        sx={{
          position: "absolute",
          top: 16,
          right: 76,
        }}
      >
        <Print />
      </IconButton>

      <IconButton
        id="download-button"
        onClick={handleDownload}
        sx={{
          position: "absolute",
          color: "green",
          top: 16,
          right: 16,
        }}
      >
        <Download />
      </IconButton>

      <Paper
        id="printable-content"
        sx={{
          padding: 4,
          width: "100%",
          maxWidth: "900px",
          backgroundColor: "#fff",
        }}
      >
        <OrderInvoiceHeader
          invoiceNumber={orderData?.invoiceId}
          invoiceDate={orderData?.payoutDate}
          orderNumber={orderData?.orderID}
          restaurantName={orderData?.caterer?.businessName}
          restaurantAddress={orderData?.catererId?.address}
          restaurantGST={orderData?.caterer?.gstNo}
          transactionId={orderData?.catPayoutTransactionId}
          paymentStage={orderData?.paymentStage}
          payoutDate={orderData?.payoutDate}
        />

        <OrderServiceProviderDetails
          ownerName={orderData?.caterer?.serviceProvider?.spName}
          businessName={orderData?.caterer?.businessName}
          serviceProviderAddress={orderData?.caterer?.address}
          serviceProviderPhone={orderData?.userId?.phoneNumber}
        />

        <OrderPriceSummary
          initialPayment={orderData?.grossAmount}
          gstOnFessteFee={orderData?.gstOnFessteFee}
          transactionFee={orderData?.transactionFee}
          fessteFee={orderData?.fessteFee}
          netPayout={orderData?.netPayout}
          platformFeeGst={orderData?.platformFeeGst}
          packagingCharges={orderData?.packagingCharges}
          gstTotal={orderData?.gst}
          platformFee={orderData?.platformFee}
          deliveryFee={orderData?.deliveryFee}
          tds={orderData?.tds}
          initialPayout={orderData?.initialPayout}
          secondPayout={orderData?.secondPayout}
          finalPayout={orderData?.finalPayout}
          paymentStage={orderData?.paymentStage}
          additionalTissueCharges={orderData?.additionalTissueCharges}
          additionlWBCharges={orderData?.additionlWBCharges}
          buffetCharge={orderData?.buffetCharge}
          payoutWithAdditionalCharges={orderData?.payoutWithAdditionalCharges}
        />
        <Divider sx={{ marginY: 1, borderColor: "#000" }} />
      </Paper>
    </Container>
  );
};

export default PayoutInvoice;

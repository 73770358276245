import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  Typography,
  Paper,
  Grid,
  Box,
  Checkbox,
  ListItemText,
  OutlinedInput,
} from "@mui/material";
import AdminDrawer from "../../../components/AdminDrawer/AdminDrawer";
import Map from "../../../components/Maps/Map";
import {
  registerFessteMart,
  updateFessteMart,
} from "../../../api/servicePartners/fesstemart/fessteMartRegistrationApis";
import { getPartyHallById } from "../../../api/servicePartners/fesstemart/partyhallApis";
import { getLightingsById } from "../../../api/servicePartners/fesstemart/lightingsApi";
import { getFlowersById } from "../../../api/servicePartners/fesstemart/flowersApi";
import { getMarqueeById } from "../../../api/servicePartners/fesstemart/marqueeApi";
import CustomSnackbar from "../../../components/CustomeSnackBar/CustomSnackbar";
import BreadcrumbsComponent from "../../../components/ResuableComponents/BreadCrumb";

const stateCityData = {
  Maharashtra: ["Mumbai", "Pune", "Nagpur"],
  Karnataka: ["Bengaluru", "Mysuru", "Hubli"],
  TamilNadu: ["Chennai", "Coimbatore", "Madurai"],
};

const capitalizeFirstLetter = (word) =>
  word.charAt(0).toUpperCase() + word.slice(1);

const businessTypesOptions = ["partyHall", "flowers", "marquee", "lightings"];

const FessteMartRegistration = () => {
  const { id } = useParams(); // Get the ID from the URL
  const navigate = useNavigate();
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [businessTypes, setBusinessTypes] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [formFields, setFormFields] = useState({
    spName: "",
    spPhone: "",
    spEmail: "",
    deliveryTime: "",
    businessName: "",
    bussinessType: [],
    businessPhoneNumber: "",
    aboutUs: "",
    panNo: "",
    gstNo: "",
    addressStreet: "",
    addressPostalCode: "",
    serviceRange: "",
    waitTime: "",
    ownerPhoto: null,
    panImage: null,
    gstImage: null,
    fssaiImage: null,
    businessVideo: null,
    businessProfilePhoto: null,
  });

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const fetchPartyHallDetails = async () => {
      try {
        // Fetch details using the ID from useParams
        const data = await getPartyHallById(id);

        if (!data) {
          console.error("No data returned from API");
          return;
        }

        // Ensure `businesses` exist before trying to find the business
        const business = data?.fessteMart?.businesses?.find(
          (b) => b.businessId === data.businessId
        );

        setFormFields({
          businessType: business ? business.businessType : "", // Set businessType from found business
          serviceProviderId: data?.serviceProvider?._id,
          fessteMartId: data?.fessteMart?._id,
          spName: data?.serviceProvider?.spName,
          spPhone: data?.serviceProvider?.spPhone,
          spEmail: data?.serviceProvider?.spEmail,
          businessName: data?.businessName || "", // Ensure there's a fallback
          businessPhoneNumber: data?.businessPhoneNumber || "",
          panNo: data?.kyc?.panNo || "", // Adjusted to use kyc structure
          gstNo: data?.kyc?.gstNo || "", // Adjusted to use kyc structure
          addressStreet: data?.address?.street || "", // Correct key from address
          addressPostalCode: data?.address?.postalCode || "", // Correct key from address
          serviceRange: data?.serviceRange || "",
          waitTime: data?.waitTime || "",
          deliveryTime: data?.deliveryTime || "",
          aboutUs: data?.aboutUs || "",
          ownerPhoto: data?.fessteMart?.ownerPhoto || "", // If it's a single image URL
          panImage: data?.kyc?.panImage || "", // Adjusted to use kyc structure
          gstImage: data?.kyc?.gstImage || "", // Adjusted to use kyc structure
          businessProfilePhoto: Array.isArray(
            data?.fessteMart?.businessProfilePhoto
          )
            ? data.fessteMart.businessProfilePhoto[0]
            : data?.fessteMart?.businessProfilePhoto || "",
          businessVideo: Array.isArray(data?.fessteMart?.businessVideo)
            ? data.fessteMart.businessVideo[0]
            : data?.fessteMart?.businessVideo || "",
        });
      } catch (error) {
        console.error("Error fetching party hall or lighting details:", error);
      }
    };

    fetchPartyHallDetails();
  }, [id]);

  useEffect(() => {
    const fetchLightingDetails = async () => {
      try {
        // Fetch details using the ID from useParams
        const data = await getFlowersById(id);

        if (!data) {
          console.error("No data returned from API");
          return;
        }

        // Ensure `businesses` exist before trying to find the business
        const business = data?.fessteMart?.businesses?.find(
          (b) => b.businessId === data.businessId
        );

        setFormFields({
          businessType: business ? business.businessType : "", // Set businessType from found business
          serviceProviderId: data?.serviceProvider?._id,
          fessteMartId: data?.fessteMart?._id,
          spName: data?.serviceProvider?.spName,
          spPhone: data?.serviceProvider?.spPhone,
          spEmail: data?.serviceProvider?.spEmail,
          businessName: data?.businessName || "", // Ensure there's a fallback
          businessPhoneNumber: data?.businessPhoneNumber || "",
          panNo: data?.kyc?.panNo || "", // Adjusted to use kyc structure
          gstNo: data?.kyc?.gstNo || "", // Adjusted to use kyc structure
          addressStreet: data?.address?.street || "", // Correct key from address
          addressPostalCode: data?.address?.postalCode || "", // Correct key from address
          serviceRange: data?.serviceRange || "",
          waitTime: data?.waitTime || "",
          deliveryTime: data?.deliveryTime || "",
          aboutUs: data?.aboutUs || "",
          ownerPhoto: data?.fessteMart?.ownerPhoto || "", // If it's a single image URL
          panImage: data?.kyc?.panImage || "", // Adjusted to use kyc structure
          gstImage: data?.kyc?.gstImage || "", // Adjusted to use kyc structure
          businessProfilePhoto: Array.isArray(
            data?.fessteMart?.businessProfilePhoto
          )
            ? data.fessteMart.businessProfilePhoto[0]
            : data?.fessteMart?.businessProfilePhoto || "",
          businessVideo: Array.isArray(data?.fessteMart?.businessVideo)
            ? data.fessteMart.businessVideo[0]
            : data?.fessteMart?.businessVideo || "",
        });
      } catch (error) {
        console.error("Error fetching party hall or lighting details:", error);
      }
    };

    fetchLightingDetails();
  }, [id]);

  useEffect(() => {
    const fetchLightingDetails = async () => {
      try {
        // Fetch details using the ID from useParams
        const data = await getLightingsById(id);

        if (!data) {
          console.error("No data returned from API");
          return;
        }

        // Ensure `businesses` exist before trying to find the business
        const business = data?.fessteMart?.businesses?.find(
          (b) => b.businessId === data.businessId
        );

        setFormFields({
          businessType: business ? business.businessType : "", // Set businessType from found business
          serviceProviderId: data?.serviceProvider?._id,
          fessteMartId: data?.fessteMart?._id,
          spName: data?.serviceProvider?.spName,
          spPhone: data?.serviceProvider?.spPhone,
          spEmail: data?.serviceProvider?.spEmail,
          businessName: data?.businessName || "", // Ensure there's a fallback
          businessPhoneNumber: data?.businessPhoneNumber || "",
          panNo: data?.kyc?.panNo || "", // Adjusted to use kyc structure
          gstNo: data?.kyc?.gstNo || "", // Adjusted to use kyc structure
          addressStreet: data?.address?.street || "", // Correct key from address
          addressPostalCode: data?.address?.postalCode || "", // Correct key from address
          serviceRange: data?.serviceRange || "",
          waitTime: data?.waitTime || "",
          deliveryTime: data?.deliveryTime || "",
          aboutUs: data?.aboutUs || "",
          ownerPhoto: data?.fessteMart?.ownerPhoto || "", // If it's a single image URL
          panImage: data?.kyc?.panImage || "", // Adjusted to use kyc structure
          gstImage: data?.kyc?.gstImage || "", // Adjusted to use kyc structure
          businessProfilePhoto: Array.isArray(
            data?.fessteMart?.businessProfilePhoto
          )
            ? data.fessteMart.businessProfilePhoto[0]
            : data?.fessteMart?.businessProfilePhoto || "",
          businessVideo: Array.isArray(data?.fessteMart?.businessVideo)
            ? data.fessteMart.businessVideo[0]
            : data?.fessteMart?.businessVideo || "",
        });
      } catch (error) {
        console.error("Error fetching party hall or lighting details:", error);
      }
    };

    fetchLightingDetails();
  }, [id]);

  useEffect(() => {
    const fetchMarqueeDetails = async () => {
      try {
        // Fetch details using the ID from useParams
        const data = await getMarqueeById(id);

        if (!data) {
          console.error("No data returned from API");
          return;
        }

        // Ensure `businesses` exist before trying to find the business
        const business = data?.fessteMart?.businesses?.find(
          (b) => b.businessId === data.businessId
        );

        setFormFields({
          businessType: business ? business.businessType : "", // Set businessType from found business
          serviceProviderId: data?.serviceProvider?._id,
          fessteMartId: data?.fessteMart?._id,
          spName: data?.serviceProvider?.spName,
          spPhone: data?.serviceProvider?.spPhone,
          spEmail: data?.serviceProvider?.spEmail,
          businessName: data?.businessName || "", // Ensure there's a fallback
          businessPhoneNumber: data?.businessPhoneNumber || "",
          panNo: data?.kyc?.panNo || "", // Adjusted to use kyc structure
          gstNo: data?.kyc?.gstNo || "", // Adjusted to use kyc structure
          addressStreet: data?.address?.street || "", // Correct key from address
          addressPostalCode: data?.address?.postalCode || "", // Correct key from address
          serviceRange: data?.serviceRange || "",
          waitTime: data?.waitTime || "",
          deliveryTime: data?.deliveryTime || "",
          aboutUs: data?.aboutUs || "",
          ownerPhoto: data?.fessteMart?.ownerPhoto || "", // If it's a single image URL
          panImage: data?.kyc?.panImage || "", // Adjusted to use kyc structure
          gstImage: data?.kyc?.gstImage || "", // Adjusted to use kyc structure
          businessProfilePhoto: Array.isArray(
            data?.fessteMart?.businessProfilePhoto
          )
            ? data.fessteMart.businessProfilePhoto[0]
            : data?.fessteMart?.businessProfilePhoto || "",
          businessVideo: Array.isArray(data?.fessteMart?.businessVideo)
            ? data.fessteMart.businessVideo[0]
            : data?.fessteMart?.businessVideo || "",
        });
      } catch (error) {
        console.error("Error fetching party hall or lighting details:", error);
      }
    };

    fetchMarqueeDetails();
  }, [id]);

  const handleAddressChange = (address) => {
    setSelectedAddress(address);
  };

  // Handle state change
  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
    setSelectedCity(""); // Reset city when state changes
  };

  // Handle city change
  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };
  const handleBusinessTypesChange = (event) => {
    const { value } = event.target;
    setBusinessTypes(
      typeof value === "string" ? value.split(",") : value // Ensure correct data structure for multiple selections
    );
    console.log("Selected Business Types:", value); // Log selected business types for debugging
  };

  const handleChange = (event) => {
    const { name } = event.target;
    if (event.target.type === 'file') {
        setFormFields({
            ...formFields,
            [name]: event.target.files[0], // Store the first selected file
        });
    } else {
        setFormFields({
            ...formFields,
            [name]: event.target.value,
        });
    }
};


  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();

    // Add fields to formData
    formData.append("panNo", formFields.panNo);
    formData.append("gstNo", formFields.gstNo);

    // Append business types based on whether creating or updating
    if (id) {
      // For updating: Append individual `businessType`
      businessTypes.forEach((type) => {
        formData.append("businessType", type);
      });
    } else {
      // For creating: Append as `businessTypes[]`
      businessTypes.forEach((type) => {
        formData.append(`businessTypes[]`, type);
      });
    }

    // Append other fields
    formData.append("spName", formFields.spName);
    formData.append("spPhone", formFields.spPhone);
    formData.append("spEmail", formFields.spEmail);
    formData.append("businessName", formFields.businessName);
    formData.append("businessPhoneNumber", formFields.businessPhoneNumber);
    formData.append("addressType", "Work");
    formData.append("address[street]", formFields.addressStreet);
    formData.append("address[city]", selectedCity);
    formData.append("address[postalCode]", formFields.addressPostalCode);
    formData.append("serviceRange", formFields.serviceRange);
    formData.append("waitTime", formFields.waitTime);
    formData.append("deliveryTime", formFields.deliveryTime);
    formData.append("aboutUs", formFields.aboutUs);
    formData.append("useParentKyc", true);

    // Append files to FormData
    if (formFields.ownerPhoto instanceof File) {
      formData.append("ownerPhoto", formFields.ownerPhoto);
    }
    if (formFields.panImage instanceof File) {
      formData.append("panImage", formFields.panImage);
    }
    if (formFields.gstImage instanceof File) {
      formData.append("gstImage", formFields.gstImage);
    }
    if (formFields.businessProfilePhoto instanceof File) {
      formData.append("businessProfilePhoto", formFields.businessProfilePhoto);
    }
    if (formFields.businessVideo instanceof File) {
      formData.append("businessVideo", formFields.businessVideo);
    }

    // Only append `serviceProviderId` and `fessteMartId` when updating (i.e., if `id` exists)
    if (id) {
      formData.append("serviceProviderId", formFields.serviceProviderId);
      formData.append("fessteMartId", formFields.fessteMartId);
    }

    try {
      let response;
      if (id) {
        // Check if id exists for update
        response = await updateFessteMart(formData);
      } else {
        response = await registerFessteMart(formData);
      }

      console.log("response", response);

      // Customize the snackbar message and severity based on the response
      if (response.fessteMartId) {
        console.log("response", response);

        setSnackbarSeverity("success");
        setSnackbarMessage(
          `${id ? "Update" : "Registration"} successful: ${
            response.message || ""
          }`
        );
        setTimeout(() => {
          navigate("/party-hall"); // Navigate to home page after snackbar closes
        }, 500);
      }
      if (response.fessteMartId || response.updatedBusiness) {
        console.log("response", response);

        setSnackbarSeverity("success");
        setSnackbarMessage(
          `${id ? "Update" : "Registration"} successful: ${
            response.message || ""
          }`
        );
        setTimeout(() => {
          navigate("/party-hall"); // Navigate to home page after snackbar closes
        }, 500);
      } else {
        setSnackbarSeverity("warning");
        setSnackbarMessage(
          response.error || "Operation succeeded but with warnings"
        );
      }
    } catch (error) {
      setSnackbarSeverity("error");
      setSnackbarMessage(
        `Failed to ${id ? "update" : "register"} Fesste Mart: ${error.message}`
      );
      console.error(`Error during ${id ? "update" : "registration"}:`, error);
    } finally {
      setSnackbarOpen(true); // Show snackbar after API call
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#f2f2f2",
        padding: "20px",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <AdminDrawer>
        <Paper
          elevation={0}
          sx={{
            margin: "0 auto",
            padding: "20px",
            backgroundColor: "white",
            flex: 1, // Allow Paper to expand
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box display="flex" alignItems="center">
            <Typography variant="h5" fontWeight="bold" gutterBottom mb={3}>
              {id ? "Update FessteMart" : "Fesste Mart Registration"}
            </Typography>
            <Typography sx={{mb: 2, ml: 5}}>
            <BreadcrumbsComponent />
            </Typography>
           
          </Box>

          {/* Form Fields */}
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography component="legend">Business Types</Typography>

                  <Select
                    multiple
                    size="small"
                    value={businessTypes}
                    onChange={handleBusinessTypesChange}
                    input={<OutlinedInput placeholder="Business Types" />}
                    renderValue={(selected) =>
                      selected
                        .map((type) => capitalizeFirstLetter(type))
                        .join(", ")
                    } // Capitalize for display
                  >
                    {businessTypesOptions.map((type) => (
                      <MenuItem key={type} value={type}>
                        <Checkbox checked={businessTypes.indexOf(type) > -1} />
                        <ListItemText
                          primary={capitalizeFirstLetter(type)}
                        />{" "}
                        {/* Capitalize for display */}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {/* Owner Name and Mobile No */}
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={6}>
                <Typography component="legend">Owner Name</Typography>
                <TextField
                  fullWidth
                  placeholder="Enter Owner Name Here"
                  name="spName"
                  variant="outlined"
                  value={formFields.spName} // Bind value to formFields.spName
                  onChange={handleChange}
                  size="small"
                  sx={{ marginBottom: 2, marginTop: 1 }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography component="legend">Owner Mobile No</Typography>
                <TextField
                  fullWidth
                  placeholder="Enter Mobile Number Here"
                  name="spPhone"
                  value={formFields.spPhone} // Bind value to formFields.spName
                  variant="outlined"
                  onChange={handleChange}
                  size="small"
                  sx={{ marginBottom: 2, marginTop: 1 }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>

            {/* Email ID and Delivery Time */}
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={6}>
                <Typography component="legend">Email ID</Typography>
                <TextField
                  fullWidth
                  placeholder="Enter Email ID Here"
                  name="spEmail"
                  value={formFields.spEmail}
                  variant="outlined"
                  onChange={handleChange}
                  size="small"
                  sx={{ marginBottom: 2, marginTop: 1 }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography component="legend">
                  Service Range (in km)
                </Typography>
                <TextField
                  fullWidth
                  placeholder="Enter Service Range"
                  name="serviceRange"
                  variant="outlined"
                  value={formFields.serviceRange}
                  onChange={handleChange}
                  size="small"
                  sx={{ marginBottom: 2, marginTop: 1 }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={6}>
                <Typography component="legend">Wait Time (in hours)</Typography>
                <TextField
                  fullWidth
                  placeholder="Enter Wait Time in hours"
                  name="waitTime"
                  variant="outlined"
                  value={formFields.waitTime}
                  type="number" // This will allow only numerical input
                  onChange={handleChange}
                  size="small"
                  sx={{ marginBottom: 2, marginTop: 1 }}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ min: 0 }} // Optional: Prevents negative values
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography component="legend">Delivery (in days)</Typography>
                <TextField
                  fullWidth
                  placeholder="Enter Delivery Time"
                  name="deliveryTime"
                  variant="outlined"
                  value={formFields.deliveryTime}
                  onChange={handleChange}
                  size="small"
                  sx={{ marginBottom: 2, marginTop: 1 }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
            {/* bussiness Name and Contact Number */}
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={6}>
                <Typography component="legend">Bussiness Name</Typography>
                <TextField
                  fullWidth
                  placeholder="Enter Business Name Here"
                  name="businessName"
                  variant="outlined"
                  value={formFields.businessName}
                  onChange={handleChange}
                  size="small"
                  sx={{ marginBottom: 2, marginTop: 1 }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography component="legend">
                  Bussiness Phone Number
                </Typography>
                <TextField
                  fullWidth
                  placeholder="Enter Bussiness Phone Number Here"
                  name="businessPhoneNumber"
                  variant="outlined"
                  value={formFields.businessPhoneNumber}
                  onChange={handleChange}
                  size="small"
                  sx={{ marginBottom: 2, marginTop: 1 }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>

            {/* State and City */}
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={6}>
                <Typography component="legend" sx={{ marginBottom: 1.5 }}>
                  State
                </Typography>
                <FormControl fullWidth variant="outlined" size="small">
                  <Select
                    value={selectedState}
                    onChange={handleStateChange}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select State
                    </MenuItem>
                    {Object.keys(stateCityData).map((state) => (
                      <MenuItem key={state} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography component="legend" sx={{ marginBottom: 1.5 }}>
                  City
                </Typography>
                <FormControl fullWidth variant="outlined" size="small">
                  <Select
                    value={selectedCity}
                    onChange={handleCityChange}
                    displayEmpty
                    disabled={!selectedState}
                  >
                    <MenuItem value="" disabled>
                      Select City
                    </MenuItem>
                    {selectedState &&
                      stateCityData[selectedState].map((city) => (
                        <MenuItem key={city} value={city}>
                          {city}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            {/* Address */}
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} style={{ height: "400px" }}>
                <Map
                  onAddressChange={handleAddressChange}
                  mapContainerStyle={{ height: "100%" }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography component="legend">Address</Typography>
                <TextField
                  disabled
                  fullWidth
                  placeholder="Enter Address"
                  name="address"
                  value={selectedAddress}
                  variant="outlined"
                  size="small"
                  sx={{ marginBottom: 2, marginTop: 1 }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>

            {/* About Us */}
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12}>
                <Typography component="legend">About Us</Typography>
                <TextField
                  fullWidth
                  placeholder="Enter About Us"
                  name="aboutUs"
                  variant="outlined"
                  value={formFields.aboutUs}
                  onChange={handleChange}
                  multiline
                  rows={4}
                  size="small"
                  sx={{ marginBottom: 2, marginTop: 1 }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>

            {/* PAN Number */}
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={6}>
                <Typography component="legend">PAN Number</Typography>
                <TextField
                  fullWidth
                  placeholder="Enter PAN Number Here"
                  name="panNo"
                  variant="outlined"
                  size="small"
                  value={formFields?.panNo}
                  sx={{ marginBottom: 2, marginTop: 1 }}
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                />
              </Grid>
              {/* GST Number */}

              <Grid item xs={12} sm={6}>
                <Typography component="legend">GST Number</Typography>
                <TextField
                  fullWidth
                  placeholder="Enter GST Number Here"
                  name="gstNo"
                  variant="outlined"
                  size="small"
                  value={formFields.gstNo}
                  sx={{ marginBottom: 2, marginTop: 1 }}
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            {/* FSSAI Number */}
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={6}>
                <Typography component="legend">Street</Typography>
                <TextField
                  fullWidth
                  placeholder="Enter Street Number Here"
                  name="addressStreet"
                  variant="outlined"
                  size="small"
                  value={formFields.addressStreet}
                  sx={{ marginBottom: 2, marginTop: 1 }}
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography component="legend">PostalCode</Typography>
                <TextField
                  fullWidth
                  placeholder="Enter Postal Code Here"
                  name="addressPostalCode"
                  variant="outlined"
                  size="small"
                  value={formFields.addressPostalCode}
                  sx={{ marginBottom: 2, marginTop: 1 }}
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            {/* File Uploads */}
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={4}>
                <Typography component="legend">Business Video</Typography>
                <TextField
                  fullWidth
                  name="businessVideo"
                  variant="outlined"
                  type="file" // The type is set to 'file' to allow file upload
                  size="small"
                  accept="video/*" // Restricts the file type to videos
                  onChange={handleChange}
                  sx={{ marginBottom: 2, marginTop: 1 }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <Typography component="legend">
                  Bussiness Profile Photo
                </Typography>
                <TextField
                  fullWidth
                  name="businessProfilePhoto"
                  variant="outlined"
                  type="file"
                  onChange={handleChange}
                  size="small"
                  sx={{ marginBottom: 2, marginTop: 1 }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography component="legend">Owner Photo</Typography>
                <TextField
                  fullWidth
                  name="ownerPhoto"
                  variant="outlined"
                  type="file"
                  onChange={handleChange}
                  size="small"
                  sx={{ marginBottom: 2, marginTop: 1 }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>

            {/* File Uploads for FSSAI, PAN, GST */}
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={4}>
                <Typography component="legend">Upload FSSAI</Typography>
                <TextField
                  fullWidth
                  name="fssaiImage"
                  variant="outlined"
                  type="file"
                  onChange={handleChange}
                  size="small"
                  sx={{ marginBottom: 2, marginTop: 1 }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography component="legend">Upload PAN</Typography>
                <TextField
                  fullWidth
                  name="panImage"
                  variant="outlined"
                  type="file"
                  size="small"
                  onChange={handleChange}
                  sx={{ marginBottom: 2, marginTop: 1 }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography component="legend">Upload GST</Typography>
                <TextField
                  fullWidth
                  name="gstImage"
                  variant="outlined"
                  type="file"
                  onChange={handleChange}
                  size="small"
                  sx={{ marginBottom: 2, marginTop: 1 }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>

            {/* Submit Button */}
            <Box display="flex" justifyContent="flex-end">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
              >
                {id ? "Update" : "Submit"}
              </Button>
            </Box>
          </form>
        </Paper>
        <CustomSnackbar
          open={snackbarOpen}
          message={snackbarMessage}
          severity={snackbarSeverity}
          onClose={handleCloseSnackbar}
        />
      </AdminDrawer>
    </div>
  );
};

export default FessteMartRegistration;

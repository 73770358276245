import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Checkbox, Tooltip, IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PayoutTable from "../../../DataTables/PayoutTables/PayoutTable";
import { fetchFmInvoicePayoutById } from "../../../../api/payouts/payoutsApi";
import { renderCellWithMore } from "../../../ResuableComponents/ViewMoreComponents/ViewMorePopup";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useDialog } from "../../../../hooks/useDialog";

const FessteMartPayoutTable = ({ orders, selectedOrder, onCheckboxChange }) => {
  const { dialogContent, dialogOpen, handleOpenDialog, handleCloseDialog } =
    useDialog();
  const [snackbarOpenClipCard, setSnackbarOpenClipCard] = useState(false);
  // Transform the orders array into a format suitable for the table
  console.log("orders in fesste", orders);
  const navigate = useNavigate();

  const transformedOrders = orders.map((order) => ({
    id: order?._id || "N/A", // Use _id as the unique ID (important for DataGrid)
    orderID: order?.orderId?.orderId || "N/A", // Order ID from the order object
    custName: order?.customerId?.fullName || "N/A", // Customer Name
    deliveryAddress:
      `${order?.customerId?.addresses?.[0]?.address1}, ${order?.customerId?.addresses?.[0]?.address2}` ||
      "N/A", // Delivery Address
    orderDate: order?.payoutDate
      ? new Date(order?.payoutDate).toLocaleDateString()
      : "N/A", // Delivery Date (using payoutDate)
    bookDate: order?.paymentScheduleId?.eventDate,
    quantity:
      order?.paymentScheduleId?.services?.[0]?.serviceDetails?.[0]
        ?.guestCount || 1, // Quantity (not directly available in response)
    paymentStatus: order?.paymentStatus || "N/A", // Payment Status
    fessteFee: order?.fessteFee?.toFixed(2) || "0.00", // Fesste Fee
    transactionFee: order?.transactionFee?.toFixed(2) || "0.00", // Transaction Fee
    netPayout:order?.netPayout?.toFixed(2) || 0,
    payoutId: order?._id || "N/A", // Payout ID
    payoutStatus: order?.status || "N/A", // Payout Status
    totalOrderPaymentStatus: order?.paymentScheduleId?.paymentStatus || "N/A", // Total Order Payment Status
    fmPayoutTransactionId: order?.fmPayoutTransactionId || "N/A",
    initialPayout: order?.initialPayout?.toFixed(2) || 0,
    secondPayout:order?.secondPayout?.toFixed(2) || 0,
    finalPayout:order?.finalPayout?.toFixed(2) || 0,
    grossAmount:order?.grossAmount?.toFixed(2) || 0,
    payoutStage: order?.paymentStage,
    invoiceID:order?.invoiceId || "NA"
  }));

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
    setSnackbarOpenClipCard(true); // Show Snackbar when text is copied
  };

  const handleViewDetails = async (orderId) => {
    try {
      const data = await fetchFmInvoicePayoutById(orderId);
      navigate(`/fessteMart-price-summary-payout/${orderId}`, {
        state: { orderData: data?.payout },
      });
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  // Define columns for the table
  const columns = [
    { field: "orderID", headerName: "Order ID", minWidth: 200 },
    { field: "invoiceID", headerName: "Invoice ID", minWidth: 200 },
    { field: "custName", headerName: "Customer Name", minWidth: 200 },
    {
      field: "deliveryAddress",
      headerName: "Delivery Address",
      minWidth: 300,
      flex: 1, // Makes the column responsive based on available space
      renderCell: (params) => {
        const text = params.value || ""; // Ensure value is a string
        return (
          <Box
            sx={{
              display: "flex", // Use flexbox for alignment
              justifyContent: "space-between", // Align text on the left and icon on the right
              alignItems: "center", // Align vertically to the center
              whiteSpace: "normal", // Allow the text to wrap to the next line
              wordWrap: "break-word", // Break long words into the next line
              overflow: "visible", // Ensure the content isn't hidden
              textOverflow: "clip", // Avoid ellipsis and display all text
              lineHeight: 1.2, // Adjust line height for better readability
              height: "100%", // Ensure the content fills the cell vertically
              padding: "12px 10px", // Add padding for better spacing within the cell
            }}
          >
            <Box sx={{ flexGrow: 1, paddingRight: "10px" }}>
              {/* Render the address value with "Show More" functionality */}
              {renderCellWithMore(text, handleOpenDialog)}
            </Box>
            <IconButton
              aria-label="copy to clipboard"
              size="small"
              onClick={() => handleCopy(params.value)} // Trigger copy action
            >
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </Box>
        );
      },
    },
    { field: "orderDate", headerName: "Order Date", minWidth: 200 },
    { field: "bookDate", headerName: "Book Date", minWidth: 200 },
    { field: "quantity", headerName: "Quantity", minWidth: 200 },
    { field: "grossAmount", headerName: "Gross Payout", minWidth: 220 },
    { field: "initialPayout", headerName: "Initial Payout", minWidth: 200 },
    { field: "secondPayout", headerName: "Second Payout", minWidth: 200 },
    { field: "finalPayout", headerName: "Final Payout", minWidth: 200 },
    { field: "fessteFee", headerName: "Fesste Fee", minWidth: 200 },
    { field: "transactionFee", headerName: "Transaction Fee", minWidth: 200 },
    { field: "netPayout", headerName: "Net Payout", minWidth: 200 },
    { field: "payoutStage", headerName: "Payout Stage", minWidth: 200 },
    { field: "payoutStatus", headerName: "Payout Status", minWidth: 200 },
    {
      field: "fmPayoutTransactionId",
      headerName: "Payout Transaction ID",
      minWidth: 200,
    },
    {
      field: "pay",
      headerName: "Pay",
      minWidth: 160,
      renderCell: (params) => (
        <Checkbox
          color="primary"
          checked={selectedOrder?._id === params.row.id} // Check if the current row is selected
          onChange={() => onCheckboxChange(params.row)} // Handle checkbox change
        />
      ),
      flex: 0.5,
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 60,
      sortable: false,
      renderCell: (params) => (
        <>
          <Tooltip title="View Details">
            <IconButton
              color="default"
              onClick={() => handleViewDetails(params.row.id)}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <Box style={{ height: 400, width: "100%" }}>
      <PayoutTable
        rows={transformedOrders} // Use the transformed data
        columns={columns}
        getRowId={(row) => row.id} // Use _id as row identifier
        pageSize={5}
        disableSelectionOnClick
        autoHeight
      />
    </Box>
  );
};

export default FessteMartPayoutTable;

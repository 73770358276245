import React from 'react';
import { Grid, Typography, Box } from '@mui/material';

const OrderDetailsCard = ({ customerDetails, restaurantDetails, orderData }) => {
  console.log('restaurantDetails', restaurantDetails);
  console.log('customerDetails', customerDetails);

  return (
    <><Box
      sx={{
        border: '1px solid #e0e0e0',
        borderRadius: 2,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        padding: 3,
        marginTop: 2,
        backgroundColor: '#fff',
      }}
    >
      <Grid container spacing={3}>
        {/* Customer Details Section */}
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
            Customer Details:
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 0.5 }}>
            <strong>Name:</strong> {customerDetails?.fullName || 'N/A'}
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 0.5 }}>
            <strong>Phone:</strong> {customerDetails?.phoneNumber || 'N/A'}
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 0.5 }}>
            <strong>Email:</strong> {customerDetails?.email || 'N/A'}
          </Typography>
        </Grid>

        {/* Restaurant Details Section */}
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
            Restaurant Details:
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 0.5 }}>
            <strong>Name:</strong> {restaurantDetails?.restName || restaurantDetails?.businessName || 'N/A'}
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 0.5 }}>
            <strong>Phone:</strong> {restaurantDetails?.managerNumber || restaurantDetails?.businessPhoneNumber || 'N/A'}
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 0.5 }}>
            <strong>Address:</strong>
            {restaurantDetails?.restAddress?.street || restaurantDetails?.address?.street || 'N/A'},
            {restaurantDetails?.restAddress?.city || restaurantDetails?.address?.city || 'N/A'},
            {restaurantDetails?.restAddress?.postalCode || restaurantDetails?.address?.postalCode || 'N/A'}
          </Typography>
        </Grid>
      </Grid>

    </Box>
    </>

  );
};

export default OrderDetailsCard;

import React, { useState, useEffect } from 'react';
import {
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Collapse,
} from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import PeopleIcon from '@mui/icons-material/People';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PaymentIcon from '@mui/icons-material/Payment';
import SupportIcon from '@mui/icons-material/Support';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ShieldIcon from '@mui/icons-material/Shield';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import TimerIcon from '@mui/icons-material/Timer';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionIcon from '@mui/icons-material/Description'; // Import the new icon


const AdminDrawerList = ({ selectedIndex }) => {
    // State object for managing collapsible sections
    const [openSections, setOpenSections] = useState({
        menus: false,
        servicePartners: false,
        fessteMart: false,
        deliveryPartners: false,
        payouts: false,
        servicePartnerQueries: false,
        contactSupport: false,
        contentManagement: false,
        orders: false,
        reports: false,
        users: false,
        settings: false,
        fessteMartReports: false,
        servicePartnersReports: false,
        deletedSections: false,
        deletedData: false,
        fessteMartDeleted: false,
        deliveryPartnersDeleted: false,
        servicePartnersPayout: false,
        usersDeleted: false,
        fessteMartMenu:false,
    });

    const navigate = useNavigate();
    const userRole = localStorage.getItem("role");

    const handleNavigation = (path) => {
        navigate(path);
    };

    // General handler for toggling sections
    useEffect(() => {
        console.log('Updated openSections:', openSections);
    }, [openSections]);

    // General handler for toggling sections
    const handleClick = (section) => {
        setOpenSections((prevOpenSections) => ({
            ...prevOpenSections,
            [section]: !prevOpenSections[section], // Toggle the clicked section
        }));
    };

    console.log('openSections', openSections);

    const listItemStyles = {
        display: 'block',
        borderRadius: '8px', // Smooth corners for a modern look
        marginBottom: '8px', // Space between list items
        '&:hover': {
            transform: 'scale(1.02)', // Slight scaling effect
            boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.15)', // Enhance shadow on hover
        },
        transition: 'transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease', // Smooth transition
    };

    const listItemButtonStyles = {
        minHeight: 48,
        justifyContent: 'initial',
        px: 2.5,
        borderRadius: '8px', // Match the parent ListItem for consistent look
        '&:hover': {
            ml: '1rem', // Slight movement on hover
        },
        transition: 'margin-left 0.3s ease', // Smooth movement transition
    };

    const listItemIconStyles = {
        minWidth: 0,
        mr: 3,
        justifyContent: 'center',
        color: '#00796b', // Icon color to match the theme
    };

    const listItemTextStyles = {
        color: '#004d40', // Text color to match the theme
        fontWeight: 'bold', // Make the text bolder
    };

    const nestedListItemButtonStyles = {
        pl: 8,
        '&:hover': {
            ml: '1rem', // Slight movement on hover
            transform: 'scale(1.02)', // Slight scaling effect
        },
        transition: 'margin-left 0.3s ease', // Smooth movement transition

    };

    const deeperNestedListItemButtonStyles = {
        pl: 12,
        '&:hover': {
            transform: 'scale(1.02)', // Slight scaling effect
            ml: '1rem', // Slight movement on hover
        },
        transition: 'margin-left 0.3s ease', // Smooth movement transition
    };

    const deepestNestedListItemButtonStyles = {
        pl: 14,
        '&:hover': {
            transform: 'scale(1.02)', // Slight scaling effect
            ml: '1rem', // Slight movement on hover
        },
        transition: 'margin-left 0.3s ease', // Smooth movement transition
    };


    return (
        <><List >
            <ListItem
                disablePadding
                sx={{
                    display: 'block',
                    backgroundColor: '#cbf2f7', // Light blue background color
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
                    borderRadius: '8px', // Smooth corners for a modern look
                    marginBottom: '10px', // Space between list items
                    '&:hover': {
                        backgroundColor: '#cbf2f7', // Slightly darker background on hover
                        transform: 'scale(1.02)', // Slight scaling effect
                        boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.15)', // Enhance shadow on hover
                    },
                    transition: 'transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease', // Smooth transition
                }}
            >
                <ListItemButton
                    selected={selectedIndex === 0}
                    onClick={() => handleNavigation('/', 0)}
                    sx={{
                        minHeight: 48,
                        justifyContent: 'initial',
                        px: 2.5,
                        borderRadius: '8px', // Match the parent ListItem for consistent look
                        '&:hover': {
                            ml: '1rem', // Slight movement on hover
                        },
                        transition: 'margin-left 0.3s ease', // Smooth movement transition
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: 3,
                            justifyContent: 'center',
                            color: '#00796b', // Icon color to match the theme
                        }}
                    >
                        <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary="Dashboard"
                        sx={{
                            color: '#004d40', // Text color to match the theme
                            fontWeight: 'bold', // Make the text bolder
                        }} />
                </ListItemButton>
            </ListItem>
            {/* menus */}
            <ListItem disablePadding sx={listItemStyles}>
                <ListItemButton
                    selected={selectedIndex === 1}
                    onClick={() => handleClick('menus')} // Pass the correct section key
                    sx={{ ...listItemButtonStyles }} // Apply consistent styles
                >
                    <ListItemIcon sx={{ ...listItemIconStyles }}>
                        <RestaurantMenuIcon />
                    </ListItemIcon>
                    <ListItemText primary="Menu Listing" sx={{ ...listItemTextStyles }} />
                    {openSections.menus ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openSections.menus} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton
                            sx={nestedListItemButtonStyles}
                            onClick={() => handleNavigation('/caterers-menu', 7)}
                        >
                            <ListItemText primary="Caterers" sx={listItemTextStyles} />
                        </ListItemButton>
                        <ListItemButton
                            sx={nestedListItemButtonStyles}
                            onClick={() => handleNavigation('/restaurants-menu', 6)}
                        >
                            <ListItemText primary="Restaurants" sx={listItemTextStyles} />
                        </ListItemButton>
                    </List>
                    <ListItemButton
                        sx={nestedListItemButtonStyles}
                        onClick={() => handleClick('fessteMartMenu')}
                    >
                        <ListItemText primary="Fesste Mart" sx={listItemTextStyles} />
                        {openSections.fessteMartMenu ? <ExpandLess /> : <ExpandMore />}

                    </ListItemButton>
                    <Collapse in={openSections.fessteMartMenu} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          
                            <ListItemButton
                                sx={deeperNestedListItemButtonStyles}
                                onClick={() => handleNavigation('/party-hall-menu', 8)}
                            >
                                <ListItemText primary="Party Halls" sx={listItemTextStyles} />
                            </ListItemButton>
                            <ListItemButton
                                sx={deeperNestedListItemButtonStyles}
                                onClick={() => handleNavigation('/lightings-menu', 8)}
                            >
                                <ListItemText primary="Lightings" sx={listItemTextStyles} />
                            </ListItemButton>
                            <ListItemButton
                                sx={deeperNestedListItemButtonStyles}
                                onClick={() => handleNavigation('/flowers-menu', 8)}
                            >
                                <ListItemText primary="Flowers" sx={listItemTextStyles} />
                            </ListItemButton>
                            <ListItemButton
                                sx={deeperNestedListItemButtonStyles}
                                onClick={() => handleNavigation('/marquee-menu', 8)}
                            >
                                <ListItemText primary="Marquee " sx={listItemTextStyles} />
                            </ListItemButton>
                        </List>
                    </Collapse>
                </Collapse>
            </ListItem>



            {/* service Providers */}
            <ListItem disablePadding sx={listItemStyles}>
                <ListItemButton
                    selected={selectedIndex === 2}
                    onClick={() => handleClick('servicePartners')}
                    sx={listItemButtonStyles}
                >
                    <ListItemIcon sx={listItemIconStyles}>
                        <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Service Partners" sx={listItemTextStyles} />
                    {openSections.servicePartners ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openSections.servicePartners} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton
                            sx={nestedListItemButtonStyles}
                            onClick={() => handleNavigation('/service-providers', 7)}
                        >
                            <ListItemText primary="Service Providers" sx={listItemTextStyles} />
                        </ListItemButton>
                        <ListItemButton
                            sx={nestedListItemButtonStyles}
                            onClick={() => handleNavigation('/caterers', 7)}
                        >
                            <ListItemText primary="Caterers" sx={listItemTextStyles} />
                        </ListItemButton>
                        <ListItemButton
                            sx={nestedListItemButtonStyles}
                            onClick={() => handleNavigation('/restaurants', 6)}
                        >
                            <ListItemText primary="Restaurants" sx={listItemTextStyles} />
                        </ListItemButton>
                        <ListItemButton
                            sx={nestedListItemButtonStyles}
                            onClick={() => handleClick('fessteMart')}
                        >
                            <ListItemText primary="Fesste Mart" sx={listItemTextStyles} />
                            {openSections.fessteMart ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openSections.fessteMart} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton
                                    sx={deeperNestedListItemButtonStyles}
                                    onClick={() => handleNavigation('/fesste-mart-registration', 8)}
                                >
                                    <ListItemText primary="FessteMart Registration" sx={listItemTextStyles} />
                                </ListItemButton>
                                <ListItemButton
                                    sx={deeperNestedListItemButtonStyles}
                                    onClick={() => handleNavigation('/party-hall', 8)}
                                >
                                    <ListItemText primary="Party Hall" sx={listItemTextStyles} />
                                </ListItemButton>
                                <ListItemButton
                                    sx={deeperNestedListItemButtonStyles}
                                    onClick={() => handleNavigation('/lightings', 8)}
                                >
                                    <ListItemText primary="Lightings" sx={listItemTextStyles} />
                                </ListItemButton>
                                <ListItemButton
                                    sx={deeperNestedListItemButtonStyles}
                                    onClick={() => handleNavigation('/flowers', 8)}
                                >
                                    <ListItemText primary="Flowers" sx={listItemTextStyles} />
                                </ListItemButton>
                                <ListItemButton
                                    sx={deeperNestedListItemButtonStyles}
                                    onClick={() => handleNavigation('/marquee', 8)}
                                >
                                    <ListItemText primary="Marquee" sx={listItemTextStyles} />
                                </ListItemButton>
                            </List>
                        </Collapse>
                    </List>
                </Collapse>
            </ListItem>

            <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                    selected={selectedIndex === 3}
                    onClick={() => handleClick('ongoing-orders')}
                    sx={{ ...listItemButtonStyles }}
                >
                    <ListItemIcon sx={{ ...listItemIconStyles }}>
                        <TimerIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary="Ongoing Orders"
                        sx={{ ...listItemTextStyles }}
                        onClick={() => handleNavigation('/ongoing-orders', 6)} />
                </ListItemButton>
            </ListItem>

            {/* orders */}
            <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                    selected={selectedIndex === 2}
                    onClick={() => handleClick('orders')}
                    sx={{ ...listItemButtonStyles }}
                >
                    <ListItemIcon sx={{ ...listItemIconStyles }}>
                        <ReceiptIcon />
                    </ListItemIcon>
                    <ListItemText primary="Orders" sx={{ ...listItemTextStyles }} />
                    {openSections.orders ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openSections.orders} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton
                            sx={{ ...nestedListItemButtonStyles }}
                            onClick={() => handleNavigation('/caterer-orders', 7)}
                        >
                            <ListItemText primary="Caterers" sx={{ ...listItemTextStyles }} />
                        </ListItemButton>
                        <ListItemButton
                            sx={{ ...nestedListItemButtonStyles }}
                            onClick={() => handleNavigation('/restaurant-orders', 6)}
                        >
                            <ListItemText primary="Restaurants" sx={{ ...listItemTextStyles }} />
                        </ListItemButton>
                        <ListItemButton
                            sx={{ ...nestedListItemButtonStyles }}
                            onClick={() => handleClick('fessteMart')}
                        >
                            <ListItemText primary="Fesste Mart" sx={{ ...listItemTextStyles }} />
                            {openSections.fessteMart ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openSections.fessteMart} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton
                                    sx={{ ...deeperNestedListItemButtonStyles }}
                                    onClick={() => handleNavigation('/fesste-mart-orders', 8)}
                                >
                                    <ListItemText primary="FessteMart Orders" sx={{ ...listItemTextStyles }} />
                                </ListItemButton>
                                {/* <ListItemButton
            sx={{ ...deeperNestedListItemButtonStyles }}
            onClick={() => handleNavigation('/party-hall-orders', 8)}
        >
            <ListItemText primary="Party Hall" sx={{ ...listItemTextStyles }} />
        </ListItemButton>
        <ListItemButton
            sx={{ ...deeperNestedListItemButtonStyles }}
            onClick={() => handleNavigation('/lightings-orders', 8)}
        >
            <ListItemText primary="Lightings" sx={{ ...listItemTextStyles }} />
        </ListItemButton>
        <ListItemButton
            sx={{ ...deeperNestedListItemButtonStyles }}
            onClick={() => handleNavigation('/flower-orders', 8)}
        >
            <ListItemText primary="Flowers" sx={{ ...listItemTextStyles }} />
        </ListItemButton>
        <ListItemButton
            sx={{ ...deeperNestedListItemButtonStyles }}
            onClick={() => handleNavigation('/marquee-orders', 8)}
        >
            <ListItemText primary="Marquee" sx={{ ...listItemTextStyles }} />
        </ListItemButton> */}
                            </List>
                        </Collapse>
                    </List>
                </Collapse>
            </ListItem>


            {/* Delivery Partners Section */}
            <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                    selected={selectedIndex === 3}
                    onClick={() => handleClick('deliveryPartners')}
                    sx={{ ...listItemButtonStyles }}
                >
                    <ListItemIcon sx={{ ...listItemIconStyles }}>
                        <LocalShippingIcon />
                    </ListItemIcon>
                    <ListItemText primary="Delivery Partners" sx={{ ...listItemTextStyles }} onClick={() => handleNavigation('/delivery-partner-list', 6)} />
                </ListItemButton>

            </ListItem>

            {/* payouts */}
            <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                    selected={selectedIndex === 3}
                    onClick={() => handleClick('payouts')}
                    sx={{ ...listItemButtonStyles }}
                >
                    <ListItemIcon sx={{ ...listItemIconStyles }}>
                        <PaymentIcon />
                    </ListItemIcon>
                    <ListItemText primary="Payouts" sx={{ ...listItemTextStyles }} />
                    {openSections.payouts ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openSections.payouts} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton
                            sx={{ ...nestedListItemButtonStyles }}
                            onClick={() => handleClick('servicePartnersPayout')}
                        >
                            <ListItemText primary="Service Partners" sx={{ ...listItemTextStyles }} />
                            {openSections.servicePartnersPayout ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openSections.servicePartnersPayout} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton
                                    sx={{ ...deeperNestedListItemButtonStyles }}
                                    onClick={() => handleNavigation('/caterers-payout', 6)}
                                >
                                    <ListItemText primary="Caterers" sx={{ ...listItemTextStyles }} />
                                </ListItemButton>
                                <ListItemButton
                                    sx={{ ...deeperNestedListItemButtonStyles }}
                                    onClick={() => handleNavigation('/restaurant-payout', 7)}
                                >
                                    <ListItemText primary="Restaurants" sx={{ ...listItemTextStyles }} />
                                </ListItemButton>
                                <ListItemButton
                                    sx={{ ...deeperNestedListItemButtonStyles }}
                                    onClick={() => handleClick('fessteMartPayout')}
                                >
                                    <ListItemText primary="Fesste Mart" sx={{ ...listItemTextStyles }} />
                                    {openSections.fessteMartPayout ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                                <Collapse in={openSections.fessteMartPayout} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ListItemButton
                                            sx={{ ...deepestNestedListItemButtonStyles }}
                                            onClick={() => handleNavigation('/fessteMart-payouts', 8)}
                                        >
                                            <ListItemText primary="FessteMart Payouts" sx={{ ...listItemTextStyles }} />
                                        </ListItemButton>
                                        {/* <ListItemButton
            sx={{ ...deepestNestedListItemButtonStyles }}
            onClick={() => handleNavigation('/lightings-payout', 8)}
        >
            <ListItemText primary="Lightings" sx={{ ...listItemTextStyles }} />
        </ListItemButton>
        <ListItemButton
            sx={{ ...deepestNestedListItemButtonStyles }}
            onClick={() => handleNavigation('/party-hall-payout', 8)}
        >
            <ListItemText primary="Party Hall" sx={{ ...listItemTextStyles }} />
        </ListItemButton>
        <ListItemButton
            sx={{ ...deepestNestedListItemButtonStyles }}
            onClick={() => handleNavigation('/flowers-payout', 8)}
        >
            <ListItemText primary="Flowers" sx={{ ...listItemTextStyles }} />
        </ListItemButton>
        <ListItemButton
            sx={{ ...deepestNestedListItemButtonStyles }}
            onClick={() => handleNavigation('/marquee-payout', 8)}
        >
            <ListItemText primary="Marquee" sx={{ ...listItemTextStyles }} />
        </ListItemButton> */}
                                    </List>
                                </Collapse>
                            </List>
                        </Collapse>
                        <ListItemButton
                            sx={{ ...nestedListItemButtonStyles }}
                            onClick={() => handleNavigation('/delivery-partners-payout', 8)}
                        >
                            <ListItemText primary="Delivery Partners" sx={{ ...listItemTextStyles }} />
                        </ListItemButton>
                    </List>
                </Collapse>
            </ListItem>

            {/* contact support */}
            <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                    selected={selectedIndex === 3}
                    onClick={() => handleClick('contactSupport')}
                    sx={{ ...listItemButtonStyles }}
                >
                    <ListItemIcon sx={{ ...listItemIconStyles }}>
                        <SupportIcon />
                    </ListItemIcon>
                    <ListItemText primary="Support" sx={{ ...listItemTextStyles }} />
                    {openSections.contactSupport ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>

                <Collapse in={openSections.contactSupport} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton
                            sx={{ ...nestedListItemButtonStyles }}
                            onClick={() => handleNavigation('/customer-queries', 7)}
                        >
                            <ListItemText primary="Customer Queries" sx={{ ...listItemTextStyles }} />
                        </ListItemButton>

                        <ListItemButton
                            sx={{ ...nestedListItemButtonStyles }}
                            onClick={() => handleClick('servicePartnerQueries')}
                        >
                            <ListItemText primary="Service Partner Queries" sx={{ ...listItemTextStyles }} />
                            {openSections.servicePartnerQueries ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>

                        <Collapse in={openSections.servicePartnerQueries} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton
                                    sx={{ ...deepestNestedListItemButtonStyles }}
                                    onClick={() => handleNavigation('/caterer-queries', 9)}
                                >
                                    <ListItemText primary="Caterer Queries" sx={{ ...listItemTextStyles }} />
                                </ListItemButton>
                                <ListItemButton
                                    sx={{ ...deepestNestedListItemButtonStyles }}
                                    onClick={() => handleNavigation('/restaurant-queries', 10)}
                                >
                                    <ListItemText primary="Restaurant Queries" sx={{ ...listItemTextStyles }} />
                                </ListItemButton>

                                <ListItemButton
                                    sx={{ ...deepestNestedListItemButtonStyles }}
                                    onClick={() => handleClick('fessteMart')}
                                >
                                    <ListItemText primary="Fesste Mart" sx={{ ...listItemTextStyles }} />
                                    {openSections.fessteMart ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>

                                <Collapse in={openSections.fessteMart} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ListItemButton
                                            sx={{ ...deepestNestedListItemButtonStyles }}
                                            onClick={() => handleNavigation('/party-hall-queries', 11)}
                                        >
                                            <ListItemText primary="Party Hall Queries" sx={{ ...listItemTextStyles }} />
                                        </ListItemButton>
                                        <ListItemButton
                                            sx={{ ...deepestNestedListItemButtonStyles }}
                                            onClick={() => handleNavigation('/marquee-queries', 12)}
                                        >
                                            <ListItemText primary="Marquee Queries" sx={{ ...listItemTextStyles }} />
                                        </ListItemButton>
                                        <ListItemButton
                                            sx={{ ...deepestNestedListItemButtonStyles }}
                                            onClick={() => handleNavigation('/flowers-queries', 13)}
                                        >
                                            <ListItemText primary="Flowers Queries" sx={{ ...listItemTextStyles }} />
                                        </ListItemButton>
                                        <ListItemButton
                                            sx={{ ...deepestNestedListItemButtonStyles }}
                                            onClick={() => handleNavigation('/lightings-queries', 14)}
                                        >
                                            <ListItemText primary="Lightings Queries" sx={{ ...listItemTextStyles }} />
                                        </ListItemButton>
                                    </List>
                                </Collapse>
                            </List>
                        </Collapse>
{/* 
                        <ListItemButton
                            sx={{ ...nestedListItemButtonStyles }}
                            onClick={() => handleNavigation('/delivery-partner-queries', 8)}
                        >
                            <ListItemText primary="Delivery Partner Queries" sx={{ ...listItemTextStyles }} />
                        </ListItemButton> */}
                    </List>
                </Collapse>
            </ListItem>





            {/* Reports */}
            <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                    selected={selectedIndex === 1}
                    onClick={() => handleClick('reports')}
                    sx={{ ...listItemButtonStyles }} // Apply consistent styles
                >
                    <ListItemIcon sx={{ ...listItemIconStyles }}>
                        <AssessmentIcon />
                    </ListItemIcon>
                    <ListItemText primary="Reports" sx={{ ...listItemTextStyles }} />
                    {openSections.reports ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openSections.reports} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {/* Service Partners Section */}
                        <ListItemButton
                            sx={{ ...nestedListItemButtonStyles }} // Apply nested styles
                            onClick={() => handleClick('servicePartnersReports')}
                        >
                            <ListItemText primary="Service Partners" sx={{ ...listItemTextStyles }} />
                            {openSections.servicePartnersReports ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openSections.servicePartnersReports} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton
                                    sx={{ ...deeperNestedListItemButtonStyles }} // Apply deeper nested styles
                                    onClick={() => handleNavigation('/caterers-report', 7)}
                                >
                                    <ListItemText primary="Caterers Reports" sx={{ ...listItemTextStyles }} />
                                </ListItemButton>
                                <ListItemButton
                                    sx={{ ...deeperNestedListItemButtonStyles }} // Apply deeper nested styles
                                    onClick={() => handleNavigation('/restaurant-reports', 6)}
                                >
                                    <ListItemText primary="Restaurant Reports" sx={{ ...listItemTextStyles }} />
                                </ListItemButton>
                                <ListItemButton
                                    sx={{ ...deeperNestedListItemButtonStyles }} // Apply nested styles
                                    onClick={() => handleClick('fessteMartReports')}
                                >
                                    <ListItemText primary="Fesste Mart" sx={{ ...listItemTextStyles }} />
                                    {openSections.fessteMartReports ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                                <Collapse in={openSections.fessteMartReports} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                    <ListItemButton
                                            sx={{ ...deepestNestedListItemButtonStyles }} // Apply deeper nested styles
                                            onClick={() => handleNavigation('/fesstemart-reports', 8)}
                                        >
                                            <ListItemText primary="FessteMart Reports" sx={{ ...listItemTextStyles }} />
                                        </ListItemButton>
                                        {/* <ListItemButton
                                            sx={{ ...deepestNestedListItemButtonStyles }} // Apply deeper nested styles
                                            onClick={() => handleNavigation('/lightings-reports', 8)}
                                        >
                                            <ListItemText primary="Lightings" sx={{ ...listItemTextStyles }} />
                                        </ListItemButton>
                                        <ListItemButton
                                            sx={{ ...deepestNestedListItemButtonStyles }} // Apply deeper nested styles
                                            onClick={() => handleNavigation('/party-hall-reports', 8)}
                                        >
                                            <ListItemText primary="Party Hall" sx={{ ...listItemTextStyles }} />
                                        </ListItemButton>
                                        <ListItemButton
                                            sx={{ ...deepestNestedListItemButtonStyles }} // Apply deeper nested styles
                                            onClick={() => handleNavigation('/flowers-reports', 8)}
                                        >
                                            <ListItemText primary="Flowers" sx={{ ...listItemTextStyles }} />
                                        </ListItemButton>
                                        <ListItemButton
                                            sx={{ ...deepestNestedListItemButtonStyles }} // Apply deeper nested styles
                                            onClick={() => handleNavigation('/marquee-reports', 8)}
                                        >
                                            <ListItemText primary="Marquee" sx={{ ...listItemTextStyles }} />
                                        </ListItemButton> */}
                                    </List>
                                </Collapse>
                            </List>
                        </Collapse>

                        {/* Fesste Mart with its own nested list */}

                        <ListItemButton
                            sx={{ ...nestedListItemButtonStyles }} // Apply nested styles
                            onClick={() => handleNavigation('/user-reports', 8)}
                        >
                            <ListItemText primary="Users Reports" sx={{ ...listItemTextStyles }} />
                        </ListItemButton>

                        <ListItemButton
                            sx={{ ...nestedListItemButtonStyles }} // Apply nested styles
                            onClick={() => handleNavigation('/delivery-partner-reports', 8)}
                        >
                            <ListItemText primary="Delivery Reports" sx={{ ...listItemTextStyles }} />
                        </ListItemButton>
                    </List>
                </Collapse>
            </ListItem>

            {userRole === 'super_admin' && ( // Render only if user role is super_admin
                <ListItem disablePadding sx={{ display: 'block' }}>
                    <ListItemButton
                        selected={selectedIndex === 1}
                        onClick={() => handleNavigation('/roles', 7)} 
                        sx={{ ...listItemButtonStyles }} // Apply consistent styles
                    >
                    <ListItemIcon sx={{ ...listItemIconStyles }}>
                        <ShieldIcon />
                    </ListItemIcon>
                        <ListItemText primary="Roles" sx={{ ...listItemTextStyles }} />
                    </ListItemButton>
                </ListItem>
            )}

            {/* Users */}

            <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                    selected={selectedIndex === 1}
                    onClick={() => handleClick('users')}
                    sx={{ ...listItemButtonStyles }} // Apply consistent styles
                >
                    <ListItemIcon sx={{ ...listItemIconStyles }}>
                        <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary="Users" sx={{ ...listItemTextStyles }} onClick={() => handleNavigation('/users', 7)} />
                </ListItemButton>
            </ListItem>

            {/* PromoCodes */}

            <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                    selected={selectedIndex === 1}
                    onClick={() => handleClick('promoCodes')}
                    sx={{ ...listItemButtonStyles }} // Apply consistent styles
                >
                    <ListItemIcon sx={{ ...listItemIconStyles }}>
                        <LocalOfferIcon />
                    </ListItemIcon>
                    <ListItemText primary="PromoCodes" sx={{ ...listItemTextStyles }} onClick={() => handleNavigation('/promoCodes', 7)} />
                </ListItemButton>
            </ListItem>

            {/* Quotations */}
            <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                    selected={selectedIndex === 1}
                    onClick={() => handleClick('Quotations')}
                    sx={{ ...listItemButtonStyles }} // Apply consistent styles
                >
                    <ListItemIcon sx={{ ...listItemIconStyles }}>
                        <DescriptionIcon /> {/* Replace FormatQuoteIcon with the new icon */}
                    </ListItemIcon>
                    <ListItemText primary="Quotations" sx={{ ...listItemTextStyles }} onClick={() => handleNavigation('/quotations', 7)} />
                </ListItemButton>
            </ListItem>

            {/* content management */}
            <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                    selected={selectedIndex === 1}
                    onClick={() => handleClick('contentManagement')}
                    sx={{ ...listItemButtonStyles }} // Use the same styles as the other sections
                >
                    <ListItemIcon sx={{ ...listItemIconStyles }}>
                        <ManageAccountsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Content Management" sx={{ ...listItemTextStyles }} />
                    {openSections.contentManagement ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>

                <Collapse in={openSections.contentManagement} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {/* <ListItemButton
            sx={{ ...nestedListItemButtonStyles }} // Apply nested styles
            onClick={() => handleNavigation('/blogs', 7)}
        >
            <ListItemText primary="Blogs" sx={{ ...listItemTextStyles }} />
        </ListItemButton> */}
                        <ListItemButton
                            sx={{ ...nestedListItemButtonStyles }} // Apply nested styles
                            onClick={() => handleNavigation('/privacy-policy', 7)}
                        >
                            <ListItemText primary="Add Policy" sx={{ ...listItemTextStyles }} />
                        </ListItemButton>
                        {/* <ListItemButton
            sx={{ ...nestedListItemButtonStyles }} // Apply nested styles
            onClick={() => handleNavigation('/articles', 6)}
        >
            <ListItemText primary="Articles" sx={{ ...listItemTextStyles }} />
        </ListItemButton> */}
                        {/* <ListItemButton
            sx={{ ...nestedListItemButtonStyles }} // Apply nested styles
            onClick={() => handleNavigation('/images', 8)}
        >
            <ListItemText primary="Images" sx={{ ...listItemTextStyles }} />
        </ListItemButton> */}
                    </List>
                </Collapse>
            </ListItem>


            <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                    selected={selectedIndex === 1}
                    onClick={() => handleClick('deletedSections')}
                    sx={{ ...listItemButtonStyles }} // Apply consistent styles
                >
                    <ListItemIcon sx={{ ...listItemIconStyles }}>
                        <DeleteIcon />
                    </ListItemIcon>
                    <ListItemText primary="Deleted" sx={{ ...listItemTextStyles }} />
                    {openSections.deletedSections ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openSections.deletedSections} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {/* Service Partners Section */}
                        <ListItemButton
                            sx={{ ...nestedListItemButtonStyles }} // Apply nested styles
                            onClick={() => handleClick('deletedData')}
                        >
                            <ListItemText primary="Service Partners" sx={{ ...listItemTextStyles }} />
                            {openSections.deletedData ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openSections.deletedData} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton
                                    sx={{ ...deeperNestedListItemButtonStyles }} // Apply deeper nested styles
                                    onClick={() => handleNavigation('/caterers-deleted', 7)}
                                >
                                    <ListItemText primary="Deleted Caterers" sx={{ ...listItemTextStyles }} />
                                </ListItemButton>
                                <ListItemButton
                                    sx={{ ...deeperNestedListItemButtonStyles }} // Apply deeper nested styles
                                    onClick={() => handleNavigation('/restaurant-deleted', 6)}
                                >
                                    <ListItemText primary="Restaurant Deleted" sx={{ ...listItemTextStyles }} />
                                </ListItemButton>
                                <ListItemButton
                                    sx={{ ...deeperNestedListItemButtonStyles }} // Apply nested styles
                                    onClick={() => handleClick('fessteMartDeleted')}
                                >
                                    <ListItemText primary="Fesste Mart" sx={{ ...listItemTextStyles }} />
                                    {openSections.fessteMartDeleted ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                                <Collapse in={openSections.fessteMartDeleted} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ListItemButton
                                            sx={{ ...deepestNestedListItemButtonStyles }} // Apply deeper nested styles
                                            onClick={() => handleNavigation('/lightings-deleted', 8)}
                                        >
                                            <ListItemText primary="Lightings Deleted" sx={{ ...listItemTextStyles }} />
                                        </ListItemButton>
                                        <ListItemButton
                                            sx={{ ...deepestNestedListItemButtonStyles }} // Apply deeper nested styles
                                            onClick={() => handleNavigation('/party-hall-deleted', 8)}
                                        >
                                            <ListItemText primary="Party Hall Deleted" sx={{ ...listItemTextStyles }} />
                                        </ListItemButton>
                                        <ListItemButton
                                            sx={{ ...deepestNestedListItemButtonStyles }} // Apply deeper nested styles
                                            onClick={() => handleNavigation('/flowers-deleted', 8)}
                                        >
                                            <ListItemText primary="Flowers Deleted" sx={{ ...listItemTextStyles }} />
                                        </ListItemButton>
                                        <ListItemButton
                                            sx={{ ...deepestNestedListItemButtonStyles }} // Apply deeper nested styles
                                            onClick={() => handleNavigation('/marquee-deleted', 8)}
                                        >
                                            <ListItemText primary="Marquee Deleted" sx={{ ...listItemTextStyles }} />
                                        </ListItemButton>
                                    </List>
                                </Collapse>
                            </List>
                        </Collapse>
                        {/* Delivery Partners Deleted */}
                        <ListItemButton
                            sx={{ ...nestedListItemButtonStyles }} // Apply nested styles
                            onClick={() => handleNavigation('/delivery-partner-deleted', 8)}                        >
                            <ListItemText primary="Delivery Partners deleted" sx={{ ...listItemTextStyles }} />
                        </ListItemButton>
                        <Collapse in={openSections.deliveryPartnersDeleted} timeout="auto" unmountOnExit>
                            {/* Nested items */}
                        </Collapse>
                        <ListItemButton
                            sx={{ ...nestedListItemButtonStyles }} // Apply nested styles
                            onClick={() => handleNavigation('/users-deleted', 8)}
                        >
                            <ListItemText primary="Users deleted" sx={{ ...listItemTextStyles }} />
                        </ListItemButton>
                        <Collapse in={openSections.usersDeleted} timeout="auto" unmountOnExit>
                            {/* Nested items */}
                        </Collapse>
                    </List>
                </Collapse>
            </ListItem>
            {/* Settings */}
            {/* <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                    selected={selectedIndex === 1}
                    onClick={() => handleClick('settings')}
                    sx={{ ...listItemButtonStyles }} // Apply consistent styles
                >
                    <ListItemIcon sx={{ ...listItemIconStyles }}>
                        <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Settings" sx={{ ...listItemTextStyles }} />
                    {openSections.settings ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openSections.settings} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton
                            sx={{ ...nestedListItemButtonStyles }} // Apply nested styles
                            onClick={() => handleNavigation('/sms-settings', 7)}
                        >
                            <ListItemText primary="SMS Settings" sx={{ ...listItemTextStyles }} />
                        </ListItemButton>
                    </List>
                </Collapse>
            </ListItem> */}
        </List><Divider /></>

    );
};

export default AdminDrawerList;

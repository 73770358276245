import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";

const OrderItemsTable = ({ items }) => {
  console.log("items", items);

  const formatCurrency = (value) => `₹${Number(value).toFixed(2)}`; // Format to currency (assuming Indian Rupee)

  return (
    <TableContainer
      component={Paper}
      sx={{
        mb: 3,
        borderRadius: 2,
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Table>
        <TableHead>
          <TableRow sx={{ backgroundColor: "#f0f0f0" }}>
            <TableCell>
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                Particulars
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                Qty
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                Price
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                Gross Value
              </Typography>
            </TableCell>
           
            <TableCell>
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                Total
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, index) => (
            <>
              <TableRow
                key={index}
                sx={{
                  backgroundColor: index % 2 === 0 ? "#fafafa" : "#fff", // Alternating row colors
                  "&:last-child td, &:last-child th": { border: 0 }, // No bottom border for the last row
                }}
              >
                <TableCell>{item.particulars}</TableCell>
                <TableCell>{item.qty}</TableCell>
                <TableCell>{item?.price}</TableCell>
                <TableCell>{formatCurrency(item.grossValue)}</TableCell>
               
                <TableCell>
                  <Typography sx={{ fontWeight: "bold" }}>
                    {formatCurrency(item.total)}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow
                key={index}
                sx={{
                  backgroundColor: index % 2 === 0 ? "#fafafa" : "#fff", // Alternating row colors
                  "&:last-child td, &:last-child th": { border: 0 }, // No bottom border for the last row
                }}
              >
                <TableCell>Tissue Packets</TableCell>
                <TableCell>{item.tissuePackets}</TableCell>
                <TableCell>{item?.tissueCharges}</TableCell>
                <TableCell>
                  {formatCurrency(item.additionalTissueCharges)}
                </TableCell>
               
                <TableCell>
                  <Typography sx={{ fontWeight: "bold" }}>
                    {formatCurrency(item.additionalTissueCharges)}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow
                key={index}
                sx={{
                  backgroundColor: index % 2 === 0 ? "#fafafa" : "#fff", // Alternating row colors
                  "&:last-child td, &:last-child th": { border: 0 }, // No bottom border for the last row
                }}
              >
                <TableCell>Water Bottles</TableCell>
                <TableCell>{item.waterBottlePackets}</TableCell>
                <TableCell>{item?.wbCharges}</TableCell>
                <TableCell>
                  {formatCurrency(item.additionlWBCharges)}
                </TableCell>
               
                <TableCell>
                  <Typography sx={{ fontWeight: "bold" }}>
                    {formatCurrency(item.additionlWBCharges)}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow
                key={index}
                sx={{
                  backgroundColor: index % 2 === 0 ? "#fafafa" : "#fff", // Alternating row colors
                  "&:last-child td, &:last-child th": { border: 0 }, // No bottom border for the last row
                }}
              >
                <TableCell>Buffet Charges</TableCell>
                <TableCell>-</TableCell>
                <TableCell>-</TableCell>
                <TableCell>
                  {formatCurrency(item.buffetCharge)}
                </TableCell>
              
                <TableCell>
                  <Typography sx={{ fontWeight: "bold" }}>
                    {formatCurrency(item.buffetCharge)}
                  </Typography>
                </TableCell>
              </TableRow>
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OrderItemsTable;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Grid } from '@mui/material';
import AdminDrawer from '../../../../components/AdminDrawer/AdminDrawer';
import StatisticsCard from '../../../../components/AdvancedComponents/PayoutComponents/StatisticsCard';
import FessteMartPayoutBreakdown from '../../../../components/AdvancedComponents/PayoutComponents/FessteMartPayoutTable/FessteMartPayoutBreakDown';
import FessteMartPayoutTable from '../../../../components/AdvancedComponents/PayoutComponents/FessteMartPayoutTable/FessteMartPayoutTable';
import { fetchFessteMartPayoutByIdAndBussinessType } from '../../../../api/payouts/payoutsApi';

const PartyHallPayoutTab = ({ fessteMartId }) => {
  const { id } = useParams();
  const [payoutData, setPayoutData] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadPayoutData = async () => {
      try {
        const businessType = "PartyHall";
        const data = await fetchFessteMartPayoutByIdAndBussinessType(fessteMartId, businessType);
        setPayoutData(data);
      } catch (error) {
        console.error("Error fetching payout data:", error);
        setPayoutData([]);
      } finally {
        setLoading(false);
      }
    };
  
    loadPayoutData();
  }, [fessteMartId,id]);  // Ensure both dependencies are included
  
  const handleCheckboxChange = (order) => {
    setSelectedOrder(order);
  };

  if (loading) {
    return (
      <div style={{ backgroundColor: '#f2f2f2', height: '100vh' }}>
        <AdminDrawer>
          <div style={{ padding: '24px' }}>
            <Typography variant='h6'>Loading...</Typography>
          </div>
        </AdminDrawer>
      </div>
    );
  }

  // Instead of using an error message, we will show a more user-friendly message with empty columns
  if (!payoutData || payoutData.length === 0) {
    return (
      <div style={{ backgroundColor: '#f2f2f2', height: '100vh' }}>
        <AdminDrawer>
          <Typography variant="h5" gutterBottom>
            Party Hall Payout
          </Typography>
          <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>

            <Grid item>
              <Typography variant='h6'>No payout data found</Typography>
            </Grid>
          </Grid>
        </AdminDrawer>
      </div>
    );
  }

  const totalPayout = Array.isArray(payoutData)
    ? payoutData.reduce((acc, payoutDetails) => {
        const orderId = payoutDetails?.order?.orderId;

        // Initialize a Set to track processed orderIds
        if (!acc.processedOrders) acc.processedOrders = new Set();

        // Skip this payoutDetails if the orderId has already been processed
        if (orderId && acc.processedOrders.has(orderId)) {
            return acc;
        }

        // Mark this orderId as processed
        if (orderId) {
            acc.processedOrders.add(orderId);
        }

        acc.totalAmount += payoutDetails?.grossAmount || 0;
        acc.netPayout += payoutDetails?.netPayout || 0;
        acc.fessteFee += payoutDetails?.fessteFee || 0;
        acc.transactionFee += payoutDetails?.transactionFee || 0;
        return acc;
    }, {
        totalAmount: 0,
        netPayout: 0,
        fessteFee: 0,
        transactionFee: 0,
        processedOrders: new Set(), // Used to track processed orderIds
    })
    : {
        totalAmount: payoutData?.grossAmount || 0,
        netPayout: payoutData?.netPayout || 0,
        fessteFee: payoutData?.fessteFee || 0,
        transactionFee: payoutData?.transactionFee || 0,
    };

// Optionally, remove the `processedOrders` property if not needed
if (totalPayout.processedOrders) {
    delete totalPayout.processedOrders;
}



    console.log('payoutData in party hall tab',payoutData);
    

  return (
    <div style={{ backgroundColor: '#f2f2f2', height: 'max-content' }}>
      <div style={{ padding: '24px' }}>
        <Typography variant='h5' gutterBottom>
          {payoutData[0]?.fessteMartId?.businessName}
        </Typography>
      </div>

      <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={6} md={3}>
            <StatisticsCard
              title="Total Earnings (Till Date)"
              value={`₹${totalPayout?.totalAmount?.toFixed(2)}`}
              background="linear-gradient(135deg, #6a1b9a 0%, #8e24aa 100%)"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatisticsCard
              title="Fesste Fee (Till Date)"
              value={`₹${totalPayout.fessteFee.toFixed(2)}`}
              background="linear-gradient(135deg, #43a047 0%, #66bb6a 100%)"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatisticsCard
              title="Transaction Fee (Till Date)"
              value={`₹${totalPayout?.transactionFee?.toFixed(2)}`}
              background="linear-gradient(135deg, #ffb300 0%, #ffa000 100%)"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatisticsCard
              title="Net Payout (Till Date)"
              value={`₹${totalPayout?.netPayout?.toFixed(2)}`}
              background="linear-gradient(135deg, #1e88e5 0%, #42a5f5 100%)"
            />
          </Grid>
        </Grid>

      { selectedOrder ? (
        <FessteMartPayoutBreakdown selectedOrder={selectedOrder} />
      ) :null}

      {/* Conditionally render either PaymentDashBoardTable or FessteMartPayoutTable */}
    
        <FessteMartPayoutTable
          orders={payoutData}
          selectedOrder={selectedOrder}
          onCheckboxChange={handleCheckboxChange}
        />
    
    </div>
  );
};

export default PartyHallPayoutTab;

import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

const StatisticsCard = ({ title, value, background }) => {
    return (
        <Card elevation={3} sx={{
            p: 1,
            background: background,
            borderRadius: '8px',
        }}>
            <CardContent>
                <Typography variant="body2" sx={{ fontSize: '0.875rem', fontWeight: 'bold', color: '#ffffff' }}>
                    {title}
                </Typography>
                <Typography variant="h6" sx={{ fontSize: '1.25rem', fontWeight: 'bold', color: '#ffffff' }}>
                    {value}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default StatisticsCard;

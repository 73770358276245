import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Container,
  Paper,
  Typography,
  Grid,
  Divider,
  Avatar,
  IconButton,
  AppBar,
  Toolbar,
  Dialog,
  DialogContent,
  Box,
  Button,
  TextField,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import avatarImage from "../../assets/avatarwomen.jpg"; // Default avatar image
import { fetchDeliveryPartnerById } from "../../api/deliveryPartner/deliveryPartner";
import VerifiedIcon from "../../assets/images/verified.png";
import baseUrl from "../../config";

const DeliveryPartnerDetailsTab = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const deliveryPartner = location.state;

  const [deliveryPartnerData, setDeliveryPartnerData] = useState([]);

  // State for full-screen image preview
  const [openImageModal, setOpenImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const [verificationError, setVerificationError] = useState("");
  const [verificationMessage, setVerificationMessage] = useState("");

  // Modal state for unverify reason
  const [openUnverifyModal, setOpenUnverifyModal] = useState(false); // Define this state
  const [unverifyReason, setUnverifyReason] = useState(""); // Define this state

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchDeliveryPartnerById(deliveryPartner);
        setDeliveryPartnerData(response);
      } catch (error) {
        console.error("Error fetching delivery partner data:", error);
      }
    };

    if (deliveryPartner) {
      fetchData();
    }
  }, [deliveryPartner]);

  console.log("deliveryPartnerData", deliveryPartnerData);

  const handleImageClick = (url) => {
    setSelectedImage(url);
    setOpenImageModal(true);
  };

  const closeImageModal = () => {
    setOpenImageModal(false);
    setSelectedImage(null);
  };

  if (!deliveryPartner) {
    return <div>No details available</div>;
  }

  const handleDownload = async () => {
    const apiUrl = `${baseUrl}delivery-partners/${deliveryPartner}/download`;

    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "documents.zip"); // Replace with actual file name if necessary
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  const handleVerify = async (unverifyReason) => {
    const apiUrl = `${baseUrl}delivery-partners/${deliveryPartner}/verify`;

    try {
      let response;

      if (deliveryPartnerData?.isVerified) {
        // If already verified, unverify with a reason
        response = await fetch(apiUrl, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ unverificationReason: unverifyReason }), // Pass the reason for unverification
        });
      } else {
        // If not verified, verify the partner
        response = await fetch(apiUrl, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
        });
      }

      const result = await response.json();

      if (response.ok) {
        setVerificationMessage(result.message);
        setVerificationError("");
        fetchDeliveryPartnerById(deliveryPartner).then((response) => {
          setDeliveryPartnerData(response); // Update state with the latest data
        });
      } else {
        setVerificationError(result.message);
        setVerificationMessage("");
      }
    } catch (error) {
      setVerificationError("An error occurred during verification.");
      setVerificationMessage("");
      console.error("Error verifying delivery partner:", error);
    }
  };

  const handleUnverifyClick = () => {
    if (deliveryPartnerData?.isVerified) {
      // Open the modal to take the reason
      setOpenUnverifyModal(true);
    } else {
      // Directly verify the user if they are not verified
      handleVerify();
    }
  };

  const handleModalSubmit = () => {
    // Close the modal and call the verify API for unverification with the reason
    setOpenUnverifyModal(false);
    handleVerify(unverifyReason);
    setUnverifyReason("");
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 3 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => navigate(-1)}
            aria-label="back"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Delivery Partner Details
          </Typography>
        </Toolbar>
      </AppBar>

      <Paper elevation={3} sx={{ p: 3, mt: 2 }}>
        <Grid container spacing={3}>
          {/* Profile Section */}
          <Grid item xs={12} sm={4} sx={{ textAlign: "center" }}>
            <Avatar
              src={deliveryPartnerData?.documents?.[0]?.url || avatarImage}
              alt={deliveryPartnerData?.fullName}
              sx={{ width: 120, height: 120, mx: "auto" }}
            />
            <Typography variant="h5" sx={{ mt: 1 }}>
              {deliveryPartnerData?.fullName}
            </Typography>
          </Grid>

          {/* Details Section */}
          <Grid item xs={12} sm={8}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6">Details</Typography>
              {deliveryPartnerData?.isVerified ? (
                <IconButton onClick={handleUnverifyClick}>
                  <img
                    src={VerifiedIcon}
                    alt="Verified"
                    style={{ width: "70px", height: "70px", cursor: "pointer" }}
                  />
                </IconButton>
              ) : (
                <Button
                  onClick={handleVerify}
                  sx={{
                    backgroundColor: "#0e0593",
                    borderRadius: "2rem",
                    color: "White",
                    fontWeight: "bold",
                  }}
                >
                  Verify
                </Button>
              )}
            </Box>
            {/* Modal for unverify reason */}
            {/* Modal for unverify reason */}
            <Dialog
              open={openUnverifyModal}
              onClose={() => setOpenUnverifyModal(false)}
            >
              <DialogContent>
                <Typography variant="h6">Reason for Unverification</Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  placeholder="Enter the reason for unverification"
                  value={unverifyReason}
                  onChange={(e) => setUnverifyReason(e.target.value)}
                  sx={{ mt: 2 }}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 2,
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => setOpenUnverifyModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleModalSubmit} // Submit the reason
                    disabled={!unverifyReason} // Prevent empty submissions
                  >
                    Submit
                  </Button>
                </Box>
              </DialogContent>
            </Dialog>

            <Divider sx={{ my: 1 }} />
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography variant="body2">
                  <strong>Email:</strong> {deliveryPartnerData?.email}
                </Typography>
                <Typography variant="body2">
                  <strong>Phone Number:</strong>{" "}
                  {deliveryPartnerData?.phoneNumber}
                </Typography>
                <Typography variant="body2">
                  <strong>Secondary Phone:</strong>{" "}
                  {deliveryPartnerData?.secondaryPhone || "N/A"}
                </Typography>
                <Typography variant="body2">
                  <strong>Date of Birth:</strong>{" "}
                  {deliveryPartnerData?.dob
                    ? new Date(deliveryPartnerData?.dob).toLocaleDateString()
                    : "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">
                  <strong>Current Address:</strong>{" "}
                  {`${
                    deliveryPartnerData?.currentAddress?.addressLine1 || ""
                  }, ${
                    deliveryPartnerData?.currentAddress?.addressLine2 || ""
                  }, ${deliveryPartnerData?.currentAddress?.city || ""}, ${
                    deliveryPartnerData?.currentAddress?.state || ""
                  }, ${deliveryPartnerData?.currentAddress?.country || ""}, ${
                    deliveryPartnerData?.currentAddress?.postCode || ""
                  }`.replace(/,\s*$/, "") || "N/A"}
                </Typography>

                <Typography variant="body2">
                  <strong>Permanent Address:</strong>{" "}
                  {`${
                    deliveryPartnerData?.parmanentAddress?.addressLine1 || ""
                  }, ${
                    deliveryPartnerData?.parmanentAddress?.addressLine2 || ""
                  }, ${deliveryPartnerData?.parmanentAddress?.city || ""}, ${
                    deliveryPartnerData?.parmanentAddress?.state || ""
                  }, ${deliveryPartnerData?.parmanentAddress?.country || ""}, ${
                    deliveryPartnerData?.parmanentAddress?.postCode || ""
                  }`.replace(/,\s*$/, "") || "N/A"}
                </Typography>

                <Typography variant="body2">
                  <strong>Vehicle RC:</strong>{" "}
                  {deliveryPartnerData?.vehicleRegistrationNumber || "N/A"}
                </Typography>
                <Typography variant="body2">
                  <strong>Vehicle Type:</strong>{" "}
                  {deliveryPartnerData?.vehicleType
                    ? deliveryPartnerData.vehicleType
                        .replace(/([a-z])([A-Z])/g, "$1 $2") // Insert space between camel case words
                        .replace(/^./, (str) => str.toUpperCase()) // Capitalize the first letter
                    : "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">
                  <strong>Bank Name:</strong>{" "}
                  {deliveryPartnerData?.bankDetails?.bankName}
                </Typography>
                <Typography variant="body2">
                  <strong>Account Holder Name:</strong>{" "}
                  {deliveryPartnerData?.bankDetails?.fullName}
                </Typography>
                <Typography variant="body2">
                  <strong>Account Number:</strong>{" "}
                  {deliveryPartnerData?.bankDetails?.accountNumber || "N/A"}
                </Typography>
                <Typography variant="body2">
                  <strong>IFSC Code:</strong>{" "}
                  {deliveryPartnerData?.bankDetails?.IFSC_Code || "N/A"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Divider sx={{ my: 3 }} />

        {/* Document Images Section */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h6" sx={{ mt: 3, fontWeight: "bold" }}>
            Documents
          </Typography>
          <Button
            variant="contained"
            sx={{
              borderRadius: "2rem",
              alignSelf: "flex-end",
              fontWeight: "bold",
            }}
            onClick={handleDownload}
          >
            Download All
          </Button>
        </Box>
        <Divider sx={{ my: 1 }} />

        <Grid container spacing={2}>
          {deliveryPartnerData.documents?.map((doc) => (
            <Grid item xs={12} sm={6} md={4} key={doc.type}>
              <Paper
                elevation={2}
                sx={{ p: 2, textAlign: "center", cursor: "pointer" }}
                onClick={() => handleImageClick(doc.url || avatarImage)}
              >
                <Typography variant="body2">
                  <strong>
                    {doc.type
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, (str) => str.toUpperCase())}
                  </strong>
                </Typography>
                <Avatar
                  src={doc.url}
                  alt={doc.type}
                  variant="rounded"
                  sx={{
                    width: "100%",
                    height: 250,
                    my: 1,
                    objectFit: "cover", // Ensures the image covers the entire container
                  }}
                />
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Paper>

      {/* Full-Screen Image Modal */}
      <Dialog open={openImageModal} onClose={closeImageModal} maxWidth="md">
        <DialogContent>
          <img
            src={selectedImage}
            alt="Document Full View"
            style={{ width: "20vw", height: "50vh" }}
          />
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default DeliveryPartnerDetailsTab;

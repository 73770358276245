import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DeliveryPartnerForm from "./DeliveryPartnerForm";
import { Grid, Paper, Typography, Box } from "@mui/material";
import AdminDrawer from "../../components/AdminDrawer/AdminDrawer";
import CustomSnackbar from "../../components/CustomeSnackBar/CustomSnackbar";
import {
    registerDeliveryPartner,
    fetchDeliveryPartnerById,
    updateDeliveryPartnerById
} from '../../api/deliveryPartner/deliveryPartner';
import BreadcrumbsComponent from "../../components/ResuableComponents/BreadCrumb";

const AddDeliveryPartners = () => {
    const [selectedAddress, setSelectedAddress] = useState("");
    const [formData, setFormData] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const navigate = useNavigate();
    const { id } = useParams(); // Assuming you pass the ID via route params

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetchDeliveryPartnerById(id);                

                const prefilledData = {

                    fullName: response.fullName,
                    email: response.email,
                    phoneNumber: response.phoneNumber,  
                    secondaryPhone: response.secondaryPhone,
                    dob: response.dob,

                    // Current address details
                    currentAddressLine1: response?.currentAddress?.addressLine1,
                    currentCity: response?.currentAddress?.city,
                    currentState: response?.currentAddress?.state,
                    currentPostCode: response?.currentAddress?.postCode,

                    // Permanent address details
                    parmanentAddressLine1: response?.parmanentAddress?.addressLine1,
                    parmanentCity: response?.parmanentAddress?.city,
                    parmanentState: response?.parmanentAddress?.state,
                    parmanentPostCode: response?.parmanentAddress?.postCode,

                    // Vehicle details
                    vehicleType: response?.vehicleType,
                    vehicleRegistrationNumber: response?.vehicleRegistrationNumber,
                    panNo:response?.panNo,
                    aadharNo:response?.aadharNo,

                    // Document files
                    profileImage: response?.profileImage,
                    aadharFront: response?.aadharFront,
                    aadharBack: response?.aadharBack,
                    panCard: response?.panCard,
                    drivingLicenseFront: response?.drivingLicenseFront,
                    drivingLicenseBack: response?.data?.drivingLicenseBack,
                    vehicleFrontDoc: response?.data?.vehicleFrontDoc,
                    vehicleBackDoc: response?.data?.vehicleBackDoc
                };
                setFormData(prefilledData);
                setSelectedAddress(`${response?.data?.currentAddress?.addressLine1}, ${response?.data?.currentAddress?.city}`);
            } catch (error) {
                console.error("Error fetching delivery partner data:", error);
            }
        };
        if (id) {
            fetchData();
        }
    }, [id]);


    const handleAddressChange = (address) => {
        setSelectedAddress(address);
    };

    const handleSubmit = async (formData) => {    
        const formDataObj = new FormData();

        // Adding basic information
        formDataObj.append("fullName", formData.fullName);
        formDataObj.append("email", formData.email);
        formDataObj.append("phoneNumber", formData.phoneNumber);
        formDataObj.append("secondaryPhone", formData.secondaryPhone);
        formDataObj.append("dob", formData.dob);

        // Adding current address fields only if they are non-empty
        if (formData.currentAddressLine1) {
            formDataObj.append("currentAddress[addressLine1]", formData.currentAddressLine1);
        }
        if (formData.currentCity) {
            formDataObj.append("currentAddress[city]", formData.currentCity);
        }
        if (formData.currentState) {
            formDataObj.append("currentAddress[state]", formData.currentState);
        }
        if (formData.currentPostCode) {
            formDataObj.append("currentAddress[postCode]", formData.currentPostCode);
        }
        
        if (formData.parmanentAddressLine1) {
            formDataObj.append("parmanentAddress[addressLine1]", formData.parmanentAddressLine1);
        }
        if (formData.parmanentCity) {
            formDataObj.append("parmanentAddress[city]", formData.parmanentCity);
        }
        if (formData.parmanentState) {
            formDataObj.append("parmanentAddress[state]", formData.parmanentState);
        }
        if (formData.parmanentPostCode) {
            formDataObj.append("parmanentAddress[postCode]", formData.parmanentPostCode);
        }
        

        // Adding vehicle details
        formDataObj.append("vehicleType", formData.vehicleType);
        formDataObj.append("vehicleRegistrationNumber", formData.vehicleRegistrationNumber);
        formDataObj.append("panNo",formData.panNo);
        formDataObj.append("aadharNo",formData.aadharNo);

        // Adding image files if they are files
        if (formData.profileImage instanceof File) {
            formDataObj.append("profileImage", formData.profileImage);
        }
        if (formData.aadharFront instanceof File) {
            formDataObj.append("aadharFront", formData.aadharFront);
        }
        if (formData.aadharBack instanceof File) {
            formDataObj.append("aadharBack", formData.aadharBack);
        }
        if (formData.panCard instanceof File) {
            formDataObj.append("panCard", formData.panCard);
        }
        if (formData.drivingLicenseFront instanceof File) {
            formDataObj.append("drivingLicenseFront", formData.drivingLicenseFront);
        }
        if (formData.drivingLicenseBack instanceof File) {
            formDataObj.append("drivingLicenseBack", formData.drivingLicenseBack);
        }
        if (formData.vehicleFrontDoc instanceof File) {
            formDataObj.append("vehicleFrontDoc", formData.vehicleFrontDoc);
        }
        if (formData.vehicleBackDoc instanceof File) {
            formDataObj.append("vehicleBackDoc", formData.vehicleBackDoc);
        }

        // Check if ID exists to decide between updating or registering
        let result;
        if (id) {
            result = await updateDeliveryPartnerById(id, formDataObj); // Call update API if ID exists
        } else {
            result = await registerDeliveryPartner(formDataObj); // Otherwise call the register API
        }

        // Handling the result
        if (result.success) {
            setSnackbarMessage(
                id ? "Delivery partner Updated Successfully!" : "Delivery Partner added Successfully!"
            );
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
            setTimeout(() => {
                navigate("/delivery-partner-list");
            }, 500);
        } else {
            setSnackbarMessage(
                result.data?.message || "Failed to save delivery partner. Please try again."
            );
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };    

    return (
        <div className="AddDeliveryPartners">
            <AdminDrawer>
                <Paper
                    elevation={0}
                    sx={{
                        margin: "0 auto",
                        padding: "20px",
                        backgroundColor: "white",
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    {/* Flex container for Breadcrumbs and Heading */}
                    <Box
                        sx={{
                            display: "flex",

                            alignItems: "center",
                            mb: 3,
                        }}
                    >
                        {/* Heading */}
                        <Typography variant="h5" fontWeight="bold" sx={{ mr: 5 }}>
                            {id ? "Edit Delivery Partner Details" : "Add Delivery Partner Details"}
                        </Typography>

                        {/* Breadcrumbs beside the heading */}
                        <BreadcrumbsComponent />
                    </Box>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <DeliveryPartnerForm
                                selectedAddress={selectedAddress}
                                handleAddressChange={handleAddressChange}
                                onSubmit={handleSubmit}
                                initialValues={formData} // Pass the initial values to DeliveryPartnerForm
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </AdminDrawer>

            <CustomSnackbar
                open={snackbarOpen}
                message={snackbarMessage}
                severity={snackbarSeverity}
                onClose={handleSnackbarClose}
            />
        </div>
    );
};

export default AddDeliveryPartners;

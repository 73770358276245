import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Button, Box } from "@mui/material";
import AdminDrawer from "../../components/AdminDrawer/AdminDrawer";
import PayoutTable from "../../components/DataTables/PayoutTables/PayoutTable";
import {fetchUserById, fetchDeletedUsers } from "../../api/users/usersApi";
import BreadcrumbsComponent from "../../components/ResuableComponents/BreadCrumb";

const UsersDeleted = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const getUsers = async () => {
      try {
        const userData = await fetchDeletedUsers();        
        const mappedData = userData.map((user) => ({
          id: user._id, // Use this as a unique ID for each row
          fullName: user.fullName,
          email: user.email,
          phoneNumber: user.phoneNumber || 'N/A', // Handle missing phone numbers
          deleteReason:user.deleteReason,
          createdAt: new Date(user.createdAt).toLocaleString(), // Format the date
        }));
        setUsers(mappedData);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    getUsers();
  }, []);

  // Fetch individual user data and navigate with it
  const handleViewUser = async (row) => {
    try {
      const customerDetails = await fetchUserById(row.id); // Fetch user by ID from the API
      navigate(`/user-details/${row.id}`, { state: customerDetails }); // Navigate to user details page with data
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const columns = [
    {
      field: "fullName",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => (
        <div
          onClick={() => handleViewUser(params.row)} // Same function as the button
          style={{ cursor: "pointer" }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      renderCell: (params) => (
        <div
          onClick={() => handleViewUser(params.row)} // Same function as the button
          style={{ cursor: "pointer" }}
        >
          {params.value}
        </div>
      ),
    },
    { field: "phoneNumber", headerName: "Phone Number", flex: 1 },
    { field: "deleteReason", headerName: "Delete Reason", flex: 1 },
    
    {
      field: "createdAt",
      headerName: "Registered At",
      flex: 1,
    },
   
  ];

  return (
    <div style={{ backgroundColor: "#f2f2f2", height: "100vh" }}>
      <AdminDrawer>
        <div style={{ padding: "16px" }}>
        <Box display="flex" mb={3}>
          <Typography variant="h4" fontWeight="bolder">
            Users Deleted
          </Typography>
          <Box ml={3} mt={1}>
            <BreadcrumbsComponent />
          </Box>
        </Box>
          <PayoutTable rows={users} columns={columns} />
        </div>
      </AdminDrawer>
    </div>
  );
};

export default UsersDeleted;

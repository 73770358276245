import React, { useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import RoutingComponent from './routes/routes';
import { requestNotificationPermission } from "./utils/fcm/notificationService";

const theme = createTheme({
  palette: {
    primary: {
      main: '#0e0593', // Make sure this is a valid color code
    },
    secondary: {
      main: '#dc004e',
    },
  },
});

const App = () => {
  useEffect(() => {
    requestNotificationPermission();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RoutingComponent />
    </ThemeProvider>
  );
}

export default App;

import baseUrl from "../../config";

// Function to fetch support data based on the business type
export const fetchSupportByBusinessType = async (businessType) => {
    const url = `${baseUrl}tickets/${businessType}`; // Dynamically insert the business type into the URL

    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            throw new Error(`Error fetching data: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching support details:", error);
        return null; // Handle the error appropriately
    }
};



export const resolveTicket = async (ticketId, data) => {
  try {
    const response = await fetch(`${baseUrl}/tickets/${ticketId}/resolve`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error('Failed to update the ticket status.');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

import React from "react";
import { Breadcrumbs, Typography, Link, IconButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const BreadcrumbsComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  
  // Split the pathname into parts for breadcrumb
  const pathnames = location.pathname.split("/").filter((x) => x);
  const lastPathIndex = pathnames.length - 1;

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {/* Home breadcrumb */}
      <Link 
        underline="hover" 
        color="inherit" 
        onClick={() => navigate("/")}
        sx={{ cursor: 'pointer' }} // Set cursor to pointer on hover
      >
        <HomeIcon />
      </Link>

      {/* Second breadcrumb (Back Button) if there's more than 1 path */}
      {pathnames.length > 1 && (
        <IconButton color="inherit" onClick={() => navigate(-1)} aria-label="back">
          <ArrowBackIcon /> {/* Back icon as the second breadcrumb */}
        </IconButton>
      )}

      {/* Remaining breadcrumbs */}
      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;

        // Display the last breadcrumb as plain text
        if (index === lastPathIndex) {
          return (
            <Typography key={name} color="text.primary">
              {name.charAt(0).toUpperCase() + name.slice(1)} {/* Capitalize the last breadcrumb */}
            </Typography>
          );
        }

        // If it's the second breadcrumb, skip because it's already the back button
        if (index === 1) {
          return null;
        }

        // For other breadcrumbs, display them as links
        return (
          <Link
            key={name}
            underline="hover"
            color="inherit"
            onClick={() => navigate(routeTo)}
          >
            {name.charAt(0).toUpperCase() + name.slice(1)} {/* Capitalize breadcrumb names */}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default BreadcrumbsComponent;

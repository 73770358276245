import baseUrl from "../../config";

export const fetchCustomerSupportDetails = async () => {
  const url = `${baseUrl}usersupport`; // Update this URL if necessary.

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`Error fetching data: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error in fetchCustomerSupportDetails:", error);
    return null; // or handle error appropriately
  }
};

export const resolveTicketCustomers = async (ticketId, data) => {
  try {
    // Retrieve the access token from localStorage
    const accessToken = localStorage.getItem('accessToken');

    // If there's no access token, handle the error (e.g., by throwing an error or redirecting)
    if (!accessToken) {
      throw new Error('Access token is missing.');
    }

    // Make the API request with the Authorization header
    const response = await fetch(`${baseUrl}usersupport/${ticketId}/updateStatus`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`, // Pass the access token here
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error('Failed to update the ticket status.');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

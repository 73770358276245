import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AdminDrawer from "../../../../components/AdminDrawer/AdminDrawer";
import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  IconButton,
  Chip,
  Tooltip,
  Typography,
  styled,
  Paper,
  // Rating,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Box
} from "@mui/material";
import DataTable from "../../../../components/DataTables/DataTable";
import { deleteFessteMartLightings } from "../../../../api/servicePartners/fesstemart/fessteMartRegistrationApis";
import CustomSnackbar from "../../../../components/CustomeSnackBar/CustomSnackbar";
import ConfirmDialog from "../../../../components/ResuableComponents/ConfirmDialog";
import { useDialog } from "../../../../hooks/useDialog";
import { renderCellWithMore } from "../../../../components/ResuableComponents/ViewMoreComponents/ViewMorePopup";
import baseUrl from "../../../../config";
import BreadcrumbsComponent from "../../../../components/ResuableComponents/BreadCrumb";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; 


const TableContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  maxHeight: "100vh",
  height: "650px",
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[0],
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1),
    height: "auto",
  },
}));

const Lightings = () => {
  const { dialogContent, dialogOpen, handleOpenDialog, handleCloseDialog } =
    useDialog();

  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  // eslint-disable-next-line
  const [itemToDelete, setItemToDelete] = useState(null);
  // eslint-disable-next-line
  const [snackbarOpenClipCard, setSnackbarOpenClipCard] = useState(false);

  useEffect(() => {
    const fetchLightings = async () => {
      try {
        const response = await fetch(`${baseUrl}lightingSp`); // Replace with your API endpoint
        const jsonData = await response.json();

        // Assuming jsonData is an array of lighting objects
        const transformedData = jsonData.map((item) => ({
          id: item._id,
          lightID: item?.lightingID, // or the appropriate field for name
          fessteMartId: item?.fessteMart?._id,
          businessName: item?.businessName,
          businessContact: item?.businessPhoneNumber,
          businessImage: item?.businessProfilePhoto[0],
          spName: item.serviceProvider?.spName, // Example field
          spPhone: item.serviceProvider?.spPhone, // Customize this logic as needed
          address: `${item?.address?.addressLine1}, ${item?.address?.addressLine2},${item?.address?.landmark},${item?.address?.city}, ${item?.address?.postalCode},${item?.address?.state},${item?.address?.country}`,
          Status: item.availability ? "Active" : "In-Active",
          Rating: 4, // Set default or fetch this value if available
          ContactNumber: item.serviceProvider?.spPhone,
          CreatedOn: new Date(item.createdAt).toLocaleString(), // Format the date as needed
        }));

        setData(transformedData);
      } catch (error) {
        console.error("Error fetching lightings:", error);
      }
    };

    fetchLightings();
  }, []);

  const handleViewClick = (lightID, id) => {
    navigate(`/lighting-details/${lightID}`, { state: { id } });
  };

  const handleEditClick = (rowData) => {
    navigate(`/edit-lightings/${rowData}`, { state: rowData });
  };

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
    setSnackbarOpenClipCard(true); // Show Snackbar when text is copied
  };

  const handleBackClick = () => {
    navigate(-1); // This will take the user back to the previous page
  };

  const handleDelete = async () => {
    try {
      const response = await deleteFessteMartLightings(itemToDelete);

      if (response) {
        setSnackbarSeverity("success");
        setSnackbarMessage(
          response.message || "Fesste Mart deleted successfully"
        );
        // Close the confirmation dialog after successful deletion
        setOpenConfirmDialog(false);

        // Optionally refresh your data or update the formatdata state here
        setData((prevData) =>
          prevData.filter((item) => item.fessteMartId !== itemToDelete)
        );
      } else {
        setSnackbarSeverity("warning");
        setSnackbarMessage(
          response.message || "Operation succeeded but with warnings"
        );
      }
    } catch (error) {
      setSnackbarSeverity("error");
      setSnackbarMessage("Failed to delete Fesste Mart");
      console.error("Error during deletion:", error);
    } finally {
      setSnackbarOpen(true); // Show snackbar after API call
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  // const handleDeleteClick = (fessteMartId) => {
  //   setOpenConfirmDialog(true);
  //   // Optionally store fessteMartId to use it after confirmation
  //   setItemToDelete(fessteMartId);
  // };

  const columns = [
    {
      field: "lightID",
      headerName: "ID",
      minWidth: 10,
      sortable: true,
      renderCell: (params) => {
        const text = params.value || ""; // Ensure value is a string

        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => handleViewClick(params.row.lightID, params.row.id)}
          >
            {text}
          </div>
        );
      },
    },
    {
      field: "businessImage",
      headerName: "Business Image",
      type: "image",
      minWidth: 160,
      sortable: false,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center", // Horizontally center the image
            alignItems: "center", // Vertically center the image
            width: "100%", // Ensure the div takes full width of the cell
            height: "100%", // Ensure the div takes full height of the cell
          }}
        >
          <img
            src={
              params.value !== "N/A"
                ? params.value
                : "placeholder-image-url.jpg"
            } // Placeholder for broken images
            alt="Item"
            style={{
              width: "100px", // Fixed width
              height: "60px", // Fixed height
              borderRadius: "4px", // Rounded corners
              objectFit: "cover", // Cover the area while maintaining aspect ratio
            }}
          />
        </div>
      ),
    },
    {
      field: "businessName",
      headerName: "Business Name",
      minWidth: 200,
      sortable: true,
      renderCell: (params) => {
        const text = params.value || ""; // Ensure value is a string

        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => handleViewClick(params.row.lightID, params.row.id)}
          >
            {text}
          </div>
        );
      },
    },
    {
      field: "businessContact",
      headerName: "Business Contact",
      minWidth: 200,
      sortable: true,
    },
    {
      field: "spName",
      headerName: "Owner Name",
      minWidth: 200,
      sortable: true,
    },
    {
      field: "spPhone",
      headerName: "Owner Contact",
      minWidth: 200,
      sortable: true,
    },
    {
      field: 'address',
      headerName: 'Address',
      minWidth: 350,
      flex: 1, // Makes the column responsive based on available space
      renderCell: (params) => {
        const text = params.value || ""; // Ensure value is a string
        return (
          <Box
            sx={{
              display: 'flex',         // Use flexbox for alignment
              justifyContent: 'space-between', // Align text on the left and icon on the right
              alignItems: 'center',    // Align vertically to the center
              whiteSpace: 'normal',    // Allow the text to wrap to the next line
              wordWrap: 'break-word',  // Break long words into the next line
              overflow: 'visible',     // Ensure the content isn't hidden
              textOverflow: 'clip',    // Avoid ellipsis and display all text
              lineHeight: 1.2,         // Adjust line height for better readability
              height: '100%',          // Ensure the content fills the cell vertically
              padding: '12px 10px',    // Add padding for better spacing within the cell
            }}
          >
            <Box sx={{ flexGrow: 1, paddingRight: '10px' }}>
              {/* Render the address value with "Show More" functionality */}
              {renderCellWithMore(text, handleOpenDialog)}
            </Box>
            <IconButton
              aria-label="copy to clipboard"
              size="small"
              onClick={() => handleCopy(params.value)}  // Trigger copy action
            >
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </Box>
        );
      },
    },
    {
      field: "Status",
      headerName: "Status",
      minWidth: 200,
      sortable: true,
      renderCell: (params) => (
        <Chip
          label={params.value}
          color={params.value === "Available" ? "success" : "warning"}
        />
      ),
    },
    // {
    //   field: "Rating",
    //   headerName: "Rating",
    //   minWidth:200,
    //   sortable: true,
    //   renderCell: (params) => <Rating value={params.value} readOnly />,
    // },

    {
      field: "CreatedOn",
      headerName: "Created On",
      minWidth: 200,
      sortable: true,
    },
    {
      field: "Actions",
      headerName: "Actions",
      minWidth: 160,
      sortable: false,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Tooltip title="View">
            <IconButton
              sx={{ color: "#87CEEB", marginRight: "8px" }}
              onClick={() => handleViewClick(params.row.lightID, params.row.id)}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit">
            <IconButton
              color="success"
              onClick={() => handleEditClick(params.row.id)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          {/* <Tooltip title="Delete">
            <IconButton
              color="error"
              onClick={() => handleDeleteClick(params.row.fessteMartId)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip> */}
        </div>
      ),
    },
  ];

  return (
    <div style={{ backgroundColor: "#f2f2f2" }}>
      <AdminDrawer>
        <Box display="flex" alignItems="center" mb={2}>
        <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={handleBackClick}
            sx={{ mr: 2 }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4" fontWeight="bolder" sx={{mr: 3}}>
            Lightings
          </Typography>
          <BreadcrumbsComponent />
        </Box>
        <TableContainer>
          <DataTable rows={data} columns={columns} />
        </TableContainer>
        <ConfirmDialog
          open={openConfirmDialog}
          onClose={() => setOpenConfirmDialog(false)}
          onConfirm={handleDelete}
          title="Confirm Deletion"
          content="Are you sure you want to delete this Lightings?"
        />
        <CustomSnackbar
          open={snackbarOpen}
          message={snackbarMessage}
          severity={snackbarSeverity}
          onClose={handleCloseSnackbar}
        />
        <Dialog open={dialogOpen} onClose={handleCloseDialog}>
          <DialogTitle>Details</DialogTitle>
          <DialogContent>
            <Typography>{dialogContent}</Typography>
          </DialogContent>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </Dialog>
      </AdminDrawer>
    </div>
  );
};

export default Lightings;

import React from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Paper, Divider, Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import AdminDrawer from '../../../components/AdminDrawer/AdminDrawer';

const CaterOrderDetails = () => {
    const { orderId } = useParams();

    // Fetch order details based on orderId or use dummy data
    const orderData = [
        { id: 1, orderId: "ORD123", customerName: "John Doe", orderType: "Delivery", deliveryPerson: "Jane Smith", restaurant: "Tandoori Delights", date: "22nd August 2024", address: "B-12, Sector 15, Noida, Uttar Pradesh, India", paymentMode: "Credit Card", total: "$100", status: "Delivered" },
        // Add more data as needed...
    ];

    const order = orderData.find(order => order.orderId === orderId) || {
        orderId: "N/A",
        customerName: "N/A",
        orderType: "N/A",
        deliveryPerson: "N/A",
        restaurant: "N/A",
        date: "N/A",
        address: "N/A",
        paymentMode: "N/A",
        total: "$0",
        status: "N/A",
        items: [
            { image: "image1.png", name: "Item 1", description: "Description 1", size: "Medium", addons: "None", quantity: 1, price: "$10" },
            { image: "image2.png", name: "Item 2", description: "Description 2", size: "Large", addons: "Extra Cheese", quantity: 2, price: "$20" }
        ]
    };

    return (
        <AdminDrawer>
            <div style={{ padding: '16px', backgroundColor: '#f2f2f2', minHeight: '100vh' }}>
                <Paper style={{ padding: '16px', maxWidth: '794px', margin: '0 auto' }}>
                    <Box style={{ padding: '16px', textAlign: 'center' }}>
                        <Typography variant='h4' fontWeight='bolder'>{order.restaurant}</Typography>
                        <Typography variant='body2'>Order Status: {order.status}</Typography>
                        <Typography variant='body2'>Ordered On: {order.date}</Typography>
                    </Box>
                    <Divider style={{ margin: '16px 0' }} />

                    <Box display="flex" justifyContent="space-between">
                        <Box>
                            <Typography variant='h6'>Customer Details:</Typography>
                            <Typography>{order.customerName}</Typography>
                            <Typography>{order.address}</Typography>
                        </Box>
                        <Box>
                            <Typography variant='h6'>Restaurant Details:</Typography>
                            <Typography>{order.restaurant}</Typography>
                            <Typography>{order.deliveryPerson}</Typography>
                        </Box>
                    </Box>

                    <Divider style={{ margin: '16px 0' }} />

                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Image</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Size</TableCell>
                                <TableCell>Addons</TableCell>
                                <TableCell>Quantity</TableCell>
                                <TableCell>Price</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {order.items && order.items.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell><img src={item.image} alt={item.name} style={{ width: '50px', height: '50px' }} /></TableCell>
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell>{item.description}</TableCell>
                                    <TableCell>{item.size}</TableCell>
                                    <TableCell>{item.addons}</TableCell>
                                    <TableCell>{item.quantity}</TableCell>
                                    <TableCell>{item.price}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    <Divider style={{ margin: '16px 0' }} />

                    <Box display="flex" justifyContent="flex-end">
                        <Box>
                            <Typography>Item Total: {order.total}</Typography>
                            <Typography>Packing Charge: $5</Typography>
                            <Typography>Delivery Fee: $0</Typography>
                            <Typography>Tax: $2</Typography>
                            <Typography>Tips: $3</Typography>
                            <Typography>Total Discount: $0</Typography>
                            <Typography variant="h6">Total: {order.total}</Typography>
                        </Box>
                    </Box>
                </Paper>
            </div>
        </AdminDrawer>
    );
};

export default CaterOrderDetails;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Chip, Box } from "@mui/material";
import AdminDrawer from "../../../components/AdminDrawer/AdminDrawer";
import ReportsTable from "../../../components/DataTables/ReportsTables/ReportsTable";
import { fetchCatererReports } from "../../../api/reports/catererReportsApi";
import BreadcrumbsComponent from "../../../components/ResuableComponents/BreadCrumb";

const CatererReports = () => {
  const navigate = useNavigate();
  const [reportData, setReportData] = useState([]);

  // Move handleViewClick inside the component
  const handleViewClick = (catererId) => {
    navigate(`/caterer-details/${catererId}`, {
      state: { catererId  }, // Passing both catererId and serviceProviderId
    });
  };

  // Define columns after handleViewClick so it has access to it
  const columns = [
    {
      field: "catererName",
      headerName: "Caterer Name",
      minWidth: 200,
      renderCell: (params) => {
        const text = params.value || ""; // Ensure value is a string
        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              handleViewClick(
                params.row.catererId,
                params.row.serviceProviderId
              )
            }
          >
            {text}
          </div>
        );
      },
    },
    { field: "phone", headerName: "Phone Number", minWidth: 200 },
    { field: "email", headerName: "Email", minWidth: 250 },
    { field: "totalOrders", headerName: "Total Orders", minWidth: 200 },
    { field: "acceptedOrders", headerName: "Accepted Orders", minWidth: 200 },
    { field: "deliveredOrders", headerName: "Delivered Orders", minWidth: 200 },
    { field: "rejectedOrders", headerName: "Rejected Orders", minWidth: 200 },
    {
      field: "totalOrderValue",
      headerName: "Total Order Value (₹)",
      minWidth: 250,
    },
    { field: "totalPayout", headerName: "Total Payout (₹)", minWidth: 200 },
    {
      field: "completedPayout",
      headerName: "Completed Payout (₹)",
      minWidth: 260,
    },
    { field: "pendingPayout", headerName: "Pending Payout (₹)", minWidth: 250 },
    { field: "totalGst", headerName: "Total GST (₹)", minWidth: 200 },
    {
      field: "totalPlatformFee",
      headerName: "Total Platform Fee (₹)",
      minWidth: 250,
    },
    {
      field: "totalPlatformFeeGst",
      headerName: "Platform Fee GST (₹)",
      minWidth: 250,
    },
    {
      field: "totalDeliveryFee",
      headerName: "Total Delivery Fee (₹)",
      minWidth: 250,
    },
    {
      field: "totalBuffetCharge",
      headerName: "Total Buffet Charge (₹)",
      minWidth: 250,
    },
    {
      field: "totalFessteFee",
      headerName: "Total Fesste Fee (₹)",
      minWidth: 250,
    },
    {
      field: "totalTransactionFee",
      headerName: "Total Transaction Fee (₹)",
      minWidth: 250,
    },
    {
      field: "totalGstOnFessteFee",
      headerName: "GST on Fesste Fee (₹)",
      minWidth: 250,
    },
    { field: "totalTds", headerName: "Total TDS (₹)", minWidth: 200 },
    {
      field: "status",
      headerName: "Status",
      minWidth: 200,
      sortable: true,
      renderCell: (params) => (
        <Chip
          label={params.value}
          color={params.value === "Active" ? "success" : "warning"}
        />
      ),
    },
    { field: "date", headerName: "Date", minWidth: 200, sortable: true },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchCatererReports();
        console.log(data);
        
        // Map API response to match the structure expected by the table
        const mappedData = data.map((caterer) => ({
          id: caterer.catererId,
          catererId: caterer.catID,
          catererName: caterer.catererName,
          phone: caterer.contact.phone,
          email: caterer.contact.email,
          totalOrders: caterer.totalOrders,
          newOrders: caterer.newOrders,
          acceptedOrders: caterer.acceptedOrders,
          deliveredOrders: caterer.deliveredOrders,
          canceledOrders: caterer.canceledOrders,
          orderReadyOrders: caterer.orderReadyOrders,
          pickedUpOrders: caterer.pickedUpOrders,
          rejectedOrders: caterer.rejectedOrders,
          totalOrderValue: `₹${caterer.totalOrderValue}`,
          totalPayout: `₹${caterer.totalPayout}`,
          completedPayout: `₹${caterer.completedPayout}`,
          pendingPayout: `₹${caterer.pendingPayout}`,
          totalGst: `₹${caterer.totalGst}`,
          totalPlatformFee: `₹${caterer.totalPlatformFee}`,
          totalPlatformFeeGst: `₹${caterer.totalPlatformFeeGst}`,
          totalDeliveryFee: `₹${caterer.totalDeliveryFee}`,
          totalBuffetCharge: `₹${caterer.totalBuffetCharge}`,
          totalFessteFee: `₹${caterer.totalFessteFee}`,
          totalTransactionFee: `₹${caterer.totalTransactionFee}`,
          totalGstOnFessteFee: `₹${caterer.totalGstOnFessteFee}`,
          totalTds: `₹${caterer.totalTds}`,
          status: "Active", // Adjust the logic if needed
          date: new Date().toLocaleDateString(), // Adjust the date format as needed
        }));
        setReportData(mappedData);
        
      } catch (error) {
        console.error("Error fetching caterer reports:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div style={{ backgroundColor: "#f2f2f2", height: "80vh" }}>
      <AdminDrawer>
        <div style={{ padding: "16px" }}>
          <Box display="flex" mb={2}>
            <Typography variant="h4" fontWeight="bolder">
              Caterer Reports
            </Typography>
            <Box sx={{ mt: 1, ml: 3 }}>
              <BreadcrumbsComponent />
            </Box>
          </Box>

          <ReportsTable rows={reportData} columns={columns} />
        </div>
      </AdminDrawer>
    </div>
  );
};

export default CatererReports;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Paper, Typography, List, ListItem, ListItemAvatar, Avatar, ListItemText, CircularProgress } from '@mui/material';
import { green } from '@mui/material/colors';
import { fetchUserById } from '../../api/users/usersApi';
import BASE_URL from '../../config';

const DashBoardTopUsers = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchTopUsers = async () => {
            try {
                const response = await fetch(`${BASE_URL}dashboard/topUsers`);
                if (!response.ok) throw new Error("Failed to fetch top users");
                const data = await response.json();
                setUsers(data.topUsers.slice(0, 10)); // Limit to top 10 users
            } catch (error) {
                console.error("Error fetching top users:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchTopUsers();
    }, []);


    // Fetch individual user data and navigate with it
    const handleViewDetails = async (row) => {
        console.log('row', row);

        try {
            const customerDetails = await fetchUserById(row); // Fetch user by ID from the API
            navigate(`/user-details/${row}`, { state: customerDetails }); // Navigate to user details page with data
        } catch (error) {
            console.error("Error fetching user details:", error);
        }
    };

    return (
        <Paper sx={{ maxWidth: 400, borderRadius: 2, padding: 2 }} elevation={0}>
            <Typography variant="h6" gutterBottom>
                Top Users
            </Typography>
            {loading ? (
                <CircularProgress />
            ) : (
                <List>
                    {users.length > 0 ? users.map((user, index) => (
                        // Updated onClick event to pass the entire user object
                        <ListItem
                            key={index}
                            disableGutters
                            onClick={() => handleViewDetails(user?._id)} // Pass the entire user object
                            sx={{ cursor: 'pointer' }} // Add cursor style
                        >

                            <ListItemAvatar>
                                <Avatar>
                                    {user.name ? user.name[0].toUpperCase() : 'U'}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={user.name}
                                secondary={`Orders: ${user.orders}`}
                            />
                            <Typography variant="body2" sx={{ color: green[500], fontWeight: 'bold' }}>
                                ₹ {parseFloat(user.amount).toFixed(2)} {/* Convert to float and format */}
                            </Typography>
                        </ListItem>
                    )) : (
                        <Typography variant="body2">No top users available</Typography>
                    )}
                </List>
            )}
        </Paper>
    );
};

export default DashBoardTopUsers;

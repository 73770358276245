import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Typography, Chip, Box } from '@mui/material';
import AdminDrawer from '../../components/AdminDrawer/AdminDrawer';
import ReportsTable from '../../components/DataTables/ReportsTables/ReportsTable';
import { fetchUserReports } from '../../api/reports/userReportsApi';
import { fetchUserById } from "../../api/users/usersApi";
import BreadcrumbsComponent from '../../components/ResuableComponents/BreadCrumb';


const UsersReports = () => {
    const navigate = useNavigate();
    const [reportData, setReportData] = useState([]);

    const columns = [
    { field: 'fullName', headerName: 'Full Name', minWidth: 200 , renderCell: (params) => (
        <div
          onClick={() => handleViewUser(params.row)} // Same function as the button
          style={{
            cursor: "pointer",
          }}
        >
          {params.value}
        </div>
      ),},
    { field: 'phoneNumber', headerName: 'Phone Number', minWidth: 200 },
    { field: 'email', headerName: 'Email', minWidth: 250 },
    { field: 'totalOrders', headerName: 'Total Orders', minWidth: 200 },
    { field: 'deliveredOrders', headerName: 'Delivered Orders', minWidth: 200 },
    { field: 'cancelledOrders', headerName: 'Cancelled Orders', minWidth: 200 },
    { field: 'refundedOrders', headerName: 'Refunded Orders', minWidth: 200 },
    { field: 'mostlyUsedPaymentMethod', headerName: 'Most Used Payment Method', minWidth: 300 },
    { field: 'catererOrdersCount', headerName: 'Caterer Orders Count', minWidth: 250 },
    { field: 'fessteMartOrdersCount', headerName: 'FessteMart Orders Count', minWidth: 300 },
    { field: 'restOrdersCount', headerName: 'Restaurant Orders Count', minWidth: 250 },
    { field: 'totalSpentOnCaterers', headerName: 'Amount Spent (Caterers)', minWidth: 250 },
    { field: 'totalSpentOnRestaurants', headerName: 'Amount Spent (Restaurant)', minWidth: 270 },
    { field: 'totalSpentOnFessteMart', headerName: 'Amount Spent (FessteMart)', minWidth: 270 },
   
    // { field: 'mostlyOrderedServiceProvider', headerName: 'Mostly Ordered Service Provider', minWidth: 250 },
    {
        field: 'status',
        headerName: 'Status',
        minWidth: 200,
        sortable: true,
        renderCell: (params) => (
            <Chip label={params.value} color={params.value === 'Active' ? 'success' : 'warning'} />
        ),
    },
    { field: 'date', headerName: 'Date', minWidth: 200, sortable: true },
];

     // Fetch individual user data and navigate with it
  const handleViewUser = async (row) => {
    try {
      const customerDetails = await fetchUserById(row.id); // Fetch user by ID from the API
      navigate(`/user-details/${row.id}`, { state: customerDetails }); // Navigate to user details page with data
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchUserReports();
                // Map API response to match the structure expected by the table
                const mappedData = data.map(user => ({
                    id: user?.userId ?? 'N/A', 
                    fullName: user?.userDetails?.fullName ?? 'N/A', 
                    phoneNumber: user?.userDetails?.phoneNumber ?? 'N/A', 
                    email: user?.userDetails?.email ?? 'N/A',
                    totalOrders: user?.totalOrders ?? 0, 
                    deliveredOrders: user?.deliveredOrders ?? 0, 
                    cancelledOrders: user?.cancelledOrders ?? 0, 
                    refundedOrders: user?.refundedOrders ?? 0, 
                    mostlyUsedPaymentMethod: user?.mostlyUsedPaymentMethod ?? 'Unknown',
                    catererOrdersCount: user?.catererOrdersCount ?? 0,
                    fessteMartOrdersCount: user?.fessteMartOrdersCount ?? 0,
                    restOrdersCount: user?.restOrdersCount ?? 0, 
                    mostlyOrderedServiceProvider: user?.mostlyOrderedServiceProvider ?? 'N/A', 
                    status: 'Active', 
                    date: new Date().toLocaleDateString(), 
                    totalSpentOnCaterers: user?.totalSpentOnCaterers,
                    totalSpentOnFessteMart: user?.totalSpentOnFessteMart,
                    totalSpentOnRestaurants: user?.totalSpentOnRestaurants
                }));
                setReportData(mappedData);
            } catch (error) {
                console.error('Error fetching user reports:', error);
            }
        };
        fetchData();
    }, []);


    return (
        <div style={{ backgroundColor: '#f2f2f2', height: '80vh' }}>
            <AdminDrawer>
                <div style={{ padding: '16px' }}>
                    <Box display="flex" mb={2} >
                    <Typography variant='h4' fontWeight='bolder'>Users Reports</Typography>
                        <Box mt={1} ml={2}>
                        <BreadcrumbsComponent />
                        </Box>
                    </Box>
                    
                    <ReportsTable
                        rows={reportData}
                        columns={columns}
                    />
                </div>
            </AdminDrawer>
        </div>
    );
};

export default UsersReports;

import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Chip,
  CircularProgress,
  Container,
  Tooltip,
  Box,
  IconButton,
  Button,
  useTheme,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Modal,
  Select,
  MenuItem,
  DialogActions
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CatererOrdersTable from "../../../components/DataTables/OrdersTables/CatererOrderTables";
import {
  fetchCatererOrders,
  fetchCatererOrderDetails,
  updateDeiveryType,
} from "../../../api/orders/catererOrders";
import CustomSnackbar from "../../../components/CustomeSnackBar/CustomSnackbar"; // Import CustomSnackbar
import { useDialog } from "../../../hooks/useDialog";
import { renderCellWithMore } from "../../../components/ResuableComponents/ViewMoreComponents/ViewMorePopup";
import { fetchUserById } from "../../../api/users/usersApi";
import BreadcrumbsComponent from "../../../components/ResuableComponents/BreadCrumb";
import BASE_URL from "../../../config";

const CatererOrders = () => {
  const { dialogContent, dialogOpen, handleOpenDialog, handleCloseDialog } =
    useDialog();

  const theme = useTheme(); // Get the theme
  const [orderData, setOrderData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [backgroundColor, setBackgroundColor] = useState("#f2f2f2"); // Default background color
  const navigate = useNavigate();
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [customRejectReason, setCustomRejectReason] = useState("");
  const [currentOrderId, setCurrentOrderId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [deliveryPartners, setDeliveryPartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState("");
  const [selectedOrderId, setSelectedOrderId] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [receiptNumber, setReceiptNumber] = useState("");
  const [orderId, setOrderId] = useState(null);
  const [paymentType, setPaymentType] = useState("");

  // Function to open the dialog with the respective payment type
  const handleDialogOpen = (id, type) => {
    setOrderId(id);
    setPaymentType(type);
    setOpenDialog(true);
  };

  // Function to handle the dialog close
  const handleDialogClose = () => {
    setOpenDialog(false);
    setReceiptNumber("");
  };

  // Function to handle receipt number submission and update the payment status using fetch
  const handleSubmitReceipt = async () => {
    if (!receiptNumber) {
      alert("Please enter a receipt number.");
      return;
    }

    try {
      const response = await fetch(
        `https://admin.fesste.com/api/updateCatOrderStatus/${orderId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            paymentType,
            receiptNumber,
          }),
        }
      );

      // Check if the response is ok (status code 200-299)
      if (!response.ok) {
        throw new Error("Error updating payment status");
      }

      setSnackbar({
        open: true,
        message: "Order Status Updated",
        severity: "success",
      });

      getOrders();

      setOpenDialog(false); // Close the dialog after successful update
      setReceiptNumber(""); // Reset the receipt number field
    } catch (error) {
      console.error("Error updating payment status", error);
    }
  };

  // Wrap getOrders in useCallback
  const getOrders = useCallback(async () => {
    try {
      const data = await fetchCatererOrders();
      const updatedOrders = data.map((order) => ({
        ...order,
        // Disable the respective button based on the deliveryType
        fidDisabled: order.deliveryType === "FID",
        fedDisabled: order.deliveryType === "FED",
      }));

      setOrderData(updatedOrders);
      setLoading(false);

      // Set background color based on delivery type
      if (updatedOrders.length > 0) {
        const deliveryType = updatedOrders[0].deliveryType; // Get the delivery type of the first order
        setBackgroundColor(getBackgroundColor(deliveryType)); // Update background color
      }
    } catch (error) {
      setLoading(false);
    }
  }, []); // No dependencies needed here

  const getBackgroundColor = (deliveryType) => {
    switch (deliveryType) {
      case "FID":
        return "#e3f2fd"; // Light blue
      case "FED":
        return "#ffe0b2"; // Light orange
      case "NewOrder":
        return "#e8f5e9"; // Light green
      default:
        return "#f2f2f2"; // Default color
    }
  };

  useEffect(() => {
    getOrders();
  }, [getOrders]); // Include getOrders in the dependency array

  const acceptOrder = async (id) => {
    try {
      const response = await fetch(
        `https://admin.fesste.com/api/accept_catOrder/${id}`,
        {
          method: "POST",
        }
      );

      if (response.ok) {
        const data = await response.json(); // Assuming the response contains order details
        setOrderData((prevOrders) =>
          prevOrders.map((order) =>
            order.id === id ? { ...order, status: data.status } : order
          )
        );

        setSnackbar({
          open: true,
          message: "Order accepted successfully",
          severity: "success",
        });

        getOrders(); // Optional if this refreshes data
      } else {
        throw new Error("Failed to accept order");
      }
    } catch (error) {
      console.error("Failed to accept order:", error);
      setSnackbar({
        open: true,
        message: "Failed to accept order",
        severity: "error",
      });
    }
  };

  const rejectOrderApi = async (id, rejectReason, customRejectReason = "") => {
    await fetch(`https://admin.fesste.com/api/reject_catOrder/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ rejectReason, customRejectReason }),
    });
  };

  const handleRejectOrder = async () => {
    try {
      await rejectOrderApi(currentOrderId, rejectReason, customRejectReason);
      setSnackbar({
        open: true,
        message: "Order rejected successfully",
        severity: "success",
      });
      setRejectDialogOpen(false);
      getOrders();
    } catch (error) {
      console.error("Failed to reject order:", error);
      setSnackbar({
        open: true,
        message: "Failed to reject order",
        severity: "error",
      });
    }
  };

  const openRejectDialog = (orderId) => {
    setCurrentOrderId(orderId);
    setRejectDialogOpen(true);
  };

  const handleOpenModal = async (orderId) => {
    setSelectedOrderId(orderId);
    setOpenModal(true);

    // Fetch delivery partners from the API
    try {
      const response = await fetch(`${BASE_URL}/getNearByDp/${orderId}`);
      const data = await response.json();
      setDeliveryPartners(data?.data); // Assuming the response is an array of partners
    } catch (error) {
      console.error("Failed to fetch delivery partners:", error);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedPartner("");
  };

  // Function to handle assigning delivery partner
  const handleAssignPartner = async () => {
    if (!selectedPartner) return;

    const requestBody = {
      catererOrderId: selectedOrderId,
      deliveryPartnerId: selectedPartner,
    };

    try {
      const response = await fetch(`${BASE_URL}assignCatOrder`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
      const data = await response.json();

      if (data.success && data.data.status === "accepted") {
        // Update order data in state
        setOrderData((prevOrders) =>
          prevOrders.map((order) =>
            order._id === data.data.catererOrderId
              ? { ...order, deliveryStatus: "accepted" }
              : order
          )
        );

        setSnackbar({
          open: true,
          message: "Delivery partner assigned successfully",
          severity: "success",
        });
        handleCloseModal(); // Close modal after success
        getOrders(); // Re-fetch data after assignment
      }
    } catch (error) {
      console.error("Failed to assign delivery partner:", error);
      setSnackbar({
        open: true,
        message: "Failed to assign delivery partner",
        severity: "error",
      });
    }
  };

  const handleCatererDetails = (catererId) => {
    navigate(`/caterer-details/${catererId}`, {
      state: { catererId }, // Passing both catererId and serviceProviderId
    });
  };

  const handleViewDetails = async (orderId) => {
    try {
      const data = await fetchCatererOrderDetails(orderId);
      navigate(`/caterer-order-invoice/${orderId}`, {
        state: { orderData: data },
      });
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  const handleViewUser = async (row) => {
    try {
      const customerDetails = await fetchUserById(row.userId); // Correct the field to 'userId'
      navigate(`/user-details/${row.userId}`, { state: customerDetails }); // Use the proper userId for navigation
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const handleUpdateDeliveryType = async (orderId, deliveryType) => {
    try {
      await updateDeiveryType({ orderId, deliveryType });
      setSnackbar({
        open: true,
        message: `Delivery Type updated to ${deliveryType}`,
        severity: "success",
      });
      getOrders();
    } catch (error) {
      console.error("Failed to update delivery type:", error);
      setSnackbar({
        open: true,
        message: "Failed to update delivery type",
        severity: "error",
      });
    }
  };

  // Function to handle unassigning the delivery partner
  const handleUnassignOrder = async (id, deliveryPartnerId) => {
    try {
      const response = await fetch(`${BASE_URL}unAssignCatOrder`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          catererOrderId: id,
          deliveryPartnerId: deliveryPartnerId,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json();

      if (responseData.success) {
        // Optimistically update the order data state
        setOrderData((prevOrders) =>
          prevOrders.map((order) =>
            order._id === id
              ? {
                  ...order,
                  deliveryStatus: "unassigned",
                  deliveryPartnerId: null,
                }
              : order
          )
        );

        setSnackbar({
          open: true,
          message: "Delivery partner unassigned successfully",
          severity: "success",
        });
        getOrders(); // Re-fetch data after unassignment
      }
    } catch (error) {
      console.error("Error unassigning delivery partner:", error);
      setSnackbar({
        open: true,
        message: "Failed to unassign delivery partner",
        severity: "error",
      });
    }
  };

  const columns = [
    { field: "siNo", headerName: "ID", minWidth: 120 },
    {
      field: "orderId",
      headerName: "Order ID",
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Typography
            onClick={() => handleViewDetails(params.row.id)}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "14px",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            {params.value} {/* Caterer name displayed here */}
          </Typography>
        );
      },
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Typography
            onClick={() => handleViewUser(params.row)} // Use handleViewUser with the full row
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "14px",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            {params.value}
          </Typography>
        );
      },
    },
    { field: "customerContact", headerName: "Customer Contact", minWidth: 200 },
    { field: "date", headerName: "Order Date", minWidth: 200 },
    {
      field: "caterer",
      headerName: "Caterer Name",
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Typography
            onClick={() => handleCatererDetails(params.row.catererId)}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "14px",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            {params.value} {/* Caterer name displayed here */}
          </Typography>
        );
      },
    },
    { field: "packageType", headerName: "Package Ordered", minWidth: 200 },
    { field: "quantity", headerName: "Quantity", minWidth: 200 },
    { field: "rejectReason", headerName: "Reject Reason", minWidth: 200 },
    { field: "deliveryDate", headerName: "Delivery Date", minWidth: 200 },
    {
      field: "shippingAddress",
      headerName: "Delivered Address",
      minWidth: 250,
      sortable: true,
      renderCell: (params) => {
        const text = params.value || ""; // Ensure value is a string
        return renderCellWithMore(text, handleOpenDialog);
      },
    },
    { field: "deliveredBy", headerName: "Delivered By", minWidth: 200 },
    {
      field: "orderMode",
      headerName: "Order Mode",
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Chip
            label={params.value}
            style={{
              backgroundColor: params.value === "book" ? "#FFA500" : params.value === "paid" ? "green" : "#f5f5f5",
              color: params.value === "book" || params.value === "paid" ? "white" : "black",
            }}
          />
        );
      },
    },    
    {
      field: "grossAmount",
      headerName: "Gross Amount",
      minWidth: 300,
    },
    {
      field: "totalBillValue",
      headerName: "Total Amount(Before Discount)",
      minWidth: 300,
    },
    {
      field: "promocode",
      headerName: "Discount/Coupon Code Used",
      minWidth: 300,
    },
    { field: "discountAmount", headerName: "Discount Amount", minWidth: 250 },

    { field: "buffetCharge", headerName: "Buffet Charges", minWidth: 250 },
    { field: "tissuePackets", headerName: "Tissue Packets", minWidth: 250 },
    {
      field: "additionalTissueCharges",
      headerName: "Tissue Charges",
      minWidth: 250,
    },
    { field: "waterBottlePackets", headerName: "Water Bottles", minWidth: 250 },
    {
      field: "additionlWBCharges",
      headerName: "Water Bottle Charges",
      minWidth: 250,
    },
    {
      field: "totalAmount",
      headerName: "Total Amount(After Discount)",
      minWidth: 300,
    },
    { field: "paymentStatus", headerName: "Payment Status", minWidth: 300 },

    { field: "initialPayment", headerName: "Initial Payment", minWidth: 300 },
    { field: "initialOnlinePayment", headerName: "Initial Online Payment", minWidth: 300 },
    {
      field: "remainingInitialCashPayment",
      headerName: "Initial Cash Payment",
      minWidth: 300,
      renderCell: (params) => {
        
        const shouldDisplayButton = params.row.cashCollected === false;
    
        return (
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
            <span>{params.value}</span>
            {shouldDisplayButton && (
              <Button
                variant="contained"
                size="small"
                onClick={() => handleDialogOpen(params.row.id, "initial")}
                disabled={params.row.remainingInitialCashPayment === 0}
              >
                Enter Receipt
              </Button>
            )}
          </div>
        );
      },
    },
    ,
    { field: "secondPayment", headerName: "Second Payment", minWidth: 300 },
    {
      field: "secondPaymentDueDate",
      headerName: "Second Payment Due Date",
      minWidth: 300,
    },
    {
      field: "secondPaymentStatus",
      headerName: "Second Payment Status",
      minWidth: 300,
      renderCell: (params) => {
        
        return (
          <>
            <Chip label={params.value} color="primary" />
            {(params.value === "pending" || params.row.remainingPaymentMethod === "cash" ) && (
              <Button
                variant="contained"
                size="small"
                onClick={() => handleDialogOpen(params.row.id, "second")}
                disabled={params.row.secondPayment === 0}
                style={{ marginLeft: "58px" }}
              >
                Enter Receipt
              </Button>
            )}
          </>
        );
      },
    },
    { field: "finalPayment", headerName: "Final Payment", minWidth: 300 },
    {
      field: "finalPaymentDueDate",
      headerName: "Final Payment Due Date",
      minWidth: 300,
    },
    {
      field: "finalPaymentStatus",
      headerName: "Final Payment Status",
      minWidth: 300,
      renderCell: (params) => {
        return (
          <>
            <Chip label={params.value} color="primary" />
            {(params.value === "pending" || params.row.remainingPaymentMethod === "cash" ) && (
              <Button
                variant="contained"
                size="small"
                onClick={() => handleDialogOpen(params.row.id, "final")}
                style={{ marginLeft: "58px" }}
                disabled={params.row.finalPayment === 0}
              >
                Enter Receipt
              </Button>
            )}
          </>
        );
      },
    },
    
    {
      field: "remainingPaymentMethod",
      headerName: "Balance Payment Mode",
      minWidth: 300,
    },
    {
      field: "remainingCashPaymentHandling",
      headerName: "Payment Handling Mode",
      minWidth: 300,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 200,
      renderCell: (params) => {
        const statusMapping = {
          newOrder: "New Order",
          preparing: "Preparing",
          Delivered: "Delivered",
          Cancelled: "Cancelled",
          Refunded: "Refunded",
          Rejected: "Rejected",
        };

        const statusColors = {
          Delivered: "success",
          Rejected: "error",
          newOrder: "success",
          preparing: "warning",
          Cancelled: "default",
          Refunded: "info",
        };

        const label = statusMapping[params.value] || params.value;

        return (
          <Chip label={label} color={statusColors[params.value] || "primary"} />
        );
      },
    },
    {
      field: "acceptOrder",
      headerName: "Order Action",
      minWidth: 260,
      sortable: false,
      renderCell: (params) => (
        <>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => acceptOrder(params.row.id)}
            disabled={params.row.status !== "newOrder"}
            sx={{
              padding: "4px 8px",
              fontSize: "0.75rem",
              marginRight: "4px", // Small margin for spacing between buttons
            }}
          >
            Accept Order
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => openRejectDialog(params.row.id)}
            disabled={params.row.status !== "newOrder"}
            sx={{
              padding: "4px 8px",
              fontSize: "0.75rem",
            }}
          >
            Reject Order
          </Button>
        </>
      ),
    },
    {
      field: "assignDeliveryPartner",
      headerName: "Assign Delivery Partner",
      minWidth: 220,
      sortable: false,
      renderCell: (params) => (
        <>
          {(params.row.status === "accepted" ||
            params.row.status === "orderReady" ||
            params.row.status === "preparing") && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center", // Center horizontally
                alignItems: "center", // Center vertically
                gap: "8px", // Space between buttons
                marginTop: "10px",
              }}
            >
              {/* Assign Delivery Partner Button */}
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => handleOpenModal(params.row.id)}
                disabled={params.row.deliveryStatus === "assigned"}
                sx={{
                  padding: "4px 8px",
                  fontSize: "0.75rem",
                }}
              >
                Assign
              </Button>

              {/* Unassign Delivery Partner Button */}
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={() =>
                  handleUnassignOrder(
                    params.row.id,
                    params.row.deliveryPartnerId
                  )
                }
                sx={{
                  padding: "4px 8px",
                  fontSize: "0.75rem",
                }}
              >
                Unassign
              </Button>
            </Box>
          )}
        </>
      ),
    },
    {
      field: "deliveryStatus",
      headerName: "Delivery Status",
      minWidth: 200,
      renderCell: (params) => {
        // Map the statuses to labels
        const statusMapping = {
          assigned: "Assigned",
          unassigned: "Unassigned",
        };

        // Define colors for each status
        const statusColors = {
          assigned: "success", // Green for assigned
          unassigned: "default", // Grey for unassigned
        };

        // Get the label and color based on the status
        const label = statusMapping[params.value] || params.value;
        const color = statusColors[params.value] || "primary"; // Default to "primary" if not matched

        return <Chip label={label} color={color} />;
      },
    },
    {
      field: "deliveryPartnerName",
      headerName: "Delivery Partner Name",
      minWidth: 300,
    },
    {
      field: "deliveryPartnerContact",
      headerName: "Delivery Partner Contact",
      minWidth: 300,
    },
    {
      field: "pickUpOtp",
      headerName: "Pick Up OTP",
      minWidth: 300,
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 200,
      sortable: false,
      renderCell: (params) => (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleUpdateDeliveryType(params.row.id, "FID")}
            style={{
              marginRight: "8px",
              backgroundColor: params.row.fidDisabled
                ? theme.palette.grey[400]
                : "#6f0d6a",
              pointerEvents: params.row.fidDisabled ? "none" : undefined,
            }}
            disabled={
              params.row.fidDisabled ||
              params.row.deliveryStatus === "assigned" ||
              params.row.status === "delivered"
            }
          >
            FID
          </Button>
          <Button
            variant="contained"
            onClick={() => handleUpdateDeliveryType(params.row.id, "FED")}
            style={{
              backgroundColor: params.row.fedDisabled
                ? theme.palette.grey[400]
                : "#3c006a",
              pointerEvents: params.row.fedDisabled ? "none" : undefined,
            }}
            disabled={
              params.row.fedDisabled ||
              params.row.deliveryStatus === "assigned" ||
              params.row.status === "delivered"
            }
          >
            FED
          </Button>
          <Tooltip title="View Details">
            <IconButton
              color="default"
              onClick={() => handleViewDetails(params.row.id)}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <div style={{ backgroundColor: backgroundColor, height: "max-content" }}>
      <div style={{ padding: "16px" }}>
        <Box display="flex" mb={3}>
          <Typography variant="h4" fontWeight="bolder">
            Caterer Orders
          </Typography>
          <Box ml={3} mt={1}>
            <BreadcrumbsComponent />
          </Box>
        </Box>

        {loading ? (
          <CircularProgress />
        ) : (
          <Container sx={{ minWidth: "100%" }}>
            <CatererOrdersTable rows={orderData} columns={columns} />
          </Container>
        )}
      </div>

      {/* Reject Order Dialog */}
      <Dialog
        open={rejectDialogOpen}
        onClose={() => setRejectDialogOpen(false)}
      >
        <DialogTitle>Reject Order</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <RadioGroup
              value={rejectReason}
              onChange={(e) => setRejectReason(e.target.value)}
            >
              <FormControlLabel
                value="Unavailable on the selected Date"
                control={<Radio />}
                label="Unavailable on the selected Date"
              />
              <FormControlLabel
                value="Overbooked with existing orders"
                control={<Radio />}
                label="Overbooked with existing orders"
              />
              <FormControlLabel
                value="Menu customization not possible"
                control={<Radio />}
                label="Menu customization not possible"
              />
              <FormControlLabel
                value="Order placed too late"
                control={<Radio />}
                label="Order placed too late"
              />
              <FormControlLabel
                value="Items out of Stock"
                control={<Radio />}
                label="Items out of Stock"
              />
              <FormControlLabel
                value="Other"
                control={<Radio />}
                label="Other"
              />
            </RadioGroup>
          </FormControl>
          {rejectReason === "Other" && (
            <TextField
              label="Custom Reject Reason"
              fullWidth
              margin="normal"
              value={customRejectReason}
              onChange={(e) => setCustomRejectReason(e.target.value)}
            />
          )}
          <Button
            variant="contained"
            color="secondary"
            onClick={handleRejectOrder}
          >
            Confirm Reject
          </Button>
        </DialogContent>
      </Dialog>

      {/* Render CustomSnackbar */}
      <CustomSnackbar
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      />
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Details</DialogTitle>
        <DialogContent>
          <Typography>{dialogContent}</Typography>
        </DialogContent>
        <Button onClick={handleCloseDialog} color="primary">
          Close
        </Button>
      </Dialog>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            width: 300,
            margin: "auto",
            padding: 2,
            backgroundColor: "white",
            borderRadius: 1,
          }}
        >
          <Typography variant="h6" mb={2}>
            Assign Delivery Partner
          </Typography>
          <FormControl fullWidth>
            <Select
              value={selectedPartner}
              onChange={(e) => setSelectedPartner(e.target.value)}
              displayEmpty
            >
              <MenuItem value="" disabled>
                Select a Delivery Partner
              </MenuItem>
              {deliveryPartners.map((partner) => (
                <MenuItem key={partner._id} value={partner._id}>
                  {partner.fullName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAssignPartner}
            sx={{ marginTop: 2 }}
            disabled={!selectedPartner}
          >
            Assign
          </Button>
        </Box>
      </Modal>
       {/* Dialog to enter the receipt number */}
       <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Enter Receipt Number</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Receipt Number"
            fullWidth
            variant="outlined"
            value={receiptNumber}
            onChange={(e) => setReceiptNumber(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleSubmitReceipt}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CatererOrders;

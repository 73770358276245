import baseUrl from "../../config";

// Function to fetch caterers
export const fetchCaterers = async () => {
  try {
    const response = await fetch(`${baseUrl}getAllCaterers`);
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      throw new Error(data.message || "Failed to fetch caterers");
    }
  } catch (error) {
    console.error("Error fetching caterers:", error);
    throw error;
  }
};

export const registerCaterer = async (formDataObj) => {
  try {
    const response = await fetch(`${baseUrl}caterer/register`, {
      method: "POST",
      body: formDataObj,
    });

    const responseData = await response.json(); // Parse JSON response
    return { success: response.ok, data: responseData };
  } catch (error) {
    console.error("Fetch Error:", error);
    return { success: false, error: "Network error. Please try again." };
  }
};

// Function to fetch caterer details using fetch API
export const fetchCatererDetails = async (catererId) => {
  try {
    const response = await fetch(`${baseUrl}caterer/${catererId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    // Check if the response is ok
    if (!response.ok) {
      throw new Error(`Error fetching caterer details: ${response.statusText}`);
    }

    const data = await response.json(); // Parse JSON response
    return data;
  } catch (error) {
    console.error("Error fetching caterer details:", error);
    throw error;
  }
};

export const fetchCatererPackage = async (id) => {
  try {
    const response = await fetch(`${baseUrl}catererPackages/${id}`);

    // Check if the response is ok (status in the range 200-299)
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json(); // Parse the JSON data
    return data; // Return the fetched data
  } catch (error) {
    console.error("Error fetching caterer package:", error);
    throw error; // Handle error appropriately
  }
};

// Function to fetch package details by ID
export const fetchPackageDetails = async (id) => {
  try {
    const response = await fetch(`${baseUrl}catPackages/${id}`);

    // Check if the response is OK (status in the range 200-299)
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json(); // Parse JSON response
    return data; // Return the data from the response
  } catch (error) {
    console.error("Error fetching package details:", error);
    throw error; // Rethrow the error to handle it in the calling function
  }
};

export const fetchCatererById = async (catererId) => {
  const response = await fetch(`${baseUrl}caterers/${catererId}`);
  if (!response.ok) {
    throw new Error("Failed to fetch Caterers details");
  }
  return await response.json();
};

// Function to update caterer details
export const updateCatererById = async (catererId, formDataObj) => {
  try {
    const response = await fetch(`${baseUrl}caterer/update/${catererId}`, {
      method: "PUT", // Use PUT for updates
      body: formDataObj,
    });

    const responseData = await response.json(); // Parse the JSON response
    if (!response.ok) {
      throw new Error(responseData.message || "Failed to update caterer");
    }
    return { success: true, data: responseData }; // Return success with the response data
  } catch (error) {
    console.error("Error updating caterer:", error);
    return { success: false, error: "Network error. Please try again." };
  }
};

// Function to delete caterer by ID
export const deleteCatererById = async (catererId, deleteReason) => {
  try {
    const response = await fetch(`${baseUrl}caterer/delete/${catererId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ deleteReason }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to delete caterer");
    }

    return { success: true, message: "Caterer deleted successfully" };
  } catch (error) {
    console.error("Error deleting caterer:", error);
    return {
      success: false,
      error: "Failed to delete caterer. Please try again.",
    };
  }
};

// Function to fetch caterers
export const fetchDeletedCaterers = async () => {
  try {
    const response = await fetch(`${baseUrl}deletedCaterers`);
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      throw new Error(data.message || "Failed to fetch caterers");
    }
  } catch (error) {
    console.error("Error fetching caterers:", error);
    throw error;
  }
};

export const fetchCatererOrdersById = async (catererId) => {
  try {
    const response = await fetch(
      `https://admin.fesste.com/api/orders/caterer/${catererId}`
    );
    const data = await response.json();

    // Map the API response to match the columns
    const mappedData = data.map((order, index) => ({
      id: order?._id,
      siNo: index + 1,
      catererId: order.catererId?._id,
      orderId: order?.orderID,
      customerName: order?.userId?.fullName,
      orderType: order.status === "Delivered" ? "Delivery" : "Pickup",
      deliveryPerson: "N/A",
      caterer: order?.catererId?.businessName,
      status: order.status,
      packageType: order?.items?.[0]?.packageId?.packageName,
      quantity: order?.items?.[0]?.guestCount,
      deliveryDate: order?.items?.[0]?.deliveryDate,
      address: `${order?.catererId?.address?.street}, ${order?.catererId?.address?.city}, ${order?.catererId?.address?.state} - ${order?.catererId?.address?.postalCode}`,
      deliveryType: order?.deliveryType,
      rejectReason: order?.rejectReason,
      paymentStatus: order?.finalPaymentSchedule?.status,
      date: new Date(order.createdAt).toLocaleDateString(),
      shippingAddress: `${order?.shippingAddress?.street}, ${order?.deliveryAddress?.city}, ${order?.deliveryAddress?.state} - ${order?.deliveryAddress?.postalCode}`,
      paymentMode: order.paymentMethod || "N/A",
      total: `₹${order?.grossAmount}`,
      totalAmount: `₹${order?.totalAmount}`,
      orderDate: new Date(order?.createdAt).toLocaleDateString(),
      deliveredBy: order?.deliveryPerson || "Not Assigned",
      discountObtained: order?.discountAmount || 0,
      couponCodeUsed: order?.couponCode || "None",
      discountAmount: order?.discountAmount || 0,
      userId: order?.userId?._id,
    }));
    return mappedData;
  } catch (error) {
    console.error("Error fetching orders:", error);
    throw error;
  }
};

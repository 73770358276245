import React, { useEffect, useState } from 'react';
import { Grid, Paper, Typography, Box } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import RestaurantMenuOutlinedIcon from '@mui/icons-material/RestaurantMenuOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';

const DashboardStatistics = () => {
    // State to hold the statistics data fetched from the API
    const [stats, setStats] = useState({
        totalCaters: 0,
        totalRestaurants: 0,
        totalDeliveryPartners: 0,
        totalCustomers: 0,
        fessteMart: 0,
        totalPartyHall: 0,
        totalFlowers: 0,
        totalLightings: 0,
        totalMarquees: 0,
        totalOrders: 0,
        totalCatererOrders: 0,
        totalRestaurantOrders: 0,
        totalFessteMartOrders: 0
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://admin.fesste.com/api/dashboard/analytics');
                const data = await response.json();
                
                // Update state with fetched data, using optional chaining in case some properties are missing
                setStats({
                    totalCaters: data?.data?.totalCaterers ?? 0,
                    totalRestaurants: data?.data?.totalRestaurant ?? 0,
                    totalDeliveryPartners: data?.data?.deliveryPartner ?? 0,
                    totalCustomers: data?.data?.totalCustomers ?? 0,
                    fessteMart: data?.data?.totalFessteMart ?? 0,
                    totalFlowers:data?.data?.totalFlowers ?? 0,
                    totalLightings:data?.data?.totalLightings ?? 0,
                    totalMarquees: data?.data?.totalMarquees ?? 0,
                    totalOrders:data?.data?.totalOrders ?? 0,
                    totalPartyHall: data?.data?.totalPartyHall,
                    totalRestaurantOrders: data?.data?.totalRestaurantOrders,
                    totalCatererOrders: data?.data?.totalCatererOrders,
                    totalFessteMartOrders: data?.data?.totalFessteMartOrders
                });
            } catch (error) {
                console.error('Error fetching dashboard analytics:', error);
            }
        };
    
        fetchData();
    }, []);
    
    
    // Define the icons and labels
    const statsList = [
        { icon: <RestaurantMenuOutlinedIcon style={{ color: '#F21374' }} />, label: 'Total Caters', value: stats.totalCaters },
        { icon: <RestaurantIcon style={{ color: '#F2A774' }} />, label: 'Total Restaurants', value: stats.totalRestaurants },
        { icon: <LocalShippingIcon style={{ color: '#7AE582', }} />, label: 'Total Delivery Partners', value: stats.totalDeliveryPartners },
        { icon: <PeopleIcon style={{ color: '#65C6C4' }} />, label: 'Total Customers', value: stats.totalCustomers },
        { icon: <ShoppingCartOutlinedIcon style={{ color: '#FFB6C1' }} />, label: 'Fesste Mart', value: stats.fessteMart },
        { icon: <LocalMallIcon style={{ color: '#FFD700' }} />, label: 'Total Orders', value: stats.totalOrders },
        { icon: <RestaurantMenuOutlinedIcon style={{ color: '#7AE582' }} />, label: 'Total Caterer Orders', value: stats.totalCatererOrders },
        { icon: <RestaurantIcon style={{ color: '#FF4500' }} />, label: 'Total Restaurant Orders', value: stats.totalRestaurantOrders },
        { icon: <ShoppingCartOutlinedIcon style={{ color: '#FFD700' }} />, label: 'Total FessteMart Orders', value: stats.totalFessteMartOrders },
        { icon: <LocalFloristIcon style={{ color: '#7AE582' }} />, label: 'Total Flower Vendors', value: stats.totalFlowers },
        { icon: <LightbulbIcon style={{ color: '#FF4500' }} />, label: 'Total Lighing Vendors', value: stats.totalLightings },
        { icon: <EventAvailableIcon style={{ color: '#FF4500' }} />, label: 'Total Marquee Vendors', value: stats.totalMarquees },
        { icon: <MeetingRoomIcon style={{ color: '#FF4500' }} />, label: 'Total Party Halls', value: stats.totalPartyHall },
    ];  

    return (
        <Paper elevation={0} style={{ padding: '10px', borderRadius: '5px', maxWidth: '100%', overflowX: 'auto' }}>
            <Grid container spacing={2} wrap="wrap">
                {statsList.map((stat, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}> {/* Adjusting md to 3 so it takes 25% of the width on larger screens */}
                        <Box
                            display="flex"
                            alignItems="center"
                            style={{
                                backgroundColor: '#f9f9f9',
                                padding: '10px',
                                borderRadius: '10px',
                                boxSizing: 'border-box',
                                overflow: 'hidden', // Prevent overflow
                            }}
                        >
                            <Box mr={2}>{stat.icon}</Box>
                            <Box>
                                <Typography variant="h6" style={{ fontWeight: 'bold', color: '#333' }}>
                                    {stat.value}
                                </Typography>
                                <Typography variant="caption" style={{ color: '#777' }}>
                                    {stat.label}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Paper>
    );
};

export default DashboardStatistics;

import React, { useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import CatererOrders from './CatererOrders';
import RestaurantOrders from './RestaurantOrders';
import FessteMartOrders from './FessteMartOrders';
import AdminDrawer from '../../../components/AdminDrawer/AdminDrawer';
import BreadcrumbsComponent from '../../../components/ResuableComponents/BreadCrumb';

const OngoingOrders = () => {
    const [value, setValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
            <AdminDrawer>
               <BreadcrumbsComponent />
                {/* Optional: Box can be used for layout control */}
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                    <Tabs value={value} onChange={handleTabChange} aria-label="menu tabs">
                        <Tab label="Caterer Orders" />
                        <Tab label="Restaurant Orders" />
                        <Tab label="Fesste Mart Orders" />
                    </Tabs>

                    {/* Conditional rendering of order components based on the selected tab */}
                    {value === 0 && <CatererOrders />}
                    {value === 1 && <RestaurantOrders />}
                    {value === 2 && <FessteMartOrders />}
                </Box>
            </AdminDrawer>
    );
};

export default OngoingOrders;

import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CatererForm from "./CatererForm";
import { Grid, Paper, Typography, Box } from "@mui/material";
import AdminDrawer from "../../../components/AdminDrawer/AdminDrawer";
import CustomSnackbar from "../../../components/CustomeSnackBar/CustomSnackbar";
import {
  registerCaterer,
  fetchCatererById,
  updateCatererById,
} from "../../../api/servicePartners/CaterersApis"; // Import updateCatererById
import BreadcrumbsComponent from "../../../components/ResuableComponents/BreadCrumb";

const AddCateres = () => {
  const [selectedAddress, setSelectedAddress] = useState("");
  const [formData, setFormData] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const navigate = useNavigate();
  const { id } = useParams(); // Assuming you pass the ID via route params

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchCatererById(id);
        const prefilledData = {
          ownerName: response.serviceProvider.spName,
          mobileNo: response.serviceProvider.spPhone,
          emailID: response.serviceProvider.spEmail,
          entityName: response.businessName,
          entityPhoneNumber: response.businessPhoneNumber,
          panNo: response.panNo,
          gstNo: response.gstNo,
          fssaiNo: response.fssaiNo,
          street: response.address.street,
          city: response.address.city,
          postalCode: response.address.postalCode,
          lat: response.businessLocation.lat,
          long: response.businessLocation.long,
          addressType: response.addressType,
          address: response?.address,
          serviceRange: response.serviceRange,
          waitTime: response.waitTime,
          deliveryTime: response.deliveryTime,
          aboutUs: response.aboutUs,
          specializedAreas: response.specializedAreas,
          flowerFilter: response.flowerFilter,
          ownerPhoto: response.ownerPhoto,
          panImage: response.panImage,
          gstImage: response.gstImage,
          fssaiImage: response.fssaiImage,
          bussinessImage: response.businessProfilePhoto,
          businessVideo: response.businessVideo,
        };
        setFormData(prefilledData);
        setSelectedAddress(
          `${response.address.street}, ${response.address.city}`
        );
      } catch (error) {
        console.error("Error fetching caterer data:", error);
      }
    };
    if (id) {
      fetchData();
    }
  }, [id]);

  const handleAddressChange = (address) => {
    setSelectedAddress(address);
  };

  const handleSubmit = async (formData) => {
    const formDataObj = new FormData();
    formDataObj.append("spName", formData.ownerName);
    formDataObj.append("spPhone", formData.mobileNo);
    formDataObj.append("spEmail", formData.emailID);
    if (formData.ownerPhoto instanceof File) {
      formDataObj.append("ownerPhoto", formData.ownerPhoto);
    }
    if (formData.panImage instanceof File) {
      formDataObj.append("panImage", formData.panImage);
    }
    if (formData.gstImage instanceof File) {
      formDataObj.append("gstImage", formData.gstImage);
    }
    if (formData.fssaiImage instanceof File) {
      formDataObj.append("fssaiImage", formData.fssaiImage);
    }
    if (formData.bussinessImage instanceof File) {
      formDataObj.append("businessProfilePhoto", formData.bussinessImage);
    }
    if (formData.businessVideo instanceof File) {
      formDataObj.append("businessVideo", formData.businessVideo);
    }
    formDataObj.append("panNo", formData.panNo);
    formDataObj.append("gstNo", formData.gstNo);
    formDataObj.append("fssaiNo", formData.fssaiNo);
    formDataObj.append("lat", formData.lat || "");
    formDataObj.append("long", formData.long || "");
    formDataObj.append("businessName", formData.entityName);
    formDataObj.append("businessPhoneNumber", formData.entityPhoneNumber);
    formDataObj.append("addressType", formData.addressType || "");
    formDataObj.append("address[street]", formData.street || "");
    formDataObj.append("address[city]", formData.city || "");
    formDataObj.append("address[postalCode]", formData.postalCode || "");
    formDataObj.append("serviceRange", formData.serviceRange || "");
    formDataObj.append("waitTime", formData.waitTime || "");
    formDataObj.append("deliveryTime", formData.deliveryTime || "");
    formDataObj.append("aboutUs", formData.aboutUs || "");
    formData.specializedAreas.forEach((area) =>
      formDataObj.append("specializedAreas[]", area)
    );
    formData.flowerFilter.forEach((filter) =>
      formDataObj.append("flowerFilter[]", filter)
    );

    // Check if ID exists to decide between updating or registering
    let result;
    if (id) {
      result = await updateCatererById(id, formDataObj); // Call update API if ID exists
    } else {
      result = await registerCaterer(formDataObj); // Otherwise call the register API
    }

    if (result.success) {
      setSnackbarMessage(
        id ? "Caterer updated successfully!" : "Caterer added successfully!"
      );
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      setTimeout(() => {
        navigate("/caterers");
      }, 500);
    } else {
      setSnackbarMessage(
        result.data?.message || "Failed to save caterer. Please try again."
      );
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div className="AddCateres">
      <AdminDrawer>
        <Paper
          elevation={0}
          sx={{
            margin: "0 auto",
            padding: "20px",
            backgroundColor: "white",
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* Flex container for Breadcrumbs and Heading */}
          <Box
            sx={{
              display: "flex",

              alignItems: "center",
              mb: 3,
            }}
          >
            {/* Heading */}
            <Typography variant="h5" fontWeight="bold" sx={{mr:5}}>
              {id ? "Edit Caterer Details" : "Add Caterer Details"}
            </Typography>

            {/* Breadcrumbs beside the heading */}
            <BreadcrumbsComponent />
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CatererForm
                selectedAddress={selectedAddress}
                handleAddressChange={handleAddressChange}
                onSubmit={handleSubmit}
                initialValues={formData} // Pass the initial values to CatererForm
              />
            </Grid>
          </Grid>
        </Paper>
      </AdminDrawer>

      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </div>
  );
};

export default AddCateres;

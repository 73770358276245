import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AdminDrawer from "../../../components/AdminDrawer/AdminDrawer";
import {
  Typography,
  styled,
  Paper,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Tooltip,
  IconButton,
  Box,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DataTable from "../../../components/DataTables/DataTable";
import CustomSnackbar from "../../../components/CustomeSnackBar/CustomSnackbar";
import { useDialog } from "../../../hooks/useDialog";
import { renderCellWithMore } from "../../../components/ResuableComponents/ViewMoreComponents/ViewMorePopup";
import BreadcrumbsComponent from "../../../components/ResuableComponents/BreadCrumb";

const TableContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  maxHeight: "100vh",
  height: "650px",
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[0],
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1),
    height: "auto",
  },
}));

const PartyHallMenu = () => {
  const { dialogContent, dialogOpen, handleOpenDialog, handleCloseDialog } =
    useDialog();

  const navigate = useNavigate(); // Initialize navigate
  const [formatdata, setFormatData] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  useEffect(() => {
    const fetchPartyHalls = async () => {
      try {
        const response = await fetch(
          "https://admin.fesste.com/api/partyHallSp"
        );
        const jsonData = await response.json();

        // Transform the fetched data to match your column structure
        const transformedData = jsonData.map((partyHall) => ({
          id: partyHall._id,
          partyHallId: partyHall?.partyHallID,
          fessteMartId: partyHall?.fessteMart?._id,
          businessName: partyHall?.businessName, // Already included
          businessImage: partyHall?.businessProfilePhoto[0], // Take the first image
          businessPhoneNumber: partyHall?.businessPhoneNumber,
          address: `${partyHall?.address?.addressLine1}, ${partyHall?.address?.addressLine2}, ${partyHall?.address?.landmark}, ${partyHall?.address?.city}, ${partyHall?.address?.postalCode}, ${partyHall?.address?.state}, ${partyHall?.address?.country}`,
          spName: partyHall?.serviceProvider?.spName,
          spEmail: partyHall?.serviceProvider?.spEmail,
          spPhone: partyHall?.serviceProvider?.spPhone,
          createdOn: new Date(partyHall?.createdAt).toLocaleDateString(), // Formatting created date
          // Extracting menu details - you can customize this logic as per your need
          menu:
            partyHall?.menu?.halls
              ?.map((hall) => hall.partyHallType)
              .join(", ") || "N/A",
        }));

        setFormatData(transformedData);
      } catch (error) {
        console.error("Error fetching PartyHalls:", error);
      }
    };

    fetchPartyHalls();
  }, []);

  console.log("formatdata", formatdata);

  const handleViewClick = (partyHallId, id) => {
    navigate(`/view-party-halls/${partyHallId}`, { state: { id } });
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const columns = [
    {
      field: "partyHallId",
      headerName: "ID",
      minWidth: 10,
      sortable: false,
      renderCell: (params) => {
        const text = params.value || ""; // Ensure value is a string

        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              handleViewClick(params.row.partyHallId, params.row.id)
            }
          >
            {text}
          </div>
        );
      },
    },
    {
      field: "businessImage",
      headerName: "Business Image",
      type: "image",
      minWidth: 160,
      sortable: false,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <img
            src={
              params.value !== "N/A"
                ? params.value
                : "placeholder-image-url.jpg"
            } // Placeholder for broken images
            alt="Business"
            style={{
              width: "100px",
              height: "60px",
              borderRadius: "4px",
              objectFit: "cover",
            }}
          />
        </div>
      ),
    },
    {
      field: "businessName",
      headerName: "Business Name",
      minWidth: 250,
      sortable: true,
      renderCell: (params) => {
        const text = params.value || "";

        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              handleViewClick(params.row.partyHallId, params.row.id)
            }
          >
            {text}
          </div>
        );
      },
    },
    {
      field: "menu",
      headerName: "Menu Details",
      minWidth: 300,
      sortable: true,
      renderCell: (params) => {
        const text = params.value || "N/A";
        return <div>{text}</div>;
      },
    },
    {
      field: "businessPhoneNumber",
      headerName: "Business Contact",
      minWidth: 250,
      sortable: true,
    },
    {
      field: "spName",
      headerName: "Owner Name",
      minWidth: 250,
      sortable: true,
    },
    {
      field: "spEmail",
      headerName: "Owner Email",
      minWidth: 250,
      sortable: true,
    },
    {
      field: "spPhone",
      headerName: "Owner Contact",
      minWidth: 250,
      sortable: true,
    },
    {
      field: "address",
      headerName: "Address",
      minWidth: 250,
      sortable: true,
      type: "text",
      renderCell: (params) => {
        const text = params.value || "";
        return renderCellWithMore(text, handleOpenDialog);
      },
    },
    {
      field: "createdOn",
      headerName: "Created On",
      minWidth: 250,
      sortable: true,
      renderCell: (params) => {
        const text = params.value || "";
        return renderCellWithMore(text, handleOpenDialog);
      },
    },
    {
      field: "Actions",
      headerName: "Actions",
      minWidth: 160,
      sortable: false,

      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Tooltip title="View">
            <IconButton
              sx={{ color: "#87CEEB", marginRight: "8px" }}
              onClick={() =>
                handleViewClick(params.row.partyHallId, params.row.id)
              }
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div style={{ backgroundColor: "#f2f2f2" }}>
      <AdminDrawer>
        <Box display="flex">
          <Typography variant="h4" fontWeight="bolder">
            Party Halls
          </Typography>
          <Typography mt={1} ml={3}>
            <BreadcrumbsComponent />
          </Typography>
        </Box>

        <TableContainer>
          <DataTable rows={formatdata} columns={columns} />
          <CustomSnackbar
            open={snackbarOpen}
            message={snackbarMessage}
            severity={snackbarSeverity}
            onClose={handleCloseSnackbar}
          />
        </TableContainer>
        <Dialog open={dialogOpen} onClose={handleCloseDialog}>
          <DialogTitle>Details</DialogTitle>
          <DialogContent>
            <Typography>{dialogContent}</Typography>
          </DialogContent>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </Dialog>
      </AdminDrawer>
    </div>
  );
};

export default PartyHallMenu;

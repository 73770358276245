import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Typography, Box, CircularProgress, Paper, Grid, } from "@mui/material";
import AdminDrawer from "../../../../components/AdminDrawer/AdminDrawer";
import BASE_URL from "../../../../config";
import BreadcrumbsComponent from "../../../../components/ResuableComponents/BreadCrumb";

const ViewLightings = () => {
    const { state } = useLocation();
    const { id } = state || {};
    const [loading, setLoading] = useState(true);
    const [lightingDetails, setLightingDetails] = useState(null);

    useEffect(() => {
        const fetchLightingDetails = async () => {
            if (id) {
                try {
                    const response = await fetch(`${BASE_URL}lightingSp/${id}`);
                    const data = await response.json();
                    setLightingDetails(data);
                } catch (error) {
                    console.error("Error fetching lighting details:", error);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchLightingDetails();
    }, [id]);


    if (loading) {
        return <CircularProgress />;
    }

    return (
        <AdminDrawer>
            <BreadcrumbsComponent />
            <Paper elevation={3} style={{ padding: "20px", backgroundColor: "#f9f9f9" }}>
                <Typography variant="h4" gutterBottom>
                    Lighting Details: {lightingDetails?.lightingID || "Unknown Lighting"}
                </Typography>
                <Box mt={2}>
                    <Box>
                        <Typography variant="h5" gutterBottom>
                            Menu Items
                        </Typography>
                        {lightingDetails?.menu?.lights && lightingDetails.menu.lights.length > 0 ? (
                            <Grid container spacing={2}>
                                {lightingDetails.menu.lights.map((lights) => (
                                    <Grid item xs={12} md={6} key={lights._id}>
                                        <Paper elevation={2} style={{ padding: '16px' }}>
                                            <Typography variant="h6">{lights.lightingType.typeName}</Typography>
                                            <img src={lights.lightingType.images[0]} alt={lights.lightingType.typeName} width="100" />
                                            <Typography mt={2}>Color: {lights.lightingType.color}</Typography>
                                            <Typography>Area (Sqft): {lights.lightingType.areainsqft}</Typography>
                                            <Typography>Price: ₹{lights.lightingType.price}</Typography>
                                            <Typography>Minimum Price: ₹{lights.lightingType.minPrice}</Typography>
                                            <Typography>Maximum Price: ₹{lights.lightingType.maxPrice}</Typography>
                                           
                                            <Typography mt={2} mb={2}>Description: {lights.lightingType.description}</Typography>
                                            <Typography>Available: {lights.lightingType.availability ? "Yes" : "No"}</Typography>
                                        </Paper>
                                    </Grid>
                                ))}
                            </Grid>
                        ) : (
                            <Typography>No Lightings available</Typography>
                        )}
                    </Box>
                </Box>
            </Paper>
        </AdminDrawer>
    );
};

export default ViewLightings;

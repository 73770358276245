import React from 'react';
import { Box, Typography, Tooltip } from '@mui/material';

const OrderCustomerDetails = ({ customerName, customerAddress, customerPhone }) => {
    const fullAddress = `${customerAddress?.street || ''}, ${customerAddress?.city || ''}, ${customerAddress?.pincode || ''}`.replace(/, , /g, ', ').trim();

    return (
        <Box sx={{ mt: 3 }}>
            <Typography variant="h6" fontWeight="bold" gutterBottom>Bill To:</Typography>
            <Typography>{customerName}</Typography>
            
            <Tooltip title={fullAddress} placement="top" arrow>
                <Typography 
                    variant="body2"
                    noWrap
                    sx={{ 
                        maxWidth: 250, // Adjust this to fit the desired width before truncation
                        overflow: 'hidden', 
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        cursor: 'pointer' // Adds a pointer cursor to indicate interactivity
                    }}
                >
                    Restaurant Address: {customerAddress}
                </Typography>
            </Tooltip>
            
            <Typography>{customerPhone}</Typography>
        </Box>
    );
};

export default OrderCustomerDetails;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminDrawer from '../../components/AdminDrawer/AdminDrawer';
import { Typography, Paper, Button, Tooltip, IconButton, styled, Skeleton, Grid, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LockResetIcon from '@mui/icons-material/LockReset';
import AddIcon from '@mui/icons-material/Add';
import DataTable from '../../components/DataTables/DataTable';
import { fetchRolesData, deleteRole, resetPassword, deletesaRole, resetsaPassword } from '../../api/userRoles/roles';
import CustomSnackbar from '../../components/CustomeSnackBar/CustomSnackbar';
import ConfirmDialog from '../../components/ResuableComponents/ConfirmDialog';
import ResetPasswordDialog from '../../components/ResuableComponents/ResetPasswordDialog';
import BreadcrumbsComponent from '../../components/ResuableComponents/BreadCrumb';

const TableContainer = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    maxHeight: '100vh',
    height: '650px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[0],
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
        height: 'auto',
    },
}));

const ButtonContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
}));

const SkeletonTable = () => (
    <Grid container spacing={2}>
        {[...Array(5)].map((_, index) => (
            <React.Fragment key={index}>
                <Grid item xs={2}>
                    <Skeleton variant="rectangular" height={40} />
                </Grid>
                <Grid item xs={2}>
                    <Skeleton variant="rectangular" height={40} />
                </Grid>
                <Grid item xs={2}>
                    <Skeleton variant="rectangular" height={40} />
                </Grid>
                <Grid item xs={2}>
                    <Skeleton variant="rectangular" height={40} />
                </Grid>
                <Grid item xs={2}>
                    <Skeleton variant="rectangular" height={40} />
                </Grid>
                <Grid item xs={2}>
                    <Skeleton variant="rectangular" height={40} />
                </Grid>
            </React.Fragment>
        ))}
    </Grid>
);

const Roles = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [openResetDialog, setOpenResetDialog] = useState(false);

    useEffect(() => {
        const getData = async () => {
            try {
                const rolesData = await fetchRolesData();
                setData(rolesData);
            } catch (error) {
                handleErrorMessage(error);
            } finally {
                setLoading(false);
            }
        };

        getData();
    }, []);

    const handleAddStaffClick = () => {
        navigate('/add-roles');
    };

    const handleEditClick = (rowData) => {
        navigate(`/edit-roles/${rowData._id}`, { state: rowData });
    };

    const handleDeleteClick = (rowData) => {
        setSelectedRow(rowData);
        setOpenDialog(true);
    };

    const confirmDelete = async () => {
        if (selectedRow) {
            try {
                // Check if the role is 'super_admin' and use deletesaRole accordingly
                if (selectedRow.role === 'super_admin') {
                    await deletesaRole(selectedRow._id); // Call deletesaRole for super_admin
                } else {
                    await deleteRole(selectedRow._id); // Call deleteRole for other roles
                }
    
                // Update the data state by removing the deleted row
                setData(data.filter((row) => row._id !== selectedRow._id));
    
                // Set the snackbar message for success
                setSnackbarMessage('Role deleted successfully');
            } catch (error) {
                // Handle any errors that occur during deletion
                handleErrorMessage(error);
            } finally {
                // Ensure the snackbar is shown and the dialog is closed
                setSnackbarOpen(true);
                setOpenDialog(false);
            }
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleResetPasswordClick = (rowData) => {
        setSelectedRow(rowData);
        setOpenResetDialog(true);
    };

    const handleConfirmResetPassword = async (newPassword, secretKey) => {
        if (selectedRow) {
            try {
                // Check if the role is 'super_admin' and use resetsaPassword accordingly
                if (selectedRow.role === 'super_admin') {
                    // Send email, newPassword, and secretKey separately in the payload
                    await resetsaPassword({
                        email: selectedRow.email,
                        newPassword, // Send the password separately
                        secretKey,   // Send the secretKey separately
                    });
                } else {
                    // For other roles, continue using resetPassword
                    await resetPassword({
                        adminId: selectedRow._id,
                        newPassword, // Send newPassword separately
                    });
                }
    
                // Set the snackbar message for success
                setSnackbarMessage('Password reset successfully');
            } catch (error) {
                // Handle any errors that occur during the reset
                handleErrorMessage(error);
            } finally {
                // Ensure the snackbar is shown and the dialog is closed
                setSnackbarOpen(true);
                setOpenResetDialog(false);
            }
        }
    };
    

    const handleCloseResetDialog = () => {
        setOpenResetDialog(false);
    };

    const handleErrorMessage = (error) => {
        const message = error.response?.message;
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const columns = [
        { field: 'id', headerName: 'User ID', flex: 1, sortable: true },
        { field: 'name', headerName: 'Name', flex: 1, sortable: true },
        { field: 'email', headerName: 'Email', flex: 1, sortable: true },
        { field: 'phoneNumber', headerName: 'Phone Number', flex: 1, sortable: true },
        { field: 'role', headerName: 'Role', flex: 1, sortable: true },
        { field: 'createdAt', headerName: 'Register Date', flex: 1.5, sortable: true },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Tooltip title="Reset Password">
                        <IconButton onClick={() => handleResetPasswordClick(params.row)}>
                            <LockResetIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit">
                        <IconButton onClick={() => handleEditClick(params.row)}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <IconButton onClick={() => handleDeleteClick(params.row)}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            ),
        },
    ];

    return (
        <div style={{ backgroundColor: '#f2f2f2' }}>
            <AdminDrawer>
                <Box display="flex">
                    <Typography variant="h4" fontWeight="bolder">Roles</Typography>
                    <Box ml={3} mt={1}>
                        <BreadcrumbsComponent />
                    </Box>
                </Box>

                <TableContainer>
                    <ButtonContainer>
                        <Button
                            variant="contained"
                            color="success"
                            startIcon={<AddIcon />}
                            onClick={handleAddStaffClick}
                        >
                            Add Roles
                        </Button>
                    </ButtonContainer>
                    {loading ? (
                        <SkeletonTable />
                    ) : (
                        <DataTable
                            rows={data}
                            columns={columns}
                            disableRowSelected
                        />
                    )}
                </TableContainer>
                
                <ConfirmDialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    onConfirm={confirmDelete}
                    title="Confirm Deletion"
                    content="Are you sure you want to delete this role?"
                />

                <ResetPasswordDialog
                    open={openResetDialog}
                    onClose={handleCloseResetDialog}
                    onConfirm={handleConfirmResetPassword}
                    title={`Reset Password for ${selectedRow?.name}`}
                    role={selectedRow?.role}
                    email={selectedRow?.email}
                />

                <CustomSnackbar
                    open={snackbarOpen}
                    onClose={handleSnackbarClose}
                    message={snackbarMessage}
                    severity={snackbarMessage.includes('successfully') ? 'success' : 'error'}
                />
            </AdminDrawer>
        </div>
    );
};

export default Roles;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Typography, Box, Table, TableBody, TableCell, CircularProgress, TableHead, TableRow, Paper, Container, IconButton, Divider } from '@mui/material';
import { Print, Download } from '@mui/icons-material';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import AdminDrawer from '../../components/AdminDrawer/AdminDrawer';
import { getQuoteById } from '../../api/quotations/quotationsApis'; // Import the API function

const FessteMartQuotationDetails = () => {
  const { id } = useParams(); // Get the ID from the URL
  const [quotation, setQuotation] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchQuotation = async () => {
      setLoading(true);
      const data = await getQuoteById(id);
      if (data) {
        setQuotation(data);
      }
      setLoading(false);
    };

    fetchQuotation();
  }, [id]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!quotation) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography variant="h6">No quotation found.</Typography>
      </Box>
    );
  }

  console.log('quotation', quotation);

  const handlePrint = () => {
    window.print();
  };

  const handleDownload = () => {
    const content = document.getElementById('printable-content');
    const excludedElements = document.querySelectorAll('.exclude-from-download');

    // Hide the elements with the 'exclude-from-download' class
    excludedElements.forEach(element => {
      element.style.visibility = 'hidden'; // Set visibility to hidden instead of display none
    });

    html2canvas(content, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgWidth = 210;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save('quotation.pdf');

      // Restore the visibility of the excluded elements
      excludedElements.forEach(element => {
        element.style.visibility = ''; // Restore visibility
      });
    });
  };


  return (
    <AdminDrawer>
      <Container
        sx={{
          padding: 4,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          backgroundColor: '#f5f5f5',
          position: 'relative',
        }}
      >
        {/* Print Button */}
        <IconButton
          id="print-button"
          onClick={handlePrint}
          sx={{
            position: 'absolute',
            top: 16,
            right: 76,
          }}
        >
          <Print />
        </IconButton>

        {/* Download Button */}
        <IconButton
          id="download-button"
          onClick={handleDownload}
          sx={{
            position: 'absolute',
            color: 'green',
            top: 16,
            right: 16,
          }}
        >
          <Download />
        </IconButton>

        <Paper id="printable-content" sx={{ padding: 4, width: '100%', maxWidth: '900px', backgroundColor: '#fff' }}>
          {/* Quotation Header */}
          <Box mb={2}>
            <Typography variant="h6" align="center">Quotation</Typography>
          </Box>

          {/* Date and Quotation Number */}
          <Grid container direction="column" style={{ marginBottom: '20px' }}>
            <Grid item>
              <Typography>
                <strong>Quote Date:</strong>
                <Box component="span" ml={1}>
                  {new Intl.DateTimeFormat('en-GB', { dateStyle: 'medium' }).format(new Date())}
                </Box>
              </Typography>
            </Grid>

            <Grid item>
              <Typography>
                <strong>Event Start Date and Time:</strong>
                <Box component="span" ml={1}>
                  {quotation?.eventStartTime
                    ? new Intl.DateTimeFormat('en-GB', { dateStyle: 'medium', timeStyle: 'short' }).format(new Date(quotation.eventStartTime))
                    : 'N/A'}
                </Box>
              </Typography>
            </Grid>

            <Grid item>
              <Typography>
                <strong>Event End Date and Time:</strong>
                <Box component="span" ml={1}>
                  {quotation?.eventEndTime
                    ? new Intl.DateTimeFormat('en-GB', { dateStyle: 'medium', timeStyle: 'short' }).format(new Date(quotation.eventEndTime))
                    : 'N/A'}
                </Box>
              </Typography>
            </Grid>

            {/* Quotation Number aligned to flex-end */}
            <Grid item container justifyContent="flex-end" style={{ marginTop: 'auto' }}>
              <Typography>
                <strong>Quotation Number:</strong>
                <Box component="span" ml={1}>
                  0234
                </Box>
              </Typography>
            </Grid>
          </Grid>



          {/* Sender Details */}
          <Box mb={2}>
            <Typography variant="subtitle1"><strong>From:</strong></Typography>
            <Typography>Fesste (Selfeey Infotech Pvt Limited)</Typography>
            <Typography>8310811233</Typography>
            <Typography>ajay@fesste.com</Typography>
            <Typography>www.fesste.com</Typography>
          </Box>

          {/* Recipient Details */}
          <Box mb={2}>
            <Typography variant="subtitle1"><strong>To:</strong></Typography>
            <Typography>{quotation?.customer?.fullName}</Typography>
            <Typography>{quotation?.address?.street},{quotation?.address?.city},{quotation?.address?.postalCode}</Typography>
            <Typography>{quotation?.customer?.email}</Typography>
            <Typography>{quotation?.customer?.phoneNumber}</Typography>
          </Box>

          <Divider sx={{ marginY: 1, borderColor: '#000' }} />

          <Box mb={2} mt={4} textAlign="left"> {/* Set text alignment as needed */}
            <Typography variant="subtitle1" gutterBottom>
              <strong>Quotation Details</strong>
            </Typography>
            <Typography gutterBottom>
              Dear {quotation?.customer?.fullName},
            </Typography>
            <Typography>
              Thank you for choosing Fesste to assist with your upcoming event. Below is the
              quotation for the services you have selected from Fesstemart.
            </Typography>
          </Box>

          {/* Quotation Table */}
          <Table style={{ marginBottom: '20px', borderCollapse: 'collapse', width: '100%' }}>
            <TableHead>
              <TableRow>
                <TableCell style={{ border: '1px solid #ccc' }}><strong>Service</strong></TableCell>
                <TableCell style={{ border: '1px solid #ccc' }}><strong>Description</strong></TableCell>
                <TableCell align="right" style={{ border: '1px solid #ccc' }}><strong>Unit Price</strong></TableCell>
                <TableCell align="right" style={{ border: '1px solid #ccc' }}><strong>Total Price</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {quotation?.services?.map((service, index) => (
                service.serviceIds.map((serviceId, serviceIndex) => {
                  let description;

                  // Determine the description based on the mainBusiness
                  switch (quotation.mainBusiness) {
                    case "Marquee":
                      // Match more fields for a detailed description
                      description = (
                        <>
                          <Typography variant="body2" fontWeight="bold">Type:</Typography> {serviceId.typeName}
                          <br />
                          <Typography variant="body2" fontWeight="bold">Material:</Typography> {serviceId.marqueeMaterial}
                          <br />
                          <Typography variant="body2" fontWeight="bold">Area:</Typography> {serviceId.areainsqft} sqft
                          <br />
                          <Typography variant="body2" fontWeight="bold">Colors:</Typography> {serviceId.color}
                          <br />
                          <Typography variant="body2" fontWeight="bold">Description:</Typography> {serviceId.description}
                        </>
                      );
                      break;
                    case "Lighting":
                      description = (
                        <>
                          <Typography variant="body2" fontWeight="bold">Type:</Typography> {serviceId.typeName}
                          <br />
                          <Typography variant="body2" fontWeight="bold">Colors:</Typography> {serviceId.color}
                          <br />
                          <Typography variant="body2" fontWeight="bold">Description:</Typography> {serviceId.description}
                        </>
                      );
                      break;
                    case "Flowers":
                      description = (
                        <>
                          <Typography variant="body2" fontWeight="bold">Type:</Typography> {serviceId.typeName}
                          <br />
                          <Typography variant="body2" fontWeight="bold">Unit:</Typography> {serviceId.unit}
                          <br />
                          <Typography variant="body2" fontWeight="bold">Description:</Typography> {serviceId.description}
                        </>
                      );
                      break;
                    case "PartyHall":
                    default:
                      description = (
                        <>
                          <Typography variant="body2" fontWeight="bold">Description:</Typography> {serviceId.description}
                        </>
                      );
                      break;
                  }

                  return (
                    <TableRow key={`${index}-${serviceIndex}`}>
                      <TableCell style={{ border: '1px solid #ccc' }}>
                        <Typography variant="body2" fontWeight="bold">Business:</Typography> {quotation?.mainBusiness}
                      </TableCell>
                      <TableCell style={{ border: '1px solid #ccc' }}>
                        {description}
                      </TableCell>
                      <TableCell align="right" style={{ border: '1px solid #ccc' }}>
                        <Typography variant="body2" fontWeight="bold">Price:</Typography> ₹{serviceId.price}
                      </TableCell>
                      <TableCell align="right" style={{ border: '1px solid #ccc' }}>
                        <Typography variant="body2" fontWeight="bold">Total Price:</Typography> ₹{quotation?.totalPrice}
                      </TableCell>
                    </TableRow>
                  );
                })
              ))}
            </TableBody>
          </Table>

        </Paper>
      </Container>
    </AdminDrawer>
  );
};

export default FessteMartQuotationDetails;

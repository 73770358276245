import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Typography, Tabs, Tab, Box, CircularProgress, Paper, Dialog, Button, Divider, Grid, Card, TextField, DialogTitle, DialogContent, DialogActions, IconButton } from "@mui/material";
import { Download as DownloadIcon } from '@mui/icons-material';
import AdminDrawer from "../../../../components/AdminDrawer/AdminDrawer";
import baseUrl from "../../../../config";
import BreadcrumbsComponent from "../../../../components/ResuableComponents/BreadCrumb";
import VerifiedIcon from '../../../../assets/images/verified.png';
import CustomSnackbar from "../../../../components/CustomeSnackBar/CustomSnackbar";
import MarqueeOrderTab from "./MarqueeOrderTab";
import MarqueePayoutTab from "./MarqueePayoutTab";
import AdminChatBox from "../../../../components/ResuableComponents/ChatBoxes/CatererChatBox/AdminChatBox";


const MarqueeDetails = () => {
  const { state } = useLocation();
  const { id, } = state || {};
  const [loading, setLoading] = useState(true);
  const [marqueeDetails, setMarqueeDetails] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [open, setOpen] = useState(false);  // State to control the modal
  const [selectedImage, setSelectedImage] = useState(null); // State to store the selected image
  const [rejectionReason, setRejectionReason] = useState('');
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
  const [isVerified, setIsVerified] = useState('');

  // Snackbar states
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  // Snackbar close handler
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };


  useEffect(() => {
    const fetchMarqueeDetails = async () => {
      if (id) {
        try {
          const response = await fetch(`${baseUrl}marqueeSp/${id}`);
          const data = await response.json();
          setMarqueeDetails(data);
          setIsVerified(data?.isVerified)
        } catch (error) {
          console.error("Error fetching lighting details:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchMarqueeDetails();
  }, [id]);



  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if (loading) {
    return <CircularProgress />;
  }

  const { serviceProvider, fessteMart } = marqueeDetails;

  // Handle image click to open the preview
  const handleImageClick = (image) => {
    setSelectedImage(image); // Set the clicked image URL
    setOpen(true); // Open the modal
  };

  const handleDownload = async () => {
    const apiUrl = `${baseUrl}downloadFessteMarts/${marqueeDetails?.fessteMart?._id}`;

    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'documents.zip'); // Replace with actual file name if necessary
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  // Handle modal close
  const handleClose = () => {
    setOpen(false); // Close the modal
    setSelectedImage(null); // Clear the selected image
  };


  // Handle verify or unverify based on current state
  const handleVerify = async () => {
    try {
      if (!isVerified) {
        // Call API to verify the restaurant
        const response = await fetch(`https://admin.fesste.com/api/verifyFm/${marqueeDetails?.fessteMart?._id}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          console.log('response', response);

          setIsVerified(true); // Update to verified state
          setSnackbarMessage('Marquee verified successfully');
          setSnackbarSeverity("success");
        } else {
          setSnackbarMessage('Failed to verify Marquee');
          setSnackbarSeverity('error');
        }
      }
    } catch (error) {
      console.error('Error verifying marquee', error);
      setSnackbarMessage('Error verifying marquee');
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true);
    }
  };


  // Handle reject click (open dialog)
  const handleReject = () => {
    setRejectDialogOpen(true);
  };

  // Handle rejection submission
  const handleRejectSubmit = async () => {
    try {
      const response = await fetch(`https://admin.fesste.com/api/rejectFm/${marqueeDetails?.fessteMart?._id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ rejectionReason }),  // Pass rejection reason from state
      });

      if (response.ok) {
        setSnackbarMessage(`Rejected with reason: ${rejectionReason}`);
        setIsVerified(false); // Set to unverified state
      } else {
        setSnackbarMessage('Failed to reject restaurant');
        setSnackbarSeverity('error');
      }
    } catch (error) {
      console.error('Error rejecting restaurant', error);
      setSnackbarMessage('Error rejecting restaurant');
    } finally {
      setRejectDialogOpen(false);
      setSnackbarOpen(true);
    }
  };

  // Handle dialog close without submission
  const handleDialogClose = () => {
    setRejectDialogOpen(false);
  };



  return (
    <AdminDrawer>
      <BreadcrumbsComponent />
      <Paper elevation={3} style={{ padding: "20px", backgroundColor: "#f9f9f9" }}>
        <Typography variant="h4" gutterBottom>
          Marquee Details: {marqueeDetails?.marqueeID || "Unknown Lighting"}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          {/* Verify/Unverify Button */}
          <Button
            onClick={isVerified ? handleReject : handleVerify} // Handle reject if verified, else handle verify
            sx={{
              backgroundColor: isVerified ? 'transparent' : '#0e0593',
              borderRadius: '2rem',
              color: 'white',
              '&:hover': {
                backgroundColor: isVerified ? 'transparent' : '#0e0593',
              },
            }}
          >
            <Typography color='white'>
              {isVerified ? <img src={VerifiedIcon} alt="isVerify" /> : <Typography variant="body2" fontWeight='bold'>Verify</Typography>}
            </Typography>
          </Button>
        </Box>

        {/* Rejection Reason Dialog */}
        <Dialog open={rejectDialogOpen} onClose={handleDialogClose}>
          <DialogTitle>Reason for Rejection</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              placeholder="Rejection Reason"
              fullWidth
              variant="outlined"
              value={rejectionReason}
              onChange={(e) => setRejectionReason(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Cancel</Button>
            <Button onClick={handleRejectSubmit} variant="contained" color="error">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
        <Tabs value={tabValue} onChange={handleChange} centered>
          <Tab label="Marquee Details" />
          <Tab label="MarQuue Menu" />
          <Tab label="Marquee Orders" />
          <Tab label="Marquee Payout" />
        </Tabs>

        <Box mt={2}>
          {tabValue === 0 && (
            <><Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography variant="h5" gutterBottom>
                    Service Provider Details
                  </Typography>
                  <Typography><strong>Name:</strong> {serviceProvider?.spName}</Typography>
                  <Typography><strong>Phone:</strong> {serviceProvider?.spPhone}</Typography>
                  <Typography><strong>Email:</strong> {serviceProvider?.spEmail}</Typography>
                  <Divider sx={{ my: 2 }} />
                  <Typography variant="h5" gutterBottom>
                    KYC Details
                  </Typography>
                  <Typography><strong>PAN No:</strong> {marqueeDetails?.fessteMart?.panNo}</Typography>
                  <Typography><strong>GST No:</strong> {marqueeDetails?.fessteMart?.gstNo}</Typography>

                  {/* Display KYC Images */}
                  <Typography variant="h6" gutterBottom mt={2}>
                  Bank Details
                  </Typography>
                  <Typography><strong>Account Holder Name:</strong> {marqueeDetails?.bankAccountDetails?.accountHolderName}</Typography>
                  <Typography><strong>Account Number:</strong> {marqueeDetails?.bankAccountDetails?.accountNumber}</Typography>
                  <Typography><strong>Account Type:</strong> {marqueeDetails?.bankAccountDetails?.accountType}</Typography>
                  <Typography><strong>Bank Name:</strong> {marqueeDetails?.bankAccountDetails?.bankName}</Typography>
                  <Typography><strong>IFSC Code:</strong> {marqueeDetails?.bankAccountDetails?.ifscCode}</Typography>

                </Grid>

                <Grid item xs={12} md={6} mb={2}>
                  <Typography variant="h5" gutterBottom>
                    Business Details
                  </Typography>
                  <Typography><strong>Business ID:</strong> {fessteMart?.fmID}</Typography>
                  <Typography><strong>Street:</strong> {fessteMart?.address?.street}</Typography>
                  <Typography><strong>City:</strong> {fessteMart?.address?.city}</Typography>
                  <Typography><strong>Postal Code:</strong> {fessteMart?.address?.postalCode}</Typography>
                  <Typography><strong>Business Phone:</strong> {fessteMart?.businessPhoneNumber}</Typography>
                  <Typography><strong>Service Range:</strong> {fessteMart?.serviceRange} km</Typography>
                  <Typography><strong>Delivery Time:</strong> {fessteMart?.deliveryTime} hour(s)</Typography>
                  <Typography><strong>About Us:</strong> {fessteMart?.aboutUs}</Typography>

                  {/* Display Business Images */}

                </Grid>
              </Grid>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6" fontWeight="bold" sx={{ mt: 2, alignSelf: 'flex-start' }}>
                  KYC Details
                </Typography>
                <Button variant="contained"
                  sx={{ borderRadius: '2rem', alignSelf: 'flex-end' }}
                  onClick={handleDownload}>
                  Download All
                </Button>
              </Box>
            </Box>{/* Dialog for full-size image */}
              <Dialog open={open} onClose={handleClose} maxWidth="md">
                <img src={selectedImage} alt="Documents" style={{ maxHeight: '60vh', width: '50vw', objectFit: 'fill' }} />
              </Dialog><Grid container spacing={1}>

                <Grid item xs={4}>
                  <Card
                    sx={{
                      borderRadius: 2,
                      overflow: "hidden",
                      boxShadow: 1,
                      position: 'relative',
                      padding: 2,
                    }}
                  >
                    <Typography variant="body2" fontWeight="bold">
                      PAN No:
                    </Typography>
                    <Typography variant="body2">
                      {marqueeDetails.panNo}
                    </Typography>
                    {/* Pan Image with Download Icon Overlay */}
                    <Box sx={{ position: 'relative' }}>
                      <img
                        component="img"
                        style={{
                          width: '100%', // Ensure the image takes up the full card width
                          height: '180px', // Set a fixed height for the image
                          objectFit: 'fill', // Ensure the image covers the area
                          cursor: 'pointer',
                        }}
                        src={marqueeDetails?.fessteMart?.panImage} // Image URL
                        alt="Pan"
                        onClick={() => handleImageClick(marqueeDetails?.fessteMart?.panImage)} // Pass specific image URL
                      />
                      {/* Download Icon Overlay */}
                      <a href={marqueeDetails?.fessteMart?.panImage} download>
                        <IconButton
                          sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            color: 'green',
                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            '&:hover': {
                              backgroundColor: 'rgba(255, 255, 255, 0.9)',
                            },
                          }}
                        >
                          <DownloadIcon />
                        </IconButton>
                      </a>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card
                    sx={{
                      borderRadius: 2,
                      overflow: "hidden",
                      boxShadow: 1,
                      position: 'relative',
                      padding: 2,
                    }}
                  >
                    <Typography variant="body2" fontWeight="bold">
                      GST No:
                    </Typography>
                    <Typography variant="body2">
                      {marqueeDetails?.fessteMart?.gstNo}
                    </Typography>
                    {/* Gst Image with Download Icon Overlay */}
                    <Box sx={{ position: 'relative' }}>
                      <img
                        component="img"
                        src={marqueeDetails?.fessteMart?.gstImage} // Image URL
                        onClick={() => handleImageClick(marqueeDetails?.fessteMart?.gstImage)} // Pass specific image URL
                        alt="GST"
                        style={{
                          width: '100%', // Ensure the image takes up the full card width
                          height: '180px', // Set a fixed height for the image
                          objectFit: 'fill', // Ensure the image covers the area
                          cursor: 'pointer',
                        }} />
                      {/* Download Icon Overlay */}
                      <a href={marqueeDetails?.fessteMart?.gstImage} download>
                        <IconButton
                          sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            color: 'green',
                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            '&:hover': {
                              backgroundColor: 'rgba(255, 255, 255, 0.9)',
                            },
                          }}
                        >
                          <DownloadIcon fontSize="medium" />
                        </IconButton>
                      </a>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card
                    sx={{
                      overflow: "hidden",
                      boxShadow: 1,
                      padding: 2,
                    }}
                  >
                    <video
                      component="video"
                      controls
                      src={marqueeDetails?.fessteMart?.businessVideo} // Ensure this contains the correct video URL
                      alt="Business Video"
                      height="200"
                      style={{
                        width: '100%', // Ensure the image takes up the full card width
                        height: '250px', // Set a fixed height for the image
                        objectFit: 'fill', // Ensure the image covers the area
                        cursor: 'pointer',
                      }} />
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card
                    sx={{
                      borderRadius: 2,
                      overflow: 'hidden',  // Prevent the card from scrolling
                      boxShadow: 1,
                      position: 'relative',
                      padding: 2,
                      height: 'auto',  // Ensure the card can grow to fit content if needed
                    }}
                  >
                    {/* PDF Preview */}
                    <Box sx={{ position: 'relative', height: '250px' }}>  {/* Match video height */}
                      <iframe
                        src={marqueeDetails?.fessteMart?.contractUrl}  // PDF URL
                        title="Contract PDF"
                        style={{
                          width: '100%',  // Ensure the iframe takes up the full card width
                          height: '100%',  // Fill the container height (250px)
                          border: 'none',  // Remove default iframe borders
                        }}
                      />
                    </Box>

                    {/* Download Icon */}
                    <a href={marqueeDetails?.fessteMart?.contractUrl} download>
                      <IconButton
                        sx={{
                          position: 'absolute',
                          top: 8,
                          right: 20,
                          color: 'green',
                          backgroundColor: 'rgba(255, 255, 255, 0.7)',
                          '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.9)',
                          },
                        }}
                      >
                        <DownloadIcon fontSize="medium" />
                      </IconButton>
                    </a>
                  </Card>
                </Grid>
              </Grid>
              <AdminChatBox type={'Marquee'} />

            </>
          )}
          {tabValue === 1 && (
            <><Box>
              <Typography variant="h5" gutterBottom>
                Menu Items
              </Typography>
              {marqueeDetails?.menu?.marquees && marqueeDetails.menu.marquees.length > 0 ? (
                <Grid container spacing={2}>
                  {marqueeDetails.menu.marquees.map((marquee) => (
                    <Grid item xs={12} md={6} key={marquee._id}>
                      <Paper elevation={2} style={{ padding: '16px' }}>
                        <Typography variant="h6">{marquee.marqueeType.typeName}</Typography>
                        <img src={marquee.marqueeType.images[0]} alt={marquee.marqueeType.typeName} width="100" />
                        <Typography>Price: ₹{marquee.customPrice}</Typography>
                        <Typography>Description: {marquee.marqueeType.description}</Typography>
                        <Typography>Available: {marquee.availability ? "Yes" : "No"}</Typography>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Typography>No Halls available</Typography>
              )}
            </Box><AdminChatBox type={'Marquee_menu'} /></>

          )}
          {tabValue === 2 && (
            <><MarqueeOrderTab fessteMartId={fessteMart?._id} /><AdminChatBox type={'Marquee_order'} /></>

          )}
          {tabValue === 3 && (
            <><MarqueePayoutTab fessteMartId={fessteMart?._id} /><AdminChatBox type={'Marquee_payouts'} /></>

          )}
        </Box>
      </Paper>
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </AdminDrawer>
  );
};

export default MarqueeDetails;

import { Button, Typography } from '@mui/material';

export const renderCellWithMore = (text, handleOpenDialog) => (
    <div style={{
        whiteSpace: 'normal', // Allow text to wrap
        overflow: 'hidden',
        textOverflow: 'ellipsis', // Add ellipsis for overflow text
        display: '-webkit-box',
        
    }}>
        {text.length > 100 ? `${text.slice(0, 100)}...` : text}
        {text.length > 100 && (
            <Button
                variant="text"
                onClick={() => handleOpenDialog(text)} // Open dialog with the full description
            >
                <Typography fontSize='12px' variant='secondary' sx={{ textTransform: 'none'}}>more</Typography>
            </Button>
        )}
    </div>
);

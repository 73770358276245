import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  styled,
  Paper,
  Button,
  IconButton,
  Tooltip,
  Box
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AdminDrawer from "../../components/AdminDrawer/AdminDrawer";
import DataTable from "../../components/DataTables/DataTable";
import { fetchPolicies } from "../../api/contentManagement/contentManagementApis";
import BreadcrumbsComponent from "../../components/ResuableComponents/BreadCrumb";

const TableContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  maxHeight: "100vh",
  height: "650px",
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[0],
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1),
    height: "auto",
  },
}));

const ButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: theme.spacing(2),
}));

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const [policies, setPolicies] = useState([]); // State to hold fetched policies

  const handleAddPrivacyPolicy = () => {
    navigate("/add-privacy");
  };

  const columns = [
    { field: "siNo", headerName: "ID", flex: 0.2, sortable: false },
    { field: "title", headerName: "Title", flex: 1.5, sortable: true },
    {
      field: "effectiveDate",
      headerName: "Effective Date",
      flex: 1.5,
      sortable: true,
    },
    { field: "createdAt", headerName: "Created At", flex: 1.5, sortable: true },
    {
      field: "Actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Tooltip title="View">
            <IconButton color="default">
              <VisibilityIcon
                onClick={() => navigate(`/view-policy/${params.row.id}`)}
              />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  // Fetch policies on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchPolicies();
        // Map the fetched data to match the table structure
        const formattedData = data.map((policy, index) => ({
          siNo: index + 1,
          id: policy._id,
          title: policy.title,
          effectiveDate: new Date(policy.effectiveDate).toLocaleDateString(),
          createdAt: new Date(policy.createdAt).toLocaleDateString(),
        }));
        setPolicies(formattedData);
      } catch (error) {
        console.error("Error fetching policies:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div style={{ backgroundColor: "#f2f2f2", minHeight: "100vh" }}>
      <AdminDrawer>
        <Box display="flex" mb={3}>
          <Typography variant="h4" fontWeight="bolder">
            Privacy Policy
          </Typography>
          <Box ml={3} mt={1}>
            <BreadcrumbsComponent />
          </Box>
        </Box>

        <TableContainer>
          <ButtonContainer>
            <Button
              variant="contained"
              color="success"
              startIcon={<AddIcon />}
              onClick={handleAddPrivacyPolicy}
            >
              Add Privacy Policy
            </Button>
          </ButtonContainer>
          <DataTable
            rows={policies} // Use the fetched policies as rows
            columns={columns}
            onAddClick={handleAddPrivacyPolicy}
          />
        </TableContainer>
      </AdminDrawer>
    </div>
  );
};

export default PrivacyPolicy;

import React, { useState } from 'react';
import { Typography, Button, Chip} from '@mui/material';
import AdminDrawer from '../../../components/AdminDrawer/AdminDrawer';
import PayoutTable from '../../../components/DataTables/PayoutTables/PayoutTable';
import DeliveryPartnerQueriesModal from './DeliveryPartnerQueriesModal';

const initialdeliveryPartnerData = [
    { id: 1, deliveryPartnerId: "U12345", siNumber: "SI001", userName: "John Doe", phoneNumber: "9876543210", email: "john@example.com", orderId: "ORD001", serviceProviderId: "SP001", reason: "Late delivery", actionTaken: "Pending" },
    { id: 2, deliveryPartnerId: "U12346", siNumber: "SI002", userName: "Jane Smith", phoneNumber: "8765432109", email: "jane@example.com", orderId: "ORD002", serviceProviderId: "SP002", reason: "Wrong item delivered", actionTaken: "Resolved" },
    // Add more deliveryPartner data here...
];

const DeliveryPartnerQueries = () => {
    const [deliveryPartnerData, setdeliveryPartnerData] = useState(initialdeliveryPartnerData); 
    const [openModal, setOpenModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [action, setAction] = useState('');

    const handleOpenModal = (row) => {
        setSelectedRow(row);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setAction('');
    };

    const handleActionChange = (event) => {
        setAction(event.target.value);
    };

    const handleSubmit = () => {
        const updatedData = deliveryPartnerData.map((deliveryPartner) =>
            deliveryPartner.id === selectedRow.id ? { ...deliveryPartner, actionTaken: action } : deliveryPartner
        );

        setdeliveryPartnerData(updatedData);
        handleCloseModal();
    };

    const columns = [
        { field: 'id', headerName: 'SI No', flex: 0.5, sortable: false },
        { field: 'deliveryPartnerId', headerName: 'Delivery Partner Id', flex: 1 },
        { field: 'userName', headerName: 'Delivery Partner Name', flex: 1 },
        { field: 'phoneNumber', headerName: 'Phone Number', flex: 1 },
        { field: 'email', headerName: 'Email', flex: 1 },
        { field: 'orderId', headerName: 'Order ID', flex: 1 },
        { field: 'serviceProviderId', headerName: 'Service Provider ID', flex: 1 },
        { field: 'reason', headerName: 'Reason', flex: 1 },
        {
            field: 'actionTaken',
            headerName: 'Action Taken',
            flex: 1,
            renderCell: (params) => {
                let color;
                switch (params.value) {
                    case 'Pending':
                        color = 'warning';
                        break;
                    case 'Resolved':
                        color = 'success';
                        break;
                    case 'In Progress':
                        color = 'primary';
                        break;
                    default:
                        color = 'default';
                        break;
                }
                return <Chip label={params.value} color={color} />;
            },
        },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1.2,
            renderCell: (params) => (
                <Button
                    variant="outlined"
                    color="success"
                    onClick={() => handleOpenModal(params.row)}
                >
                    <Typography fontSize='12px' textTransform='none' fontWeight='bold'>View Details</Typography>
                </Button>
            ),
        },
    ];

    return (
        <div style={{ backgroundColor: '#f2f2f2', height: '100vh' }}>
            <AdminDrawer>
                    <Typography variant='h4' fontWeight='bolder'>Delivery Partner Queries</Typography>
                    <PayoutTable
                        rows={deliveryPartnerData}
                        columns={columns}
                    />
            </AdminDrawer>

            <DeliveryPartnerQueriesModal
                open={openModal}
                onClose={handleCloseModal}
                selectedRow={selectedRow}
                action={action}
                onActionChange={handleActionChange}
                onSubmit={handleSubmit}
            />
        </div>
    );
};

export default DeliveryPartnerQueries;

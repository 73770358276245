// OrderInvoiceHeader.js
import React from "react";
import { Box, Typography } from "@mui/material";

const OrderInvoiceHeader = ({
  invoiceNumber,
  invoiceDate,
  orderNumber,

  restPayoutTransactionId,
}) => {
  

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
      }}
    >
      {/* Left section for Restaurant Details */}
      <Box sx={{ textAlign: "left", flex: 1 }}>
        <Typography
          variant="h4"
          sx={{ color: "#6A1B9A", fontWeight: "bold", mb: 10 }}
        >
          Fesste
        </Typography>
        <Typography variant="body" mb={2}>
          Tax Invoice on behalf of -
        </Typography>
        <Typography variant="body2">
          <strong>Company Name:</strong> Selfeey Infotech Pvt. Ltd.
        </Typography>
        <Typography variant="body2">
          <strong>Company Address:</strong> No: 10/1, Ramamurthy Nagar Main Road, Doddabanaswadi,
          Bangalore - 560043
        </Typography>
        <Typography variant="body2"><strong>Company GST:</strong> 29NJAPE0987T1ZR</Typography>
      </Box>

      {/* Right section for Invoice Details */}
      <Box sx={{ textAlign: "right", flex: 1, mt: 8 }}>
        <Typography
          variant="h6"
          sx={{ marginBottom: "8px", fontWeight: "bold" }}
        >
          <strong>TAX INVOICE</strong>
        </Typography>
        <Typography variant="body2"><strong>ORIGINAL For Recipient</strong></Typography>
        <Typography variant="body2"><strong>Invoice No:</strong> {invoiceNumber}</Typography>
        <Typography variant="body2">
        <strong>Invoice Date:</strong> {new Date(invoiceDate).toDateString()}
        </Typography>
        <Typography variant="body2"><strong>Order No:</strong> {orderNumber}</Typography>
        {restPayoutTransactionId && (
          <Typography variant="body2">
            <strong>Transaction ID:</strong> {restPayoutTransactionId}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default OrderInvoiceHeader;
